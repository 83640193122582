<template>
    <div class="tickmaillRow d-flex align-items-center justify-content-center mt-1">
        <span class="tikmaillButton me-3 d-flex align-items-center flex-shrink-0">
            <img class="me-1" src="/assets/images/icon/ICIcon.svg" alt="ICMarkets" title="ICMarkets" />
        </span>
        <p class="mb-0 me-md-3 f-14"><strong>Action Required:</strong> Move your Account to ZuluTrade Group</p>
        <a data-bs-toggle="modal" data-bs-target="#ICMarketsModal" class="flex-shrink-0 f-13 button white_btn bg-white rounded border-button secondary border border-secondary" href="javascript:void(0)">View Details</a>
    </div>

    <!-- Modal TICKMILL -->
    <div class="modal fade max-575 tickMillModalCP" id="ICMarketsModal" tabindex="-1" aria-labelledby="exampletickmillModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-body">
                    <img class="d-block mx-auto" src="/assets/images/icon/ICShield.svg" alt="ICMarkets" title="ICMarkets" />
                    <div class="blockParrel">
                        <h6>Important Update:</h6>
                        <p>We're upgrading our connection with <span class="secondary">IC Broker.</span></p>
                    </div>
                    <div class="blockParrel">
                        <h6>Action Required:</h6>
                        <p>Please email your broker with the following text to ensure your trading account stays connected to ZuluTrade:</p>
                    </div>
                    <div class="blockParrel italic d-flex align-items-start bodyBG mb-3">
                        <p class="mb-0 me-3">
                            <i>
                                Please move my trading account [<span class="secondary">xxxxxx</span> - add trading account number here] to the ZuluTrade group
                            </i>
                        </p>
                        <div class="position-relative">
                            <button class="btn copyButton" type="button" v-clipboard:copy="body" v-clipboard:success="onCopy" v-clipboard:error="onError">
                                <span><vue-feather size="15" type="copy"></vue-feather> </span>
                            </button>
                            <span class="customTooltip" v-if="copyMsg">{{ copyMsg }}</span>
                        </div>
                    </div>
                    <div class="blockParrel d-block reminder pb-1">
                        <h6>Reminder:</h6>
                        <p>Kindly Inform us over live chat once IC Broker confirms the change, before the end of this month, <span class="secondary">to avoid disconnection from the ZuluTrade platform.</span></p>
                    </div>
                    <div class="blockParrel text-center mt-4 mb-3">
                        <a href="https://direct.lc.chat/13534029/6" target="_blank" class="bold button fillBtn zulu_btn copyStrategyTour d-inline-flex align-items-center px-md-4">
                            <img class="me-2" src="/assets/images/icon/headContact.svg" alt="TickMail" title="tickmill" /> Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                body: `Please move my trading account [xxxxxx - add trading account number here] to the ZuluTrade group`,
                copyMsg: '',
            }
        },
        methods:{
            onCopy(){
                this.copyMsg = "Copied";
                setTimeout(() => {
                    this.copyMsg = "";
                }, 3000);
            },
            onError(){
                this.copyMsg = "Unable to copy";
                    setTimeout(() => {
                        this.copyMsg = "";
                    }, 3000);
            },
        }
    }
</script>
