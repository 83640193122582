import axios from 'axios'
import { useToast } from 'vue-toastification'
import { myStore } from '@/store/pinia-store'
import router from '@/router'
import static_vars from '../staticVars.js'

const toast = useToast()

// const handleUnauthorizedToken = () => {
//     const store = myStore()
//     toast('Authentication expired. Please login again', {
//         type: 'error'
//     })
//     store.reset()
//     router.replace('/login')
// }


export const post = (url, params,token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {//for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        // headers["Referer"] = window.location.href
        // headers["Referrer-Policy"] =  "strict-origin-when-cross-origin"
        // if(static_vars.getCookie('_ga')){
        //     headers['Cookie'] = document.cookie
        // }
        if((url != 'https://betapi.portfolios.com/get/newsby/symbol') && (url != 'https://betapi.portfolios.com/get/calendar') && (url != 'https://betapi.portfolios.com/get/ticker/history') && (url != 'https://betapi.portfolios.com/get/news')){
            headers['Content-Encoding'] = 'gzip, deflate, br'
        }
        axios.post(
            url,
            params,
            { headers }
        ).then(response => {
            if (response?.data?.status === 404) {
                toast(response?.data?.message, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error?.response?.status === 401) {
                if(error?.response?.data?.message){
                    toast(error?.response?.data.message, {
                        type: 'error'
                    })
                }else{
                    toast(error?.response?.data?.error_description, {
                        type: 'error'
                    })
                    store.reset()
                    router.replace('/login')
                }
                reject(error)
            } else {
                if((url != 'https://betapi.portfolios.com/get/newsby/symbol') && (url != 'https://betapi.portfolios.com/get/calendar') && (url != 'https://betapi.portfolios.com/get/ticker/history') && (url != 'https://betapi.portfolios.com/get/news') && url != 'https://www.zulutrade.com/zulutrade-client/payments/api/checkout/customers' && url != 'https://www.zulutrade.com/zulutrade-client/api/customer/register'){
                    if(error?.response?.data?.message){
                        if(error?.response?.data?.code != 1100 && error?.response?.data?.code != 6971){
                            let showError = true;
                            if(url.includes('/customer/register') && params?.tokenType == 'GOOGLE')
                            {   
                                showError = false
                            }
                            if(showError){
                                toast(error?.response?.data?.message, {
                                    type: 'error'
                                })
                            }

                        }
                    }else{
                        toast(error?.response?.data?.error_description, {
                            type: 'error'
                        })
                    }
                }
                reject({ ...error })
            }
        })
    })
}

export const get = (url,noAuth) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if(noAuth != 'noAuth') {
            if (store.user.access_token) {
                headers['Authorization'] = `Bearer ${store.user.access_token}`
            }
        }
        // if (token && typeof token != 'object') {
        //     headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        // }
        // headers["Referer"] = window.location.href
        // headers["Referrer-Policy"] =  "strict-origin-when-cross-origin"
        // if(static_vars.getCookie('_ga')){
        //     headers['Cookie'] = document.cookie
        // }
        axios.get(
            url,
            { headers }
        ).then(response => {
            if (response.info && response.info == 'NOK') {
                toast(response?.data?.message, {
                    type: 'error'
                })
                reject(response)
            } else {
                let isBlogURL = url.includes('categories')
                if(!isBlogURL){
                    resolve(response.data)
                } else {
                    let res = {}
                    res['data'] = response.data
                    res['headers'] = response.headers
                    resolve(res)
                }
            }
        }).catch(error => {
            if (error.response?.status === 401) {
                if(error?.response?.data?.message){
                    toast(error?.response?.data?.message, {
                        type: 'error'
                    })
                }else{
                    toast(error?.response?.data?.error_description, {
                        type: 'error'
                    })
                    store.reset()
                    router.replace('/login')
                }
                reject(error)
            } else {
                if(error?.response?.data?.message){
                    toast(error?.response?.data?.message, {
                        type: 'error'
                    })
                }else{
                    let showError = true;
                    let demo_account = false;
                    if(store && store.userTradingAccountsList){
                        Object.keys(store?.userTradingAccountsList).forEach(function(acc){
                            if(store?.userTradingAccountsList[acc].demo == true){
                                demo_account = true;
                            } 
                        });
                    }
                //    console.log("demo_account", demo_account)
                    if(url.includes('flavors/api/flavors/light/secure') && demo_account == false)
                    {   
                        showError = false
                    }
                    if(showError){
                        toast(error.response.data.error_description, {
                            type: 'error'
                        })
                    }
                }
                reject({ ...error })
            }
        })
    })
}

export const DELETE = (url,params) => {
    try{
        return new Promise((resolve, reject) => {
            const store = myStore()
            const headers = {}
            if (store.user.access_token) {
                headers['Authorization'] = `Bearer ${store.user.access_token}`
            }
            headers['Content-Type'] = 'application/json'
            headers['Accept'] = 'application/json'
            headers['Content-Encoding'] = 'gzip, deflate, br'
            // headers["Referer"] = window.location.href
            // headers["Referrer-Policy"] =  "strict-origin-when-cross-origin"
            // if(static_vars.getCookie('_ga')){
            //     headers['Cookie'] = document.cookie
            // }
            axios.delete(url,{
                data : params,
                headers 
            }).then(response => {
                if (response.data.status && response.data.status === 404) {
                    toast(response?.data?.message, {
                        type: 'error'
                    })
                    reject(response.data)
                } else {
                    resolve(response.data)
                }
            }).catch(error => {
                if (error?.response?.status === 401) {
                    if(error?.response?.data?.message){
                        toast(error?.response?.data?.message, {
                            type: 'error'
                        })
                    }else{
                        toast(error?.response?.data?.error_description, {
                            type: 'error'
                        })
                        store.reset()
                        router.replace('/login')
                    }
                    reject(error)
                } else {
                    if(error?.response?.data?.message){
                        toast(error?.response?.data?.message, {
                            type: 'error'
                        })
                    }else{
                        toast(error?.response?.data?.error_description, {
                            type: 'error'
                        })
                    }
                    reject({ ...error })
                }
            })
        })
    }catch(e){
        console.log('e',e)
    }
}

export const put = (url, params,token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {//for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        headers['Content-Encoding'] = 'gzip, deflate, br'
        // headers["Referer"] = window.location.href
        // headers["Referrer-Policy"] =  "strict-origin-when-cross-origin"
        // if(static_vars.getCookie('_ga')){
        //     headers['Cookie'] = document.cookie
        // }
        axios.put(
            url,
            params,
            { headers }
        ).then(response => {
            if (response.data.status && response.data.status === 404) {
                toast(response.message, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error?.response?.status === 401) {
                if(error?.response?.data?.message){
                    toast(error?.response?.data?.message, {
                        type: 'error'
                    })
                }else{
                    toast(error?.response?.data?.error_description, {
                        type: 'error'
                    })
                    store.reset()
                    router.replace('/login')
                }
                reject(error)
            } else {
                if(error?.response?.data?.message){
                    toast(error?.response?.data?.message, {
                        type: 'error'
                    })
                }else{
                    toast(error?.response?.data?.error_description, {
                        type: 'error'
                    })
                }
                reject({ ...error })
            }
        })
    })
}