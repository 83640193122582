// import { myStore } from "@/store/pinia-store.ts";
// const store = myStore();
module.exports = [{
    "":  {
        "META_TITLE": "ZuluTrade: Social Copy Trading Platform | Copy Trader Forex",
        "META_DESCRIPTION": "We are the global leader in forex social trading. Trade manually or through copy trading top-performing leaders. Create a demo or live account today.",
        "META_KEYWORDS": "zulutrade, zulu trade, copy trader forex, social trading in forex, zulu trading, social trading platform, social trading network, forex social trading, forex trade copier, forex trading platforms",
    },
    "mobile-faqs":  {
        "META_TITLE": "FAQs: Frequently Asked Questions | ZuluTrade",
        "META_DESCRIPTION": "Get answers to all your questions about ZuluTrade's copy trading platform. Explore FAQs on fees, traders, risks, and more.",
        "META_KEYWORDS": "",
    },
    "leaders":  {
        "META_TITLE": "ZuluTrade Leaders: Follow Stock Traders | Copy Top Traders",
        "META_DESCRIPTION": "Discover top-performing leaders on ZuluTrade and start copying their trades to make profits. Choose from a wide range of Leader Strategies to follow and copy.",
        "META_KEYWORDS": "top traders, social trading community, best day traders to follow, best traders of all time, follow stock traders, traders community, stock traders to follow",
    },
    "markets":  {
        "META_TITLE": "ZuluTrade Markets | Currency & Forex Trading | Crypto Trade",
        "META_DESCRIPTION": "Get detailed market information for major and exotic currencies, including charts, quotes, and news. Stay up-to-date with the latest market trends.",
        "META_KEYWORDS": "zulu calendar, forex trading calendar, global economic calendar, european economic calendar, euro economic calendar",
    },
    // "`/trader/${store.traderDetail.trader.stats.providerId}/trading`":  {
    //     "META_TITLE": "ZuluTrade Trader {TRADER_NAME} Trading Statistics & Performance",
    //     "META_DESCRIPTION": "Explore the trading statistics and performance of ZuluTrade trader  {TRADER_NAME}. Discover their profit, drawdown, and trading history.",
    //     "META_KEYWORDS": "",
    // },
    "help-center":  {
        "META_TITLE": "ZuluTrade - Help Center",
        "META_DESCRIPTION": "Get answers to your questions about social copy trading. Browse the knowledge base, and read our guides & tutorials. Contact our support team for assistance.",
        "META_KEYWORDS": "",
    },
    "pricing":  {
        "META_TITLE": "ZuluTrade - Subscription Plan | CopyTrading Pricing Plans",
        "META_DESCRIPTION": "Explore ZuluTrade's Subscription Plan to start copy trading. Unlock the zero Subscription Plan with Integrated and selected Co-Branded Brokers. Open your account!",
        "META_KEYWORDS": "",
    },
    "user-guide":  {
        "META_TITLE": "ZuluTrade - User Guide",
        "META_DESCRIPTION": "Learn how to use ZuluTrade and social trading to reach your goals. Our user guide covers everything, from account setup to copying trades & managing risk.",
        "META_KEYWORDS": "",
    },
    "leader-guide":  {
        "META_TITLE": "ZuluTrade - Leader Guide | Create a Leader Account",
        "META_DESCRIPTION": "Become a ZuluTrade leader & earn by sharing your trades. Our leader guide covers everything, from account setup to managing followers & earning commissions.",
        "META_KEYWORDS": "leader guide, create a leader account",
    },
    "affiliate-guide":  {
        "META_TITLE": "ZuluTrade Affiliate Program Guide | Affiliate Guide",
        "META_DESCRIPTION": "ZuluTrade's comprehensive Affiliate program guide  details all the Affiliate program conditions and opportunities for Affiliates and partners.",
        "META_KEYWORDS": "affiliate guide, affiliate program guide",
    },
    "introducing-zulutrade":  {
        "META_TITLE": "Introducing ZuluTrade | Social Intelligence Platform",
        "META_DESCRIPTION": "Trade smarter, not harder. ZuluTrade's social intelligence platform connects you with traders from around the world & empowers your investment journey.",
        "META_KEYWORDS": "",
    },
    "simulation":  {
        "META_TITLE": "ZuluTrade Simulation: Leader Simulator | Trade Simulation",
        "META_DESCRIPTION": "Simulate trading ideas & test your strategies with our simulation feature. Trade with virtual money & get real-time market data to improve your trading skills.",
        "META_KEYWORDS": "leader simulator, trade simulation",
    },
    "social-feed":  {
        "META_TITLE": "ZuluTrade: Social Feed Community | Interact with 2M+ Leaders",
        "META_DESCRIPTION": "Experience the power of community on ZuluTrade Social Feed. Discover diverse trading strategies, gain valuable insights, and follow trending hashtags.",
        "META_KEYWORDS": "",
    },
    "calendar":  {
        "META_TITLE": "ZuluTrade: Global Economic Calendar",
        "META_DESCRIPTION": "Stay up to date with the latest economic events and market news with ZuluTrade's economic calendar. Get updates on market-moving events and make informed trading decisions.",
        "META_KEYWORDS": "zulu calendar, forex trading calendar, global economic calendar, european economic calendar, euro economic calendar",
    },
    "trading-tools":  {
        "META_TITLE": "ZuluTrade - Trading Tools | Forex Calculator for Traders",
        "META_DESCRIPTION": "Maximize your profits and manage your risk with ZuluTrade's trading tools. Use our calculators to make informed trading decisions. Premium trading tools.",
        "META_KEYWORDS": "",
    },
    "trading-tools/pip-calculator":  {
        "META_TITLE": "Pip Calculator | Forex Pip | Pip Value Counter - ZuluTrade",
        "META_DESCRIPTION": "Calculate the pip value for different currency pairs and position sizes effortlessly using pip calculator. Effectively manage risk and optimize profits.",
        "META_KEYWORDS": "pip calculator, pips calculator, pip value calculator, pip calculator forex, pips trading, calculate pip, forex calculator pip, pip counter, nas100 pip calculator, ticks to pips calculator",
    },
    "trading-tools/margin-calculator":  {
        "META_TITLE": "ZuluTrade - Margin Calculator | Leverage Trading Calculator",
        "META_DESCRIPTION": "Calculate leverage for each trade with margin calculator for different currency pairs & position sizes. Effectively manage risk & trade with confidence.",
        "META_KEYWORDS": "margin calculator, leverage calculator, forex leverage calculator, leverage trading calculator, margin calculator forex, margin trading calculator, forex trading margin calculator, currency margin calculator",
    },
    "trading-tools/profit-calculator":  {
        "META_TITLE": "ZuluTrade - Profit Margin Calculator | Forex | Trade Return",
        "META_DESCRIPTION": "Use the profit calculator to estimate potential gains & losses for various currency pairs & position sizes. Aiding smart trading decisions with this tool.",
        "META_KEYWORDS": "profit calculator, forex risk calculator, forex calculator profit, trade return calculator, xauusd profit calculator, us30 profit calculator, nas100 profit calculator, trading profit calculator, indices profit calculator, forex trading calculator",
    },
    "trading-tools/currency-converter":  {
        "META_TITLE": "ZuluTrade - Forex Currency Calculator | Currency Difference",
        "META_DESCRIPTION": "Calculate currency exchange rates with the ZuluTrade Forex calculator. Easily convert over 50+ currency pairs. Get accurate & reliable foreign exchange rates.",
        "META_KEYWORDS": "currency difference calculator, forex market converter, forex currency calculator, forex exchange rate calculator, forex currency converter calculator, forex currency convert, calculate currency conversions",
    },
    "trading-signals":  {
        "META_TITLE": "Copy Trading Signals | Trade Signals Forex - ZuluTrade",
        "META_DESCRIPTION": "Discover trading signals in real-time in both Forex & Crypto trading. Start following 335K ZuluTrade leaders & gain access to the world of CopyTrading signals.",
        "META_KEYWORDS": "copy trading signals, copy forex trading signals, trading signals, copy forex signals, trade signals, trade signals forex, trading signals service, trading signals online",
    },
    "about":  {
        "META_TITLE": "About Us | Who We Are - ZuluTrade",
        "META_DESCRIPTION": "Learn about ZuluTrade, the leading social copy trading platform that connects traders from all around the world. Start by creating a demo or live account.",
        "META_KEYWORDS": "",
    },
    "news-and-media":  {
        "META_TITLE": "ZuluTrade: Latest News and Media | PR Media Releases",
        "META_DESCRIPTION": "Stay up-to-date with the latest news and media coverage about ZuluTrade, the world's leading social copy trading platform.",
        "META_KEYWORDS": "",
    },
    "become-an-affiliate":  {
        "META_TITLE": "Join ZuluTrade Affiliate Program | Affiliate Trading",
        "META_DESCRIPTION": "ZuluTrade Affiliate program allows website & community managers, influencers and any content creator to monetize their audience reach. Join the Fintech Era!",
        "META_KEYWORDS": "trade affiliate, trade affiliate program, trading affiliate program, affiliate marketing trading, affiliate trading, squaretrade affiliate program",
    },
    "become-a-leader":  {
        "META_TITLE": "Become a ZuluTrade Leader | Join as Leader | Trader Account",
        "META_DESCRIPTION": "Join ZuluTrade. Become a Leader with the largest trading community. Earn from your expertise by sharing trades with others. Check our leaders’ program guide.",
        "META_KEYWORDS": "trader account, become a leader",
    },
    "partnership-program":  {
        "META_TITLE": "ZuluTrade Partnership Program | Partner With Us",
        "META_DESCRIPTION": "Join the ZuluTrade Partnership Program. Enjoy the benefits of partnering with the world's largest social wealth management platform.",
        "META_KEYWORDS": "partnership program",
    },
    "terms-of-service":  {
        "META_TITLE": "ZuluTrade - Terms of Service & Agreement",
        "META_DESCRIPTION": "Read the ZuluTrade terms of service and understand the legal agreements between you and ZuluTrade.",
        "META_KEYWORDS": "",
    },
    "privacy-policy":  {
        "META_TITLE": "ZuluTrade - Privacy Policy",
        "META_DESCRIPTION": "Read the ZuluTrade privacy policy to understand how we collect, use, and protect your personal information.",
        "META_KEYWORDS": "",
    },
    "risk-disclaimer":  {
        "META_TITLE": "ZuluTrade - Risk Disclaimer",
        "META_DESCRIPTION": "Trading foreign exchange carries a high level of risk and may not be suitable for all investors. Read the ZuluTrade risk disclaimer carefully.",
        "META_KEYWORDS": "",
    },
    "conflict-of-interest-policy":  {
        "META_TITLE": "ZuluTrade - Conflict of Interest Policy",
        "META_DESCRIPTION": "Read the ZuluTrade conflict of interest policy to understand how we manage conflicts of interest between our clients and our business.",
        "META_KEYWORDS": "",
    },
    "mobile-risk-disclaimer":  {
        "META_TITLE": "ZuluTrade - Mobile Risk Disclaimer",
        "META_DESCRIPTION": "Trading foreign exchange via mobile app  involves a high level of risk of losing money. It is essential to carefully read & understand the ZuluTrade mobile risk disclaimer.",
        "META_KEYWORDS": "",
    },
    "declaration-of-consent":  {
        "META_TITLE": "ZuluTrade - Declaration of Consent",
        "META_DESCRIPTION": "Read and accept the ZuluTrade declaration of consent to start using our social trading platform. You can withdraw the declaration of consent at any time by email.",
        "META_KEYWORDS": "",
    },
    "autoprotect-your-account":  {
        "META_TITLE": "ZuluGuard: Autoprotect Your Account | Capital Protection",
        "META_DESCRIPTION": "ZuluTrade safeguards your account with ZuluGuard, an advanced risk management tool to protect your investments automatically. Available for all investors.",
        "META_KEYWORDS": "zuluguard, capital protection",
    },
    "login":  {
        "META_TITLE": "ZuluTrade - Login to your Account | Trade on Best Social Trading Platform",
        "META_DESCRIPTION": "Log in to your ZuluTrade account and trade with the best social trading platform. Follow and copy the trades of top-performing Leaders easily.",
        "META_KEYWORDS": "",
    },
    "register":  {
        "META_TITLE": "ZuluTrade - Sign Up for Free and Trade Your Way to Success",
        "META_DESCRIPTION": "Join ZuluTrade today. Access a wide range of financial assets and resources for social trading. Sign up for free and start social trading with confidence!",
        "META_KEYWORDS": "",
    },
    "forgot-password":  {
        "META_TITLE": "ZuluTrade - Forgot Password",
        "META_DESCRIPTION": "Forgot your ZuluTrade password? No worries, we've got you covered. Enter your email address and we'll send you instructions on how to reset your password.",
        "META_KEYWORDS": "",
    },
    "zulutrade-data":  {
        "META_TITLE": "ZuluTrade Data - Analytics and Insights",
        "META_DESCRIPTION": "Access valuable analytics and insights with ZuluTrade Data. Make informed trading decisions with our comprehensive data offerings.",
        "META_KEYWORDS": "",
    },
    "settings":  {
        "META_TITLE": "Settings - Customize Your Trading Experience",
        "META_DESCRIPTION": "Customize your ZuluTrade trading experience with advanced settings and tools",
        "META_KEYWORDS": "",
    },
    "forex-trading":  {
        "META_TITLE": "Forex Trading - Trade the Global Currencies with ZuluTrade",
        "META_DESCRIPTION": "Trade forex with ZuluTrade and access a global network of expert leaders to copy their trading signals. Sign up now & experience the future of online trading.",
        "META_KEYWORDS": "forex trading platform, forex trading platforms, forex trading systems, best forex trading, best forex trading broker",
    },
    "stock-trading":  {
        "META_TITLE": "Stock Trading - Invest in Leading Global Companies, trade stocks in ZuluTrade",
        "META_DESCRIPTION": "Invest in leading global companies with ZuluTrade's stock copy trading platform",
        "META_KEYWORDS": "",
    },
    "cryptocurrency-trading":  {
        "META_TITLE": "Crypto Trading - Trade the Future of currencies",
        "META_DESCRIPTION": "Trade the future with ZuluTrade's crypto copy trading platform",
        "META_KEYWORDS": "",
    },
    "commodities-trading":  {
        "META_TITLE": "Commodities Trading - Diversify Your Portfolio",
        "META_DESCRIPTION": "Trade gold, oil, and other commodities like a pro by following and copying the trades of successful traders with ZuluTrade. Join our social trading community.",
        "META_KEYWORDS": "",
    },
    "index-trading":  {
        "META_TITLE": "Index Trading with ZuluTrade - Follow and Copy Top Traders",
        "META_DESCRIPTION": "Trade stock indices like the NAS 100,  SPX500 and more by following and copying the trades of successful traders with ZuluTrade. Join our social trading community",
        "META_KEYWORDS": "",
    },
    "automated-trading":  {
        "META_TITLE": "Automated Trading Platform | Algorithmic | Auto Trading",
        "META_DESCRIPTION": "Automate your trading by following & copying trades of expert leaders on ZuluTrade. Sign up & experience future of online trading.",
        "META_KEYWORDS": "automated trader, automated trading platform, automated trading, automated forex trading, forex automation, Algorithmic trading, Automated Trading System, Auto Trading, Automated Trading Software, Algorithmic Trading Strategies",
    },
    "newsfeed/share":  {
        "META_TITLE": "ZuluTrade - Newsfeed for the Latest Updates & Insights",
        "META_DESCRIPTION": "Stay up-to-date with the latest news and market insights on ZuluTrade newsfeed. Share your thoughts and opinions with the community. Join now!",
        "META_KEYWORDS": "",
    },
    "compare":  {
        "META_TITLE": "ZuluTrade - Compare Trading Strategies and Trading systems",
        "META_DESCRIPTION": "Compare the performance of different Leaders on ZuluTrade.Choose the best ones to copy and start your social trading journey. Join Now!",
        "META_KEYWORDS": "",
    },
    // "tutorials":  {
    //     "META_TITLE": "ZuluTrade - Tutorials",
    //     "META_DESCRIPTION": "Learn how to use ZuluTrade with our easy-to-follow tutorials. Start copying the trades of professional traders and earn profits with social copy trading. Join now!",
    //     "META_KEYWORDS": "",
    // },
    "disclaimer":  {
        "META_TITLE": "ZuluTrade - Disclaimer",
        "META_DESCRIPTION": "Read our disclaimer before using ZuluTrade. Trading involves risks and you should be aware of them before investing your money.",
        "META_KEYWORDS": "",
    },
    "help-center-questions":  {
        "META_TITLE": "ZuluTrade - Help Center | Questions",
        "META_DESCRIPTION": "Find answers to your questions and get support from our team. Our help centre is here to assist you in using ZuluTrade. Join now!",
        "META_KEYWORDS": "",
    },
    "zuluranking":  {
        "META_TITLE": "ZuluTrade - ZuluRanking System",
        "META_DESCRIPTION": "See the top-ranked leaders on ZuluTrade based on their ZuluRanking performance. Copy their trades & start earning profits with social copy trading. Join now!",
        "META_KEYWORDS": "",
    },
    "terms-of-service-eu":  {
        "META_TITLE": "ZuluTrade - Terms of Service for EU Clients",
        "META_DESCRIPTION": "Read our terms of service before using ZuluTrade. By using our platform, you agree to abide by these terms.",
        "META_KEYWORDS": "",
    },
    "select-broker":  {
        "META_TITLE": "Select Your Broker and Start Social Trading with ZuluTrade",
        "META_DESCRIPTION": "Choose your broker and kickstart your social trading journey today with ZuluTrade. Join us for a new era of trading and investment.",
        "META_KEYWORDS": "",
    },
    "copy-trading":  {
        "META_TITLE": "Copy Trade With the #1 Copy Trading Platform | ZuluTrade",
        "META_DESCRIPTION": "Copy trade with #1 copytrader, Steps to start copytrading. Trade markets with ZuluTrade copy trader platform & copy trading software (AKA social trading).",
        "META_KEYWORDS": "copy trading services, copy trading strategies, copy trading system, copy trading strategy, best copy trading, copy trading broker, copy trading crypto, copy trading stocks, copy traders, copy trading platform",
    },
    "not-found":  {
        "META_TITLE": "ZuluTrade - Page Not Found",
        "META_DESCRIPTION": "Oops! The page you were looking for does not exist. Please check the URL or go to our homepage to find what you are looking for.",
        "META_KEYWORDS": "",
    },
    "open-new-investror-account":  {
        "META_TITLE": "ZuluTrade - Open a New Investor Account",
        "META_DESCRIPTION": "Open a new investor account on ZuluTrade and start copying the trades of professional traders. Join now and larn experience with social trading!",
        "META_KEYWORDS": "",
    },
    "printable-terms":  {
        "META_TITLE": "ZuluTrade - Printable Terms",
        "META_DESCRIPTION": "Download and print our terms of service for your records. By using ZuluTrade, you agree to abide by these terms.",
        "META_KEYWORDS": "",
    },
    // "signup-activation":  {
    //     "META_TITLE": "ZuluTrade - Sign Up Activation",
    //     "META_DESCRIPTION": "Activate your ZuluTrade account and start copying the trades of professional traders.",
    //     "META_KEYWORDS": "",
    // },
    "social-trading":  {
        "META_TITLE": "Social Trading Platform | Social Copy Trading - ZuluTrade",
        "META_DESCRIPTION": "Social trading allows exchanging ideas between a community and copying experienced traders' trades via copytrading. Join the social trading revolution.",
        "META_KEYWORDS": "social trading tool, stock trading social network, social trading tools, social media trading, trading social media, social trading platform",
    },
    "page-not-found":  {
        "META_TITLE": "404 Error - Page Not Found",
        "META_DESCRIPTION": "Sorry, the page you are looking for could not be found. Please check the URL or return to the homepage.",
        "META_KEYWORDS": "",
    },
    "payment/success":  {
        "META_TITLE": "Payment Successful - Thank You for Choosing ZuluTrade",
        "META_DESCRIPTION": "Your payment has been successfully processed. Thank you for choosing ZuluTrade as your trading platform.",
        "META_KEYWORDS": "",
    },
    "payment/failure":  {
        "META_TITLE": "Payment Failed",
        "META_DESCRIPTION": "Sorry, your payment could not be processed. Please check your payment information. If you continue to experience problems, please contact our customer support team for assistance.",
        "META_KEYWORDS": "",
    },
    "rewards":  {
        "META_TITLE": "ZuluTrade Rewards Program - Earn While You Trade",
        "META_DESCRIPTION": "Join the ZuluTrade Rewards Program and earn rewards for every uswr yoou reffer to us.",
        "META_KEYWORDS": "",
    },
    "notifications":  {
        "META_TITLE": "Stay Up-to-Date with ZuluTrade Notifications",
        "META_DESCRIPTION": "Receive real-time notifications about your trades, top traders, and market events with ZuluTrade. Stay on top of your trading with our advanced notification system.",
        "META_KEYWORDS": "",
    },
    "watchlist":  {
        "META_TITLE": "Create Your Watchlist with ZuluTrade",
        "META_DESCRIPTION": "Stay on top of the markets by creating your own personalized watchlist with ZuluTrade. Follow the traders and instruments that interest you the most.",
        "META_KEYWORDS": "",
    },
    "dashboard":  {
        "META_TITLE": "Your Trading Dashboard with ZuluTrade",
        "META_DESCRIPTION": "Stay informed about your trading with ZuluTrade's advanced dashboard. Monitor your performance, follow top traders, and access our suite of trading tools.",
        "META_KEYWORDS": "",
    },
    "zuluguard-guide":  {
        "META_TITLE": "ZuluGuard Guide: Protect Your Trading | Capital Protection",
        "META_DESCRIPTION": "ZuluGuard helps protect your trading from unexpected losses. Its a powerful risk management tool that can automatically close trades to protect your account.",
        "META_KEYWORDS": "",
    },
    "blog/category/knowledge-crunch":  {
        "META_TITLE": "ZuluTrade Knowledge Crunch | Financial Trading Guide",
        "META_DESCRIPTION": "Explore essential trading insights with our Knowledge Crunch: Your ultimate Financial Trading Guide for all levels of traders. Expand your knowledge now.",
        "META_KEYWORDS": "",
    },
    "blog/category/market-view":  {
        "META_TITLE": "ZuluTrade Market View | Latest News From Trading Markets",
        "META_DESCRIPTION": "Stay updated with our Market View: Catch the latest news and trends from the trading markets to enhance your financial strategy. Empower your trading now.",
        "META_KEYWORDS": "",
    },
    "blog/category/trading-floor":  {
        "META_TITLE": "Latest News & Blogs - Trading Floor | Markets - ZuluTrade",
        "META_DESCRIPTION": "Explore the latest insights and strategies on ZuluTrade's Trading Floor blog, your go-to source for expert trading advice and market analysis.",
        "META_KEYWORDS": "",
    },
    "blog/category/new-features":  {
        "META_TITLE": "Blog - New Features | Latest Market News - ZuluTrade",
        "META_DESCRIPTION": "Stay ahead with ZuluTrade: Dive into our Blog for exciting new features and the latest in market news, designed to enhance your trading experience.",
        "META_KEYWORDS": "",
    },
}]