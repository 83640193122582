<template>
   <AppLoader v-if="store.loading && $route.name == 'login'" />
  <router-view />
</template>

<script>
import { myStore } from "@/store/pinia-store";
import moment from 'moment'
export default {
   setup() {
        const store = myStore();
        return { store };
    },
   methods:{
    getAllCurrencies(){
        let json = {
            'from' : moment().subtract(5,'days').format('YYYY-MM-DD'),
            'to' : moment().format('YYYY-MM-DD')
        }
        this.store.getMarketCaterogyWise(json,false)
    },
   },
   created(){
      setTimeout(() =>{
          this.store.affilateLanded({},false)
      },3000);
      if(!this.store.user.access_token){
        this.getAllCurrencies();
      }
   }
};
</script>
<style>
@import "../node_modules/vue-toastification/dist/index.css";
  .Vue-Toastification__toast-body {
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .Vue-Toastification__toast {
    padding: 20px 13px;
    max-width: 460px;
  }
</style>
