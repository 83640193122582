export default {
  locales: {
    en: "English",
  },
  //=============Start=================//
  footer: {
    globalFooter: `Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade..`,
    globalCR: `Copyright`,
    footer_branding: `ZuluTrade`,
    euFooter: `Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade...`,
    footer_branding_eu: `ZuluTrade EU`,
    euService: `This service is provided by Triple A Experts SA - EU Regulated, Licence # 2/540/17.2.2010`,
    text1: `Follow us on`,
    text2: `Copytrade with`,
    text3: `ZuluTrade`,
    text4: `Download app for`,
    text5: `Legal`,
    text6: `Conflict of Interest Policy`,
    text7: `Declaration of Consent`,
    text8: `Features`,
    text9: `ZuluGuard™`,
    text10: `Learn`,
    text11: `Leader Guide`,
    text12: `User Guide`,
    text13: `New Update`,
    text14: `Send Feedback`,
    text15: `Sitemap`,
    text16: `About us`,
    text17: `Affiliate`,
    text18: `Broker`,
    text19: `Disclaimer`,
    text20: `Terms Of Service`,
    text21: `Leader`,
    text22: `End-User License Agreement`,
    text23: `Footer Logo`,
    text24: `ZulluTrade`,
    text25: `For Mac`,
    text26: `Mac App Store`,
    text27: `For Android`,
    text28: `Play Store Android`,
    text29: `For Mobile`,
    text30: `Mobile App`,
    text31: `Facebook`,
    text32: `Twitter`,
    text33: `Linkedin`,
    text34: `Youtube`,
    text35: `Instagram`,
    text36: `TikTok`,
    text37: `reddit`,
    text38: `Contact`,
  },
  riskDisc: {
    heading: `Risk Disclaimer`,
    gtext1: `Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly performance fee per selected trading system.`,
    gtext2: `Forex trading involve a real risk of loss. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.`,
    gtext3: `Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    gtext4: `One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results. Please check our full disclaimer.`,
    fullDisc: `Full Disclaimer`,
    gtext5: `Past performance posted by Leaders (Signal and Systems Providers), is not necessarily indicative of future results. There is no guarantee that an account will make a profit or suffer a loss similarly to the Leaders’ accounts. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    gtext6: `The hypothetical performance results displayed on this website are hypothetical results in that they represent trades made in a demonstration ("demo") account. Transaction prices were determined by assuming that buyers received the ask price and sellers the bid price of quotes ZuluTrade receives from the Forex broker at which a Leader maintains a demo account. The hypothetical results do not include any additional mark-ups or commissions which may be charged by a customer's Forex broker and are based on a one lot trade size. Trades placed in demo accounts are based on a Leader having access to an unlimited amount of funds. As a result, demo accounts are not subject to margin calls and have the ability to withstand large, sustained drawdowns which a customer account may not be able to afford. Trades placed in demo accounts are not subject to price slippage which may occur when a signal is actually traded in a customer account. The number of pips gained or lost by each Leader may be based on the trading of mini, micro or standard lots. The performance of customers electing to trade a different lot size from those used by a Leader will therefore vary. Furthermore, customers may place trades regardless of those provided by a Leader or place customized orders to exit positions which differ from those of a Leader. All performance results presented only include the results of completed trades and do not reflect the profit or loss on open positions. Due to differences in the bid/ask offered by various counterparties, all trades executed in the account of a Leader may not be executed in a customer account if the bid/ask of the Forex broker at which the customer maintains the customer's account is different from that of the Leader's broker or due to volatility in the market.`,
    gtext7: `Customers may elect not to follow all the trading signals provided by a specific Trader and might not be able to trade the recommended number of contracts due to insufficient funds in their account. Therefore, the results portrayed are not indicative of a live account. Furthermore, by electing to follow a number of different Traders at one time, customers may not be able to follow all the generated signals due to insufficient funds in their account. Accordingly, the performance of customer accounts may vary significantly from the results portrayed on this website.`,
    gtext8: `Past performance is not indicative of future results. Accordingly, performance results may vary substantially from account to account, depending on the number of signals and contracts traded, and Leaders followed.`,
    gtext9: `This website does not guarantee that the trading systems mentioned above are suitable for you or will be profitable. You may consult a financial professional on the risks associated with trading Forex instruments before moving forward. The trading systems herein described have been developed for sophisticated traders who fully understand the nature and the scope of the risks associated with trading. The website does not offer any advice on the systems’ signals to be traded.`,
    eutext1: `Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly performance fee per selected trading system.`,
    eutext2: `Forex trading involve a real risk of loss. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.`,
    eutext3: `Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    eutext4: `One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results. Please check our full disclaimer.`,
    eutext10: `This service is provided by Triple A Experts SA - EU Regulated, Licence # 2/540/17.2.2010`,
  },
  TOS: {
    heading: `Agreement as to Terms of Service for the use of the ZuluTrade Platform`,
    gText1: `By utilizing this website, you agree to be bound by these terms and conditions. This is a legal agreement ("Agreement") between you and Zulu Trade Ltd, or any of its affiliated entities ("ZuluTrade") for use of the website, data, ZuluTrade electronic trading platform, and products and services which you selected or initiated, which may include the ZuluTrade trading platform and third party signal providers ("Products and Services").`,
    gText2: `1. Scope – General Information`,
    gText3: `1.1. Scope of agreement`,
    gText4: `This is a legal agreement (hereinafter the "Agreement") between you and Zulu Trade Ltd ("ZuluTrade") for use of the website, data, ZuluTrade electronic trading platform, and products and services which you selected or initiated, which may include the ZuluTrade trading platform and third party signal providers ("Products and Services").`,
    gText5: `1.2. ZuluTrade and the ZuluTrade Platform`,
    gText6: `1.2.1. ZuluTrade is a technology provider that provides to a number of FX and CFD Brokers and to their respective clients the possibility to integrate in their business relationship the functionality of the ZuluTrade Trading Platform (hereinafter the “ZuluTrade Platform” or the “Platform”).`,
    gText7: `1.2.2. The ZuluTrade Platform is an copy trading system, allowing a Broker’s client to reproduce in his/her trading account held with the Broker (the “Trading Account”), in an automated way and subject to the application of various parameters and limitations, trading signals generated by third parties (the “Signal Providers” or “Traders”), as further set out in this agreement and explained on the Platform’s website.`,
    gText8: `1.2.3. Any reference to your “Account” in the Platform and generally in relation to ZuluTrade, for the purposes of this Agreement, shall mean the personalized operation of the Platform concerning you, your relations with ZuluTrade and the transactions made by you through the use of the Platform’s functionality, as well as the capture and recording of such operation in the records and servers of ZuluTrade. Except for the purpose of receiving the applicable fees, ZuluTrade shall not receive or keep money or other assets belonging to you and, therefore, the meaning of the term “Account”, as defined above, does not refer to the holding of any of your assets.`,
    gText9: `1.2.4. ZuluTrade does not undertake, by this Agreement to conduct any of your transactions either as your counterparty or as your broker and therefore assumes no liability in the event of non-performance (total or partial) or delayed execution of the signals generated through the Platform.`,
    gText10: `1.2.5. ZuluTrade does not have any involvement in the determination and provision of the products made available to you by any Broker, including the applicable leverage and spreads.`,
    gText11: `1.2.6. In addition to the automated generation of trading signals, the Platform allows you to generate your own trading signals (“manual” signals), as well as to monitor your transactions and be informed of their results and of your positions held with your Broker in the context of your Trading Account(s) that are connected with the Platform.`,
    gText12: `1.2.7. The choice of your Broker shall be made exclusively by you and your relationship with such Broker shall be governed by the agreement entered into between you and your Broker. ZuluTrade shall make available to you, through the website hosting the Platform, a list of the Brokers with which the use of the Platform is compatible, solely for your convenience, without proposing or suggesting any specific Broker to you.`,
    gText13: `2. Rules and arrangements for the provision of the service`,
    gText14: `2.1. Means of providing the service`,
    gText15: `2.1.1. You have access to the Platform through the website`,
    gText16: ` or any other websit communicated for this purpose to you by ZuluTrade or your Broker.`,
    gText17: `2.1.2. Given the nature of the ZuluTrade Platform, as provided through the use of electronic applications, you are required to make use of appropriate technical means (e.g. a computer, internet connection etc.), as well as to have the necessary knowledge for the appropriate handling of such means. By entering into this agreement and accessing the ZuluTrade Platform, you acknowledge and confirm that you have the necessary equipment and sufficient expertise for its proper use.`,
    gText18: `2.1.3. By using the ZuluTrade Platform you acknowledge that you understand and accept the risks associated with Internet-based trading services including, but not limited to, hardware, software or Internet connection related issues, and the potential inability of timely transmission of the relevant communication due to interruptions and/or errors. Since ZuluTrade does not control signal power, its reception or routing via Internet, the configuration of your hardware or software, or the reliability of your Internet connection, you agree that you are solely responsible for such failures, including communication failures, disruptions, distortions and delays in trading. ZuluTrade recommends that you should be cautious when you use an iPhone, Smartphone, Blackberry or other handheld mobile device for trading or receiving data from the ZuluTrade platform. Handheld mobile devices depend on wireless connectivity, are subject to the limitations of mobile data, WiFi and mobile communication networks restrictions, and may not provide you with the same functions as accessing the ZuluTrade platform from a web browser. ZuluTrade shall not be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) resulting from the use of a handheld mobile device.`,
    gText19: `2.1.4. The use of the ZuluTrade Platform by you requires the combined use of your user name and password. The user name and password are created by ZuluTrade and sent to you after your registration in the Platform and remain private and strictly confidential. ZuluTrade may require for the activation of your access in the Platform a confirmatory email process or any other confirmation process ensuring that the identification data have been sent to/from you. The combined use of the user name and password by you establishes that the use of the ZuluTrade Platform and any activity in your Account (indicatively, choice of Signal Providers, Account settings, manual actions, information on performed transactions, etc.) is made by you. It will be your responsibility to protect the secrecy of the user-name and password, bearing exclusive liability for any damage suffered as a result of any leaks or use thereof by a third party in any way, with or without your authorization. You may change the password at any time by following the relevant instructions listed on the website hosting the Platform, without any further disclosure of such change to ZuluTrade. For the purpose of preventing the use of the ZuluTrade Platform by a third party, it is recommended to change your password on a regular basis. Especially, in case of a leak of the user name or password, you shall immediately notify ZuluTrade and change the password.`,
    gText20: `2.2. No investment advice`,
    gText21: `2.2.1. ZuluTrade shall not provide to you any kind of investment advice or other investment service. ZuluTrade offers solely the technical means that enable you to adopt trading strategies of other users of the Platform, based on the assumption that you have consciously chosen to conduct CFD and/or FX transactions with a Broker of your choice. ZuluTrade does not recommend/suggest to any user of the Platform any transactions in any financial instrument nor does ZuluTrade represent that such transactions may be suitable or appropriate for you or any other user.`,
    gText22: `2.2.2. The history and statistics of the Signal Providers, the trading signals generated by the Signal Providers and the comments made by any Signal Provider on the Platform’s website do not constitute any kind of personal recommendation towards you or any other user to conduct or abstain from any transaction or to follow or unfollow any Signal Provider and should not be regarded as such.`,
    gText23: `2.3. Registration with the Platform`,
    gText51: `Unless otherwise expressly agreed, your registration with the Platform is that of a normal user (i.e. not a Signal Provider) and this Agreement governs your relations with ZuluTrade under this specific and sole capacity.`,
    gText24: `2.4. Choice of Signal Providers`,
    gText25: `The Platform provides you the opportunity to trade based on automated electronic signals. You may adjust the Platform’s settings so that it replicates in the Account the actions of one or more Signal Providers of your choice.`,
    gText26: `The Signal Providers are third parties who use their signals (as displayed on the Platform) to carry out transactions for their own account, through Brokers of their choice. In the case in which transactions of a specific Signal Provider are performed in a simulated trading environment (“demo account”) and not though a real Broker account, ZuluTrade shall draw your attention to such fact with a relevant indication in a prominent position in the Provider’s profile in the Platform.`,
    gText27: `The Platform may incorporate, from time to time, tools or features classifying the Signal Providers into specific categories, or attributing specific ratings or filtering a limited number of specific Signal Providers or combinations thereof. Such tools are based on the application of technical criteria to the Signal Providers’ past performance and statistics, in a way to bring to your attention Signal Providers or combinations thereof that may correspond to your trading preferences. In all such cases, you will be able to view the Signal Provider’s trading characteristics as well as to compare his trading history and performance with those of any other Signal Provider. By entering into this Agreement, you acknowledge your understanding that in no case, such classifications, ratings filtering or combinations of Signal Providers will be considered to constitute any kind of recommendation from ZuluTrade to follow any Signal Provider (or any combined strategies) with a view to achieve any specific future result.`,
    gText28: `In the case of combinations of Signal Providers, the operation of the said combination may be subject to specific rules that ZuluTrade will communicate to you and that you will be requested to accept before proceeding to the choice of such combinations.`,
    gText29: `2.5. Application of settings to selected Systems`,
    gText30: `The replication of a Signal Provider’s signals in your Account will always be subject to the restrictions applied by your Broker as to the offer of the products that you trade. In addition, you may choose specific settings and limits for the signals generated by each selected Signal Provider.`,
    gText31: `2.6. “Manual” actions`,
    gText32: `The Platform enables you to introduce trading signals in your Broker Trading Account at your own initiative (manually), meaning that you may open new positions or close positions previously opened by you or previously opened by a Signal Provider. When you introduce your own trading signals manually through the ZuluTrade Platform interface, such signals will be executed by your Broker, however, for technical and other operational reasons, the prices provided by your Broker may be different than those that you see on the Platform at the moment of the placement of your manual signal. ZuluTrade shall accept no liability for any issues related to the execution of manual trades.`,
    gText33: `2.7. Limitations of the services and of ZuluTrade’s responsibility`,
    gText34: `2.7.1. ZuluTrade draws your attention to the following:`,
    gText35: `- The Signal Providers neither are related to ZuluTrade nor have they any professional certifications or titles with relation to financial markets.`,
    gText36: `- ZuluTrade does not intervene in the content of the signals produced by the Signal Providers.`,
    gText37: `- The Signal Providers do not have access to and do not take into consideration any of the personal information or the position of the account of any of their respective followers.`,
    gText38: `- Monitoring and evaluation of the Signal Providers by ZuluTrade takes place solely based on their past behavior and will in no case refer to their future behavior or to the achievement of future performance.`,
    gText39: `2.7.2. Although the Platform replicates exactly the signals of the relevant Signal Provider(s) (after applying any limitations set by you in your settings), delays in the execution of your transactions by your Broker are probable. It is also probable that divergences arise between the prices that any Signal Provider achieves, with respect to the his own trades, and the prices that you achieve when following the signals of such Signal Provider, even if your trades are carried out at the same time as those of the Signal Provider. Such delays and divergences are normally attributable to the structure and function of the market, and particularly to (indicatively):`,
    gText40: `a) The time period, even if very short, between the moment a signal is received from the Signal Provider and the moment of the execution of your trade by your Broker.`,
    gText41: `b) The overall operation of the FX and CFDs market, given that there is no single reference price for the participants in such market.`,
    gText42: `c) The special events occurring in the market, such as a steep price fluctuation (for example in the case of news announcement), a great volume of transactions waiting to be executed by your Broker or by your Broker’s liquidity provider.`,
    gText43: `Volatility or illiquidity in the Forex markets may also prevent orders from being executed at advantageous prices, or at all.`,
    gText44: `Therefore, ZuluTrade does`,
    gText45: `not`,
    gText46: `guarantee:`,
    gText47: `- An immediate and complete execution of the signals by your Broker.`,
    gText48: `- The matching of the prices at which you carry out any specific trade with those at which the Signal Provider carries out the same trade.`,
    gText49: `Generally, ZuluTrade shall not be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) occurring because trades cannot be executed due to market conditions, or from any errors of your Broker.`,
    gText50: `2.7.4. By entering into this agreement, you acknowledge that you accept the risks inherent to the performance of transactions in the financial instruments supported by the Platform and provided to you by your Broker and that ZuluTrade bears no liability for these risks. In particular you acknowledge:`,
    gText52: `i) That you are aware that transactions on financial instruments involve risks causing the reduction of the value of investments.`,
    gText53: `ii) That foreign exchange, CFDs and other leveraged trading activities involve significant risk of loss. Such activity is not suitable for all investors and you should make sure you understand the risks involved, seeking independent advice if necessary.`,
    gText54: `iii) That your trading results may vary depending on many factors. Because the risk factor is high in the foreign exchange, CFDs and other leveraged products, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in leveraged products. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss.`,
    gText55: `iv) That hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program. One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.`,
    gText56: `v) That you are aware and accept the risks arising from the replication of investment strategies of third parties when using the ZuluTrade Platform and that past performance posted on the Platform is not indicative of future results.`,
    gText57: `In this respect, you are required to carefully read the risk disclosure note found here:`,
    gText58: ` , before accepting these terms and before commencing the use of the ZuluTrade Platform.`,
    gText59: `2.7.5. ZuluTrade shall not be responsible for any damage incurred by you (or a third party having any relevant right through you) due to force majeure (including the exercise of the right of strike, the malfunction of computers including the breakdown of computer systems or electronic communications), fraudulent usage of the data stored in its records and servers by third parties, failure of its electronic systems due to malfunctions of the communications’ network or malfunctioning of its software and hardware that is not attributable to the lack of maintenance or monitoring, as well as events related to the operation of third parties, such as interruption or suspension or limitation of the activities carried out by Brokers with whom ZuluTrade cooperates in the context of this agreement or third parties intervening in the course of your relationships and transactions with your Broker (exchanges, companies settling transactions, credit institutions etc.) for whatever reason.`,
    gText60: `2.7.6. ZuluTrade is not responsible for the actions or omissions of your Broker or any other third party with respect to the transactions that you carry out with your Broker for whatever reason.`,
    gText61: ` 2.7.7. ZuluTrade does not guarantee that the use of the ZuluTrade Platform will have a positive result or a specific performance for you. Any reference to the performance of transactions carried out by users of the Platform or Signal Providers is related exclusively to the past and by accepting these terms you acknowledge that you are aware of such fact. Any reference to the performances of any Signal Provider may not take into account the costs of transactions, such as Broker fees or any other fees applicable to your ZuluTrade Platform Account in the context of this Agreement.`,
    gText62: `2.7.8. The trades conducted by Signal Provider on his own trading account may involve amounts greatly different from the amounts allocated by you to each transaction following the Signal Provider’s activity. This may itself cause a significantly different result compared to the Signal Provider’s profits or losses even if the remaining characteristics of your positions are the same as the ones of the specific Signal Provider. In addition, particular limitations may be applied to your trading account by your Broker, which may not necessarily apply to the Signal Provider at the same time. Such limitations may be due to specificities of the regulatory framework applicable to each Broker or to the Broker’s policies. The application of such limitations on your trading account may lead to further divergences between the results of the Signal Providers’ trades and your own trades, especially if the Signal Provider’s trading account is not subject to the same limitations.`,
    gText63: `2.8. Access to CFD Market Data to Demo Accounts`,
    gText64: `As regards CFDs, and to the extent feasible, ZuluTrade may provide to you access to online price and trade-related data regarding the underlying securities (“Market Data”). Such Market Data may include the streaming of quotes, market volume information, as well as pubic announcements relating to the issuers of underlying securities. Any such Market Data will be displayed on an “as is” basis, i.e. as received from ZuluTrade’s Market Data provider, and will include information that is available in a specific exchange. In this context, the prices of a specific underlying security that are displayed in the Market Data may be different from the prices of the same security in other exchanges or markets where the same security may be admitted.`,
    gText65: `The provision of access to certain categories of Market Data is subject to the acceptance by you of the terms of the Market Data Addendum which forms an annex to this Agreement. To the extent that the Market Data Addendum refers to a specific Market Data provider, such Addendum will be terminated or amended or substituted in the event of termination of ZuluTrade’s relationship with the specific Market Data provider or in the event of substitution of the Market Data provider. In such case ZuluTrade will apply its best endeavors in order to give you reasonable notice of the relevant event. In the case of substitution of the Market Data Provider, the provision of access to Market Data may be subject to the acceptance of additional terms by you.`,
    gText66: `Access to Market Data is provided to you exclusively for internal/personal use. You may not sell, lease, furnish or otherwise permit or provide access to Market Data to any other Person or to any other office or place. You will not engage in the operation of any illegal business use or permit anyone else to use Market Data, or any part thereof, for any illegal purpose or violation of any Regulatory Requirements. You may not present Market Data rendered in any unfair, misleading, or discriminatory format. You shall take reasonable security precautions to prevent unauthorized persons from gaining access to Market Data.`,
    gText67: `By utilizing Market Data related to individual US Stocks, you agree to be bound by the Market Data Addendum that can be found`,
    gText68: `here`,
    gText69: `2.9. Inability to access the Platform`,
    gText70: `In case, for whatever reason, you are unable to access the Platform and its services, you must immediately notify ZuluTrade and contact your Broker directly to manage any open positions you have in your Trading Account. You may also request from your Broker to disconnect your account from ZuluTrade, while you are unable to access the ZuluTrade services, so as to avoid receiving new positions from Signal Providers in your Trading Account.`,
    gText71: `3. Communication `,
    gText72: `3.1. Means of Communication`,
    gText73: `The communication between you and ZuluTrade shall take place, depending on the case, either in writing or orally. More particularly:`,
    gText74: `- The written communication takes place via documents exchange/shipping, via fax or via electronic mails being sent to the electronic addresses of the parties or via the Platform’s dedicated application for messages and communication.`,
    gText75: `- Oral communication takes place in principle via telephone. ZuluTrade may tape record your telephone conversations with its employees, in order to prove the content of such conversations, as well as for the protection of your and ZuluTrade’s mutual interests. All such recorded telephone conversations may be stored in accordance with its Data Privacy Policy that is available on ZuluTrade’s website.`,
    gText76: `3.2. Confidentiality`,
    gText77: `ZuluTrade will keep the information provided by you confidential under this agreement and take the necessary internal measures in order to ensure the confidentiality of such information. Confidentiality does not extend to any data or information`,
    gText78: `a) That ZuluTrade has an obligation to disclose to the public or any competent authority,`,
    gText79: `b) That have to be invoked by ZuluTrade before a Court or any competent authority for ZuluTrade’s defense with respect to a case pending against ZuluTrade relating to the relationships governed by this agreement,`,
    gText80: `c) That have to be communicated to companies cooperating with ZuluTrade in order for the obligations undertaken by ZuluTrade under this agreement to  be fulfilled.`,
    gText81: `3.3. Information regarding your trading activity`,
    gText82: `ZuluTrade provides you with ongoing access to a personalized area of the Platform, in which you enter by using your individual codes, for the purpose of receiving at any time information on your trading activity at any period of time.`,
    gText83: `In case of various events that – in ZuluTrade’s reasonable opinion – may have an effect on your Account (such as a substantial change of trading behavior by a Signal Provider that you may have selected), ZuluTrade may send you relevant notices through the Platform. `,
    gText84: `ZuluTrade provides 24-hour telephone helpline for the Platform. The relevant details can be found here:`,
    gText85: `3.4. Probative force of book files and computer records`,
    gText86: `Any copy or extract of the forms, data, books, records and files issued or kept by ZuluTrade, either in paper or in electronic form – in accordance with applicable legislation - or any extract of the ZuluTrade Account, which shall be exported by ZuluTrade and shall display the history of the ZuluTrade Account’s operation, shall constitute full proof for the activity of the Account. Similarly, extraction of logs regarding every aspect of the operation of your Account, including logs relating to your logging in the Platform and any use of the Platform by you, shall constitute full proof of the related facts.`,
    gText87: `3.5. Liability for transmission errors`,
    gText88: `In cases of delay, interruptions or other errors that may occur during the transmission of electronic mail or fax, as well as in cases of incorrect translation or interpretation of technical terms, ZuluTrade shall only be liable for willful misconduct or gross negligence of its executives, agents or employees. `,
    gText89: `4. Fees`,
    gText90: `4.1. Classic Account`,
    gText91: `Unless explicitly indicated otherwise by ZuluTrade, in the case of a "Classic Account", ZuluTrade is compensated for its services based on the spread between the bid/ask prices provided to you by your Broker or based on any other specific costs charged to you by your Broker when initiating a trade .`,
    gText92: `4.2. Profit Sharing Account`,
    gText93: `In the case of a Profit Sharing Account, you shall pay directly to ZuluTrade a monthly fixed Subscription Fee at the start of the calendar month (calculated on a pro-rata basis for periods of less than a full month).`,
    gText94: `The Performance Fee will be applicable separately on each Signal Provider and not in the aggregate performance of the Account as a whole. `,
    "": `To open a Profit Sharing Account you must provide one or more payment methods. In case your primary payment method is declined or no longer available to ZuluTrade for the payment of the Subscription Fee and/or Performance Fees, ZuluTrade shall charge any one of the other payment methods associated to your Account, as the case may be, including but not limited to the Trading Account with your Broker. In such case, ZuluTrade will proceed to the collection of the fees from the Broker on a best effort basis, without further direction or confirmation from you, unless otherwise notified in writing. This applies to outstanding fees of a current or previous months. ZuluTrade shall not be held responsible or liable for any miscalculation or non-payment of said fees for any reason whatsoever.`,
    gText95: `You can cancel the Profit Sharing account subscription at any time, and will continue to have access to the account through the end of the billing period. Payments are non-refundable and ZuluTrade does not provide refunds or credits for any partial - month subscription periods or unused Profit Sharing Account. To cancel the subscription, you should follow the relevant instructions of the Platform.`,
    gText96: `The amounts of the Subscription Fee and Performance Fees are available on ZuluTrade’s website and shall be communicated to you in the context of the opening a Profit Sharing account. ZuluTrade may change the said amounts from time to time; however, any such changes will apply to subsequent billing cycles following notice of the change(s) to you. Profit Sharing Account fees may be charged by ZuluTrade itself or by any other Affiliate entity of ZuluTrade as the case may be.`,
    gText97: `By utilizing a Profit Sharing account, you agree to be bound by the Agreement on the Storage of the Cardholder’s Credentials that can be found`,
    gText99: `3.4. Probative force of book files and computer records`,
    gText100: `5. Authorizations`,
    gText101: `You hereby authorize ZuluTrade to access the Trading Account held by you with your Broker, via all appropriate technical means and - to the extent necessary - the relevant competent personnel and executives thereof, in order`,
    gText102: `a) to connect the Platform to your Trading Account in a way to allow the introduction of the signals resulting from the operation of the Platform in your Trading Account;`,
    gText103: `b) to obtain full information about your Trading Account connected to the Platform, in order to be able to provide comprehensive information to you via the Platform;`,
    gText104: `c) to obtain information regarding your identification, for the purpose of providing the ZuluTrade Platform to you, receiving payments from you and complying with any applicable rules (including for the prevention of money laundering)`,
    gText105: `d) to proceed to any other necessary consultation and collaboration with the Broker in order for the purpose of this agreement to be fulfilled.`,
    gText106: `6. Client’s Data`,
    gText107: `6.1. Client’s identity`,
    gText108: `For proof of your identity and that of any representatives designated by you, ZuluTrade may rely upon any document that, by law, is regarded as proof of identity of a person or legal entity. ZuluTrade shall not be responsible for the validity, legal integrity or authenticity of such documents. If you suffer loss by error, misunderstanding, deception or fraud as to the person giving instructions to ZuluTrade to carry out a specific action, ZuluTrade shall only be liable for willful misconduct or gross negligence of its executives, employees and agents.`,
    gText109: `6.2. Signature specimens`,
    gText110: `ZuluTrade is entitled to receive and keep specimens of your and your representatives’ signatures, if necessary for the fulfilment of the Service.`,
    gText111: `6.3. Change of data`,
    gText112: `You shall notify ZuluTrade on your own initiative, without delay, of any change of name, contact information (especially e-mail address) and other data you have provided to ZuluTrade when entering into this agreement, as well as any change regarding the powers of representation granted to other persons (if applicable). You are not entitled to oppose against ZuluTrade any change of the above data, if such change has not been notified to ZuluTrade in writing.`,
    gText113: `6.4. Personal Data`,
    gText114: `Your personal data, as disclosed to ZuluTrade from time to time, will be stored, processed and disclosed to third parties for the purpose of providing the services and complying with ZuluTrade’s obligations, in accordance with ZuluTrade’s Data Privacy Policy, which is available through the link`,
    gText115: `and the links provided therein).`,
    gText116: `7. Refund Policy`,
    gText117: `ZuluTrade will issue a refund to an investor for losses caused by Platform Errors, if the investor provides timely notice of the error to ZuluTrade as described below. A Platform Error occurs if a trading signal that should have been rejected according to the investor’s account settings is accepted by ZuluTrade and submitted to the investor’s broker, or if a trading signal that should have been accepted according to the investor’s account settings is rejected by ZuluTrade and not submitted to the investor’s broker. ZuluTrade shall not be responsible for refunding lost profits resulting from Platform Errors under any circumstances.`,
    gText118: `Errors of Signal Providers or Brokers shall not be considered Platform Errors, and ZuluTrade shall not be responsible for refunding losses or lost profits resulting from such errors under any circumstances.`,
    gText119: `In order to receive a refund of losses caused by Platform Errors, you must notify ZuluTrade in writing of your potential claim. You must deliver the written notice to ZuluTrade as soon as you knew, or should have known, of the Platform Error, but in any event, no later than twenty-four hours after the Platform Error occurred. If ZuluTrade does not receive such notice from you within twenty-four hours after the Platform Error occurred, it will not be liable for any losses resulting from the Platform Error.`,
    gText120: `If ZuluTrade confirms that a Platform Error occurred with respect to your account, and you provided the required notice to ZuluTrade within twenty-four hours, you will receive a refund of your losses caused by the Platform Error (not including lost profits). ZuluTrade processes all Refunds requests within 20 days following the acknowledgment of the receipt of the Refund request.`,
    gText121: `You are required to review your Account statements and monitor your Account status at least daily to ensure that your trades were placed, and that all trades that were made on your Account were authorized by you and also monitor your Broker’s reports and platform with respect to your Trading Account, as highlighted in clause 3.3. hereof.`,
    gText122: `Except as expressly provided by this refund policy, neither ZuluTrade, nor our officers, principals, employees or agents shall be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) resulting from any errors in the ZuluTrade Platform.`,
    gText123: `ZuluTrade's refund policy does not apply to IC Markets Investor accounts`,
    gText124: `8. Final provisions`,
    gText125: `8.1. Representation regarding funds`,
    gText126: `By accepting these terms and proceeding to the opening and operation of your Account, you represent and certify to ZuluTrade that`,
    gText127: `a) all amounts of money that you have deposited and/or will deposit with your Broker belong to you, are from a legal source and do not derive from acts or activities falling within the prohibitions of the laws on the prevention and suppression of money laundering;`,
    gText128: `b) generally you shall comply with the legal provisions and shall not engage in illegal or prohibited actions or practices;`,
    gText129: `c) all information that you have provided or that you will provide to your Broker or to ZuluTrade regarding yourself and the origin of your assets are/will be true.`,
    gText130: `8.2. Restrictions to the use of Platform Information`,
    gText131: `By viewing ZuluTrade’s website and trading on Platform, you fully understand and agree to respect the proprietary rights of Signal Providers and of ZuluTrade, and you represent that you do not desire to acquire from the Signal Providers any trade secrets or confidential information.`,
    gText132: `You represent that you will be using signals solely for your own ZuluTrade Account and that you will not be reproducing such signals with any third party platforms or services outside of the ZuluTrade platform, without the written permission of ZuluTrade. Immediately upon notice from ZuluTrade that, in ZuluTrade’s opinion, any use of the Platform does not comply with this standard, you will cease such manner of use. Furthermore you agree that ZuluTrade may at its sole discretion disable your access to the Platform at any time, in the case of system abuse or any infringement of any patent, copyright, trademark or other proprietary right or infringement upon a trade secret of any person or entity, or other reasons not described in this Agreement.`,
    gText133: `8.2. Duration – Termination`,
    gText134: `This agreement is of indefinite duration. The agreement shall take effect after acceptance by the Client and upon the opening of the Account by ZuluTrade, and shall be terminated upon the closure of the Account. The Account may be closed either by a request from you, subject to ZuluTrade’s acceptance and provided that no Fees are outstanding, or in ZuluTrade’s initiative with a reasonable notice or – in the case of inactive accounts – with no notice.`,
    gText135: `8.3. Governing law – Jurisdiction`,
    gText136: `8.2.1. All contractual relationships between ZuluTrade and the Client are governed by the laws of Cyprus.`,
    gText137: `8.2.2. The courts of Cyprus will be competent for any dispute between you and ZuluTrade arising from this contractual relationship. ZuluTrade has the discretion to choose any other court having jurisdiction for the exercise of its claims against you.`,
    gText138: `8.3. Other terms`,
    gText139: `8.3.1. The terms of this agreement shall only be amended by a written agreement between you and ZuluTrade (including by way of acceptance by electronic means), without prejudice to the provisions of the following paragraph.`,
    gText140: `8.3.2. ZuluTrade shall have the right, at any time and under its sole and absolute discretion, to unilaterally change and/or amend the terms and conditions of this Agreement. You agree that any new format of this Agreement which shall be posted on ZuluTrade’s Website shall be considered as sufficient provision of notice for the changes and/or amendments made in such new format and shall become effective as of the date of posting it as aforesaid.`,
    gText141: `8.3.3. Any delay or omission of ZuluTrade to exercise any statutory or contractual right, in no way shall be deemed or construed as a waiver of the respective right.`,
    gText142: `8.3.4. In case one or more the terms of this agreement is or becomes unenforceable, it shall not affect the validity of the remaining terms and the remaining provisions shall remain in force.`,
    gText143: `8.3.5. You shall not assign or transfer in any way to third parties any of your rights and claims arising from your relations with ZuluTrade under this Agreement, unless otherwise agreed in writing.`,
    //Footer Text
    gText144: `ZuluTrade, is a registered trademark of ZuluTrade International Limited, a software development company registered in Cyprus under Registration No HE242240.`,
    gText145: `The service provided by ZuluTrade `,
    gText145a: `is operated by the ZuluTrade Group of Companies ('the Company') and is regulated by the Hellenic Capital Markets Commission in Greece under License No 2/540/17.2.2010. The Company may extend its services to various regions, including but not limited to the European Economic Area ("EEA") countries.`,
    // 'gText146': `Trading in financial instruments involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the financial market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the financial markets. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.`,
    gText147: `Trading in financial instruments involves substantial risk and there is always the potential for loss. Your trading results may vary.`,
    gText148: `Past performance is not indicative of future results.`,

    gText152: `Please check our full`,
    gText153: `disclaimer`,
    // 'gText154' : ``,
    // 'gText155' : ``,
  },
  TOSEU: {
    heading: `AUTOTRADING SERVICES AGREEMENT`,
    eText1: `1. Scope - general information`,
    eText2: `1.1. AAA Status TRIPLE A EXPERTS INVESTMENT SERVICES S.A. (“AAA”) is an Investment Services Company established in Greece, authorized by the Hellenic Capital Markets Commission, subject to the supervision of the latter and entitled to provide, inter alia, investment services of reception and transmission of orders, management of investment portfolios and investment advice under the provisions of Greek Law 4514/2018.`,
    eText2_2: `1.2. Services`,
    eText3: `1.2.1. This agreement governs the transactional relations of the Client with AAA which have as their object the use of the Autotrading service by the Client for the purpose of conducting transactions in financial instruments. In particular, AAA shall provide to the Client the ability to use an copy trading system (“Autotrading”) through a special platform provided to the Client on AAA’s website or a third-party website that will function under AAA’ s administration (hereinafter the “Platform” and the “Website” respectively). By using the Platform, the Client shall be able to reproduce in an automated way, - having the possibility to apply parameters and limitations of his/her choice-, investment strategies generated by electronic applications (hereinafter the “Systems”) that have been developed by third parties, for conducting transactions in financial instruments, as specifically defined according to clause 1.3. This Agreement is supplemented by the legal documentation that the Client must peruse and take into consideration before entering into the business relationship with AAA, and in particular the informative documents regarding Investment Risks (), AAA’s Conflicts of Interests Policy`,
    eText4: `https://aaafx.com/Assets/AAAFx/ConflictsOfInterestPolicy.pdf`,
    eText5: `AAA’s Data Privacy Statement`,
    eText6: `https://aaafx.com/privacy?Lang=en-US`,
    eText7: `and the links provided therein, as well as the Key Information Documents for each product that may be the object of the Clients’ transactions, as issued by the Broker with which the Client holds a trading account (the “Executing Firm”) or by the relevant product manufacturer. It is highlighted that, although AAA has in place a Best Execution Policy, which is displayed on its website and is also available upon request, such Policy shall not apply to the relationships governed by this Agreement, taking into account that under this Agreement AAA will be submitting the trades for execution through an Executing Firm or any other Execution Venue with which the client has opened a trading account for the provision of reception, transmission and execution or orders.1.2.5. The Client business relationship with the Executing Firm with which the trades will be executed shall be governed by the agreement entered into between the Client and the Executing Firm. AAA shall make available to the Client, through the website hosting the Platform or any other mean a list of the Executing Firms with which the use of the Platform is compatible, for the convenience and attention of the Client.`,
    eText8: `1.2.2. The Platform shall reproduce the investment activity of the Systems chosen by the Client, in the Account of the Client (as defined below) provided that the conditions set by the Client in the relevant settings of the Platform are met, without requiring the prior approval of each transaction by the Client. The reproduction of trading signals or strategies of the Systems has the meaning of the automated generation of trading signals that meet the parameters set by the Client as well as the transmission of such signals electronically to the firm (FX broker or other) with which the Client maintains a trading account and which has been indicated by the Client to AAA (the “Executing Firm”). Any reference to the “Account” of the Client in the Platform and generally in relation to AAA, for the purposes of this Agreement, shall mean the personalized operation of the Platform concerning the Client, his/her relations with AAA and the transactions made through the Platform on behalf of the Client, as well as the capture and recording of such operation in the records and servers of AAA. It is expressly stated that AAA does not undertake, by this Agreement, to conduct itself the related transactions as the Client’s counterparty or broker and therefore assumes no liability in the event of non-performance (total or partial) or delayed execution of orders generated by the Platform. It is also specified that in any case, under this Agreement, AAA shall not receive and keep money or other assets belonging to the Client and, therefore, the meaning of the term “Account”, as defined above, does not refer to the holding of such assets.`,
    eText9: `AAA is not responsible under this Agreement for determining the range and the characteristics of the products that are made available to the Client by the Executing Firm with which the Client holds his/her trading account, including the applicable leverage and spreads. It is further stressed out that AAA bears no responsibility or liability whatsoever regarding the Executing Firm’s compliance with the applicable Regulations, including those applicable to the offer of FX and CFDs products or any other financial instruments.`,
    eText10: `1.2.3. In addition to the automated generation of trading signals, the Client may generate, through the Platform, trading signals on his/her own initiative (“manual” orders), if he/she so wishes, as well as monitor his/her transactions and be informed of their results and of the positions held with the Executing Firms on the basis of the Client’s activity carried out through the Platform.`,
    eText11: `1.2.4. The operation of the Platform by AAA is conducted under the authorization granted to AAA by the Hellenic Capital Market Commission to provide portfolio management services, taking into account the fact that automated transactions are carried out without the specific approval for each of them by the Client. It is expressly clarified that any reference to the term “portfolio management” or similar terms, whether in this Agreement or in any other written means of communication, as regards the services and operation of the Platform, shall only have the above meaning and shall be exclusively related to the services specifically described herein. Under no circumstances ΑΑΑ shall be deemed to undertake any obligation towards the Client to make investment decisions on the Client’s behalf, on AAA’s initiative or discretion, for the achievement of any investment objective of the Client.`,
    eText12: `1.2.5. The choice of the Executing Firm with which the Client will execute trades shall be made exclusively by the Client and his/her business relationship with such firm shall be governed by the agreement entered into between the Client and the Executing Firm. AAA shall make available to the Client, through the website hosting the Platform, a list of the Executing Firms with which the use of the Platform is compatible, solely for the convenience of the Client, without proposing or suggesting any specific Firm to the Client.`,
    eText13: `1.3. Financial instruments`,
    eText14: `The financial instruments for which the services provided in the context of this Agreement will be available shall be determined by AAA and a list thereof shall be available to the Client on the website hosting the Platform. Without limitation, it is indicated that the Autotrading service primarily concerns financial derivatives which include high leverage, such as in particular Rolling Spot FOREX and Contracts for Differences (CFDs), having as underlying assets exchange rates, commodities or other assets, without excluding the extension of the Autotrading services to other financial instruments in the future.`,
    eText15: `1.4. Client Classification`,
    eText16: `Under the current legislation, AAA classifies clients to which it provides investment services in one of the following categories: a) retail, b) professional. Based on the information available, AAA has classified the Client as a retail client regarding all services and financial instruments covered by this Agreement. This category provides for the highest level of protection in the application of rules of professional conduct governing the provision of investment services. In case the Client wishes to change his/her classification, considering that AAA could treat him/her as a professional client, the Client should send a written request to AAA in order for AAA to consider whether he/she meets the legal requirements and consider the case. In accordance with the applicable law and regulations, a Professional Client shall be deemed to have the necessary experience, knowledge and expertise with regards to the services and products in respect of which he/she will be classified as professional, may receive less detailed pre-contractual information compared to a retail client, may be subject to different rules as to the execution and handling of orders and receive less detailed information regarding the costs of the services and transactions.`,
    eText17: `1.5. Further information as to AAA`,
    eText18: `1.5.1. General information on AAA and the services provided is available at the website of AAA (www.aaafx.com). The Client declares that before entering into this agreement he/she has received and reviewed the said information.`,
    eText19: `1.5.2. According to the provisions of Law 2533/1997, AAA participates in the Greek Investment Services Guarantee Fund, which covers claims against investment firms arising from the provision of investment services for a value up to 30.000 euros per customer, subject to the specific provisions of the law.`,
    eText20: `1.5.3. AAA takes the necessary measures to avoid situations of conflict of interests, which may arise in the exercise by AAA of investment activities and provision of investment and ancillary services. A conflict of interests situation is a situation in which, in the exercise AAA’s activities, the interests of AAA and the interests of its clients, or its affiliates, are in conflict directly or indirectly. A summary of AAA’s policy with regard to the prevention and management of situations of conflict of interests is available on its website`,
    eText17a: `Conflict of Interest`,
    eText21: `https://aaafx.com/Assets/AAAFx/ConflictsOfInterestPolicy.pdf`,
    eText22: `The Client may at any time request from AAA further information on the policies established to prevent and manage conflicts of interests.`,
    eText23: `2. Rules and arrangements for the provision of the service`,
    eText24: `2.1. Means of providing the service`,
    eText25: `2.1.1. The Client has access to the Platform through the website`,
    eText25_1: `www.zulutrade.com`,
    eText25_2: `which is operated by AAA or any other website communicated for this purpose by AAA to the Client.`,
    eText26: `2.1.2. Given the nature of the Autotrading service, as provided through the use of electronic applications, the Client is required to make use of appropriate technical means (e.g. a computer, internet connection etc.), as well as to have the necessary knowledge for the appropriate handling of such means. By entering into this agreement and accepting the services of AAA, the Client declares and affirms to AAA that he/she has the necessary equipment and sufficient expertise for its proper use. In addition, the Client understands and accepts the risks involved in the use of computers and communication systems in the context of conducting transactions in financial instruments and the possible inability of timely transmission of the relevant communication due to interruptions, faults etc.`,
    eText27: `2.1.3. The use of the Autotrading service by the Client requires the combined use of the Client’s user name and password. The user name and password are created by AAA and sent to the Client after the Client’s registration in the Platform and remain private and strictly confidential. AAA may require for the activation of the Client’s access in the Platform a confirmatory email process or any other confirmation process ensuring that the identification data have been sent to/from the Client. The combined use of the user name and password by the Client establishes that the use of the Autotrading service and any activity in the Account of the Client (indicatively, choice of Systems, Account settings, manual actions, information on performed transactions, etc.) is made by the Client. The Client undertakes to protect the secrecy of the user-name and password, bearing exclusive liability for any damage suffered as a result of any leaks or use thereof by a third party in any way, with or without the authorization of the Client. The Client may change the password at any time by following the change instructions listed on the website hosting the Platform, without any further disclosure of such change to AAA. For the purpose of preventing the use of the Service by a third party, it is recommended to the Client to change his/her password on a regular basis. Especially, in case of a leak of the user name or password, the Client shall immediately notify AAA and change the password.`,
    eText28: `2.2. Investment characteristics of the Client`,
    eText29: `The platform is addressed to investors who have decided beforehand to carry out transactions in one or more products/financial instruments from those available on the Platform, as specified in clause 1.3. and, thus, have speculative investment objectives. In addition, subject to the suitability test as specifically provided for in Section 3 herein, AAA does not propose/suggest to the users of the Platform any transactions in any financial instrument as suitable or appropriate for the Clientnor does AAA represent that such transactions are more suitable or appropriate for the Client than other forms of investment. Given the above, the Client expressly declares that he/she does not expect AAA or the Platform to propose transactions in order to serve specific investment objectives.`,
    eText30: `2.3. Registration in the Platform`,
    eText31: `Unless otherwise expressly agreed, the Client is registered in the Platform as a normal user (i.e. not a System Provider) and the present Agreement governs his/her relations with AAA under this specific and sole capacity.`,
    eText32: `2.4. Choice of Systems`,
    eText33: `The Platform provides the client the opportunity to adopt investment actions (trading signals) generated by automated electronic signals. The Client may adjust the Platform’s settings so that it replicates in the Account the actions of one or more System(s) of his/her choice.`,
    eText34: `The Systems are created by third parties (“System Providers” or “Providers”), who use the signals generated by their Systems to carry out transactions for their own account, through their Executing Firm(s) of their choice. In the case in which transactions of a specific Provider are performed in a simulated trading environment (“demo account”) and not though an Executing Firm, AAA shall`,
    eText35: `draw the Client’s attention to such fact with a relevant indication in a prominent position in the Provider’s profile in the Platform.`,
    eText36: `2.5. Application of settings to selected Systems`,
    eText37: `The replication in the Client’s Account of trading signals deriving from a System is subject to the restrictions applied by the Executing Firm as to the offer of the financial instruments traded through the Platform, as well as to the restrictions set under clause 3.2.1. hereof. In addition, the Client may choose specific settings and limits for the signals generated by each selected System, as available from time to time on the Platform. AAA may designate specific kinds of settings as mandatory and disable the choice of a System if the Client does not complete the required mandatory settings. Indicatively (without limitation) AAA may appoint as mandatory settings the following:`,
    eText38: `Quantity of contracts / nominal amount (lots) per order: Under this setting, the Client shall specify the number of lots he/she wishes to allocate to each of his/her orders, which are activated by the signals of the selected System (total number of lots activated by each individual buy and sell signal of each selected System).`,
    eText39: `Allocation of capital to each of the selected Systems Under this setting, the Client shall allocate to each System he/she has selected (if more than one selected), the total tolerable loss limit applicable in the Autotrading Account, as specifically described in clause 3.2.5.`,
    eText40: `The mandatory settings and other (optional) settings as available shall be determined at the sole discretion of AAA and shall be incorporated in the relevant sections of the Platform, without requiring any prior notice to the Client. In case of changes that may cause interruption of transactions carried out by the Client via the Platform, AAA shall make every reasonable effort to notify the Client and call him/her to take the necessary measures before the changes occur.`,
    eText41: `2.6. “Manual” actions`,
    eText42: `The Platform enables the Client to send trading signals to his Executing Firm at his/her own initiative (manually), meaning that the Client may open new positions or close positions opened by him/her or opened by the selected System’s signals. In such case, this specific activity of the Client is not part of the Autotrading service and will not be covered by the provisions of this agreement concerning the application of risk limits (as further set out in clause 3.2.7.), but shall be treated as simple order input by the Client.`,
    eText43: `2.7. Selection of Available Systems`,
    eText44: `The number of Systems available to the Client (the “Available Systems”), and in general to the users of the Platform, is defined by AAA based on the criteria it applies, which have regard to (but are not limited to) the performance, the duration of operation, the consistency of the Systems in achieving performance and their tendency towards taking limited risks. Systems that do not meet the requirements of the Platform (and for as long as such requirements are not met) remain visible in the Platform but cannot be selected by the Users.`,
    eText45: `The Client may choose a System other than the Available Systems only in cases where the Client is the Provider of the System. In this case, the Client shall submit to AAA a relevant request, which AAA will approve if the above situation applies. In this case, the transactions made by the Client from the specific System shall not be governed by the provisions of this Agreement and all the orders generated from the Client’s own System shall be considered as originating from the Client.`,
    eText46: `AAA may change the classification and sorting of the Available Systems even on a daily basis or at intervals within the same day based on the criteria it applies from time to time. Such changes may have the following direct consequences:`,
    eText47: `If a System chosen by the Client ceases to be Available, such System shall cease opening positions in the Client’s Account. Any open positions of the Client shall remain connected with the said System unless the Client chooses to close them on his/her own initiative. In the event of such change, AAA shall notify the Client by e-mail.`,
    eText48: `The Systems that were not Available before the change and that, as a result of the change, become Available Systems are immediately accessible to the Client.`,
    eText49: `2.8. Content of the services and responsibility of AAA2.8.1. AAA draws the attention of the client to the following:`,
    eText49_1: `2.8.1. AAA draws the attention of the client to the following:`,
    eText50: `The persons, who have developed the applications referred to in this agreement (i.e. the System Providers), neither are related to AAA nor have any professional certifications.`,
    eText51: `AAA provides access to Systems that meet specific reliability criteria based on their past behavior, applies rules permitting the selection of such Systems, ensures that the Systems’ activity in the context of the Autotrading service is supervised by a competent certified member of AAA’s personnel and evaluates the Systems based on their trading behavior. However, AAA does not build the strategies of the Systems and, in general, the content of the signals produced by such Systems.`,
    eText52: `The Systems available to the Client through the Platform do not take into consideration Client’s personal information in the context of their trading strategies, given that each System is normally adapted to the investing choices of the Provider who has developed such System.
        Monitoring and evaluation of the Systems by AAA takes place solely based on their past behavior and in no case refer to their future behavior or the achievement of future performance.`,
    eText52_1: `Monitoring and evaluation of the Systems by AAA takes place solely based on their past behavior and in no case refer to their future behavior or the achievement of future performance.`,
    eText53: `The application of the Client’s choices and settings is automated and –therefore- it takes place without human intervention (save in exceptional cases).`,
    eText54: `2.8.2. Although the Platform replicates exactly the signals of the Systems (after applying any limitations set by the Client in his/her settings), delays in the execution of the Client’s transactions are probable. It is also probable that divergences between the prices that each System achieves with respect to the Provider’s own transactions and the prices that the Client achieves when following the signals of such System, even if the transactions of the Client are carried out at the same time with the transactions of the Provider. Such delays and divergences are normally attributable to the structure and function of the market, and particularly to (indicatively):`,
    eText55: `a) The time period, even if very short, between the moment a signal is received from the System and the moment of the execution of the Client’s order by the Executing Firm.`,
    eText56: `b) The overall operation of the Over-The-Counter market, given that there is no single reference price for the participants in such market.`,
    eText57: `c) The special events occurring in the market, such as a steep price fluctuation (for example in the case of news announcement), a great volume of transactions waiting to be executed by the Executing Firm in which the Client has a trading account or by the liquidity provider of the Executing Firm.`,
    eText58: `Therefore, AAA does not guarantee:`,
    eText59: `- An immediate and complete execution of the signals generated by the Platform by the Executing Firms.`,
    eText60: `- The matching of the prices at which the Client carries out any specific transaction with those at which the Provider of the selected System carries out the same transaction.`,
    eText61: `2.8.3. AAA applies mandatory settings in the Client’s Account in accordance with his/her investment profile in order to allow him/her to follow the selected Systems by limiting the risks to suffer damages, as such settings and their purpose are defined herein. However, AAA neither undertakes to achieve nor guarantees that the Client will achieve a specific result by using the Platform.`,
    eText62: `2.8.4. By entering into this agreement, the Client declares to AAA that he/she accepts the risks inherent to the performance of transactions in the financial instruments supported by the Platform. He/She declares in particular that he/she is aware that the transactions on financial instruments involve risks causing the reduction of the value of investments and that AAA bears no liability for these risks. The Client confirms that before entering into this agreement he/she has read AAA’s documentation on the risks involved with transactions in the specific financial instruments (risk disclosure available through the link`,
    eText63: `https://aaafx.com/Assets/AAAFx/RiskDisclaimer.pdf`,
    eText64: `together with the additional risk disclosure attached to this agreement). The Client also declares that he/she is aware and accepts the risks arising by the use of the Autotrading services, which are based on the investment strategies of third parties and are not determined by AAA.`,
    eText65: `2.8.5. AAA is not responsible for any damage incurred by the Client (or a third party having any relevant right through the Client) due to force majeure (including the exercise of the right of strike, the malfunction of computers including the breakdown of computer systems or electronic communications), fraudulent usage of the data stored in its records and servers by third parties, failure of its electronic systems due to malfunctions of the communications’ network or malfunctioning of its software and hardware that is not attributable to the lack of maintenance or monitoring, as well as events related to the operation of third parties, such as interruption or suspension or limitation of the activities carried out by stock exchanges, Executing Firms, companies settling transactions, credit institutions or investment firms with whom AAA potentially deals or cooperates in the context of this agreement for whatever reason.`,
    eText66: `2.8.6. AAA is not responsible for the actions or omissions of the Executing Firm or any other third party company with respect to the transactions carried out on behalf of the Client for whatever reason.`,
    eText68: `2.8.7. In any case, AAA does not guarantee that the provision of the Autotrading services will have a positive result or a specific performance. Any reference to the performance of transactions being carried out by users of the Platform or Systems Providers is related exclusively to the past and the Client declares that he/she is aware of such fact. Any reference to the performances of any System Provider may not take into account the costs of transactions, such as Broker fees or any other fees applicable to the Client’s Autotrading Account in the context of this Agreement.`,
    eText69: `2.8.8. The Client is also aware that the transactions concluded by each System Provider on the Provider’s own account, by using the System developed by the Provider, may refer to amounts greatly different from the amounts allocated by the Client to each transaction. This may itself cause a significantly different result compared to the Provider’s profits or losses even if the remaining characteristics of the positions opened by the Client are the same as the ones of the positions of a specific Provider. In addition, particular limitations may be applied to the client’s trading account by the Executing Firm with which such trading account is held, which may not necessarily be applied to the System Provider at the same time. Such limitations may be due to specificities of the regulatory framework applicable to each Executing Firm or to the Executing Firms’ policies. The application of such limitations on the client’s trading account may lead to further divergences between the results of the System Providers’ transactions and the Client’s transactions, especially if the System Provider’s trading account is not subject to the same limitations.`,
    eText70: `3. Suitability test`,
    eText71: `3.1. In general`,
    eText72: `3.1.1. Before entering into this agreement, the Client has provided AAA with information related to his/her investment profile, in order for AAA to examine if the provision of the Autotrading service is suitable for the Client. The data under examination for this purpose refer to (a) the educational level and the experience of the Client, in order to assess whether the Client is able to realise the nature and the risks of such service as well as of the financial instruments related to such service, (b) the investment objectives of the Client, including the Clients’s risk apetite, in order to assess if they are in line with the characteristics of the service and the available financial instruments, (c) the Client’s financial situation and his/her ability to undertake the investment risks, in order to be assessed whether the Client is able to bear the potential loss of the invested amounts as well the limit to which such losses should be limited.`,
    eText73: `3.1.2. The Client declares that the data provided by him/her are true and reflect his/her actual situation. The Client fully understands that the Autotrading service has primarily a speculative orientation and requires the undertaking of investment risks which cannot be fully avoided, although they can be limited with respect to their extent through the usage of the Platform’s tools. In the event that the Client believes that the above description does not match with his/her actual investment profile, he/she must not proceed to the acceptance of these terms and quit the Autotrading Account opening process.`,
    eText74: `3.1.3. Moreover, the Client undertakes to inform AAA, on his/her own initiative, in case of change of the data communicated to AAA through the questionnaire, as well as to re-fill out the questionnaire at regular intervals as requested by AAA. The Client is not able to oppose against AAA any such changes if he/she has not previously informed AAA in writing of the specific changes.`,
    eText75: `3.2. Risk Categorisation`,
    eText76: `3.2.1. AAA categorizes the Client based on the suitability questionnaire filled out by him/her according to the risk AAA determines from time to time. As a consequence of such risk categorization, AAA applies to the Client’s Account a “Risk Tolerance Index” which is defined as a percentage of the total amount of the Client’s deposit with the Executing Firm (for example 25%, 50%, 75% or as otherwise AAA defines such index per risk category). The application of the Risk Tolerance Index is ensured by the activation of a special tool of the Platform, which monitors the Client’s total losses and, if such losses approach the Index applied to the Client’s Account, proceeds to the generation of signals closing all the open positions of the Client. The activation of this tool, in principle allows to limit the losses to the amount corresponding to the Risk Tolerance Index. However, in case of special circumstances not allowing the immediate execution of position closing orders, the Client may suffer further losses due to rapid fluctuations taking place between the time of production of the relevant trading signal from AAA and the time of actual execution of such signal. Such special circumstances may relate to the foreign exchange market generally or to one or more currency pairs or to the Executing Firm with which the client’s trading account is held or to a combination thereof. Therefore, the Customer understands and accepts the risk of having losses greater than his Risk Tolerance Index, for which AAA shall not be liable.`,
    eText77: `3.2.2. The Client accepts that, in the context of his/her risk categorization in accordance with the above, AAA shall take into account not only his/her answers related to his/her risk appetite but also any other information included in the suitability questionnaire. He/She also accepts that the application of the Risk Tolerance Index based on the categorisation of the Client is mandatory.`,
    eText78: `3.2.3. The Client is able to request to be classified in a lower risk category (without justifying his/her decision) or to a higher risk category (provided that he/she will provide additional data changing the result of his/her risk evaluation).`,
    eText79: `3.2.4. Unless the Client’s Executing Firm is AAA, the amount of the Client’s deposit with the Executing Firm, to which the Risk Tolerance Index is applied, shall be defined based on the information provided by the Client to AAA. If the Client adds or withdraws amounts to or from his/her deposit with the Executing Firm, the Client shall immediately inform AAA by e-mail in order for the latter to readjust the amount covered by the Risk Tolerance Index.`,
    eText80: `3.2.5. In the case that the Client has selected more than one Systems, it is up to the Client to determine the allocation of the protection percentage among the different Systems (for example if the Client has an Index of 50%, he/she may select a loss tolerance up to 35% in the System A and up to 15% in the System B, in order for the total limit to be 50%).`,
    eText81: `3.2.6. In case of money losses due to damages up to the maximum tolerance percentage, the Account of the Client in the Platform will continue to replicate the signals of the selected Systems only if the Client explicitly requests so. In such case, the Client’s risk profile shall be evaluated again based on the remaining amounts of the Client’s deposit (to which the relevant limits will be applied).`,
    eText82: `3.2.7. In case of execution of specific transactions (opened positions) carried out on Client’s own initiative through the Platform (manual trading), taking into consideration that such transactions are not included in the context of this Αgreement, the above Account’s settings on the protection of the Client’s deposits will not be applicable.`,
    eText83: `3.2.8. In certain cases, the Client’s positions held with the Executing Firm may be closed before reaching the Client’s Risk Tolerance Index, due to the application by the Executing Firm of specific limitations to the Client’s
        positions (such as close-out limits), either based on applicable regulatory provisions or based on the Executing Firm’s policy. In such cases, AAA will not be held liable for the closing of such positions and AAA shall not be under
        any obligation whatsoever to inform the Client about any such additional limitations.`,
    eText84: `4. Communication principles – Client information`,
    eText85: `4.1. Means of Communication`,
    eText86: `4.1.1. The communication between the parties takes place, depending on the case, either in writing or orally. More particularly:`,
    eText87: `The written communication takes place via documents exchange/shipping, via fax or via electronic mails being sent to the electronic addresses of the parties or via the Platform’s dedicated application for messages and
        communication. The Client hereby states that he/she accepts e-mail communications as suitable for the reception of any kind of information with respect to the service provided under this agreement.`,
    eText88: `Oral communication takes place in principle via telephone. AAA is entitled to tape record the telephone conversations between its executives and employees and the Client, in order to prove the content of such conversations,
        for purposes related to the protection of the transactions and the smooth operation of the capital markets (complying with the applicable legislation) as well as the protection of the mutual interest of AAA and the Client.
        Taking into consideration the applicable legislation, AAA is entitled to not respond to telephone calls, if such calls do not take place via telephone numbers connected to a recording device. The Client accepts that all
        telephone conversations with AAA may be recorded and stored, according to the aforementioned, regardless of any prior announcement to the Client, such as a message informing that a call is about to be recorded. AAA may be
        obliged to communicate the content of the said Communications to the relevant supervisory authority in accordance with its Data Privacy Policy that is available on AAA’s website (`,
    eText89: `https://aaafx.com/privacy`,
    eText90: `). AAA shall not be responsible for any damage caused to the Client due to errors in the content of the transcriptions of the conversations,
        unless such errors are attributable to willful misconduct or gross negligence of AAA’s executives, employees or agents.`,
    eText91: `4.1.2. When this agreement refers to a written notification from AAA to the Client, such notification shall be made via e-mail at the latest e-mail address duly notified by the Client to AAA, unless expressly and specifically agreed
        otherwise. The Client expressly agrees and accepts that, when he/she declares to AAA specific contact details (e.g. fax number, e-mail address etc.), the way of communication concerned by such details shall be considered as adequate
        and appropriate for the reception of any kind of information provided by AAA. AAA shall not be liable in case of change of contact details without prior written notification by the Client. Information and delivery of data to the
        Client’s representative, or other person delegated by him/her, shall be equivalent to delivery of information to the Client in person and discharge AAA from any relevant obligation and liability.`,
    eText92: `4.1.3. AAA draws the Client’s attention to the fact that electronic mail is the most frequent communication method used by AAA. The Clients assures AAA that he/she will have regular access to and check on a day-to-day basis the
        e-mails sent to the electronic address he/she has communicated to AAA. The Client may send inquiries to AAA as well as be informed by AAA via, among other, the aforementioned way of communication. The documents sent to AAA by the
        Client, including fax documents and messages in electronic form or sent via electronic means, which contain the Client’s statements and communication with AAA, as well as the magnetic and electronic means on which the Client’s
        instructions, statements and communication are recorded, shall constitute full evidence against the Client with respect to the content of its communication and arrangements with AAA; When the information to be provided is not
        addressed personally to the Client (e.g. general information addressed to all the clients of AAA), AAA may provide such information through its website www.aaafx.com) or the Platform’s website.`,
    eText93: `4.2. Communication Language`,
    eText94: `The communication language is English. AAA also supports oral communication or Platform layout in other languages, as indicated on the Platform’s website. The Client declares that he has sufficient knowledge of the English language in order to be able to understand the content of the information provided in English or any contractual documentation in English he may receive from AAA.`,
    eText95: `4.3. Confidentiality`,
    eText96: `AAA will keep the information provided by the Client confidential under this agreement and take the necessary internal measures in order to ensure the confidentiality of such information. Confidentiality does not extend to any data
        or information`,
    eText97: `a) That AAA has an obligation to disclose to the public or any competent authority,`,
    eText98: `b) That have to be invoked by AAA before a Court or any competent authority for AAA’s defense with respect to a case pending against AAA relating to the relationships governed by this agreement,`,
    eText99: `c) That have to be communicated to companies cooperating with AAA in order for the obligations undertaken by AAA under this agreement to be fulfilled.`,
    eText100: `4.4. Content of information on the Autotrading service`,
    eText101: `4.4.1. AAA provides the Client with ongoing access to a personalized area of the Platform, in which he/she enters by using his/her individual codes, for the purpose of receiving at any time information on the transactions carried
        out at any period of time. In any case of order execution, opening-closing of position or other change in the Account, AAA shall send to the Client relevant notice (alert) via e-mail. Platform tools provide the Client with displays
        of the Account performance and its comparison with performance of any System.`,
    eText102: `AAA provides 24-hour telephone helpline for platform and data extraction issues.`,
    eText103: `4.4.2. AAA sends by e-mail a periodic statement every month, in accordance with the specific arrangements applied by AAA and in compliance with the current regulatory framework governing its services. Such statement shall include
        information on the positions held by the Client on the last day of the preceding month, and in particular the overall valuation of positions and each of these separately, Account performance during the said period, information on
        the fees received by AAA and costs incurred by the Client through AAA (not including costs charged by the Executing Firm) and other relevant information. To the extent that such information relates to data obtained from the
        Executing Firm, AAA shall make every reasonable effort to provide accurate information to the Client. However, given the direct relationship between the Client and the Executing Firm, the Client must verify the information on the
        basis of the information he/she receives from the Executing Firm.`,
    eText104: `4.4.3. In the event that the total loss of the Client reaches 75% of the Client’s Risk Tolerance Index (i.e. the maximum loss set for the Client according to his/her risk classification according to clause 3.2.1. above), AAA shall
        automatically send to the Client an e-mail notifying him/her such fact in order to take appropriate measures at his/her discretion (e.g. to remove a System from his/her Account, to reduce the number of lots by which he/she follows
        the selected Systems etc.).`,
    eText105: `4.4.4. Where the the overall value of the autotrading account, as evaluated at the beginning of each reporting period, depreciates by 10 % and thereafter at multiples of 10 %, AAA will notify the Client via e-mail no later than the
        end of the day in which the threshold is exceeded. The evaluation of the depreciation percentage regarding the Client’s positions may take place only once a day, at a specific time determined by AAA and with an evaluation method as
        determined from time to time by AAA.`,
    eText106: `4.5. Probative force of book files and computer records`,
    eText107: `Any copy or extract of the forms, data, books, records and files issued or kept by AAA, either in paper or electronic form – in accordance with applicable legislation - or any extract of the Autotrading Account held by AAA under
        this agreement, which is exported by AAA and displays the history of the Account’s operation, shall constitute full proof for the content of the Account, provided that it contains data and information notified to the Client.
        Similarly, extraction of logs regarding every aspect of the operation of the Client’s Account, including logs relating to Client's entry into the Platform and any type of use of the Platform by the Client, shall constitute full
        proof of the related facts.`,
    eText108: `4.6. Liability for transmission errors (including orders-instructions)`,
    eText109: `In cases of delay, interruptions or other errors that may occur during the transmission of electronic mail or Fax, as well as in cases of incorrect translation or interpretation of technical terms, AAA shall only be liable for
        willful misconduct or gross negligence of its executives, agents or employees. In any case of improper execution or transmission of declaration of intention/order, the Client waives his/her right of annulment of any transaction.`,
    eText110: `5. Fees`,
    eText111: `5.1. Fees charged to or paid by the Client`,
    eText112: `5.1.1. Classic Account Fees`,
    eText113: `Unless explicitly indicated otherwise by AAA, the following fees will be applicable in the case the Client holds a "Classic Account".`,
    eText114: `(a) Execution through AAA trading account`,
    eText115: `When the Client executes orders through a trading account held with AAA, AAA shall charge the Client’s trading account with the execution fees applicable in accordance with the trading account agreement.`,
    eText116: `(b) Execution through third party Executing Broker`,
    eText117: `When the Client executes orders through a third party Executing Broker (i.e. where AAA is not an Executing Firm), the Executing Firm, based on an explicit authorization by the Client, shall pay to AAA on behalf of the Client an amount determined based on the fees that the Executing Firm receives from the Client (e.g. spread between the bid/ask prices or cost to initiate a trade through the bid/ask spread or fees determined through any other applicable method).`,
    eText118: `5.1.2. Profit Sharing Account Fees`,
    eText119: `In the case of a Profit Sharing Account, the Client shall pay a monthly fixed Subscription Fee at the start of the calendar month (calculated on a pro-rata basis for periods of less than a full month). In addition to the Subscription Fee, the Client shall pay to AAA a percentage based Monthly Performance Fee per selected System. The Monthly Performance Fee shall be calculated on a monthly basis and shall be based upon the Client’s profit made from each selected System that exceeds the Benchmark. For the purpose of this agreement, a “Benchmark” is defined as the net new high in profit of a SP System at the end of a given monthly period, during the life of the Account. Profit shall be determined by taking into consideration the Client’s equity at the end of each month, on all trading activity from each selected System (which includes all open and closed positions) during the same month, comparing it to previous Benchmark(s).`,
    eText120: `The Performance Fee will be applicable separately on each System and not in the aggregate performance of the Account as a whole. To open a Profit Sharing account the Client must provide one or more payment methods. In case the Client’s primary payment method is declined or no longer available to AAA for the payment of the Subscription Fee and/or Performance Fees, AAA shall charge any one of the other payment methods associated to the Client’s account, as the case may be, including but not limited to the Client’s trading account with the Executing Firm. In such case, AAA will proceed to the collection of the Fee from the Executing Firm on a best effort basis, without further direction or confirmation from the Client, unless otherwise notified in writing. AAA shall not be held responsible or liable for any miscalculation or non-payment of said Fees for any reason whatsoever.`,
    eText121: `The Client shall remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and the Client does not cancel his/her account, AAA may suspend access to the account until it has successfully charged a valid payment method. For some payment methods, the issuer may charge the investor certain fees, such as foreign transaction fees or other fees relating to the processing of the payment method. Local tax charges may vary depending on the payment method used. The Client should check with his/her payment method service provider for further details`,
    eText122: `The Client can cancel the Profit Sharing account subscription at any time, and will continue to have access to the account through the end of the billing period. Payments are non-refundable and AAA does not provide refunds or credits for any partial - month subscription periods or unused Profit Sharing Account. To cancel the subscription, the Client should follow the relevant instructions of the Platform.`,
    eText123: `The amounts of the Subscription Fee and Performance Fees are available on AAA’s website and shall be communicated to the Client in the context of the opening a Profit Sharing account. AAA may change the said amounts from time to time; however, any such changes will apply to subsequent billing cycles following notice of the change(s) to the Client.`,
    eText124: `By utilizing a Profit Sharing account, you agree to be bound by the Agreement on the Storage of the Cardholder’s Credentials that can be found `,
    eText125: `here`,
    eText126: `5.2. Introduction fees received from third parties`,
    eText127: `When the Client executes orders through another entity (i.e. where AAA is not an Executing Firm), AAA may receive from the Executing Firm fees remunerating the introduction of the Client by AAA to the Executing Firm, as such fee will be communicated from time to time to the Client. It is hereby clarified that AAA does not have any discretion in case of the choice of any specific Executing Firm by the Client and, therefore, any such introduction fee cannot affect the Client’s freedom to choose any other Executing Firm. The above introduction fee is independent from the services provided by AAA to the Client by virtue of this Agreement.`,
    eText128: `5.3. Payment of fees by AAA to third parties`,
    eText129: `5.3.1 Payments to platform technology providers`,
    eText130: `AAA shall pay to third parties (which may be related entities or not) fees associated with the services provided by AAA to the users of the Platform. Such fees are related to the use of the software and technological infrastructure of the Platform and network of System Providers of the said entity, without which the provision of the Autotrading service to the Client would not be possible.`,
    eText131: `(a) For Classic Accounts`,
    eText132: `The fee paid to the third party technology providers will be calculated on a transaction volume basis, as it may be communicated to the Client from time to time.`,
    eText133: `(b) For Profit Sharing accounts`,
    eText134: `AAA shall pay to the third party technology provider(s) an amount up to the amount of the applicable monthly Profit Sharing Account Fees paid by the Client in accordance with paragraphs 5.1.1. and 5.1.2.`,
    eText135: `In both cases hereunder, part of or the entire amount of the fee may be paid by the third party entity to the System Providers based on the agreement entered into between them. Additional information regarding the payment of fees to the above third parties is available in AAA’s Trader Guide which may be accessed through the following link:`,
    eText136: `https://www.zulutrade.com/affiliate-guide`,
    eText137: `5.3.2. Fees to introducing persons`,
    eText138: `In the event that the Client has been introduced to AAA by a third party (hereinafter the “introducer”), AAA may pay fees to such person, the amount of which is determined based on the fees received by AAA with respect to the transactions of the Client. In particular, the fee paid to the introducer (if applicable) is part of the fees received by AAA and related to the Client. The introducers used by AAA are not employees of AAA and cannot provide investment services to clients. The introducers are in any case independent businesses or freelancers who undertake, each of them, under a special agreement with AAA, to indicate to AAA persons who wish to become clients (client recommendation agreement). The role of the recommended person is limited to introducing AAA to prospective investors-clients and the services it provides, so as each investor-client enters into an agreement with AAA for the provision of investment services. In the event the Client wishes to know whether he/she belongs to the category of clients in respect of which AAA grants payments to third parties, as well as to obtain further information as of the fees of the introducers (if applicable), the Client may communicate with AAA.`,
    eText139: `The payment of fees by AAA to introducers does not adversely affect the services provided to the Client, or the Client’s interest in general, neither does it lead to additional charges by the Executing Firm. On the contrary, the
        cooperation of AAA with introducers facilitates clients and potential clients to have access to the services of AAA and ensures the best possible relationship and information at the precontractual stage.`,
    eText140: `6. Provision of authorisations`,
    eText141: `The Client hereby authorizes AAA to access the account held by the Client with the Executing Firm, via all appropriate technical means and - to the extent necessary - the relevant competent personnel and executives thereof, in order`,
    eText142: `a) to direct to the Executing Firm the orders that will result from the operation of the Platform;`,
    eText143: `b) to obtain full information about the trading account connected to the Platform and held with the Executing Firm, in order to be able to provide comprehensive information to the Client via the Platform and to monitor the exposure of the client at risk, for the purposes of effective implementation of the Risk Tolerance Index;`,
    eText144: `c) to obtain information on the client, especially in the context of the Client’s identification for the purpose of prevention of money laundering,`,
    eText145: `d) to proceed to any other necessary consultation and collaboration with the Executing Firm in order for the purpose of this agreement to be fulfilled.`,
    eText146: `7. Client’s Data`,
    eText147: `7.1. Client’s identity`,
    eText148: `For proof of the Client's identity and of any representatives designated by him/her, AAA may rely upon any document that, by law, is regarded as proof of identity of a person or legal entity. AAA shall not be responsible for the
        validity, legal integrity or authenticity of such documents. If the Client suffers loss by error, misunderstanding, deception or fraud as to the person giving instructions to AAA to carry out a specific action, AAA shall only be
        liable for willful misconduct or gross negligence of its executives and agents. In any case the Client waives the right of annulment of any transaction.`,
    eText149: `7.2. Signature specimens`,
    eText150: `AAA is entitled to receive and keep specimen signatures of the Client and his/her representatives, if necessary for the fulfilment of the services provided.`,
    eText151: `7.3. Change of data`,
    eText152: `The Client shall notify AAA on his/her own initiative, without delay, of any change of name, contact information (especially e-mail address) and other data he/she has provided to AAA when entering into this agreement, as well as any change regarding the powers of representation granted to other persons (if applicable). The Client is not entitled to oppose against AAA any change of the above data, if such change has not been notified to AAA in writing.`,
    eText153: `7.4. Acts with a Client’s representative`,
    eText154: `All acts of AAA towards a representative of the Client shall be deemed valid for the duration of the power of representation, unless there is prior notification to AAA in writing for the revocation or amendment of such power.`,
    eText155: `7.5. Personal Data`,
    eText156: `The Client’s personal data, as disclosed to AAA from time to time, will be stored, processed and disclosed to third parties for the purpose of providing the services and complying with AAA’s regulatory or other obligations, in
        accordance with AAA’s Data Privacy Policy, which is notified to the Client by the document “Data Privacy Policy” (available through the link`,
    eText157: `https://aaafx.com/privacy`,
    eText158: `and the links provided therein). In the course of providing the Client with the services contemplated under this Agreement, AAA may need to disclose some or all of the Client’s personal data, whether sensitive or otherwise, to its employee/s, associate/s, agent/s, sub‐contractor/s, product provider/s and/or investment institution/s, (all of which shall be made subject to such confidentiality and data protection obligations as shall be considered necessary by AAA in order to comply with its obligations under this Agreement) for the purpose of providing the said services and/or to any regulatory or public authorities to comply with its regulatory or other obligations in terms of law. For these purposes, the Client explicitly consents to the transfer of his/her personal data, whether sensitive or otherwise to other countries in accordance with the applicable Data Protection laws and regulations. The Client is hereby giving his explicit consent to the processing of personal data, whether sensitive or otherwise, as contemplated in and for the purposes provided for in this Clause. The Client has a right of access and rectification of his/her personal data, as indicated in the aforementioned “Data Privacy Policy”. Whilst AAA may request that the Client reconfirm his/her personal data from time to time, the Client must notify AAA immediately in writing if such data has changed.`,
    eText159: `8. Final provisions`,
    eText160: `8.1. Duration – Termination`,
    eText161: `This agreement is of indefinite duration. The agreement shall take effect after acceptance by the Client and upon the opening of the Account by AAA, following the necessary verifications, and shall expire upon termination by either of the Parties. Termination shall be in writing and its effects shall occur within 15 days following the date on which it was received by the other party. The termination shall not affect the validity of orders transmitted to the Executive Firm.`,
    eText162: `8.2. Governing law – Jurisdiction`,
    eText163: `8.2.1. All contractual relationships between AAA and the Client are governed by Greek law. Place of performance shall be deemed to be the place of the registered seat of AAA.`,
    eText164: `8.2.2. The courts of Athens will be competent for any dispute between AAA and the Client arising from the contractual relationship. AAA has the discretion to choose the courts of general jurisdiction of the Client for the exercise of its claims against the Client.`,
    eText165: `8.3. Other terms`,
    eText166: `8.3.1. The Client represents and certifies to AAA that`,
    eText167: `a) all amount of money used for transactions belong to the Client, are from a legal source and do not derive from acts or activities falling within the prohibitions of the laws on the prevention and suppression of money laundering;`,
    eText168: `b) generally the Client shall comply with the legal provisions and shall not engage in illegal or prohibited actions or practices;`,
    eText169: `c) all data available to AAA regarding the Client and the origin of his/her assets are true.`,
    eText170: `8.3.2. The terms of this agreement, including any additional agreements between AAA and the Client in the context of this agreement, shall only be amended by a written agreement between the parties (including by way of acceptance by electronic means), without prejudice to the provisions of the following paragraph.`,
    eText171: `8.3.3. AAA may unilaterally amend the terms of the present agreement, by a written notice of the amendments (including by e-mail). In the event of objections as to the amendments, the Client may terminate in writing this agreement within one month from receipt of the notice of the amendments, in which case the agreement shall be deemed not to have been amended. Upon expiration of the onemonth period without action, it shall be deemed that the Client accepts the amendments. The Client shall not be able to raise objections in case of amendments on legislation, or issuance of regulatory decisions or interpretative circulars affecting the agreement, or amendment of the principles or practices as to market operation and participants thereof. It is understood that in this case the Client is entitled to terminate the agreement.`,
    eText172: `8.3.4. Specific written arrangements between AAA and the Client (e.g. in relation to a particular transaction or group of transactions) shall prevail over this agreement.`,
    eText173: `8.3.5. In accordance with the Applicable Regulations, AAA is required to put in place internal procedures for handling complaints fairly and promptly. AAA only accepts complaints received in writing either by submitting the relevant complaints’ form or by sending to AAA an email and/or letter. The Client should submit all supportive documentation that may assist AAA in its investigation along with the written complaint. AAA will send a written acknowledgement of the reception of the complaint promptly following receipt, enclosing details of the complaints procedure, including when and how the Client may be able to refer his/her complaint to the relevant competent authorities and/or alternative dispute resolution mechanisms. Further details regarding AAA’s complaints procedures are available on AAA’s website and/or upon request by communicating with AAA’s customer support service.`,
    eText174: `8.3.6. Any delay or omission of AAA to exercise any statutory or contractual right, in no way shall be deemed or construed as a waiver of the respective right`,
    eText175: `8.3.7. In case one or more the terms of this agreement is or becomes unenforceable, it shall not affect the validity of the remaining terms and the remaining provisions shall remain in force.`,
    eText176: `8.3.8. Any rights and claims of the Client arising from his/her relations with AAA under the present, shall not be assigned or transferred in any way to third parties unless otherwise agreed in writing`,
    eText177: `Refund Policy`,
    eText178: `ZuluTrade will issue a refund to an investor for losses caused by Platform Errors, if the investor provides timely notice of the error to ZuluTrade as described below. A Platform Error occurs if a trading signal that should have been rejected according to the investor’s account settings is accepted by ZuluTrade and submitted to the investor’s broker, or if a trading signal that should have been accepted according to the investor’s account settings is rejected by ZuluTrade and not submitted to the investor’s broker. ZuluTrade shall not be responsible for refunding lost profits resulting from Platform Errors under any circumstances.`,
    eText179: `Errors of Signal Providers or Brokers shall not be considered Platform Errors, and ZuluTrade shall not be responsible for refunding losses or lost profits resulting from such errors under any circumstances.`,
    eText180: `In order to receive a refund of losses caused by Platform Errors, you must notify ZuluTrade in writing of your potential claim. You must deliver the written notice to ZuluTrade as soon as you knew, or should have known, of the Platform Error, but in any event, no later than twenty-four hours after the Platform Error occurred. If ZuluTrade does not receive such notice from you within twenty-four hours after the Platform Error occurred, it will not be liable for any losses resulting from the Platform Error.`,
    eText181: `If ZuluTrade confirms that a Platform Error occurred with respect to your account, and you provided the required notice to ZuluTrade within twenty-four hours, you will receive a refund of your losses caused by the Platform Error (not including lost profits). ZuluTrade processes all Refunds requests within 20 days following the acknowledgment of the receipt of the Refund request.`,
    eText182: `Risks Related to the use of Autotrading`,
    eText183: `I. Risks associated to the financial instruments available to the Platform`,
    eText184: `Trading leveraged financial instruments involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the market of FX and CFD instruments, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the FX – CFDmarket. Trading involve a real risk of loss. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.`,
    eText185: `You are required to review your Account statements and monitor your Account status at least daily to ensure that your trades were placed, and that all trades that were made on your Account were authorized by you and also monitor your Broker’s reports and platform with respect to your Trading Account, as highlighted in clause 3.3. hereof.`,
    eText186: `You are required to review your Account statements and monitor your Account status at least daily to ensure that your trades were placed, and that all trades that were made on your Account were authorized by you and also monitor your Broker’s reports and platform with respect to your Trading Account, as highlighted in clause 3.3. hereof.`,
    eText187: `* ZuluTrade's refund policy does not apply to IC Markets Investor accounts`,
    eText188: `II. Risks associated with the service of autotrading and the use of the Platform`,
    eText189: `1. References to past performance`,
    eText190: `Past performance posted by System Providers, is not necessarily indicative of future results. No representation is made that any account is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particularly trading program.`,
    eText191: `2. Limitations of hypothetical performance results`,
    eText192: `Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    eText193: `One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.`,
    eText194: `The hypothetical performance results displayed on the Platform’s website are hypothetical results in that they represent trades made in a demonstration ("demo") account. Transaction prices were determined by assuming that buyers received the ask price and sellers the bid price of quotes AAA receives from the Executing Firm at which a System Provider maintains a demo account. The hypothetical results do not include any additional mark-ups or commissions which may be charged by a customer's Forex broker and are based on a one lot trade size. Trades placed in demo accounts are based on a System Provider having access to an unlimited amount of funds. As a result, demo accounts are not subject to margin calls and have the ability to withstand large, sustained drawdowns which a customer account may not be able to afford. Trades placed in demo accounts are not subject to price slippage which may occur when a signal is actually traded in a customer account.`,
    eText195: `3. Differences in lot sizes and bid/ask price`,
    eText196: `The number of pips gained or lost by each System Provider may be based on the trading of mini, micro or standard lots. The performance of customers electing to trade a different lot size from those used by a System Provider will therefore vary. Further, customers may place trades independent of those provided by a Signal Provider or place customized orders to exit positions which differ from those of a Signal Provider. All performance results presented only include the results of completed trades and do not reflect the profit or loss on open positions. Due to differences in the bid/ask offered by various counterparties, all trades executed in the account of a System Provider may not be executed in a customer account if the bid/ask of the Forex broker at which the customer maintains the customer's account is different from that of the Signal Provider's broker or due to volatility in the market.`,
    eText197: `4. Partial replication of Systems’ signals`,
    eText198: `Customers may elect not to follow all of the trading signals provided by the signal providers or not be able to trade the recommended number of contracts due to insufficient funds in an account. Therefore, the results portrayed are not indicative of an account which may have traded all a Signal Provider’s signals or contracts. Further, by electing to follow a number of different Signal Providers at one time, customers may not be able to follow all of the signals generated due to the customer’s account having insufficient funds. Accordingly, the performance of customer accounts may vary significantly from the results portrayed on this website.`,
    eText199: `5. Interpretation of results of other followers’ accounts`,
    eText200: `Past performance is not indicative of future results. Note that the accounts represented may not follow all of the trading signals provided by the System Providers or trade the recommended number of contracts. Therefore, the results portrayed are not indicative of an account which may have traded all the recommended signals or contract of the Providers. The number of System Providers followed by these accounts may also vary. Accordingly, performance results may vary substantially from account to account, depending on the number of signals and contracts traded and signal providers followed.`,
    eText201: `This website does not make any representation whatsoever that the above mentioned trading systems might be or are suitable or that they would be profitable for you. Please realize the risk involved with trading foreign exchange investments and consult an investment professional before proceeding. The trading systems herein described have been developed for sophisticated traders who fully understand the nature and the scope of the risks that are associated with trading. Should you decide to trade any or all of these systems' signals, it is your decision.`,
    eText202: `III. Overview of derivative financial instruments`,
    eText203: `The document is not designed to present an exhaustive analysis of the risks associated with financial instruments. The purpose of this document is to provide clients with summarized information and with a general warning about the risks associated with derivative financial instruments so that the clients have a reasonable understanding of the general nature and risks of such financial instruments and, as a consequence, are able to make informed investment decision.`,
    eText204: `1. Overview`,
    eText205: `Derivatives are contracts that give one person the option or right to obtain from another person over the course of the investment period or on maturity of the derivative itself, an asset, the price of which is subject to fluctuation, or interest rate, for a price or obligations determined at the conclusion of the contract. The parties can negotiate on market or by mutual agreement (OTC).`,
    eText206: `These instruments are referred to as "derivatives" because they represent rights and financial commitments the value of which vary or is derived from underlying assets or liabilities.`,
    eText207: `There are various types of derivatives differentiated by the nature of the underlying instrument (shares, bonds, money-market instruments, interest rates or exchange rates, stock exchange indices or commodities etc.).`,
    eText208: `There are many combinations of products in which it is possible to invest and therefore derivatives are characterized by very diverse risk profiles. Some are characterized by limited risk and unlimited potential upside for one party while the other party takes an inverse position because it exposes itself to potentially unlimited losses and potentially limited gains.`,
    eText209: `In the same way, the mode of settlement of a product may have a significant impact on the types of risks associated with that product. Whilst derivatives that are settled by a cash payment are mainly subject to counter party risk
        and market risks, those that are physically settled with the delivery of the underlying instrument are subject to the same risks and directly to risks relating to the relevant underlying instrument and its delivery.`,
    eText210: `Besides the structure of the relevant product, much of the risk associated with derivatives contracts results from the fact that these contracts are leveraged. This means that it is necessary only to pay a part (by paying a premium or a deposit) of the total exposure to market risk opening and maintaining a position. The actual exposure to market risk with derivatives contracts can be an amount that is several times the premium or deposit paid.`,
    eText211: `For derivatives, market risk encompasses the exposure to changes in the value of market parameters such as interest rates, exchange rates, share prices, index movement or commodities prices, but also exposure to variations in the
        price of the underlying instrument or to other factors such as the implied volatility or the time value.`,
    eText212: `Given the wide range of risk profiles as regards derivatives, it is important to understand the specific risk/yield of any strategy in relation with the relevant product.`,
    eText213: `2. Option contracts`,
    eText214: `The buyer of an option acquires the right to buy (call) or to sell (put) to the seller a given quantity of an underlying instrument at a price determined beforehand (the strike price), or to receive an amount of monies equal to the difference between the strike price and the current price of the underlying instrument, on a definite date (a "European Option") or at any time until the maturity date (an "American Option"). The price paid for this right is called
        the premium. The seller of an option makes a commitment to the buyer to sell (call) or to buy (put) the underlying instrument or to receive an amount equal to the difference in the price of the underlying instrument, at the strike
        price, whatever is the current price of the underlying instrument.`,
    eText215: `Market risk will be limited for the buyer of an option to the amount of the premium (revalued as the case may be) and it will be considerably higher for a seller.`,
    eText216: `The buyer takes counter party risk on the counter party with which it has negotiated the option.`,
    eText217: `3. Futures contracts: forwards and futures`,
    eText218: `A party to a forward or futures contract makes a commitment to receive or to deliver when due, a definite quantity of an underlying instrument, at a price determined at the time the contract is agreed. A party can also receive a
        payment if the value of the underlying instrument increases, while the other party can receive a payment in case of decrease in the value of the underlying instrument between two dates.`,
    eText219: `Futures are standardized instruments traded on a stock exchange (regulated or organised markets). They are contracts standardized as regards the quantity of the underlying instrument and the due dates for delivery or payment.
        Futures on commodities or physical goods can be generally distinguished from purely financial futures where the underlying instrument is a financial instrument.`,
    eText220: `Forwards are traded by mutual agreement (OTC). Their terms are either standardized, or agreed between the buyer and the seller.`,
    eText221: `In a forward sale, underlying instruments must be delivered at the price and the time agreed on the conclusion of the contract, even if the price of the underlying instrument has risen above this agreed price in the meantime. The risk of loss thus lies in the difference between these two values. In theory as prices can have an unlimited upside, the exposure to potential loss is also potentially unlimited. In a forward purchase, the delivery of underlying
        instruments must be at the price and time agreed when the contract is concluded, even if the price of the underlying instrument has fallen below the agreed price in the meantime. The risk of loss thus lies in the difference between
        these two values. At most it is possible to lose the amount corresponding to the agreed initial price.`,
    eText222: `A future rate agreement ("FRA") is a fixed income product particularly used to exchange a difference in interest rates in the same currency at a predetermined date. There is no exchange of any nominal amount, either at the beginning or at the end of the transaction. For example, a FRA allows an interest rate to be locked in. Used as a speculative product (in case the transaction does not provide for any financial instruments to be hedged), this product allows an investor to speculate on the increase (purchase of FRA) or the decrease (sale of FRA) of the reference-floating rate. The principal risk in relation to FRAs is linked to their sensitivity to interest rate movement.`,
    eText223: `4. Swap`,
    eText224: `At its most general, a swap is a contract by which the parties to it exchange interest flows or currencies.`,
    eText225: `Swaps can be distinguished according to their subject matter, the two main types or swap being the currency swap (or the exchange swap) and the interest rate swap.`,
    eText226: `The first one is a double transaction on currencies by which one party sells to another party at the spot price, an amount of currency and as a counterpart buys an amount in another currency, and agrees to buy it back at maturity at an agreed price which corresponds to the spot price increased or reduced by the difference in the interest rate between the two investments, given that the two swaps have equivalent terms for each of the relevant currencies. The
        main risk linked to this type of swap is thus interest rate risk on exchange rates, foreign exchange risk only on the implicit interests, liquidity risk and counter party risk.`,
    eText227: `The second type is a contract by which the parties "exchange" interest rates, that is, make a commitment to pay, on an agreed frequency, amounts corresponding to the application to a given nominal amount of different interest rates. The main risk linked to this type of swap is interest rate risk and counterpart risk.`,
    eText228: `IV. General risks associated with investments`,
    eText229: `Besides the specific risks related to particular types of financial instruments, there are certain risks that apply to any type of financial instrument. The types of risks described below can thus impact every type of investment.`,
    eText230: `1. Market risk`,
    eText231: `Market risk covers exposures in the change of the value of a market indicator such as interest rates, credit spreads, share prices, index prices commodities prices or variation in volatility.`,
    eText232: `Market risk does not cover exposures to (i) credit events linked to market transaction counter parties, or (ii) settlement/delivery risks, and legal or operational risks.`,
    eText233: `The different types of market risk is as follows:`,
    eText234: `Interest rate risk`,
    eText235: `Interest rate risk is the risk linked to an unfavorable fluctuation of interest rates. Interest rate risk also includes the cost of carry. The cost of carry is positive or negative of the financing cost of the asset is respectively lower or higher than the received interest. Thus, the cost of carrying for a floating rate loan can increase with a rise in rates. Fluctuations in interest rates can expose the holder of financial instruments to the risk of capital
        loss. The importance of the risk differs according to the type of financial instrument.`,
    eText236: `Spread risk`,
    eText237: `Spread risk measures the loss associated with an unfavorable change in the probability of implicit failure (estimated by the market) of a debtor, measured (if appropriate) by the rating of the issuer.`,
    eText238: `Leverage risk`,
    eText239: `Leverage Risk is characterized by an exposure to a market risk based on a notional amount that is higher than the invested capital (examples: option premiums or future contracts).`,
    eText240: `Correlation risk`,
    eText241: `Correlation is an indication that measures the degree according to which the variations in two variables are linked.`,
    eText242: `Option risk`,
    eText243: `When the asset has a component, which is an option, the value of the asset may be affected by variations of some particular factors:`,
    eText244: `- volatility: a measure of the variability of the price of the underlying asset of the option.`,
    eText245: `- time value: the value of the option excluding its intrinsic value. This includes the cost of carry and the probability that the option can be exercised.`,
    eText246: `- intrinsic value; the amount by which an option is in the money, that is, its relative value with regard to the forward price inferred by the market at the time of the revaluation.`,
    eText247: `- the risk-free interest rate: the interest rate of a risk-free investment.`,
    eText248: `In case of exotic options, in some circumstances the risk can result from the functions of the underlying asset as much as from conditions in relation to its evolution (corridors, options with average, barrier options etc).`,
    eText249: `Basic risk`,
    eText250: `The basic risk is the risk that the cost of a hedged product does not move in line with that of the asset to be protected (example: a future contract with regard to its underlying asset).`,
    eText251: `Risk of early redemption`,
    eText252: `This risk is similar to the risk of reinvestment in the case of a bond or a swap that can be terminated prior to maturity. It is the risk that an investor cannot find the same investment conditions in the market if an existing
        investment is cancelled.`,
    eText253: `Risk of relative performance`,
    eText254: `The risk that an asset does not match the performances of the benchmark index.`,
    eText255: `Specific risk`,
    eText256: `The risk of fluctuation in the price of an asset, due to factors specific to that asset contrary to the general risk of market, which reflects general movement of rates, or the equity market.`,
    eText257: `2. Liquidity risk`,
    eText258: `Liquidity risk is the risk that an asset cannot be bought or sold quickly. The liquidity of a market depends on how it is organised (stock exchange or over-the-counter) but also of the relevant underlying instruments. Indeed, it can
        be easy to buy or to sell a common product, but more difficult in the case of a very specialized product. If a market is not liquid, it may not be possible to find a buyer or a seller at the required time. The liquidity of a
        financial instrument can change over time.`,
    eText259: `3. Volatility Risk`,
    eText260: `This is the risk linked to the movements of specific prices of a security. Volatility is high if the security is subject to wide movements over a relative time period (ie daily for some types of instrument and longer for others). The risk of volatility is calculated on the basis of the average difference between the lowest prices and the highest prices of a financial instrument over a given period.`,
    eText261: `4. Counter party risk`,
    eText262: `Counter party risk is the risk that a debtor does not pay his debt in time. It takes into three factors; the amount of the debt, the probability of insolvency and the proportion of the debt which should be recovered in case of
        insolvency. This is to measure the loss in connection with the failure of a debtor. For example, an investor must consider the quality of the issuer of securities, that is the issuer's capacity to repay or redeem (as appropriate).
        In respect of OTC derivatives, transactions that do not involve debt, counter party risk corresponds at any given time to the replacement value of the relevant derivative instrument if this value is positive.`,
    eText263: `5. Valuation risk`,
    eText264: `Valuation risk is lined to the unfavorable change in the estimated variables that are used to the valuation of an investment, that is volatility, interest rate and/or as the case may be the estimated dividend yield.`,
    eText265: `6. Operational risks`,
    eText266: `Operational risk is the risk of loss resulting from inadequate or failing internal processes, employees, or systems, or external events. This risk covers human error, the fraud and malicious behaviors, failures of information
        systems, problems linked to the staff management, commercial disputes, as well as external events such as accidents, fires, floods etc.`,
    eText267: `7. Political or Legal Risk`,
    eText268: `The risk that a government (or any other relevant authority) imposes new taxes, regulatory or legal obligations or limitations on securities, which an investor has already acquired. For example, a country's government can decide to prohibit the repatriation of assets in custody from this country.`,
    eText269: `8. Force majeure`,
    eText270: `Apart from the above mentioned risks, Force Majeure is a risk associated with industrial or natural disasters or decisions taken by the regulatory authorities or the market bodies that result, for example, in the suspension of the introduction of a financial instrument to the stock exchange. Force Majeure cases are not due to the liability of the issuer or the market or the Company. These events, however, if they are of a big scale, can influence the
        issuer's/counterparty’s ability to meet its commitments or influence the operation of the market.`,
    eText271: `Printable`,
    // Footer text
    eText272: `ZuluTrade is a registered trademark of ZuluTrade International Limited, a software development company registered in Cyprus (Registration No HE242240).`,
    eText273: `ZuluTrade as a service (zulutrade.com), is provided by ZuluTrade Group of Companies regulated by:`,
    eText274: `The Hellenic Capital Markets Commission in Greece (License No 2/540/17.2.2010) and under passporting provisions the service is also available to the European Economic Area ("EEA") countries. `,
    eText275: `The Financial Service Authorities in Japan (License No 0123-01-006371) in Tokai Finance Bureau.`,
    eText276: `The Financial Services Commission in Mauritius (License No IK21000018).`,
    eText277: `The Financial Sector Conduct Authority in South Africa (License No 49299)`,
    eText278: `Trading in financial instruments involves substantial risk and there is always the potential for loss. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Your trading results may vary. Because the risk factor is high in the financial market trading, only genuine "risk" funds should be used in such trading. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.`,
    eText279: `Hypothetical performance results have many inherent limitations. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    eText280: `Please check our full`,
    eText281: `disclaimer`,
    eText282: `Click here for the Terms of Service`,
  },
  //Home page
  home: {
    text1: `The`,
    text2: `Social Intelligence Platform`,
    text3: `that helps you invest in a smart way`,
    text4: `Go long or short? Go Zulu!`,
    text4er: `150+`,
    text4es: `Countries`,
    text4ee: `30M+`,
    text4et: `Accounts`,
    text4ey: `2M+`,
    text4eu: `Leaders`,
    text4ei: `Regulated Globally`,

    text5: `ZuluTrade brings CopyTrading to your fingertips! Copy the actions of experienced Leaders and begin your trading journey.`,
    text6: `Join Now`,
    text7: ` Truth is...`,
    text8: `Trading is not easy`,
    text9: `Studying the market takes time`,
    text10: `Building and maintaining a trading strategy is hard`,
    text11: `That’s why only `,
    text12: `11-26% of manual investors`,
    text13: `end up winning.`,
    text14: `"Between 74-89% of retail investor accounts lose money when trading CFDs." (source:`,
    text14a: `ZuluTrade enables you to invest your capital and manage your wealth more efficiently. With the help of our large and diverse community, technology, and open ecosystem, we help you make informed investment decisions in a safe and trusted environment.`,
    text15: `ESMA`,
    text16: `Beat the odds with Copy Trading`,
    text17: `Did you know that`,
    text18: ` 73% of our investors make profit `,
    text19: `when copying top leaders correctly?`,
    text20: `Refers to hands-off Copy Trading data from the past 12 months. Click on the link below for more information.`,
    text21: `Explore ZuluTrade’s Statistics`,
    text22: `Try it Yourself`,
    text23: `How it works?`,
    text25: `Leaders join ZuluTrade and share their trading strategies.`,
    text27: `ZuluTrade ranks Leaders based on performance, stability, and trading behavior.`,
    text29: `Investors join ZuluTrade, and select Leaders to copy based on their investment capital, risk appetite, and expectations.`,
    text30: `Create Account`,
    text31: `ZuluTrade's Top Leaders`,
    text32: `Risk`,
    text33: `Share`,
    text34: `Compare`,
    text35: `COPIERS`,
    text36: `FOLLOWERS`,
    text37: `Rising`,
    text38: `Strategies that have positive momentum and trading history up to 30 weeks.`,
    text38a: `Discover your perfect match using more than 40 filters. Rank Leaders based on your preferences.`,
    text39: `AMOUNT FOLLOWING - HIGHEST AUM`,
    text40: `Strategies that have positive momentum and move near ATHs (All Time Highs)`,
    text41: `Our`,
    text42: `Awards`,
    text43: `UF AWARDS`,
    text44: `CYPRUS, 2022`,
    text45: `Best Social Trading`,
    text46: `Solution`,
    text47: `FOREX EXPO`,
    text48: `DUBAI, 2022`,
    text49: `Best Social Wealth`,
    text50: `Management Platform`,
    text51: `UF AWARDS`,
    text52: `DUBAI, 2023`,
    text53: `Best Social Trading`,
    text54: `Solution in MEA`,
    text55: `Benefit`,
    text55b: `From`,
    text56: `Transparent Platform`,
    text57: `Transparency is in our DNA. All performance data is shared openly with everyone! Plus, there are no hidden fees.`,
    text58: `Advanced Tools`,
    text59: `If you prefer manual trading with ZuluTrade, you can always customize your settings and trade with various advanced tools.`,
    text60: `Innovative Solutions`,
    text61: `Our in-house team analyzes your needs to provide new features and optimize your trading experience.`,
    text62: `Customer-Centric Culture`,
    text63: `We are all here for you to improve your overall experience! Reach our highly-rated Customer Service 24/5 via chat, phone or email.`,
    text64: `Learning Environment `,
    text65: `Leaders explain their trading strategy and answer investors’ questions. Our social tools let you interact with leaders and fellow investors.`,
    text66: `Unique Advanced Features`,
    text67: `Discover Backtest, explore Watchlist, Risk-score and ZuluGuard. Make informed investing decisions and get control of your trade.`,
    text68: `Wide variety of instruments`,
    text69: `Whether you CopyTrade or trade manually, you can choose from a full range of investment instruments.`,
    text70: `If you are an advanced investor and prefer manual trading, you are always able to customize your settings or trade with a variety of advanced tools.`,
    text71: `View All`,
    text72: `Most Popular`,
    text73: `Forex`,
    text74: `Commodities`,
    text75: `Indices`,
    text76: `Stocks`,
    text77: `Cryptocurrencies`,
    text78: `Buy`,
    text79: `Sell`,
    text80: `Join Now`,
    text81: `trading is hard`,
    text82: `Truth is.Trading is not easy`,
    text83: `Clock`,
    text84: `Studying the market takes time`,
    text85: `Building`,
    text86: `Building and maintaining`,
    text87: `How does zulutrade work?`,
    text88: `Traders join ZuluTrade`,
    text89: `Transparency`,
    text90: `Transparency is in our DNA`,
    text91: `Innovation`,
    text92: `Our in-house team constantly`,
    text93: `Social Features`,
    text94: `Our social tools`,
    text95: `Customer Orientation`,
    text96: `We are all here for you!`,
    text97: `Learn by Copying`,
    text98: `Traders explain their strategy `,
    text99: `Unique Advanced Features`,
    text100: `Discover Backtest`,
    text101: `Copy Trading at your Fingertips`,
    text102: `Use`,
    text102a: `CopyTrading`,
    text102b: `to copy the actions of experienced Leaders.`,
    text103: `Multiple Assets`,
    text104: `Access Assets like `,
    text104a: `forex, commodities, cryptocurrencies, indices, and stocks.`,
    text105: `Integrated Brokers`,
    text106: `Create your own`,
    text106a: `Integrated Broker`,
    text106b: `account without leaving the platform and enjoy free CopyTrading.`,
    text107: `Transparent Subscription`,
    text108: `Benefit from the new and innovative`,
    text108a: `Subscription plan`,
    text108b: `starting as low as`,
    text108c: `$10`,
    text108d: `and eliminate platform fees based on transactions and volume.`,
    text109: `Platform Agnostic`,
    text110: `Link any MT4, MT5, ActTrader, X Open Hub broker account using our on-the-fly terminal connection within seconds.`,
    text111: `Broker Agnostic`,
    text112: `Link any of your existing broker accounts using your Client ID or create a new account from the list of our co-branded brokers or any other broker of your choice.`,
    text113: `Benefit From`,
  },
  //Markets
  marketList: {
    text1: `Markets`,
    text22: `Follow the markets and discover valuable data you need.`,
    text2: `Top Gainers`,
    text3: `Top Flops`,
    text4: `Most Popular`,
    text5: `Forex`,
    text6: `View All`,
    text7: `Stocks`,
    text8: `Indices`,
    text9: `Commodities`,
    text10: `Crypto CFD`,
    text11: `Watchlist`,
    text12: `Market News`,
    text13: `News`,
    text14: `Load More`,
    text15: `All`,
    text16: `Crypto`,
    text17: `Market`,
    text18: `Back`,
    text19: `No Data Found`,
    text20: `View Watchlist`,
    text21: `IconArrow`,
  },
  //Forex Trading page
  forexTrading: {
    forexText1: `Explore`,
    forexText1a: `Forex `,
    forexText1b: `Trading with ZuluTrade`,
    forexText2: `Join millions of investors who have chosen ZuluTrade as their preferred platform. Sign up now and experience the future of online trading.`,
    forexText3: `Get Started Now`,
    forexText4: `Explore Forex Trading with ZuluTrade`,
    forexText5: `What is Forex Trading?`,
    forexText6: `Forex is the global market where currencies are traded. With Forex, you buy one currency, and at the same time, you sell another. This is called a Currency Pair. The goal is to make a profit based on the fluctuations in exchange rates. Forex trading is decentralized and operates 24/5, allowing participants to trade in currency across different time zones. Forex is the largest financial market in the world, with high liquidity and numerous participants, including banks, financial institutions, corporations, governments, and individual traders.`,
    forexText7: `Forex Trading with `,
    forexText7a: `ZuluTrade`,
    forexText8: `Fill in your details`,
    forexText9: `Social Trading`,
    forexText10: `Follow and copy trades of handpicked Leaders based on their actual, verified performance.`,
    forexText11: `Choose the links,..`,
    forexText12: `Choose Promotional Content`,
    forexText13: `Platform Neutral`,
    forexText14: `Integrated with multiple trading platforms, including MT4, MT5, ActTrader and XOH.`,
    forexText15: `Earn`,
    forexText16: `Enjoy our transparent`,
    forexText17: `Risk Management`,
    forexText18: `Protect your invested capital based on pre-defined criteria, limit your risk exposure and avoid significant losses.`,
    forexText19: `A Sizable Community`,
    forexText20: `Access a vibrant trading community for knowledge sharing and interaction.`,
    forexText21: `Portfolio Diversification`,
    forexText22: `Diversification is easier, allowing you to follow multiple Leaders and several financial instruments.`,
    forexText23: `Mobile Trading`,
    forexText24: `Manage accounts, place trades, and monitor portfolios on the go with the ZuluTrade mobile app.`,
    forexText25: `Open an Account`,
    forexText26: `Harness the power of Social Intelligence`,
    forexText27: `Join ZuluTrade and harness the power of a leading traders community. Discover experienced Leaders and automatically copy their positions. Whether you're a beginner looking to improve your trading skills or an experienced investor seeking a straightforward solution, ZuluTrade is the place for you.`,
    forexText28: `What Makes ZuluTrade Different?`,
    forexText29: `Trading Forex With A Broker Neutral Trading Platform`,
    forexText30: `ZuluTrade offers a broker-neutral platform, allowing Investors to connect with a wide range of compatible Forex brokers. This flexibility lets you choose a broker based on your trading needs and optimizing strategies. The platform remains independent, unbiased, and transparent, empowering traders to make informed decisions.`,
    forexText31: `Clear and`,
    forexText31a: `Transparent Subscription Plan`,
    forexText31b: `with the following`,
    forexText32: `With our innovative and transparent subscription plan, you'll always know exactly what you're paying for. The cost is just `,
    forexText32a: `$10 per month`,
    forexText32b: `for each Leader Strategy, with discounts available. Enjoy Free CopyTrading with Integrated Brokers and other selected Brokers, or choose the subscription plan that suits you best.`,
    forexText33: `Trade `,
    forexText33a: `Forex`,
    forexText33b: `with Confidence`,
    forexText34: `At ZuluTrade, we empower investors like you to confidently explore the world of`,
    forexText34a: `Forex trading. `,
    forexText34b: `Our advanced trading platform and diverse community give you everything you need to make informed trading decisions.`,
    forexText35: `Unlock Opportunities with Ease`,
    forexText36: `Whether you're a beginner or an experienced investor, our user-friendly interface and comprehensive resources will help`,
    forexText36a: `unlock opportunities`,
    forexText36b: `in the Forex market. Access a wide range of currency pairs, analyze real-time market data, and execute trades effortlessly, all in one place.`,
    forexText37: `Stay Ahead with`,
    forexText37a: `Real-Time`,
    forexText37b: `Signals`,
    forexText38: `Never miss a trading opportunity with ZuluTrade's real-time signals. Our platform connects you to a vast network of Leaders, delivering instant`,
    forexText38a: `notifications on market trends,`,
    forexText38b: `buy/sell recommendations, and trade updates. Stay informed and stay ahead with our reliable and timely signals.`,

    forexText39: `Customize Your `,
    forexText39a: `Trading Strategy`,
    forexText40: `Take control of your trading strategy with ZuluTrade. Choose from various trading tools and indicators,`,
    forexText40a: `pick one or more Leader Strategies`,
    forexText40b: `and tailor your approach to match your preferences and risk tolerance. It's flexible and customizable.`,
    forexText41: `How to Start`,
    forexText41a: `Forex`,
    forexText41b: `Trading at ZuluTrade?`,
    forexText42: `Join millions of investors who have chosen ZuluTrade as their preferred platform. Sign up now and experience the future of online trading.`,
    forexText43: `Register`,
    forexText44: `Choose your broker`,
    forexText45: `Select a Subscription Plan`,
    forexText46: `Choose your Leaders`,
    forexText47: `Start Copying!`,
    forexText48: `Register`,
    forexText48a: `with your personal details. We need these details to get to know you.`,
    forexText49: `You can `,
    forexText49a: `choose a Broker`,
    forexText49b: `to open a new account or connect an existing one.`,
    forexText50: `Enjoy`,
    forexText50a: `Free CopyTrading`,
    forexText50b: `with `,
    forexText50c: `Integrated Brokers`,
    forexText50d: `and other selected Brokers, or choose the subscription plan that suits you best, starting from 10$ per Month per Leader strategy.`,
    forexText51: `Discover the Leader`,
    forexText51a: `that matches your needs using more than 40 filters.`,
    forexText52: `Start CopyTrading.`,
    forexText53: `Forex Trading at ZuluTrade`,
    forexText54: `What is forex trading?`,
    forexText55: `The term “Forex Trading” means foreign exchange and refers to buying or selling one currency in exchange for another. Currencies are traded in the foreign exchange market, a global marketplace that’s open 24 hours a day in different parts of the world. All forex trading is conducted over the counter (OTC), meaning there’s no physical exchange, and a global network of banks and other financial institutions oversee the market.`,
    forexText56: `How do I start forex trading with ZuluTrade?`,
    forexText57: `Connect your Forex Broker Account or create a new one with ZuluTrade and start forex trading. Once you have an account, connect it the platform provides access to experienced leaders (called leaders), a user-friendly interface, and a wide range of currency pairs.`,
    forexText58: `Can I trade Forex with ZuluTrade as a beginner?`,
    forexText59: `Yes, ZuluTrade is suitable for beginners. The platform offers educational resources, a supportive community, and the ability to copy trades from experienced leaders. This allows beginners to learn the forex market without extensive trading knowledge.`,
    forexText60: `What are the risks involved in forex trading?`,
    forexText61: `Forex trading involves risks, including the potential loss of capital. Currency prices can be volatile and affected by various factors. Educating yourself, managing risk through tools like stop-loss orders, and making informed trading decisions are important.`,
    forexText62: `Trading in financial instruments involves substantial risk, and there is always the potential for loss. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Your trading results may vary. Because the risk factor is high in financial market trading, only genuine "risk" funds should be used. Consider whether you understand how CFDs work and whether you can afford to risk losing your money. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results. Between 74-89% of retail investor accounts lose money when trading CFDs`,
    forexText63: `Learn more`,
    forexText64: `ZuluTrade terms of service`,
    forexText65: `Can I trade Forex on ZuluTrade using a mobile device?`,
    forexText66: `Absolutely! ZuluTrade provides a mobile app for iOS and Android devices, allowing you to trade Forex on the go. You can conveniently monitor your trades, execute orders, and manage your investments from your mobile device.`,
    forexText67: `How can I choose the best forex signals available on ZuluTrade?`,
    forexText68: `ZuluTrade provides comprehensive data for each signal provider. To assess their performance, you can review their historical trades, success rates, drawdowns, and other metrics. Additionally, you can read reviews and ratings from other users to make informed decisions.`,
    forexText69: `Are there any fees associated with forex trading on ZuluTrade?`,
    forexText70: `ZuluTrade now features an innovative subscription plan. With our transparent subscription plan, you'll always know exactly what you're paying for. The cost is just $10 per month for each Leader Strategy, with discounts available. Enjoy Free CopyTrading with selected Brokers, or choose the subscription plan that suits you best. Also, there may be fees associated with the broker you choose, such as spreads or commissions. It's important to review the fee structure of your selected broker.`,
    forexText71: `Can I trade manually alongside copying forex signals on ZuluTrade?`,
    forexText72: `ZuluTrade allows you to combine manual trading with copying forex signals. You have the flexibility to execute your own trades while simultaneously following signal providers, allowing you to customize your trading strategy.`,
    forexText73: `How can I manage my risk while forex trading on ZuluTrade?`,
    forexText74: `ZuluTrade offers risk management tools like ZuluGuard. ZuluGuard provides protection for your invested capital based on pre-defined criteria. This feature allows users to limit their risk exposure and avoid significant losses.`,
    forexText75: `Is ZuluTrade service regulated and secure for forex trading?`,
    forexText76: `ZuluTrade service by the licensed companies of the ZuluTrade group in their respective territories.  ZuluTrade cooperates with licensed brokers and follows industry best practices to ensure the security of your trading activities. However, choosing a reputable broker and adhering to risk-balanced trading practices is important to protect your funds.`,
    forexText77: `When did Forex trading start?`,
    forexText78: `Forex trading has been around for as long as international commerce. Even as far back as the time of Babylonians, goods were being traded for other tangible items. But modern Forex trading that we know today began in the early 1970s when the U.S. allowed the USD to float freely in the Forex market.`,
    forexText79: `What is the difference between Forex trading and Stock trading?`,
    forexText80: `Forex trading is the act of trading in a marketplace for the buying and selling of currencies, while stock trading is the act of buying and selling the units of ownership in a company, otherwise known as shares.`,
    forexText81: `Is Forex trading profitable?`,
    forexText82: `Forex trading, like other types of trading, can be profitable. But there are no guarantees, as Trading involves High Risk. The key to success with forex trading is finding a successful trader to follow and copy their strategies. While there is potential to make money, it is essential to understand the risks involved.`,
    forexText83: `Trading in financial instruments involves substantial risk, and there is always the potential for loss. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Your trading results may vary. Because the risk factor is high in financial market trading, only genuine "risk" funds should be used. Consider whether you understand how CFDs work and whether you can afford to risk losing your money. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results. Between 74-89% of retail investor accounts lose money when trading CFDs`,
    forexText84: `Learn More`,
    forexText85: `ZuluTrade terms of service`,
    forexText86: `What are Forex trading hours? `,
    forexText87: `The forex market is open 24 hours a day in different parts of the world, from 5 p.m. EST on Sunday until 4 p.m. EST on Friday. The ability of the forex to trade over 24 hours is due in part to different international time zones.`,
    forexText88: `What is the best Forex Copy trading platform?`,
    forexText89: `Many Forex Trading Platforms are out there, but ZuluTrade remains the most transparent and reliable. Our users are always our top priority, so we continuously improve our products and features to benefit their trading experience. Our 17 years of experience, outstanding customer support, and excellent features make ZuluTrade a top choice Forex trading platform.`,
  },
  //Trading signals page
  tradingSignals: {
    textSig1: `Unlock the Power of Copy Trading and Trading Signals in real-time`,
    textSig2: `Experience the benefits of copy trading with ZuluTrade. Experienced Leader share their signals publicly with just a push of a button. Discover the Leader Strategies that suit your needs and let our cutting-edge technology take care of the rest. With copy trading, you can optimize your trading strategy and capitalize on market opportunities around the clock.`,
    textSig3: `Leaders share their strategy by describing their trading pattern, giving information on their preferred financial instruments they invest in, mentioning their trading habits, and more.`,
    textSig4: `Investors search for the perfect match using more than 40 filters, including Risk Score, minimum investment capital, Win %, investment vehicles, and more.`,
    textSig5: `Investors choose the strategy they like, and as soon as they press Copy Strategy, all trades made from the leader (aka signals) are automatically copied to the investors' account.`,
    textSig6: `Strengthen your Forex & Crypto trading experience with CopyTrading`,
    textSig7: `Join ZuluTrade and start copying trading signals instantly`,
    textSig8: `Copy trading allows you to replicate the strategies of top traders effortlessly. ZuluTrade has a vast community of signal providers with a proven track record. Our intelligent algorithm filters and ranks these signals based on your preferences. Choose Leaders based on performance, risk score, type of trades, and many more, ensuring you have the most reliable options at your fingertips.`,
    textSig9: `aaa`,
    textSig10: `aaa`,
    signalsText1: `Discover Trading Signals with the help of ZuluTrade Leaders`,
    signalsText2: `Unlock the Power of Copy Trading and Trading Signals in real time`,
    signalsText3: `Open an Account`,
    signalsText4: `Discover Trading Signals with the help of ZuluTrade Leaders`,
    signalsText4title: `Discover Trading Signals with the help of ZuluTrade Leaders - Unlock the Power of Copy Trading and Trading Signals in real time`,
    signalsText5: `How it Works?`,
    signalsText6: `Experience the benefits of copy trading with ZuluTrade. Experienced leaders share their signals publicly with just a push of a button. Discover the Leader Strategies that suit your needs and let our cutting-edge technology take care of the rest. With copy trading, you can optimize your trading strategy and capitalize on market opportunities around the clock.`,
    signalsText7: `Leaders share their strategy by describing their trading pattern, giving information on their preferred financial instruments they invest in, mentioning their trading habits, and more.`,
    signalsText8: `Investors search for the perfect match using more than 40 filters, including Risk Score, minimum investment capital, Win %, investment vehicles, and more.`,
    signalsText9: `Investors choose the strategy they like, and as soon as they press Copy Strategy, all trades made from the leader (aka signals) are automatically copied to the investors' account.`,
    signalsText10: `Strengthen your Forex & Crypto trading experience with CopyTrading`,
    signalsText11: `ZuluTrade offers the best platform to access and follow expert trading signals for Forex & Crypto trading. Whether you are a novice or an experienced investor, our vast list of signal providers allows you to mirror the trades of experienced leaders in real-time.`,
    signalsText12: `Join ZuluTrade and start copying trading signals instantly - Copy trading allows you to replicate the strategies of top traders effortlessly`,
    signalsText13: `Discover trading signal providers, choose the strategy you like, and start copy trading`,
    signalsText14: `Copy trading allows you to replicate the strategies of top traders effortlessly. ZuluTrade has a vast community of signal providers, each with a proven track record. Our intelligent algorithm filters and ranks these signals based on your preferences. Choose Leaders based on performance, risk score, type of trades, and many more, ensuring you have the most reliable options at your fingertips.`,
    signalsText15: `At ZuluTrade, we prioritize transparency and accountability.`,
    signalsText16: `At ZuluTrade, we prioritize transparency and accountability. Each signal provider is thoroughly vetted, and their performance metrics, including historical trades and risk levels, are readily available for you to review. We help you make informed decisions and choose signal providers that align with your investment goals and risk tolerance.`,
    signalsText17: `Transparency and Accountability`,
    signalsText18: `At ZuluTrade, we prioritize transparency and accountability. Each signal provider is thoroughly vetted, and their performance metrics, including historical trades and risk levels, are readily available for you to review. We help you make informed decisions and choose signal providers that align with your investment goals and risk tolerance.`,
    signalsText19: `Start your journey with ZuluTrade today and gain access to the world of CopyTrading signals.`,
    signalsText20: `Join our community and take advantage of the power of trading signals. Sign up now and transform the way you trade!`,
    signalsText21: `Create Account`,
    signalsText22: `What are trading signals?`,
    signalsText23: `Trading signals are notifications generated by Traders or automated systems that indicate potential entry and exit points for trading. These signals provide valuable insights into market trends and can help traders make informed decisions.`,
    signalsText24: `How does copy trading work?`,
    signalsText25: `Copy trading allows you to automatically replicate the trades of experienced leaders, known as signal providers - Leaders. When you choose a signal provider on ZuluTrade, their trades are mirrored in your trading account in real time. You can benefit from the Leaders’ know-how and achieve similar trading results.`,
    signalsText26: `Can I choose multiple signal providers?`,
    signalsText27: `Absolutely! ZuluTrade allows you to select multiple signal providers simultaneously. Just choose the number of Leader Strategies you need in your Trading Account. Multiple Leader Strategies provide you with diversification. You can then allocate your capital to multiple signal providers based on preferences and risk tolerance.`,
    signalsText28: `How do I evaluate signal providers?`,
    signalsText29: `ZuluTrade provides comprehensive performance data for each signal provider. You can review their historical trades, success rates, drawdowns, and other performance metrics to assess their track record. Additionally, you can read reviews and ratings from other users to make informed decisions.`,
    signalsText30: `Is it possible to customize risk management settings?`,
    signalsText31: `Yes, ZuluTrade offers robust risk management tools. You can set parameters such as stop-loss and take-profit levels to manage your risk effectively. These features allow you to protect your capital and control the potential downside of your trades.`,
    signalsText32: `Can I trade manually alongside copy trading?`,
    signalsText33: `Yes. ZuluTrade provides seamless integration of manual and copy trading. You have the freedom to execute your trades while simultaneously following signal providers. This way, you can combine your analysis with Leaders’ insights.`,
    signalsText34: `Are there any fees associated with forex trading on ZuluTrade?`,
    signalsText35: `ZuluTrade now features an innovative subscription. With our transparent subscription plan, you'll always know exactly what you're paying for. The cost is just $10 per month for each Leader Strategy. If you're using an Integrated Broker, you don't need to worry about subscribing to a plan. Also, there may be fees associated with the broker you choose, such as spreads or commissions. It's important to review the fee structure of your selected broker.`,
    signalsText36: `Is ZuluTrade suitable for beginners?`,
    signalsText37: `Yes, ZuluTrade is beginner-friendly. With copy trading, beginners can learn from experienced leaders and start trading without in-depth market knowledge. The platform provides educational resources, a supportive community, and intuitive tools to help beginners navigate the world of trading signals.`,
    signalsText38: `Can I cancel or modify trades executed through copy trading?`,
    signalsText39: `Yes, you have full control over your trades. You can manually intervene, modify, or close trades executed through copy trading at any time. ZuluTrade ensures you have the flexibility to manage your positions according to your preferences.`,
    signalsText40: `Is ZuluTrade available on mobile devices?`,
    signalsText41: `Absolutely! ZuluTrade offers a mobile app for both iOS and Android devices. You can conveniently access and manage your trading signals, execute trades, and monitor your investments on the go.`,
  },
  //Copy Trading page
  copyTrading: {
    copyText1: `Investing Made Easy With CopyTrading`,
    copyText2: `Join ZuluTrade and experience copy-trading with a leading traders community. Discover experienced leaders globally and automatically copy their positions. Whether you're a beginner looking to improve your trading skills or an experienced investor seeking a straightforward solution, ZuluTrade is the place for you.`,
    copyText3: `Create Account`,
    copyText5: `video/mp4`,
    copyText6: `video/ogg`,
    copyText7: `What is Copy Trading?`,
    copyText7new: `CopyTrading with the Power of Social Intelligence`,
    copyText7new1: `Unlock the Power of Copy Trading with ZuluTrade`,
    copyText7new2: `Join Now`,
    copyText8: `Copy trading is a trading method where investors replicate experienced leaders' moves. In this way, they automatically mirror the trading activities of experienced leaders - you can find them in the Leaders section - and follow their investment strategies.`,
    copyText9: `Discover why copy trading with ZuluTrade is so popular`,
    copyText10: `Access to Leaders`,
    copyText10title: `ZuluTrade gives you access to a dynamic community of more than 300K experienced leaders. Start copying their forex, stocks, crypto, or other financial instruments strategies today.`,
    copyText11: `Copy Trading is a great way to learn how global markets operate. Learn from other experienced leaders who have their signals strategies with trading forex, stocks, crypto or other financial instruments.`,
    copyText12: `Beginner Friendly`,
    copyText13: `Even if you don’t know everything about currencies, CFDs, or global markets, you can start your trading journey. Simply choose the Leaders who match your needs and risk tolerance and gain experience in the investing landscape.`,
    copyText14: `Diversification`,
    copyText15: `Diversification is easier, allowing you to follow multiple Leaders and several financial instruments.`,
    copyText14new: `Save Time`,
    copyText14new1: `Copy trading minimizes the time required for extensive market research and analysis. Copy traders can benefit from the skills and knowledge of Leaders to make informed trading decisions, saving time and effort.`,
    copyText16: `Flexibility in Investment Size`,
    copyText17: `Adjust the strategies you copy based on your account balance and risk appetite.`,
    copyText18: `Transparency`,
    copyText19: `Track your Leaders through a leaderboard and real-time verified data.`,
    copyText20: `Learning Opportunities`,
    copyText21: `Gain valuable insights from Leaders and apply them to your trading strategies in the future.`,
    copyText22: `Copy Trading vs. Manual Trading`,
    copyText23: `Features`,
    copyText24: `Manual Trading`,
    copyText25: `Copy Trading`,
    copyText26: `Start Trading with little trading experience`,
    copyText27: `No icon`,
    copyText28: `Yes icon`,
    copyText29: `Learn from experienced leaders`,
    copyText30: `Access to verified data`,
    copyText31: `Diversify your portfolio`,
    copyText32: `Choose a trading strategy that suits your style.`,
    copyText33: `Save time and effort from extensive market research.`,
    copyText34: `No need to monitor trades multiple times throughout the day`,
    copyText35: `How to start CopyTrading with ZuluTrade?`,
    copyText36: `Opening an account with ZuluTrade is simple`,
    copyText37: `First, sign in to ZuluTrade`,
    copyText38: `Register`,
    copyText39: `Register with your personal details. We need these details to get to know you.`,
    copyText40: `Open the Top Trader’s tab`,
    copyText41: `Simply go to the Trader’s`,
    copyText42: `Choose your broker`,
    copyText43: `You can choose a Broker to open a new account or connect an existing one.`,
    copyText44: `Select the Trader`,
    copyText45: `When you’ve found the right Trader`,
    copyText46: `Select a Subscription Plan`,
    copyText47: `Enjoy Free CopyTrading with selected Brokers, or choose the subscription plan that suits you best. Starting from 10$ per Month per Leader strategy.`,
    copyText48: `Choose your Leaders`,
    copyText49: `Hit the follow button again`,
    copyText50: `Discover the Leader that matches your needs using more than 40 filters.`,
    copyText383: `Start Copying!`,
    copyText393: `Start CopyTrading.`,
    copyText51: `Discover ZuluTrade`,
    copyText52: `ZuluGuard`,
    copyText53: `ZuluGuard provides protection for your invested capital based on pre-defined criteria. This feature allows users to limit their risk exposure and avoid significant losses.`,
    copyText52a: `Learn More`,
    copyText54: `Simulation`,
    copyText55: `A powerful tool that allows investors to test their selected Leader’s performance according to their account settings.`,
    copyText56: `Demo Trading`,
    copyText57: `ZuluTrade offers a demo function that allows Users to experience CopyTrading in a virtual environment before they engage with live trading. This feature helps users gain experience and confidence in their trading decisions.`,
    copyText581: `Community`,
    copyText58: ` ZuluTrade provides an interactive environment where investors can track the Leaders’ performance, compare strategies, and discuss their experiences to gain insight and adjust their trading decisions. Users can connect, collaborate, and benefit from shared knowledge.`,
    copyText582: `Platform Agnostic`,
    copyText583: `With ZuluTrade, you can link any MT4, MT5, ActTrader, X Open Hub broker account using our on-the-fly terminal connection. Enter your platform credentials, wait a few seconds, and you are ready to go!`,
    copyText584: `Risk Score`,
    copyText585: `To help investors make informed decisions, we display the Risk score for all Leaders. Risk is calculated on a 1-5 scale, with 1 indicating Low Risk and 5 indicating High Risk strategies.`,
    copyText59: `Frequently Asked Questions (FAQs)`,
    copyText60: `Is CopyTrading and Social Trading the same?`,
    copyText61: `A form of CopyTrading is Social Trading. In Social Trading, users interact, share strategies, and discuss market trends. It is considered a good starting point for beginners looking to expand their knowledge and trading experience.`,
    copyText62: `What is the difference between Copy Trading and Manual Trading?`,
    copyText63: `Copy trading is a form of trading where investors look to replicate the trades of experienced leaders rather than rely on their own analysis and judgement.`,
    copyText63a: `On the other hand, manual trading requires traders to conduct detailed research and analysis themselves before placing their trades.`,
    copyText64: `Is Copy Trading Profitable?`,
    copyText65: `Copy trading is an increasingly popular form of investing. The key to success with copy trading is finding a successful trader to follow and copy their strategies. While there is potential to make money, it is essential to understand the risks involved.`,
    copyText65a: `Trading in financial instruments involves substantial risk, and there is always the potential for loss. CFDs are complex instruments with a high risk of losing money rapidly due to leverage. Your trading results may vary. Because the risk factor is high in financial market trading, only genuine "risk" funds should be used. Consider whether you understand how CFDs work and whether you can afford to risk losing your money. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results. Between 74-89% of retail investor accounts lose money when trading CFDs`,
    copyText65b: `Learn more`,
    copyText65c: `ZuluTrade terms of service`,
    copyText66: `How does copy trading work?`,
    copyText67: `Copy trading allows you to automatically replicate the trades of experienced leaders, known as signal providers/Leaders. When you choose a Leader on ZuluTrade, their trades are mirrored in your trading account in real time. You can benefit from the Leaders’ know-how and achieve similar trading results.`,
    copyText68: `Can I choose multiple Leaders?`,
    copyText69: `Absolutely! ZuluTrade allows you to select multiple Leaders simultaneously. Just choose the number of Leader Strategies you need in your Trading Account. Multiple Leader Strategies provide you with diversification. You can then allocate your capital to multiple signal providers based on preferences and risk tolerance.`,
    copyText70: `How do I evaluate signal providers?`,
    copyText71: `ZuluTrade provides comprehensive data for each Leader. To assess their performance, you can review their historical trades, success rates, drawdowns, and other metrics. Additionally, you can read reviews and ratings from other users to make informed decisions.`,
    copyText72: `Is it possible to customise risk management settings?`,
    copyText73: `Yes, ZuluTrade offers robust risk management tools. You can set parameters such as stop-loss and take-profit levels, use ZuluGuard, and manage your risk effectively. These features allow you to protect your capital and control the potential downside of your trades.`,
    copyText74: `Can I trade manually alongside copy trading?`,
    copyText75: `Yes. ZuluTrade provides seamless integration of manual and copy trading. You have the freedom to execute your trades while simultaneously following signal providers. This way, you can combine your analysis with expert Leaders' insights.`,
    copyText76: `Are there any fees associated with copy trading on ZuluTrade?`,
    copyText77: `ZuluTrade now features an innovative subscription plan. With our transparent subscription plan, you'll always know exactly what you're paying for. The cost is just`,
    copyText77a: `$10 per month `,
    copyText77b: `for each Leader Strategy, with discounts available. Enjoy Free CopyTrading with selected Brokers, or choose the subscription plan that suits you best. Also, there may be fees associated with the broker you choose, such as spreads or commissions. It's important to review the fee structure of your selected broker.`,
    copyText78: `Is ZuluTrade suitable for beginners?`,
    copyText79: `Yes, ZuluTrade is beginner-friendly. With copy trading, beginners can learn from experienced leaders and start trading without in-depth market knowledge. The platform provides educational resources, a supportive community, and intuitive tools to help beginners navigate the world of trading signals.`,
    copyText80: `Can I cancel or modify trades executed through copy trading?`,
    copyText81: `Yes, you have full control over your trades. You can manually intervene, modify, or close trades executed through copy trading at any time. ZuluTrade ensures you have the flexibility to manage your positions according to your preferences.`,
    copyText82: `Is ZuluTrade available on mobile devices?`,
    copyText83: `Absolutely! ZuluTrade offers a mobile app for both iOS and Android devices. You can conveniently access and manage your trading signals, execute trades, and monitor your investments on the go.`,
  },
  //Management
  management: {
    managementText1: `Meet Our Team`,
    managementText2: `Sarvjeet Singh Virk`,
    managementText3: `Co-Founder & CMD`,
    managementText4: `Finvasia Group`,
    managementText5: `Tajinder Singh Virk`,
    managementText6: `Co-Founder & CEO`,
    managementText7: `Dimitris Lathouras`,
    managementText8: `CFO`,
    managementText9: `ZuluTrade Group`,
    managementText10: `Padelis Andreadis`,
    managementText11: `IT Director`,
    managementText12: `Christos Stefanou`,
    managementText13: `Head of BackEnd Engineering`,
    managementText14: `Antonis Metaxas`,
    managementText15: `Head of Portfolio Management & Traders Community Manager`,
    managementText16: `Head of Portfolio Management`,
    managementText17: `& Traders Community Manager`,
  },
  //Disclaimer page
  Disclaimer: {
    disclaimerText1: `Disclaimer`,
    disclaimerText2: `Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly performance fee per selected trading system.`,
    disclaimerText3: `Forex trading involve a real risk of loss. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly subscription fee and a monthly performance fee per selected trading system.`,
    disclaimerText4: `Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    disclaimerText5: `One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results. Please check our full disclaimer.`,
  },
  //Zulutrade Data
  zulutradeData: {
    dataText1: `Copy Trading banner`,
    dataText2: `Beat the Odds with Copy Trading`,
    dataText3: `Copy Trading on our platform significantly increases the chances of being profitable compared to trading manually.`,
    dataText4: `The average winning ratio for copy trading with ZuluTrade can reach up to 80%!`,
    dataText5: `Start Copy Trading`,
    dataText6: `Copy Trading vs Manual Trading`,
    dataText7: `Winning`,
    dataText8: `Losing`,
    dataText9: `The winners/losers ratio is produced as the number of accounts with at least one closed trade over a period of X months (as displayed on the following tables) and a positive realized PnL over that period, divided by the total number of accounts with at least one closed trade over the same period.`,
    dataText10: `3 months`,
    dataText101: `Jun 2023 - Aug 2023`,
    dataText11: `6 months`,
    dataText102: `Mar 2023 - Aug 2023`,
    dataText12: `12 months`,
    dataText103: `Sep 2022 - Aug 2023`,
    dataText13: `Copy Trading - Hands-off`,
    dataText14: `Hands-off vs Interrupted`,
    dataText15: `79% Winning`,
    dataText16: `76% Winning`,
    dataText17: `73% Winning`,
    dataText18: `Manual Trading `,
    dataText19: `54% Winning`,
    dataText20: `52% Winning`,
    dataText21: `49% Winning`,
    dataText22: `3 months`,
    dataText104: `3 months`,
    dataText23: `6 months `,
    dataText105: `Mar 2023 - Aug 2023`,
    dataText24: `12 months`,
    dataText106: `Sep 2022 - Aug 2023`,
    dataText26: `Hands-off vs Interrupted`,
    dataText27: `21% Losing`,
    dataText28: `24% Losing`,
    dataText29: `27% Losing`,
    dataText30: `Manual Trading`,
    dataText31: `46% Losing`,
    dataText32: `48% Losing`,
    dataText33: `51% Losing`,
    dataText35: `Suitable for hands-off investors.`,
    dataText36: `Simply copy leader's strategies with a click of a button - data proves that Copy Trading improves chances of being profitable.`,
    dataText37: `Manual Trading`,
    dataText38: `Suitable for advanced investors.`,
    dataText39: `Not only does Manual Trading require more time and effort but data also shows that this has a significantly smaller chance of being profitable.`,
    dataText40: `Start Copy Trading`,
    dataText41: `Copy Trading vs Manual Trading`,
    dataText42: `Winning`,
    dataText43: `Losing`,
    dataText44: `The winners/losers ratio is produced as the number of accounts with at least one closed trade over a period of X months (as displayed on the following tables) and a positive realized PnL over that period, divided by the total number of accounts with at least one closed trade over the same period.`,
    dataText45: `3 months`,
    dataText107: `Jun 2023 - Aug 2023`,
    dataText46: `6 months`,
    dataText108: `Mar 2023 - Aug 2023`,
    dataText47: `12 months`,
    dataText109: `Sep 2022 - Aug 2023`,
    dataText48: `CopyTrading`,
    dataText49: `54% Winning`,
    dataText50: `58% Winning`,
    dataText51: `56% Winning`,
    dataText52: `CopyTrading - Uninterrupted`,
    dataText53: `79% Winning`,
    dataText54: `76% Winning`,
    dataText55: `73% Winning`,
    dataText56: `CopyTrading - Interrupted`,
    dataText57: `43% Winning`,
    dataText58: `46% Winning`,
    dataText59: `44% Winning`,
    dataText60: `3 months`,
    dataText110: `Jun 2023 - Aug 2023`,
    dataText61: `6 months`,
    dataText111: `Mar 2023 - Aug 2023`,
    dataText62: `12 months`,
    dataText112: `Sep 2022 - Aug 2023`,
    dataText63: `CopyTrading`,
    dataText64: `46% Losing`,
    dataText65: `42% Losing`,
    dataText66: `44% Losing`,
    dataText67: `CopyTrading - Uninterrupted`,
    dataText68: `21% Losing`,
    dataText69: `24% Losing`,
    dataText70: `27% Losing`,
    dataText71: `CopyTrading - Interrupted`,
    dataText72: `57% Losing`,
    dataText73: `54% Losing`,
    dataText74: `56% Losing`,
    dataText75: `What is Interrupted CopyTrading`,
    dataText76: `Some Investors interfere with the copied strategies, either by allocating less capital than recommended, adding custom Stop/Limit or Settings, or closing positions prematurely.`,
    dataText77: `Why are such options available to me?`,
    dataText78: `Our mission of transparency entails giving full power to our users to make their own decisions, and thus we are providing features that our community requests. However, actions that interfere with existing strategies are only recommended to users that are more advanced, as statistically manual intervention is more risky.`,
    dataText79: `Start Copy Trading`,
    dataText80: `In the section "Copy Trading vs Manual Trading", all Copy Trading data refers to Hands-off Copy Trading, which means no manual interruptions by the user.`,
    dataText81: `You may find out more details about what is Hands-off Copy Trading in`,
    dataText82: `this section`,
  },
  mobFaq: {
    text1: `What is ZuluTrade?`,
    text2: `ZuluTrade bridged the gap between valuable information in money markets and trade execution, by converting the advice of professional and talented traders globally to an executed trade rapidly and automatically in your account (from supported brokers)`,
    text3: `There was a time when trading was a headache. Not anymore! You don't have to study or monitor the market, because hundreds of Traders from all over the world are doing it for you. All you have to do is pick the Traders you like, and ZuluTrade will quickly convert their advice into live trades in your trading account directly with the broker. ZuluTrade offers Forex, CFD and Cryptocurrency trading. Binary Options trading is no longer supported!`,
    text4: `Check the performance results of our Traders`,
    text5: `here`,
    text6: `How do I get started?`,
    text7: `All you have to do is provide ZuluTrade with the name and account number of the brokerage firm you're trading with. To bridge the experts' advice with your broker account, you will need to provide consent to your Broker (normally by agreeing or signing an agreement or Letter of Direction). Click here to see which brokers we support.`,
    text8: `If it is the first time that you will trade online or you never had an on-line trading account with one of the brokers we support, then the first step is to open a new account. Click here to open one now in just 5 minutes.`,
    text9: `I already have an account with one of the dealing brokers you support. What is the next step?`,
    text10: `Click `,
    text10a: `here`,
    text10b: `and follow the wizard. When your broker notifies you that the change has been made, you will receive an email from us that you are ready to start using ZuluTrade. This process will take a maximum of 2 days.`,
    text11: `I do not want to open a new account, and I don’t want to sign any forms, even though there is not any cost involved with it. I prefer to use my existing account with the broker I’m trading with, without any changes. Is this possible?`,
    text12: `No. By signing the RB form, you enable ZuluTrade to receive commissions from the broker or dealer you have an account with, and most importantly for ZuluTrade to pay the Traders that are generating the trades being executed in your account. So if you do not wish to sign the RB form with the existing account, the alternative is to open a new account with your broker by signing the same RB form for that new account.`,
    text13: `To link your existing broker account with our revolutionary trading platform`,
    text14: `Please click`,
    text14a: `here`,
    text14b: `to fill in the signup form. We will then automatically send you your login details to begin autotrading with our platform. Accounts are usually activated within 3-5 days.`,
    text15: `How long does it take to open a new account with my existing broker?`,
    text16: `It only takes 1 day. Most brokers – if you already had an account with them, do not require duplicate proof of records, and will provide you with a new account number. Don’t forget to tell them that your Referring Broker is ZuluTrade. Click here to open a broker account already setup with ZuluTrade.`,
    text17: `I don’t have a trading account with the brokers you support. Is that a problem?`,
    text18: `No. You can open a new account already set up with ZuluTrade now by clicking `,
    text19: `Can I open my account in different base currency than USD?`,
    text20: `Yes. You can choose between USD, JPY, EUR, GBP and AUD.`,
    text21: `What is a lot?`,
    text22: `A standard lot is a buy or sell size. If you buy 1 lot of EUR/USD, trading at 100:1 leverage then you buy 100,000 EUR/USD. But since you’re trading with 100:1, the leveraged value is 1,000 EUR/USD or 1 standard lot. If you trade a lot, then you buy 10,000 EUR/USD and the leveraged value is 100 EUR/USD or 1 lot.`,
    text23: `In this case, 0.0001 * 100,000 = 10 and then, 10 / 1.13798 = 8.78750. The pip value in this case is: €8.79`,
    text24: `Can I trade manually? Or will it conflict with the automatic trades?`,
    text25: `Yes. Manual trades will not affect automatic trades.`,
    text26: `What are your trading hours?`,
    text27: `Trading hours vary by product. For Forex, trading opens on Sundays st 9:00 pm and closes on Fridays around 9:00 pm UTC times.`,
    text28: `For CFD trading hours, click`,
    text29: `Who are these Traders? How did you pick them?`,
    text30: `We do not pick them. Any individual can become a ZuluTrade Trader. It is not possible on ZuluTrade to misrepresent the performance of the signals generated. If ZuluTrade shows a profit or loss, it is accurate, because details are confirmed by the dealing broker’s statements. For each pip stated in the performance section, there is a trade registered in the broker’s back-office.`,
    text31: `How are Traders ranked?`,
    text32: `Forex Traders appear in the Performance Table ranked according to the ZuluRank algorithm, based on their performance results.`,
    text33: `For our US Traders page only, the Trader account must be compliant with the FIFO and No-Hedging market policy set up by NFA.`,
    text34: `If you want to find out more about the way ZuluRank is calculated, please click `,
    text35: `How do I control the number of lots and the number of positions a Trader opens on my account?`,
    text36: `When logged into your account at ZuluTrade, go to your account settings page. From there select the ‘max open lots’ drop down menu’, this allows you to set the number of total lots you wish a Trader to have open at any one time, before he can open any more lots. Also, next to each Trader's name you have selected, there is a drop down selection called ‘lots’. This pertains to the number of lots to be traded on each individual trade. For example: if you wish each Trader to trade with 2 lots per position, but wish there to be no more than 2 trades open at a time. Then you would set max open lots to 4, and number of lots next to your Traders name to 2.`,
    text37: `How do you sign up to be a Trader and/or an affiliate?`,
    text38: `You can find links to our Affiliate and Trader Programs at the bottom right of every page, or at the general Register page.`,
    text39: `Do you offer institutional accounts?`,
    text40: `Yes, we also offer various institutional account types based on trading volume. Please contact us for more details.`,
    text41: `Who is placing the trades on my account? Do the experts know about my account?`,
    text42: `None of the Traders that recommend trades will ever know your account's existence. ZuluTrade receives their recommendations, and checks the Traders you have subscribed with in your account profile, and decides whether or not to autotrade their advice in your Live account using a secure direct connection with the broker's backend. ZuluTrade's patented engine places trades automatically with no human intervention. ZuluTrade service is server based, in other words you don't even need to have your computer on.`,
    text43: `Are the results hypothetical? There are a lot of websites that claim all kinds of results...`,
    text44: `Every signal received by ZuluTrade is executed on at least one live / demo broker account. The results contain final spread, swap rates and profit or loss from closed positions in the account. The spread is the standard spread advertised by the broker you're trading with. Due to a variety of factors outlined in the disclaimer found at the bottom of the page, sometimes results may vary between accounts. All signals and trades executed on a demo account are considered to be hypothetical.`,
    text45: `I already have an account with one of the dealing brokers you support. Why is ZuluTrade telling me that I cannot use this account? Signing the RB form doesn't work. Do I need to open a new account? What’s wrong?`,
    text46: `This is because when you opened this account, there was a party that acted as a Referring Broker (RB). Your dealing broker cannot remove that Referring Broker from your account and replace it with ZuluTrade as the new one (legal issues). You need to open a new account and set ZuluTrade as your Referring Broker. Click`,
    text46a: `here`,
    text46b: `to open a new account already setup for ZuluTrade.`,
    text47: `Why do I need to type my username for the broker platform I’m trading with? Is there any danger for anybody to steal it?`,
    text48: `No, there is no danger. We will never ask you for your password. But even If your password was stolen, it is not possible for anyone (including ZuluTrade) to withdraw funds from your account. This is because your broker, who always wires funds to the beneficiary of the account ONLY, is the one who had opened the account`,
    text49: `What is a pip?`,
    text50: `A pip is the minimum movement of a currency upwards or downwards. For forex, the pip value calculation is performed as follows:`,
    text50a: `Pip Value = (Pip in decimal places * Trade Size) / Market Price`,
    text51: `Let's assume you trade 1 standard lot of EUR/USD with an account denominated in EUR. One pip in decimals for the standard lot = 0.0001, Trade Size = 100,000, Exchange Rate = 1.13798.`,
    text52: `In this case, 0.0001 * 100,000 = 10 and then, 10 / 1.13798 = 8.78750. The pip value in this case is: €8.79`,
    text53: `What is the minimum deposit to open a new account?`,
    text54: `It depends on the broker, but generally you can start with as little as $300.`,
    text55: `I logged into my account. How many Traders should I trade with?`,
    text56: `When you login to your account, you will be asked to set your Risk Meter Bar and add leaders to your portfolio. Based on the percentage of risk that you will set at your Risk Meter Bar, trading size will be automatically allocated for the traders you will add. If the percentage you have set is not enough for all traders, some of them may have 0 lots allocated. You can always change the percentage of the Risk Meter Bar, or remove traders, so as to set your portfolio as per your preference.`,
    text57: `Can you add more Traders?`,
    text58: `Send an email to support@zulutrade.com and we will add them as soon as possible.`,
    text59: `I have opened a new account with my broker. How do I know everything is ok?`,
    text60: `Your broker will send you an email confirming that we are the Referring Broker, and that your account is ready. Then, you log in to ZuluTrade and configure your trades.`,
    text61: `What is ‘spread’ and why do all my trades start off with negative pips?`,
    text62: `The spread in the Forex currency trading market, refers to the difference between the ‘bid’ price for the currency and the ‘ask’ price. Each traded currency pair opens with the spread. So if the spread is 5, then your trade will open at –5 pips.`,
    text63: `What do Leaders and Affiliates do? And how do they receive commission?`,
    text64: `A Trader places trades on his or her account, and all clients that have selected this Trader in their account settings page receive these trades on their accounts, unless they do not have enough usable margin, or have their max open lots set to low. Leaders provide signals for all clients who select them in their accounts settings page.`,
    text65: `Affiliates choose a designated URL (web page) to advertise ZuluTrade and draw clients to use our revolutionary service.`,
    text66: `Leaders get 0.5 pips for each traded lot of his or her own signals ONLY. So Trader signs up, sends signals, and passively waits for clients to use their signals, trying to have good performance to attract them.`,
    text67: `An Affiliate gets 0.4 pips for each traded lot of the clients that signed up through his website, for ANY signal. So Affiliate signs up, and starts advertising their website to attract clients to ZuluTrade.`,
  },
  mobDisclaimer: {
    text1: `Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly performance fee per selected trading system`,
    text2: `Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    text3: `One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results. Please click`,
    text3a: `here`,
    text3b: `to see full disclaimer.`,
    text4: `Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly subscription fee and a monthly performance fee per selected trading system.`,
  },

  //Change password
  changePassword: {
    passText1: `Change your password`,
    passText2: `Please change your password and keep it private since anybody who has access to your data may access your account.`,
    passText3: `Do not save your password in your browser’s memory.`,
    passText4: `Please note that this password will apply to any active accounts associated with your email address.`,
    passText5: `Current Password`,
    passText6: `New Password `,
    passText7: `Enter your current password`,
    passText8: `Please enter a new password. Use any combination of`,
    passText13: `letters, `,
    passText14: `numbers `,
    passText15: `and`,
    passText16: `special characters`,
    passText17: `(minimum 8 characters). Also note that letters are case-sensitive (for example, upper case A is not the same as lower case a).`,
    passText9: `Confirm New Password `,
    passText10: `Please re-enter your new password.`,
    passText11: `Change Password`,
    passText12: `Cancel`,
  },
  //Forgot password
  ForgotPassword: {
    forgotText1: `Forgot Password`,
    forgotText2: `Your ZuluTrade username or email address `,
    forgotText3: `Enter the e-mail address or username associated with your ZuluTrade account, then click Submit. We'll email you a link to a page where you can reset your password.`,
    forgotText4: `Please note that all your accounts registered under the same email address will be updated with the new password.`,
    forgotText5: `Enter your ZuluTrade Username`,
    forgotText6: `captcha`,
    forgotText7: `Submit`,
  },
  //oTppassword
  oTppassword: {
    oTpText1: `Email Address`,
    oTpText2: `Loading...`,
    oTpText3: `Thank`,
    oTpText6: `you!`,
    oTpText4: `Your account has created successfully.`,
    oTpText5: `Verify`,
  },
  //register main
  registerSteps: {
    registerText1: `Create an account`,
    registerText2: `First Name`,
    registerText3: `Last Name`,
    registerText4: `Select Country`,
    registerText5: `Search here..`,
    registerText6: `Password strength `,
    registerText7: `1 uppercase character`,
    registerText8: `8 or more characters`,
    registerText9: `at least 1 number`,
    registerText10: `at least 1 letter`,
    registerText11: `at least 1 special character`,
    registerText12: `Create account`,
    registerText13: `success`,
    registerText14: `In order to complete the onboarding process, kindly click the button below.`,
    registerText15: `Go to Website`,
  },
  //activation page
  activation: {
    activeText1: `Email Confirmation`,
    activeText2: `Verify your email`,
    activeText3: `To start using your ZuluTrade account, you will need to verify your email address. Please check your inbox for the email verification link.`,
    activeText4: `If you face any problems with the email verification link, please fill out your username and verification code in the form below.`,
    activeText5: `Username`,
    activeText6: `Enter your ZuluTrade Username`,
    activeText7: `This is your ZuluTrade username.`,
    activeText8: `Verification code`,
    activeText9: `Fill out the verification code, as found in the email you received from ZuluTrade. We recommend to copy & paste it, to prevent any type errors.`,
    activeText10: `Verify my email`,
    activeText11: `Resend Verification Link`,
  },
  //Account Setting
  acountSetting: {
    acText1: `Account Settings`,
    acText2: `Close Positions`,
    acText3: `Do not ask for confirmation when closing a position in trade tab.`,
    acText4: `Receive Newsletters Setting`,
    acText5: `Allow ZuluTrade to send you newsletters and other notifications. Your email will not be used for any kind of third party advertisements.`,
    acText6: `Yes, I would like to receive newsletters`,
    acText7: `Yes, I would like you to be notified via phone for matters related to client account services, new product information, and special offers`,
  },
  //copy trading Settings page
  automatedTrading: {
    automatedtext1: `ZuluGuard`,
    automatedtext2: `Autoprotect your account!`,
    automatedtext3: `Automated`,
    automatedtext3a: `Trading`,
    automatedtext3b: `Made Easy`,
    automatedtext4: `copy trading is a method of participating in financial markets by using an automation programme that executes pre-set rules for entering and exiting the markets. As the trader, you’ll combine thorough technical analysis with setting parameters for your positions, such as orders to open, trailing stops and pledged stops.`,
    automatedtext5: `Auto trading enables you to carry out many trades in a small amount of time, with the added benefit of taking the emotion out of your trading decisions. That’s because all the rules of the trade are already built into the parameters you set. With some algorithms, you can even use your pre-determined strategies to follow trends and trade accordingly.`,
    automatedtext6: `Open Real Account`,
    automatedtext7: `Open Demo Account`,
    automatedtext8: `What are the Benefits of Using copy trading?`,
    automatedtext9: `undefined`,
    automatedtext10: `Suitable for both beginners and professionals`,
    automatedtext11: `Suitable for both beginners and professionals`,
    automatedtext12: `Auto trading software such as MetaTrader 4, APIs is, utilised by beginners and professionals alike, both of which may find these systems helpful with decision making. Software is available sophistication.`,
    automatedtext13: `Entry Speed`,
    automatedtext14: `Improved Order Entry Speed`,
    automatedtext15: `Improved Order Entry Speed`,
    automatedtext16: `Since computers respond immediately to changing market conditions, automated systems can generate orders as soon as trade criteria are met. Getting in or out of a trade a few minutes earlier can make a big difference in the trade's outcome, although a good internet connection is important for this.`,
    automatedtext17: `Trade Diversification`,
    automatedtext18: `In this style of trading, traders can build a portfolio of different systems and instruments to cover different market conditions allowing for a level of diversification in their approach. This would be impossible for single trading to manage and is why large firms utilize trading systems because they can do so much at one time.`,
    automatedtext19: `Backtesting`,
    automatedtext20: `copy trading enables you to backtest, this is a way to come up with a strategy and iron out any problems before you put real funds on the line. This would give you invaluable insight into whether or not your strategy is going to be successful. Allowing you to optimize your strategy by looking at market data. It also allows you to determine the system’s expectancy (the amount you can expect to win or lose).'`,
    automatedtext21: `Elimination of Emotions`,
    automatedtext22: `copy trading systems minimize emotions throughout the trading process. By keeping emotions in check, traders typically have an easier time sticking to their trading strategies.`,
    automatedtext23: `Works on your Behalfs`,
    automatedtext24: `copy trading is programmed to conduct trades on your behalf according to predetermined conditions by EA’s (Expert advisors) and automatically updated in real-time. No need to be day trading again, no need to stay up at night, scan for hours on the market and this all because the EA carries the heavy load for you.`,
    automatedtext25: `copy trading`,
    automatedtext26: `Completely removes emotion from trading decisions. Most new traders simply struggle to keep on trading a strategy when they have had a few losing trades thereby never achieving a consistent set of trades to allow a statistical edge to work in their favour. Auto trading removes this emotion as the trading robot can execute your trades without any emotion.`,
    automatedtext27: `In this style of trading, traders can build a portfolio of different systems to cover different market conditions allowing for a level of diversification in their approach. As the algo can also show all the previous historical trades, traders can quickly identify whether a system has worked historically and gain useful statistics to understand when it will stop working in the future (such as exceeding historical consecutive losses, etc).`,
    automatedtext28: `Manual Traders`,
    automatedtext29: `Manual Trading does take time. The trader needs to perform research, be there to place their orders and spend time reviewing their trades and individual behaviour to try and reach superior performance. Some of these tasks can be semi-automated though. Many traders often let their emotions get to them and start to 'gamble.' It is up to the trader to maintain discipline in risk management at all times, making sure they don't risk too much to allow for losing trades, making sure they trade consistently to allow a statistical edge to work in their favour and making sure they focus on their processes rather than all the noise of other people's opinions.`,
    automatedtext30: `Many manual traders struggle with being solely accountable for their trading account and will often blame their strategy, or their platform, or their broker, rather than look internally at their behaviour and decision-making abilities.`,
    automatedtext31: `How to Get Started With Social Trading in 4 Simple Steps`,
    automatedtext32: `Open a trading account or sign in to ZuluTrade`,
    automatedtext33: `First, sign in to ZuluTrade if you have an account otherwise create a real or demo account and select a brokerage from a wide range of Forex Brokers.`,
    automatedtext34: `Open the Top Trader’s tab`,
    automatedtext35: `Simply go to the Trader’s tab on our website and browse the best Trader that would suit your risk appetite and investment levels, check what they’re trading, their performance and track record.`,
    automatedtext36: `Select the Trader`,
    automatedtext37: `When you’ve found the right Trader, select the follow button, choose the funds you wish to invest and your preferred risk.`,
    automatedtext38: `Start your copy trading Journey`,
    automatedtext39: `Hit the follow button again, and you are now Copy Trading. You now will begin to copy the positions of the Trader you have just followed.`,
    automatedtext40: `Features of our Supported copy trading Software`,
    automatedtext41: `The Automator`,
    automatedtext42: `A prized feature on ZuluTrade is The Automator. You can set rules to lock profit, update Stop/Limit for selected trades, close profitable trades or notify you of any significant changes on the markets. The Automator allows you to build rules at the Settings tab of your Forex account.`,
    automatedtext43: `Faster Calculations`,
    automatedtext44: `Compared to humans, trading robots can perform a wider range of calculations and access a wider range of data. analysis trading algorithms and increasing the scope of market analysis all through the use of a set of rules.`,
    automatedtext45: `Non-Stop Trading`,
    automatedtext46: `The copy trading software on ZuluTrade allows for trading 24/5, even when you are sleeping or unable to monitor market conditions. Trading robots can perform a wider range of calculations and access a wider range of data, increasing the scope of market analysis.`,
    automatedtext47: `Backtesting Feature on Historical Data`,
    automatedtext48: `Backtesting simulation involves testing a trading strategy on past performance. It assesses the strategy’s practicality and profitability on past data, guaranteeing it for success or failure or any needed changes.`,
    automatedtext49: `Integration With Trading Interface`,
    automatedtext50: `Algorithmic trading software places trades automatically based on the occurrence of the desired criteria. The software should have the necessary connectivity to the broker(s) network for placing the trade or direct connectivity to the exchange to send the trade orders.`,
    automatedtext51: `Testing Formula`,
    automatedtext52: `If you’ve spent years perfecting a strategy, then automating it could make it even more efficient. Which could in turn you provide you with greater and more consistent profits. No need for programmers or any programming language.`,
    automatedtext53: `Frequently Asked Questions (FAQs)`,
    automatedtext54: `Does copy trading work?`,
    automatedtext55: `The short answer is yes, copy trading involves using an algorithm to execute trades according to predetermined criteria. The benefits include more trading uptime, a reduction in the impact of emotions on your trading, and faster execution.`,
    automatedtext56: `Is Forex copy trading profitable?`,
    automatedtext57: `copy trading with social-copy or algorithmic trading tools can increase the probability of having a more diversified and less exposed portfolio, yet CFDs are complex instruments that come with a high risk of losing money, regardless of your trading strategy.`,
    automatedtext58: `What does copy trading software do?`,
    automatedtext59: `copy trading software allows traders to set criteria that determine both entry and exit points in the financial markets and make rules for money management. Once the system is programmed, the desired market is monitored and trades are made automatically.`,
    automatedtext60: `What is the best copy trading platform?`,
    automatedtext61: `There are many copy trading Platforms out there but ZuluTrade remains the most transparent and reliable among its competitors. At ZuluTrade, our investors capital is our top priority and our interests are fully aligned with the investors. We are proud of our fantastic customer support and our excellent features speak for themselves.`,
  },
  //Autoprotect Your Account
  autoprotectAccount: {
    text1: `Autoprotect your`,
    text1a: `account!`,
    text2: `ZuluGuard`,
    text3: `Autoprotect your account!`,
    text4: `ZuluGuard™`,
    text5: `Meet ZuluGuard`,
    text7: `ZuluGuard™ is an account protection feature that monitors each Trader's behavior and automatically removes a Trader when detecting a trading strategy has deviated from its expected loss profile.`,
    text8: `ZuluGuard™ Capital Protection is available for all Investors, and it is mandatory for the users residing in the EU using the EU ZuluTrade platform as it creates a protection shield for your investment capital!`,
    text9: `How does it work?`,
    text10: `The ZuluGuard™ automatically calculates a trading exit value for the trades opened in your account based on your specified capital protection amount. When your threshold is hit, ZuluGuard™ will step in, it will close all open positions and disable the Leader instantly.`,
    text11: `For detailed information about ZuluGuard, please consult the`,
    text12: `ZuluGuard Guide`,
    text13: `Open Real Account`,
    text14: `Open Demo Account`,
  },
  //Autoprotect Your Account
  //Funded Account
  FundedAccount: {
    fundedText1: `Our Funds, Your Profit`,
    fundedText2: `The Market Awaits You.`,
    fundedText3: `+10% off the profit made on your evaluation. No consistency rules, no`,
    fundedText3a: `daily drawdown limits, no rules designed to make you fail.`,
    fundedText4: `Get Funded`,
    fundedText5: `Read More`,
    fundedText6: `+10% off the profit made on your evaluation`,
    fundedText7: `The Market Awaits You`,
    fundedText8: `How it Works?`,
    fundedText9: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
    fundedText10: `Demo Stage`,
    fundedText11: `To become familiar with the platform, you’ll start with a demo stage (“Stage 1”).`,
  },
  //open new Investor account
  newInvestror: {
    newText1: `Welcome to ZuluTrade`,
    newText2: `Start trading and copying Traders with a real money account`,
    newText3: `Pick a Broker to trade with`,
    newText4: `PLATFORMS`,
    newText5: `REGULATED IN`,
    newText6: `MINIMUM DEPOSIT`,
    newText7: `SUPP.CURRENCIES`,
    newText8: `Logo`,
    newText9: `Fxview`,
    newText10: `MT4`,
    newText11: `Saint Vincent by SVGFSA`,
    newText12: `$100`,
    newText13: `USD,EUR,GBP,AUD`,
    newText14: `Select`,
    newText15: `ActivTrades`,
    newText16: `www.activtrades.com`,
    newText17: `United Kingdom by FCA`,
    newText18: `$500`,
    newText19: `EUR,USD,CHF`,
    newText20: `MT5`,
    newText21: `EU`,
    newText22: `blackbullmarkets.com/`,
    newText23: `New Zealand by FMA`,
    newText24: `$200`,
    newText25: `USD,EUR,NZD,AUD`,
    newText26: `Finveo`,
    newText27: `Serbia by SCMN`,
    newText28: `$100`,
    newText29: `USD`,
    newText30: `ITCS Capital`,
    newText31: `itcs.trade/`,
    newText32: `X3 Open Hub`,
    newText33: `Kazakhstan by ARDFM RK`,
    newText34: `$200`,
    newText35: `EUR,USD`,
    newText36: `Moneta Markets`,
    newText37: `www.monetamarkets.com/`,
    newText38: `Saint Vincent by SVGFSA`,
    newText39: `$200`,
    newText40: `EUR,USD`,
    newText41: `vantage`,
    newText42: `www.vantagefx.com`,
    newText43: `Cayman Islands by CIMA`,
    newText44: `$100`,
    newText45: `USD,EUR,GBP,AUD,CAD`,
    newText46: `aaafx-international.com`,
    newText47: `ZTP,MT4,MT5`,
    newText48: `Saint Vincent by SVGFSA`,
    newText49: `$300`,
    newText50: `EUR,USD,GBP,JPY,AUD,CHF,CAD`,
    newText51: `Tickmill`,
    newText52: `Libertex`,
    newText53: `DeltaStock`,
    newText54: `ActivTrade`,
    newText55: `BlackBull`,
    newText56: `ITCS Capital`,
    newText57: `Moneta Markets`,
    newText58: `vantage`,
    newText59: `AAAfx`,
    newText60: `Can’t find your broker.`,
    newText61: `Request to add`,
    newText62: `Please Register First`,
    newText63: `You need to register a new account to continue.`,
    newText64: `Create Account`,
    newText65: `Request to add new broker`,
    newText66: `Broker Name`,
    newText67: `Broker URL`,
    newText68: `Additional Info`,
    newText69: `Submit`,
  },
  // Market Chart
  marketChart: {
    chartText1: `Back`,
    chartText2: `My Trades`,
    chartText3: `Open`,
    chartText4: `Pending`,
    chartText5: `History`,
    chartText6: `Name`,
    chartText7: `Pnl`,
    chartText8: `Action`,
    chartText9: `Name`,
    chartText10: `Entry Price`,
    chartText11: `About`,
    chartText12: `Market News`,
    chartText13: `STATUS_UPDATE`,
    chartText14: `Name Surname`,
    chartText15: `@username`,
    chartText16: `1d`,
    chartText17: `Thank you to all my subscribers for choosing my signal. I appreciate your trust! Together we will make money in forex!`,
    chartText18: `Trader Name`,
    chartText19: `PREVIOUS CLOSE`,
    chartText20: `$194.70`,
    chartText21: `DAY RANGE`,
    chartText22: `YEAR RANGE`,
    chartText23: `$166.19 - $402.67`,
    chartText24: `MARKET CAP`,
    chartText25: `610.59B USD`,
    chartText26: `AVG. VOLUME`,
    chartText27: `87.79M`,
    chartText28: `P/E RATIO`,
    chartText29: `60.03`,
    chartText30: `DIVIDEND YIELD`,
    chartText31: `PRIMARY EXCHANGE`,
    chartText32: `NASDAQ`,
    chartText33: `About`,
    chartText34: `Tesla, Inc. is an American multinational automotive and clean energy company  headquartered in Austin, Texas. Tesla designs and manufactures electric vehicles, battery energy storage from home to grid-scale, solar panels and solar roof tiles, and related products and services. Tesla is one of the world's most valuable companies and remains the world's most valuable automaker with a market capitalization of more than US$840 billion. In 2021, the company had the most worldwide sales of battery`,
  },
  // Not Found
  notFound: {
    notText1: `404 - We are sorry. This page is missing...`,
    notText2: `404`,
    notText3: `PAGE `,
    notText3a: `NOT AVAILABLE`,
    notText4: `The page you are looking for might have been removed, had its name changed, or is temporarily unavailable`,
    notText5: `Go to ZuluTrade homepage`,
  },
  // OOPS
  oops: {
    oopstext1: `500 - An unexpected error occured when trying to serve this page to you....`,
    oopstext2: `500`,
    oopstext3: `PAGE`,
    oopstext3a: `NOT AVAILABLE`,
    oopstext4: `An unexpected error occured when trying to serve this page to you. `,
    oopstext5: `The ZuluTrade Web Administration Department has already been notified and probably working on it.`,
    oopstext6: `Contact`,
    oopstext7: `ZuluTrade`,
    oopstext8: `for more information, mentioning the error reference code`,
    oopstext9: `00000a`,
    oopstext10: `Go`,
    oopstext11: `back`,
    oopstext12: `or to`,
    oopstext13: `ZuluTrade homepage`,
  },
  //Social trading
  socialTrading: {
    socialText1: `Social Trading made Easy with ZuluTrade`,
    socialText2: `Like, comment, rate and request update from your Leaders`,
    socialText3: `OPEN REAL ACCOUNT`,
    socialText4: `OPEN DEMO ACCOUNT`,
    socialText5: `What is Social Trading?`,
    socialText6: `Social trading is a form of trading where investors can connect, observe, and copy the trading strategies of experienced leaders within a social network or platform.`,
    socialText7: `What are the Benefits of Social Trading?`,
    socialText8: `Collective knowledge in trading`,
    socialText9: `Social trading enables new traders to benefit from the wisdom, strategy, and knowledge of other, more experienced leaders to make a successful trade. While it isn’t a substitute for learning independently how to trade, it’s a useful tool to support beginners to embark on the learning curve.`,
    socialText10: `Part of the community `,
    socialText11: `It’s a huge advantage in the world of trading to be part of a community of seasoned investors. Social trading can be observed as a social network where everyone can have support, not from a single trader but from many different traders.`,
    socialText12: `Risk Monitoring`,
    socialText13: `Due to the lack of time, someone else is working for you and is at your disposal for communication. Traders can perform trading operations under their terms and with strategies that have already been tested, but also, they can discuss their way towards observing the situation in the market from several angles that way presenting diversity in trading strategies.`,
    socialText14: `Introduction to Diversification`,
    socialText15: `The improvement of the skills for managing the risk always comes hand in hand with trading. By following up with diverse and various strategies as you are copying the trade of other investors, you will be able to learn which strategies work and which are not as effective.`,
    socialText16: `Social Trading vs Manual Trading`,
    socialText17: `Features`,
    socialText18: `Manual Trading`,
    socialText19: `Copy Trading`,
    socialText20: `Trade like a professional with little Trading experience`,
    socialText21: `No icon`,
    socialText22: `Yes icon`,
    socialText23: `How to Get Started With Social Trading in 4 Simple Steps`,
    socialText24: `Open a trading account or sign in to ZuluTrade`,
    socialText25: `First, sign in to ZuluTrade`,
    socialText27: `First, sign in to ZuluTrade if you have an account otherwise create a real or demo account and select a brokerage from a wide range of Forex Brokers.`,
    socialText28: `Open the Top Leaders tab`,
    socialText29: `Simply go to the Leaders`,
    socialText31: `Simply go to the Leaders tab on our website and browse the best Leader for that would suit your risk appetite and investment levels, check what they’re trading, their performance and track record.`,
    socialText32: `Select the Leaders`,
    socialText33: `When you’ve found the right Leaders`,
    socialText34: `Select the Leader`,
    socialText35: `When you’ve found the right Leaders, select the Copy Strategy button, choose the funds you wish to invest and your preferred risk.`,
    socialText36: `Start your Copy Trading Journey`,
    socialText37: `Hit the follow button again`,
    socialText38: `Start your Copy Trading Journey`,
    socialText39: `Hit the Copy Strategy button again. You now will begin to copy the positions of the Leader you have just followed.`,
    socialText40: `Features of our Social Trading Platform`,
    socialText41: `Simulation`,
    socialText42: `A powerful tool that allows users to test their selected Leader's performance according to the Settings of their account.`,
    socialText47: `ZuluGuard™`,
    socialText48: `A unique user-friendly feature that protects Investors if erratic trades are opened by Leaders they are following. An excellent risk warning management addition.`,
    socialText49: `Social Trading Network Chart`,
    socialText50: `Here you can review signal providers, look at Leaders to copy, ask for advice, explore trends visually and ask questions. Helpful for first-time Investors to find out useful information.`,
    socialText51: `Frequently Asked Questions (FAQs)`,
    socialText52: `How does Social Trading work?`,
    socialText53: `Social trading platforms typically allow users to view the performance and trading activity of experienced leaders, and then choose to copy their strategies.`,
    socialText54: `What are the risks of Social Trading?`,
    socialText55: `Social trading carries the risk of over-reliance on the decisions of leaders, leading to potential losses if their strategies fail. Before engaging in social trading, thoroughly research and verify the track record of leaders you plan to follow, diversify your investments and leaders, and continuously monitor your portfolio to mitigate potential risks.`,
    socialText56: `Can you make money from Social Trading?`,
    socialText57: `Yes, it is possible to make money from social trading by following successful traders, learning from their strategies, and making informed decisions based on their insights and market analysis. However, it also carries risks, so it's important to approach it with caution and proper risk management strategies.`,
    socialText58: `Why is Social Trading Popular?`,
    socialText59: `Social trading is popular because it provides an opportunity for novice traders to learn from experienced ones, fosters community engagement, offers access to diverse investment strategies, and facilitates easy access to global financial markets through online platforms.`,
    socialText60: `Can I customize my investment strategy with social trading?`,
    socialText61: `Yes, you can customize your investment strategies by selecting specific leaders to follow, adjusting risk settings, and setting other parameters.`,
    socialText62: `What’s the best Social Trading platform?`,
    socialText63: `There are many Social Trading Platforms out there but ZuluTrade remains the most transparent and reliable among its competitors. ZuluTrade is known for its transparency, user-friendly interface, and extensive network of 2M+ Leaders . It allows investors to easily browse and analyze the performance of leaders, view their historical data, and choose the ones that best fit their investment goals and risk tolerance.`,
    socialText66: `Learn from other successful Leaders`,
    socialText67: `Watch professional Forex, Stocks or Crypto masters trade`,
    socialText68: `Diversify your portfolio to keep risks at a minimum`,
    socialText69: `Choose a trading strategy that suits your style`,
    socialText70: `Less susceptible to shifts or unpredictable economic events`,
    socialText71: `No need to monitor trades multiple times throughout the day`,
  },
  //Printable Terms
  printableTerms: {
    termsText1: `Agreement as to Terms of Service for the use of the Zulutrade Platform`,
    termsText2: `Click here for the Terms of Service for ZuluTrade EU`,
    termsText3: `By utilizing this website, you agree to be bound by these terms and conditions. This is a legal agreement ("Agreement") between you and Zulu Trade Ltd, or any of its affiliated entities ("ZuluTrade") for use of the website, data, ZuluTrade electronic trading platform, and products and services which you selected or initiated, which may include the ZuluTrade trading platform and third party signal providers ("Products and Services").`,
    termsText4: `1. Scope – General Information`,
    termsText5: `1.1. Scope of agreement`,
    termsText6: `This is a legal agreement (hereinafter the "Agreement") between you and Zulu Trade Ltd ("ZuluTrade") for use of the website, data, ZuluTrade electronic trading platform, and products and services which you selected or initiated,  which may include the ZuluTrade trading platform and third party signal providers ("Products and Services").`,
    termsText7: `1.2. ZuluTrade and the Zulutrade Platform`,
    termsText8: `1.2.1. ZuluTrade is a technology provider that provides to a number of FX and CFD Brokers and to their respective clients the possibility to integrate in their business relationship the functionality of the Zulutrade Trading Platform (hereinafter the “Zulutrade Platform” or the “Platform”).`,
    termsText9: `1.2.2. The ZuluTrade Platform is an copy trading system, allowing a Broker’s client to reproduce in his/her trading account held with the Broker (the “Trading Account”), in an automated way and subject to the application of various parameters and limitations, trading signals generated by third parties (the “Signal Providers” or “Traders”), as further set out in this agreement and explained on the Platform’s website.`,
    termsText10: `1.2.3. Any reference to your “Account” in the Platform and generally in relation to ZuluTrade, for the purposes of this Agreement, shall mean the personalized operation of the Platform concerning you, your relations with ZuluTrade and the transactions made by you through the use of the Platform’s functionality, as well as the capture and recording of such operation in the records and servers of ZuluTrade. Except for the purpose of receiving the applicable fees, ZuluTrade shall not receive or keep money or other assets belonging to you and, therefore, the meaning of the term “Account”, as defined above, does not refer to the holding of any of your assets.`,
    termsText11: `1.2.4. ZuluTrade does not undertake, by this Agreement to conduct any of your transactions either as your counterparty or as your broker and therefore assumes no liability in the event of non-performance (total or partial) or delayed execution of the signals generated through the Platform.`,
    termsText12: `1.2.5. ZuluTrade does not have any involvement in the determination and provision of the products made available to you by any Broker, including the applicable leverage and spreads.`,
    termsText13: `1.2.6. In addition to the automated generation of trading signals, the Platform allows you to generate your own trading signals (“manual” signals), as well as to monitor your transactions and be informed of their results and of your positions held with your Broker in the context of your Trading Account(s) that are connected with the Platform.`,
    termsText14: `1.2.7. The choice of your Broker shall be made exclusively by you and your relationship with such Broker shall be governed by the agreement entered into between you and your Broker. ZuluTrade shall make available to you, through the website hosting the Platform, a list of the Brokers with which the use of the Platform is compatible, solely for your convenience, without proposing or suggesting any specific Broker to you.`,
    termsText15: `2. Rules and arrangements for the provision of the service`,
    termsText16: `2.1. Means of providing the service`,
    termsText17: `2.1.1. You have access to the Platform through the website`,
    termsText17a: `www.zulutrade.com`,
    termsText17b: `or any other website communicated for this purpose to you by ZuluTrade or your Broker.`,
    termsText18: `2.1.2. Given the nature of the Zulutrade Platform, as provided through the use of electronic applications, you are required to make use of appropriate technical means (e.g. a computer, internet connection etc.), as well as to have the necessary knowledge for the appropriate handling of such means. By entering into this agreement and accessing the Zulutrade Platform, you acknowledge and confirm that you have the necessary equipment and sufficient expertise for its proper use.`,
    termsText19: `2.1.3. By using the Zulutrade Platform you acknowledge that you understand and accept the risks associated with Internet-based trading services including, but not limited to, hardware, software or Internet connection related issues, and the potential inability of timely transmission of the relevant communication due to interruptions and/or errors. Since ZuluTrade does not control signal power, its reception or routing via Internet, the configuration of your hardware or software, or the reliability of your Internet connection, you agree that you are solely responsible for such failures, including communication failures, disruptions, distortions and delays in trading. ZuluTrade recommends that you should be cautious when you use an iPhone, Smartphone, Blackberry or other handheld mobile device for trading or receiving data from the ZuluTrade platform. Handheld mobile devices depend on wireless connectivity, are subject to the limitations of mobile data, WiFi and mobile communication networks restrictions, and may not provide you with the same functions as accessing the ZuluTrade platform from a web browser. ZuluTrade shall not be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) resulting from the use of a handheld mobile device.`,
    termsText20: `2.1.4. The use of the Zulutrade Platform by you requires the combined use of your user name and password. The user name and password are created by ZuluTrade and sent to you after your registration in the Platform and remain private and strictly confidential. ZuluTrade may require for the activation of your access in the Platform a confirmatory email process or any other confirmation process ensuring that the identification data have been sent to/from you. The combined use of the user name and password by you establishes that the use of the Zulutrade Platform and any activity in your Account (indicatively, choice of Signal Providers, Account settings, manual actions, information on performed transactions, etc.) is made by you. It will be your responsibility to protect the secrecy of the user-name and password, bearing exclusive liability for any damage suffered as a result of any leaks or use thereof by a third party in any way, with or without your authorization. You may change the password at any time by following the relevant instructions listed on the website hosting the Platform, without any further disclosure of such change to ZuluTrade. For the purpose of preventing the use of the Zulutrade Platform by a third party, it is recommended to change your password on a regular basis. Especially, in case of a leak of the user name or password, you shall immediately notify ZuluTrade and change the password.`,
    termsText21: `2.2. No investment advice`,
    termsText22: `2.2.1. ZuluTrade shall not provide to you any kind of investment advice or other investment service. ZuluTrade offers solely the technical means that enable you to adopt trading strategies of other users of the Platform, based on the assumption that you have consciously chosen to conduct CFD and/or FX transactions with a Broker of your choice. ZuluTrade does not recommend/suggest to any user of the Platform any transactions in any financial instrument nor does ZuluTrade represent that such transactions may be suitable or appropriate for you or any other user.`,
    termsText23: `2.2.2. The history and statistics of the Signal Providers, the trading signals generated by the Signal Providers and the comments made by any Signal Provider on the Platform’s website do not constitute any kind of personal recommendation towards you or any other user to conduct or abstain from any transaction or to follow or unfollow any Signal Provider and should not be regarded as such.`,
    termsText24: `2.3. Registration with the Platform`,
    termsText25: `Unless otherwise expressly agreed, your registration with the Platform is that of a normal user (i.e. not a Signal Provider) and this Agreement governs your relations with ZuluTrade under this specific and sole capacity.`,
    termsText26: `2.4. Choice of Signal Providers`,
    termsText27: `The Platform provides you the opportunity to trade based on automated electronic signals. You may adjust the Platform’s settings so that it replicates in the Account the actions of one or more Signal Providers of your choice.`,
    termsText28: `The Signal Providers are third parties who use their signals (as displayed on the Platform) to carry out transactions for their own account, through Brokers of their choice. In the case in which transactions of a specific Signal Provider are performed in a simulated trading environment (“demo account”) and not though a real Broker account, ZuluTrade shall draw your attention to such fact with a relevant indication in a prominent position in the Provider’s  profile in the Platform.`,
    termsText29: `The Platform may incorporate, from time to time, tools or features classifying the Signal Providers into specific categories, or attributing specific ratings or filtering a limited number of specific Signal Providers or combinations thereof. Such tools are based on the application of technical criteria to the Signal Providers’ past performance and statistics, in a way to bring to your attention Signal Providers or combinations thereof that may correspond to your trading preferences. In all such cases, you will be able to view the Signal Provider’s trading characteristics as well as to compare his trading history and performance with those of any other Signal Provider. By entering into this Agreement, you acknowledge your understanding that in no case, such classifications, ratings filtering or combinations of Signal Providers will be considered to constitute any kind of recommendation from ZuluTrade to follow any Signal Provider (or any combined strategies) with a view to achieve any specific future result.`,
    termsText30: `In the case of combinations of Signal Providers, the operation of the said combination may be subject to specific rules that ZuluTrade will communicate to you and that you will be requested to accept before proceeding to the choice of such combinations.`,
    termsText31: `2.5. Application of settings to selected Systems`,
    termsText32: `The replication of a Signal Provider’s signals in your Account will always be subject to the restrictions applied by your Broker as to the offer of the products that you trade. In addition, you may choose specific settings and limits for the signals generated by each selected Signal Provider.`,
    termsText33: `2.6. “Manual” actions`,
    termsText34: `The Platform enables you to introduce trading signals in your Broker Trading Account at your own initiative (manually), meaning that you may open new positions or close positions previously opened by you or previously opened by a Signal Provider. When you introduce your own trading signals manually through the Zulutrade Platform interface, such signals will be executed by your Broker, however, for technical and other operational reasons, the prices provided by your Broker may be different than those that you see on the Platform at the moment of the placement of your manual signal. ZuluTrade shall accept no liability for any issues related to the execution of manual trades.  `,
    termsText35: `2.7. Limitations of the services and of ZuluTrade’s responsibility`,
    termsText36: `2.7.1. ZuluTrade draws your attention to the following:`,
    termsText37: `- The Signal Providers neither are related to ZuluTrade nor have they any professional certifications or titles with relation to financial markets.`,
    termsText38: `- ZuluTrade does not intervene in the content of the signals produced by the Signal Providers.`,
    termsText39: `- The Signal Providers do not have access to and do not take into consideration any of the personal information or the position of the account of any of their respective followers.`,
    termsText40: `- Monitoring and evaluation of the Signal Providers by ZuluTrade takes place solely based on their past behavior and will in no case refer to their future behavior or to the achievement of future performance.`,
    termsText41: `2.7.2. Although the Platform replicates exactly the signals of the relevant Signal Provider(s) (after applying any limitations set by you in your settings), delays in the execution of your transactions by your Broker are probable. It is also probable that divergences arise between the prices that any Signal Provider achieves, with respect to the his own trades, and the prices that you achieve when following the signals of such Signal Provider, even if your trades are carried out at the same time as those of the Signal Provider. Such delays and divergences are normally attributable to the structure and function of the market, and particularly to (indicatively):`,
    termsText42: `a) The time period, even if very short, between the moment a signal is received from the Signal Provider and the moment of the execution of your trade by your Broker.`,
    termsText43: `b) The overall operation of the FX and CFDs market, given that there is no single reference price for the participants in such market.`,
    termsText44: ` c) The special events occurring in the market, such as a steep price fluctuation (for example in the case of news announcement), a great volume of transactions waiting to be executed by your Broker or by your Broker’s liquidity provider.`,
    termsText45: `Volatility or illiquidity in the Forex markets may also prevent orders from being executed at advantageous prices, or at all.`,
    termsText46: `Therefore, ZuluTrade does`,
    termsText46a: `not`,
    termsText46b: `guarantee:`,
    termsText47: `- An immediate and complete execution of the signals by your Broker.`,
    termsText48: `- The matching of the prices at which you carry out any specific trade with those at which the Signal Provider carries out the same trade.`,
    termsText49: `Generally, ZuluTrade shall not be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) occurring because trades cannot be executed due to market conditions, or from any errors of your Broker.`,
    termsText50: `2.7.4. By entering into this agreement, you acknowledge that you accept the risks inherent to the performance of transactions in the financial instruments supported by the Platform and provided to you by your Broker and that ZuluTrade bears no liability for these risks. In particular you acknowledge:`,
    termsText51: `i) That you are aware that transactions on financial instruments involve risks causing the reduction of the value of investments.`,
    termsText52: `ii) That foreign exchange, CFDs and other leveraged trading activities involve significant risk of loss. Such activity is not suitable for all investors and you should make sure you understand the risks involved, seeking independent advice if necessary.`,
    termsText53: `iii) That your trading results may vary depending on many factors. Because the risk factor is high in the foreign exchange, CFDs and other leveraged products, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in leveraged products. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss.`,
    termsText54: `iv) That hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program. One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.`,
    termsText55: ` v) That you are aware and accept the risks arising from the replication of investment strategies of third parties when using the Zulutrade Platform and that past performance posted on the Platform is not indicative of future results.`,
    termsText56: `In this respect, you are required to carefully read the risk disclosure note found here:`,
    termsText56a: `https://www.zulutrade.com/risk-disclaimer, `,
    termsText56b: `before accepting these terms and before commencing the use of the Zulutrade Platform.`,
    termsText57: `2.7.5. ZuluTrade shall not be responsible for any damage incurred by you (or a third party having any relevant right through you) due to force majeure (including the exercise of the right of strike, the malfunction of computers including the breakdown of computer systems or electronic communications), fraudulent usage of the data stored in its records and servers by third parties, failure of its electronic systems due to malfunctions of the communications’ network or malfunctioning of its software and hardware that is not attributable to the lack of maintenance or monitoring, as well as events related to the operation of third parties, such as interruption or suspension or limitation of the activities carried out by Brokers with whom ZuluTrade cooperates in the context of this agreement or third parties intervening in the course of your relationships and transactions with your Broker (exchanges, companies settling transactions, credit institutions etc.) for whatever reason.`,
    termsText58: `2.7.6. ZuluTrade is not responsible for the actions or omissions of your Broker or any other third party with respect to the transactions that you carry out with your Broker for whatever reason.`,
    termsText59: `2.7.7. ZuluTrade does not guarantee that the use of the Zulutrade Platform will have a positive result or a specific performance for you. Any reference to the performance of transactions carried out by users of the Platform or Signal Providers is related exclusively to the past and by accepting these terms you acknowledge that you are aware of such fact. Any reference to the performances of any Signal Provider may not take into account the costs of transactions, such as Broker fees or any other fees applicable to your Zulutrade Platform Account in the context of this Agreement.`,
    termsText60: `2.7.8. The trades conducted by Signal Provider on his own trading account may involve amounts greatly different from the amounts allocated by you to each transaction following the Signal Provider’s activity. This may itself cause a significantly different result compared to the Signal Provider’s profits or losses even if the remaining characteristics of your positions are the same as the ones of the specific Signal Provider. In addition, particular limitations may be applied to your trading account by your Broker, which may not necessarily apply to the Signal Provider at the same time. Such limitations may be due to specificities of the regulatory framework applicable to each Broker or to the Broker’s policies. The application of such limitations on your trading account may lead to further divergences between the results of the Signal Providers’ trades and your own trades, especially if the Signal Provider’s trading account is not subject to the same limitations.`,
    termsText61: `2.8. Access to CFD Market Data to Demo Accounts`,
    termsText62: `As regards CFDs, and to the extent feasible, ZuluTrade may provide to you access to online price and trade-related data regarding the underlying securities (“Market Data”). Such Market Data may include the streaming of quotes, market volume information, as well as pubic announcements relating to the issuers of underlying securities. Any such Market Data will be displayed on an “as is” basis, i.e. as received from ZuluTrade’s Market Data provider, and will include information that is available in a specific exchange. In this context, the prices of a specific underlying security that are  displayed in the Market Data may be different from the prices of the same security in other exchanges or markets where the same security may be admitted.`,
    termsText63: `The provision of access to certain categories of Market Data is subject to the acceptance by you of the terms of the Market Data Addendum which forms an annex to this Agreement. To the extent that the Market Data Addendum refers to a specific Market Data provider, such Addendum will be terminated or amended or substituted in the event of termination of ZuluTrade’s relationship with the specific Market Data provider or in the event of substitution of the Market Data provider. In such case ZuluTrade will apply its best endeavors in order to give you reasonable notice of the relevant event. In the case of substitution of the Market Data Provider, the provision of access to Market Data may be subject to the acceptance of additional terms by you.`,
    termsText64: `Access to Market Data is provided to you exclusively for internal/personal use. You may not sell, lease, furnish or otherwise permit or provide access to Market Data to any other Person or to any other office or place. You will not engage in the operation of any illegal business use or permit anyone else to use Market Data, or any part thereof, for any illegal purpose or violation of any Regulatory Requirements. You may not present Market Data rendered in any  unfair, misleading, or discriminatory format. You shall take reasonable security precautions to prevent unauthorized persons from gaining access to Market Data.`,
    termsText65: `By utilizing Market Data related to individual US Stocks, you agree to be bound by the Market Data Addendum that can be found.`,
    termsText65a: `here.`,
    termsText66: `2.9. Inability to access the Platform`,
    termsText67: `In case, for whatever reason, you are unable to access the Platform and its services, you must immediately notify ZuluTrade and contact your Broker directly to manage any open positions you have in your Trading Account. You may also request from your Broker to disconnect your account from ZuluTrade, while you are unable to access the ZuluTrade services, so as to avoid receiving new positions from Signal Providers in your Trading Account.`,
    termsText68: `3. Communication`,
    termsText69: `3.1. Means of Communication`,
    termsText70: `The communication between you and ZuluTrade shall take place, depending on the case, either in writing or orally. More particularly:`,
    termsText71: `- The written communication takes place via documents exchange/shipping, via fax or via electronic mails being sent to the electronic addresses of the parties or via the Platform’s dedicated application for messages and  communication.`,
    termsText72: `- Oral communication takes place in principle via telephone. ZuluTrade may tape record your telephone conversations with its employees, in order to prove the content of such conversations, as well as for the protection of your and ZuluTrade’s mutual interests. All such recorded telephone conversations may be stored in accordance with its Data Privacy Policy that is available on ZuluTrade’s website.`,
    termsText73: `3.2. Confidentiality`,
    termsText74: `ZuluTrade will keep the information provided by you confidential under this agreement and take the necessary internal measures in order to ensure the confidentiality of such information. Confidentiality does not extend to any data or information`,
    termsText75: `a) That ZuluTrade has an obligation to disclose to the public or any competent authority,`,
    termsText76: `b) That have to be invoked by ZuluTrade before a Court or any competent authority for ZuluTrade’s defense with respect to a case pending against ZuluTrade relating to the relationships governed by this agreement,`,
    termsText77: `c) That have to be communicated to companies cooperating with ZuluTrade in order for the obligations undertaken by ZuluTrade under this agreement to be fulfilled.`,
    termsText78: `3.3. Information regarding your trading activity`,
    termsText79: `ZuluTrade provides you with ongoing access to a personalized area of the Platform, in which you enter by using your individual codes, for the purpose of receiving at any time information on your trading activity at any period of time.`,
    termsText80: `Although ZuluTrade will apply its best efforts in order to provide you complete and accurate information in a timely manner, such information is not intended to substitute the information that your Broker is expected to provide to you with respect to the activity of your Trading Account. Consequently, ZuluTrade draws your attention to the fact that you must regularly check your Trading Account’s activity and content with your Broker, through the relevant applications, trading platforms or other communication means that are made available to you by your Broker.`,
    termsText81: ` In case of various events that – in ZuluTrade’s reasonable opinion – may have an effect on your Account (such as a substantial change of trading behavior by a Signal Provider that you may have selected), ZuluTrade may send you relevant notices through the Platform.`,
    termsText82: `ZuluTrade provides 24-hour telephone helpline for the Platform. The relevant details can be found here:`,
    termsText82a: `https://www.zulutrade.com/support`,
    termsText83: `3.4. Probative force of book files and computer records`,
    termsText84: `Any copy or extract of the forms, data, books, records and files issued or kept by ZuluTrade, either in paper or in electronic form – in accordance with applicable legislation - or any extract of the Zulutrade Account, which shall be exported by ZuluTrade and shall display the history of the Zulutrade Account’s operation, shall constitute full proof for the activity of the Account. Similarly, extraction of logs regarding every aspect of the operation of your Account,  including logs relating to your logging in the Platform and any use of the Platform by you, shall constitute full proof of the related facts.`,
    termsText85: `3.5. Liability for transmission errors`,
    termsText86: `In cases of delay, interruptions or other errors that may occur during the transmission of electronic mail or fax, as well as in cases of incorrect translation or interpretation of technical terms, ZuluTrade shall only be liable for willful misconduct or gross negligence of its executives, agents or employees.`,
    termsText87: `4. Fees`,
    termsText88: `4.1. Classic Account`,
    termsText89: `Unless explicitly indicated otherwise by ZuluTrade, in the case of a "Classic Account", ZuluTrade is compensated for its services based on the spread between the bid/ask prices provided to you by your Broker or based on any other specific costs charged to you by your Broker when initiating a trade .`,
    termsText90: `4.2. Profit Sharing Account`,
    termsText91: `In the case of a Profit Sharing Account, you shall pay directly to ZuluTrade a monthly fixed Subscription Fee at the start of the calendar month (calculated on a pro-rata basis for periods of less than a full month).`,
    termsText92: `In addition to the Subscription Fee, you shall pay to ZuluTrade a percentage based Monthly Performance Fee per selected Signal Provider. The Monthly Performance Fee shall be calculated on a monthly basis and shall be based upon your profit made from each selected Signal Provider that exceeds the Benchmark. For the purpose of this agreement, a “Benchmark” is defined as the net new high in profit of a Signal Provider at the end of a given monthly period, during the life of the Account. Profit shall be determined by taking into consideration the your equity at the end of each month, on all trading activity from each selected Signal Provider (which includes all open and closed positions) during the same month, comparing it to previous Benchmark(s).`,
    termsText93: `The Performance Fee will be applicable separately on each Signal Provider and not in the aggregate performance of the Account as a whole.`,
    termsText94: `To open a Profit Sharing Account you must provide one or more payment methods. In case your primary payment method is declined or no longer available to ZuluTrade for the payment of the Subscription Fee and/or Performance Fees, ZuluTrade shall charge any one of the other payment methods associated to your Account, as the case may be, including but not limited to the Trading Account with your Broker. In such case, ZuluTrade will proceed to the collection of the fees from the Broker on a best effort basis, without further direction or confirmation from you, unless otherwise notified in writing. This applies to outstanding fees of a current or previous months. ZuluTrade shall not be held responsible or liable for any miscalculation or non-payment of said fees for any reason whatsoever.`,
    termsText95: `You can cancel the Profit Sharing account subscription at any time, and will continue to have access to the account through the end of the billing period. Payments are non-refundable and ZuluTrade does not provide refunds or credits for any partial - month subscription periods or unused Profit Sharing Account. To cancel the subscription, you should follow the relevant instructions of the Platform.`,
    termsText96: `The amounts of the Subscription Fee and Performance Fees are available on ZuluTrade’s website and shall be communicated to you in the context of the opening a Profit Sharing account. ZuluTrade may change the said amounts from time to time; however, any such changes will apply to subsequent billing cycles following notice of the change(s) to you. Profit Sharing Account fees may be charged by ZuluTrade itself or by any other Affiliate entity of ZuluTrade as the case may be.`,
    termsText97: `By utilizing a Profit Sharing account, you agree to be bound by the Agreement on the Storage of the Cardholder’s Credentials that can be found `,
    termsText97a: `here`,
    termsText98: `5. Authorizations`,
    termsText99: `You hereby authorize ZuluTrade to access the Trading Account held by you with your Broker, via all appropriate technical means and - to the extent necessary - the relevant competent personnel and executives thereof, in order`,
    termsText100: `a) to connect the Platform to your Trading Account in a way to allow the introduction of the signals resulting from the operation of the Platform in your Trading Account;`,
    termsText101: `b) to obtain full information about your Trading Account connected to the Platform, in order to be able to provide comprehensive information to you via the Platform;`,
    termsText102: `c) to obtain information regarding your identification, for the purpose of providing the Zulutrade Platform to you, receiving payments from you and complying with any applicable rules (including for the prevention of money laundering)`,
    termsText103: `d) to proceed to any other necessary consultation and collaboration with the Broker in order for the purpose of this agreement to be fulfilled.`,
    termsText104: `6. Client’s Data`,
    termsText105: `6.1. Client’s identity`,
    termsText106: ` For proof of your identity and that of any representatives designated by you, ZuluTrade may rely upon any document that, by law, is regarded as proof of identity of a person or legal entity. ZuluTrade shall not be responsible for the validity, legal integrity or authenticity of such documents. If you suffer loss by error, misunderstanding, deception or fraud as to the person giving instructions to ZuluTrade to carry out a specific action, ZuluTrade shall only be liable for willful misconduct or gross negligence of its executives, employees and agents.`,
    termsText107: `6.2. Signature specimens`,
    termsText108: `ZuluTrade is entitled to receive and keep specimens of your and your representatives’ signatures, if necessary for the fulfilment of the Service.`,
    termsText109: `6.3. Change of data`,
    termsText110: `You shall notify ZuluTrade on your own initiative, without delay, of any change of name, contact information (especially e-mail address) and other data you have provided to ZuluTrade when entering into this agreement, as well as any change regarding the powers of representation granted to other persons (if applicable). You are not entitled to oppose against ZuluTrade any change of the above data, if such change has not been notified to ZuluTrade in writing.`,
    termsText111: `6.4. Personal Data`,
    termsText112: `Your personal data, as disclosed to ZuluTrade from time to time, will be stored, processed and disclosed to third parties for the purpose of providing the services and complying with ZuluTrade’s obligations, in accordance with ZuluTrade’s Data Privacy Policy, which is available through the link`,
    termsText112a: `https://www.zulutrade.com/privacy-policy`,
    termsText112b: `and the links provided therein).`,
    termsText113: `7. Refund Policy`,
    termsText114: `ZuluTrade will issue a refund to an investor for losses caused by Platform Errors, if the investor provides timely notice of the error to ZuluTrade as described below. A Platform Error occurs if a trading signal that should have been rejected according to the investor’s account settings is accepted by ZuluTrade and submitted to the investor’s broker, or if a trading signal that should have been accepted according to the investor’s account settings is rejected by ZuluTrade and not submitted to the investor’s broker. ZuluTrade shall not be responsible for refunding lost profits resulting from Platform Errors under any circumstances.`,
    termsText115: `Errors of Signal Providers or Brokers shall not be considered Platform Errors, and ZuluTrade shall not be responsible for refunding losses or lost profits resulting from such errors under any circumstances.`,
    termsText116: `In order to receive a refund of losses caused by Platform Errors, you must notify ZuluTrade in writing of your potential claim. You must deliver the written notice to ZuluTrade as soon as you knew, or should have known, of the Platform Error, but in any event, no later than twenty-four hours after the Platform Error occurred. If ZuluTrade does not receive such notice from you within twenty-four hours after the Platform Error occurred, it will not be liable for any losses resulting from the Platform Error.`,
    termsText117: `If ZuluTrade confirms that a Platform Error occurred with respect to your account, and you provided the required notice to ZuluTrade within twenty-four hours, you will receive a refund of your losses caused by the Platform Error (not including lost profits). ZuluTrade processes all Refunds requests within 20 days following the acknowledgment of the receipt of the Refund request.`,
    termsText118: `You are required to review your Account statements and monitor your Account status at least daily to ensure that your trades were placed, and that all trades that were made on your Account were authorized by you and also monitor your Broker’s reports and platform with respect to your Trading Account, as highlighted in clause 3.3. hereof.`,
    termsText119: `Except as expressly provided by this refund policy, neither ZuluTrade, nor our officers, principals, employees or agents shall be liable to any person for any losses, damages, costs or expenses (including, but not limited to, loss of profits, loss of use, direct, indirect, incidental or consequential damages) resulting from any errors in the ZuluTrade Platform.`,
    termsText120: `* ZuluTrade's refund policy does not apply to IC Markets Investor accounts`,
    termsText121: `8. Final provisions`,
    termsText122: `8.1. Representation regarding funds`,
    termsText123: `By accepting these terms and proceeding to the opening and operation of your Account, you represent and certify to Zulutrade that`,
    termsText124: `a) all amounts of money that you have deposited and/or will deposit with your Broker belong to you, are from a legal source and do not derive from acts or activities falling within the prohibitions of the laws on the prevention and suppression of money laundering;`,
    termsText125: `b) generally you shall comply with the legal provisions and shall not engage in illegal or prohibited actions or practices;`,
    termsText126: `c) all information that you have provided or that you will provide to your Broker or to ZuluTrade regarding yourself and the origin of your assets are/will be true.`,
    termsText127: `8.2. Restrictions to the use of Platform Information`,
    termsText128: `By viewing ZuluTrade’s website and trading on Platform, you fully understand and agree to respect the proprietary rights of Signal Providers and of ZuluTrade, and you represent that you do not desire to acquire from the Signal Providers any trade secrets or confidential information.`,
    termsText129: `You represent that you will be using signals solely for your own ZuluTrade Account and that you will not be reproducing such signals with any third party platforms or services outside of the ZuluTrade platform, without the written permission of ZuluTrade. Immediately upon notice from ZuluTrade that, in ZuluTrade’s opinion, any use of the Platform does not  comply with this standard, you will cease such manner of use. Furthermore you agree that ZuluTrade may at its sole discretion disable your access to the Platform at any time, in the case of system abuse or any infringement of any patent, copyright, trademark or other proprietary right or infringement upon a trade secret of any person or entity, or other reasons not described in this Agreement.`,
    termsText130: `8.2. Duration – Termination`,
    termsText131: `This agreement is of indefinite duration. The agreement shall take effect after acceptance by the Client and upon the opening of the Account by ZuluTrade, and shall be terminated upon the closure of the Account. The Account may be closed either by a request from you, subject to ZuluTrade’s acceptance and provided that no Fees are outstanding, or in ZuluTrade’s initiative with a reasonable notice or – in the case of inactive accounts – with no notice.`,
    termsText132: `8.3. Governing law – Jurisdiction`,
    termsText133: `8.2.1. All contractual relationships between ZuluTrade and the Client are governed by the laws of Cyprus.`,
    termsText134: `8.2.2. The courts of Cyprus will be competent for any dispute between you and ZuluTrade arising from this contractual relationship. ZuluTrade has the discretion to choose any other court having jurisdiction for the exercise of its claims against you.`,
    termsText135: `8.3. Other terms`,
    termsText136: `8.3.1. The terms of this agreement shall only be amended by a written agreement between you and ZuluTrade (including by way of acceptance by electronic means), without prejudice to the provisions of the following paragraph.`,
    termsText137: `8.3.2. ZuluTrade shall have the right, at any time and under its sole and absolute discretion, to unilaterally change and/or amend the terms and conditions of this Agreement. You agree that any new format of this Agreement which shall be posted on ZuluTrade’s Website shall be considered as sufficient provision of notice for the changes and/or amendments made in such new format and shall become effective as of the date of posting it as aforesaid.`,
    termsText138: `8.3.3. Any delay or omission of ZuluTrade to exercise any statutory or contractual right, in no way shall be deemed or construed as a waiver of the respective right.`,
    termsText139: `8.3.4. In case one or more the terms of this agreement is or becomes unenforceable, it shall not affect the validity of the remaining terms and the remaining provisions shall remain in force.`,
    termsText140: `8.3.5. You shall not assign or transfer in any way to third parties any of your rights and claims arising from your relations with ZuluTrade under this Agreement, unless otherwise agreed in writing.`,
    termsText141: `Printable`,
  },
  //ZuluRanking system
  zuluRanking: {
    systemTest1: `ZuluRanking system`,
    systemTest2: `A Novel Approach to Systems Ranking by ZuluTrade`,
    systemTest3: `Most trading sites that track systems performance will show you the best performing systems near the top. But usually this does not take into account how feasible this performance is in reality. For example, when a system opens more and more trades without first closing the previous ones, your account needs to have a lot of available margin (funds) to be able to open the new positions as they come.`,
    systemTest4: `But in reality, your account might be unable to open many positions at the same time due to margin requirements.`,
    systemTest5: `Our proprietary`,
    systemTest5a: `ZuluRank`,
    systemTest5b: `system takes many parameters into consideration, like:`,
    systemTest6: `maturity`,
    systemTest6a: `(how long a system has been running for)`,
    systemTest7: `exposure`,
    systemTest7a: `(how many positions might be open at the same time)`,
    systemTest8: `drawdown`,
    systemTest8a: `(how many ups and downs the system has experienced)`,
    systemTest9: `in order to provide you with an easy way of viewing systems that are good performers and filter out feasible trading strategies that emphasize on providing solid and stable trading results, have normal trading cycles and avoid over-exposing your invested capital.`,
    systemTest10: `Please note that the ZuluRank system results are completely computer-generated. As a result, systems are sorted without regard to human viewpoints or strategy preferences, and you can always choose from a wide variety of systems that can match most styles of trading.`,
    systemTest11: `We will continue to improve ZuluTrade by adding more systems, fine-tuning our technology and providing our service to traders all around the world.`,
    systemTest12: `ZuluTrade Europe`,
    systemTest13: `In addition, for Leaders being available for EU Investors, only the top 1000 Leaders following the most solid and consistent trading strategy will be available. So they must comply to the following parameters:`,
    systemTest14: `Minimum trading time of at least 8 weeks`,
    systemTest15: `Maximum Drawdown should not exceed 30% of their total profit`,
    systemTest16: `The average number of pips is above 3 or average rate of return is above 0.015%`,
    systemTest17: `As you can see, ZuluRank uses a specific set of parameters to rank leaders. As a result, the best leader for you might be different from the best leader according to ZuluRank. Depending on your trading style, needs, risk allowance, etc., you could place priority on other factors / characteristics.`,
    systemTest18: `For example, a small sized Investor account could be more interested in safer strategies with a smaller profit margin, whereas an account with considerable margin would normally rather be seeking strategies with higher profits and be able to maintain greater drawdown.`,
    systemTest19: `So ZuluRank stresses the aforementioned trading factors, it is not necessarily the only possible ranking – so please feel free to interpret and consult ZuluRank always according to your own unique trading needs!`,
  },
  //WelCome A
  welcomeA: {
    welcomeText1: `Invest with the best!`,
    welcomeText2: `Discover the top Traders from all over the world, copy their strategy and receive their trades in real time in your investment account.`,
    welcomeText3: `Register Now`,
    welcomeText4: `How it works?`,
    welcomeText5: `Welcome to ZuluTrade | ZuluTrade Social Trading 1`,
    welcomeText6: `Traders connect their account with ZuluTrade, show their results and compete in`,
    welcomeText7: `Welcome to ZuluTrade | ZuluTrade Social Trading 2`,
    welcomeText8: `Investors can copy any strategy, apply their own `,
    welcomeText9: `Welcome to ZuluTrade | ZuluTrade Social Trading 3`,
    welcomeText10: `Traders get compensated when Investors `,
    welcomeText11: `Why join ZuluTrade?`,
    welcomeText12: `Discover the next big trading talent, in the largest community of traders in the world!`,
    welcomeText13: `Comment, rate, participate with ZuluTrade's social features and tame the market using the wisdom of the crowds!`,
    welcomeText14: `Stay in control - ZuluTrade is the most advanced and feature-rich platform to customize your experience`,
    welcomeText15: `Doesn't matter if you are newbie; learn the basics the easy way - maybe you will become #1 Trader one day!`,
    welcomeText16: `We are truly customer-oriented: our ace`,
    welcomeText17: `ZuluTrade is established globally, used by millions of users and is the only regulated service in EU &amp; Japan!`,
    welcomeText18: `Join us at ZuluTrade`,
    welcomeText19: `Create a real money account Or practise with a Demo`,
    welcomeText20: `Trading spot currencies involves substantial risk and there is always the potential for loss.`,
    welcomeText21: `Please click`,
    welcomeText22: `here`,
    welcomeText23: `to see full disclaimer.`,
    welcomeText24: `Customer Support`,
    welcomeText25: `is always here to help.`,
    welcomeText26: `copy them!`,
    welcomeText27: `rules, or trade manually`,
    welcomeText28: `Performance page`,
  },
  //WelCome B
  welcomeB: {
    welcomeText1: `Invest with the best!`,
    welcomeText2: `Discover the top Traders from all over the world, copy their strategy and`,
    welcomeText2a: `receive their trades in real time in your investment account.`,
    welcomeText3: `OPEN REAL ACCOUNT`,
    welcomeText4: `OPEN DEMO ACCOUNT`,
    welcomeText5: `How does it work?`,
    welcomeText6: `Traders connect their account with ZuluTrade, show their results and compete in `,
    welcomeText6a: `Performance page`,
    welcomeText7: `Investors can copy any strategy, apply their own rules, or trade manually`,
    welcomeText8: `Traders get compensated when Investors copy them!`,
    welcomeText9: `Why join ZuluTrade?`,
    welcomeText10: `Discover the next big trading talent, in the largest community of traders in the world!`,
    welcomeText11: `Comment, rate, participate with ZuluTrade's social features and tame the market using the wisdom of the crowds!`,
    welcomeText12: `Stay in control - ZuluTrade is the most advanced and feature-rich platform to customize your experience`,
    welcomeText13: `Doesn't matter if you are newbie; learn the basics the easy way - maybe you will become #1 Trader one day!`,
    welcomeText14: `We are truly customer-oriented: our ace`,
    welcomeText14a: `Customer Support`,
    welcomeText14b: `is always here to help`,
    welcomeText15: `ZuluTrade is established globally, used by millions of users and is the only regulated service in EU &amp; Japan!`,
  },
  //WelCome C
  welcomeC: {
    welcomeText1: `Invest with the best!`,
    welcomeText2: `Discover the top Traders from all over the world, copy their strategy and receive their trades in real time in your investment account.`,
    welcomeText3: `How does it work?`,
    welcomeText4: `Traders connect their account with  ZuluTrade, show their results and compete in `,
    welcomeText4a: `Performance page`,
    welcomeText5: `Investors can copy any strategy, apply their own rules, or trade manually`,
    welcomeText6: `Traders get compensated when Investors copy them!`,
    welcomeText7: `Why join ZuluTrade?`,
    welcomeText8: `Discover the next big trading talent, in the largest community of traders in the world!`,
    welcomeText9: `Comment, rate, participate with ZuluTrade's social features and tame the market using the wisdom of the crowds!`,
    welcomeText10: `Stay in control - ZuluTrade is the most advanced and feature-rich platform to customize your experience`,
    welcomeText11: `Doesn't matter if you are newbie; learn the basics the easy way - maybe you will become #1 Trader one day!`,
    welcomeText12: `We are truly customer-oriented: our ace`,
    welcomeText12a: `Customer Support`,
    welcomeText12b: `is always here to help`,
    welcomeText13: `ZuluTrade is established globally, used by millions of users and is the only regulated service in EU &amp; Japan!`,
  },
  // Widget carousel watchlist
  carouselWatchlist: {
    inText1: `Logo`,
    inText2: `left-arrow`,
    inText3: `right-arrow`,
    inText4: `*TRADING INVOLVES HIGH RISK`,
    inText5: `Amount Following`,
    inText6: `Annualized ROI`,
    inText7: `Profit:`,
    inText8: `Weeks:`,
    inText9: `Avg Profit:`,
    inText10: `Maximum Drawdown:`,
    inText11: `Winning trades:`,
    inText12: `Max Open Trades:`,
    inText13: `Investors:`,
    inText14: `Follow`,
    inText15: `Login`,
  },
  // Trader Info
  tradeInfo: {
    infoText1: `ZulluTrade`,
    infoText2: `*TRADING INVOLVES HIGH RISK`,
    infoText3: `Amount Following:`,
    infoText4: `Annualized ROI:`,
    infoText5: `Profit:`,
    infoText6: `Weeks:`,
    infoText7: `Avg Profit:`,
    infoText8: `Maximum Drawdown:`,
    infoText9: `Winning trades:`,
    infoText10: `Max Open Trades:`,
    infoText11: `Investors:`,
    infoText12: `Follow`,
    infoText13: `Login`,
  },
  marketDetail: {
    text1: `Added`,
    text2: `Add to watchlist`,
    text3: `Open`,
    text4: `High`,
    text5: `Low`,
    text6: `My`,
    text7: `Trades`,
    text8: `Pending`,
    text9: `History`,
    text10: `Name`,
    text11: `Pnl`,
    text12: `Action`,
    text13: `Entry Price`,
    text14: `P/L`,
    text15: `Lots`,
    text16: `About`,
    text17: `Market News`,
    text18: `STATUS_UPDATE`,
    text19: `Trader Name`,
    text20: `Name Surname`,
    text21: `@username`,
    text22: `1d`,
    text23: `Thank you to all my subscribers for choosing my signal. I appreciate your trust! Together we will make money in forex!`,
    text24: `0`,
    text25: `PREVIOUS CLOSE`,
    text26: `$194.70`,
    text27: `DAY RANGE`,
    text28: `$191.11 - $196.25`,
    text29: `YEAR RANGE`,
    text30: `$166.19 - $402.67`,
    text31: `MARKET CAP`,
    text32: `610.59B USD`,
    text33: `AVG. VOLUME`,
    text34: `87.79M`,
    text35: `P/E RATIO`,
    text36: `60.03`,
    text37: `DIVIDEND YIELD`,
    text38: `PRIMARY EXCHANGE`,
    text39: `NASDAQ`,
    text40: `About`,
    text41: `Tesla, Inc. is an American multinational automotive and clean energy company headquartered in Austin, Texas. Tesla designs and manufactures electric vehicles, battery energy storage from home to grid-scale, solar panels and solar roof tiles, and related products and services. Tesla is one of the world's most valuable companies and remains the world's most valuable automaker with a market capitalization of more than US$840 billion. In 2021, the company had the most worldwide sales of battery`,
  },
  buysell: {
    text1: `Change`,
    text2: `Price`,
    text3: `Investment`,
    text4: `Lots`,
    text5: `Unit`,
    text6: `Units`,
    text7: `Exposure`,
    text8: `Lots`,
    text9: `Leverage`,
    text10: `of available margin`,
    text11: `Stop Loss`,
    text12: `Take Profit`,
    text13: `Market is Closed`,
    text14: `Buy`,
    text15: `Sell`,
    text16: `Your signal has been submitted successfully`,
    text17: `success`,
    text18: `No balance in account`,
    text19: `Lots should be atleast`,
    text20: `Unit should be atleast`,
    text21: `Margin should be atleast`,
    text22: `Margin required greater than your account balance`,
  },
  affiliate_guide: {
    affiliateTab1: `ZuluTrade Affiliates Guide`,
    affiliateTab2: `Introduction to Affiliates`,
    affiliateTab4: `Affiliates Code of Conduct`,
    affiliateTab3: `Registration Obligations`,
    affiliateTab5: `Affiliates Non-Target Groups`,
    affiliateTab6: `Creating an Affiliate account`,
    affiliateTab7: ` How to Open an Account`,
    affiliateTab8: `Affiliates Supporting Documents`,
    affiliateTab9: `Your ZuluTrade Affiliate URL and How to Use It`,
    affiliateTab10: `Tracking`,
    affiliateTab11: `Affiliate Banners and Logo`,
    affiliateTab12: `Account Linkage Policy`,
    affiliateTab13: `Advertising and Promotional Guidelines`,
    affiliateTab14: `Guidelines`,
    affiliateTab15: `Risk Disclosure`,
    affiliateTab16: `Strategies`,
    affiliateTab17: `Payments`,
    affiliateTab18: `Commission Scheme`,
    affiliateTab19: `Requesting Payment`,
    affiliateTab20: `Support Assistance`,
    affiliateTab21: `Europe`,
    affiliateTab22: `America`,
    affiliateTab23: `Asia & Australia`,
    affiliateTab24: `Your ZuluTrade Affiliate URL and How to Use It`,
    affiliateTab25: `Individual Affiliate Accounts`,
    affiliateTab26: `Corporate Affiliate Accounts`,
    affiliateTab27: `Suspension/Termination`,
  },
  zuluTrade_affiliates: {
    affiliateTitle: `ZuluTrade Affiliates Guide`,
    affiliateQuestion1: `Introduction to Affiliates`,
    affiliateAnswer1: `An Affiliate can be either an individual user or a corporation that participates in ZuluTrade's Affiliate Program, promoting ZuluTrade's service. Affiliates register for the program and then they are given a unique and personalised URL, which they use to advertise and promote ZuluTrade. By promoting ZuluTrade, Affiliates introduce Referral Clients (new users) to ZuluTrade's services.`,
    affiliateAnswer2: `Affiliates receive a commission for their service for accounts that sign up through the Affiliate's referral link (URL). Kindly note that only one affiliate account is permitted per individual unless a written permission is granted from the affiliate department.`,
    affiliateAnswer3: `To endorse Affiliates' efforts, ZuluTrade provides its Affiliates with resources, guidelines, support, and a variety of promotional tools, such as banners. Additionally, it provides widgets (dynamic and non-dynamic ones) which can be easily integrated into personal website pages, in order to enhance Affiliates' advertising strategies.`,
    affiliateAnswer4: `The Affiliate Program offers a lucrative opportunity to supplement monthly earnings. ZuluTrade's Program is flexible; therefore depending on the time and effort Affiliates are willing to commit, they can enjoy equivalent earnings.`,
    affiliateAnswer5: `GENERAL POLICY STATEMENT`,
    affiliateAnswer6: `ZuluTrade does not directly represent, endorse, or hold commercial associations with Affiliates in any manner.`,
    affiliateQuestion2: `Affiliates Code of Conduct`,
    affiliateAnswer7: `In agreement with ZuluTrade's use of the service, you agree to: (1) provide true, accurate, current and complete information about yourself as required in the Affiliate Program on-line registration and (2) maintain and update as necessary the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or ZuluTrade has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, ZuluTrade has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof). Providing basic personal data is mandatory prior to the Affiliates payment request process. Failure to provide such information will result in the inability to proceed with requesting your payment.`,
    affiliateAnswer8: `For U.S. Residents Only:`,
    affiliateAnswer9: `Solicitation of U.S. residents without NFA membership is strictly prohibited. Any individual or organisation that solicits U.S. clients is required to be registered as an Introducing Broker with the CFTC.`,
    affiliateQuestion3: `Registration Obligations`,
    affiliateAnswer10: `1. All information, data, text, slide presentations, software, music, sound, photographs, graphics, video, messages or other materials (hereafter referred to as "Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from which such Content originated. This means that you (the Affiliate), and not ZuluTrade, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available in reference to ZuluTrade or the ZuluTrade Service.`,
    affiliateAnswer11: `2. However, in using the ZuluTrade Service, Affiliates may not use or post any materials, statements, blogs or social media pages that are offensive, indecent, objectionable or licensed copyrighted materials, brand names, images or logos. ZuluTrade reserves the right (but not the obligation) at its sole discretion to refuse or remove at any time, Content that is available via the ZuluTrade Service. ZuluTrade also reserves the explicit right to demand immediate removal of any personal Affiliate-generated content related to ZuluTrade Services, which ZuluTrade deems in violation of the company's policy or existing regulatory standards. Further information is addressed under “Advertising and Promotional Guidelines "Section".`,
    affiliateAnswer12: `3. Affiliates shall not transmit any so-called “Interstitials”, “Parasite ware”,“Parasitic Marketing”, “Shopping assistant application”, “toolbar installation and/or add-ons”,“Shopping Wallets” or, “Deceptive pop-ups and/or pop-under” to consumers from the time the consumer clicks a qualifying link until such time as the consumer has fully exited the affiliates site. Hidden or cloaked content is also prohibited. As used herein ”Parasite ware” and ”Parasitic Marketing” shall mean an application that (a)through accidental or direct intent causes the overwriting of affiliate and non-affiliate commission tracking cookies through any other means than a customer initiated click on a qualifying link on a web page or email; (b) intercepts searches to redirect traffic through an installed software, thereby causing pop ups/under, commission tracking cookies to be put in place, or other commission tracking cookies to be overwritten where a user would under normal circumstances have arrived at the same destination through the results given by engines; (c) set commission tracking cookie through loading of affiliates site in iframes, hidden links, and auto pop ups that direct to Zulutrade.com; (d)targets text on websites, other than those websites 100 percent owned by the application owner, for the purpose of contextual marketing ; (e)removes, replaces or block the visibility of Affiliate banners with any other banners, other than those that are on websites 100 percent owned by the owner of application.`,
    affiliateAnswer13: `4. Affiliates that are found to be cybersquatting or typosquatting on trademark-related domains will be banned permanently from the program with all their commissions reversed.`,
    affiliateQuestion4: `Affiliates Non-Target Groups`,
    affiliateAnswer14: `Minors`,
    affiliateAnswer15: `Affiliates may not actively target their advertising efforts or materials to any Investor who has not completed the 18 years of age, regardless of the age of majority in the location where Affiliate is marketing.`,
    affiliateAnswer16: `U.S. Residents`,
    affiliateAnswer17: `Affiliates may not target their advertising efforts or materials to any person who is a resident of the United States of America. Exempt from this restriction are individuals or corporate entities which are IB registered with the CFTC and which possess an NFA Membership Number.`,
  },
  Creating_affiliate_account: {
    contentTitle: `Creating an Affiliate account`,
    contentTab1: `How to Open an Account`,
    contentanswer1: `Access the Homepage of ZuluTrade's website`,
    anker1: `https://www.zulutrade.com.`,
    contentanswer2: `Click on the`,
    bold1: `About`,
    bold2: `dropdown menu at the top right of the page and hover over the`,
    bold3: `Partnerships`,
    bold4: `option. Click on the`,
    bold5: `Affiliate Program`,
    bold6: `option.`,
    contentanswer3: `Click the Become an Affiliate button to start the process.`,
    contentanswer4: `You will be redirected here where you will be able to`,
    contentanswer5: `Fill in all fields in the on-line Registration Form`,
    contentanswer5a: `Read the`,
    contentanswer5b: `then tick the corresponding option box if you agree with them.`,
    contentanswer5c: `Click the Register button to move to next steps.`,
    contentanswer5d: `Verify your email via the OTP code you will receive.`,
    contentanswer5e: `You will receive emails from ZuluTrade containing your unique Affiliate URL and other useful information.`,
    contentanswer5f: `Privacy Policy & Terms`,
    contentTab2: `Affiliates Supporting Documents`,
    contentanswer6: `Individual Affiliate Accounts`,
    contentanswer7: `In an effort to ensure that our Affiliates are compliant with existing due diligence procedures, Affiliates who have registered for a standard Individual Account are required to provide two forms of supporting documents:`,
    contentanswer8: `1 .A Proof of Identification document`,
    tag128: `- Clear, front and back scans of a valid photo national ID card, a valid passport or a driving licence. (Citizenship should be indicated within the document).`,
    contentanswer9: `2. A Proof of Residence document`,
    tag129: `- A recent utility bill, or a bank account statement that includes your name and full home address as it appears on your account application. The document should be dated to within 4-months of the current date.`,
    tag130: `After collecting the required documents, please send them with your registered email to`,
    tag131: `and we will proceed with their verification within 4 business days.`,
    contentanswer10: `Corporate Affiliate Accounts`,
    contentanswer11: `Due to different prerequisites for Corporate Affiliate Accounts, please contact our Affiliates Desk at `,
    tag132: `to enquire about the necessary documentation. The department will respond to your email providing you with the required list of corporate documents. It will also provide you with any instructions which are relevant to your specific application.`,
    contentanswer12: `Where to Upload Your Supporting Documents`,
    contentanswer13: `You may send us the documents via your registered email to the `,
    contentanswer1332: `mailbox and we will verify them within 4 to 8 business days.`,
    contentTab3: `Your ZuluTrade Affiliate URL and How to Use It`,
    contentanswer14: `After successfully creating your Affiliate account, you will receive emails containing your unique Affiliate URL in the below format:`,
    contentanswerTrade15: `Your individual URL appears on the top right of your Affiliate `,
    contentanswerTrade16: `Dashboard`,
    contentanswerTrade17: `tab, at the Client Referral.`,
    contentanswer15: `IMPORTANT:`,
    contentanswer21: `Your Affiliate link needs to have at least those 4 parameters with values : ref,utm_medium,utm_source,utm_campaign. From time to time our team can provide you with tailor links to enhance tracking. Links that are modified WITHOUT our guidance and consent might lead to tracking and attribution issues resulting in commissions loss that we will NOT be able to revert for you. 
        Contact our team in case you want to modify your affiliate tracking links, otherwise use them as is and do NOT remove or modify the parameters and the values that have been applied from our system.`,
    contentTab4: `Tracking`,
    contentanswer16: `Note that tracking cookies are not used. Instead we keep a log of the reference landings for all Referred Clients, so even if the Referral after navigating to your Affiliate URL link then navigates to another page and then to www.zulutrade.com (within 30 days from the initial landing), the Investor will be placed under your Affiliate account. However, it is good practice to ensure that your Referral Clients use your Affiliate URL link right before opening an account.`,
    contentanswer17: `In order to locate ZuluTrade Banners and Logos :`,
    contentanswer18: `Log in on the ZuluTrade Affiliates Dashboard here.`,
    contentanswer19: `Click on the Promotional tools tab on the left side of your Affiliate portal. You will find a variety of different ZuluTrade banners and widgets in various sizes to choose from.`,
    contentanswer20: `More Banners are being added on a frequent basis - you may check your interface regularly for updates. Once the banner or logos are integrated into your web page or blog, any User that clicks onto the ZuluTrade banner/logo will be directed to the integrated destination URL that already includes your affiliate link. If you require banners in alternative sizes, please feel free to contact Affiliates Desk by email at  `,
    contentTab6: `Affiliate Banners and Logo`,
  },
  account_linkage_policy: {
    contentTitle: `Account Linkage Policy`,
    contentanswer1: `If a User registers for an Investor account through an Affiliate URL, the account will be linked to the respective Affiliate.`,
    contentanswer2: `If a User registers for an Investor account through ZuluTrade's main website without using an Affiliate URL, but with prior landings from an Affiliate URL in the last 30 days, on registration we will take into account the reference responsible for the initial introduction of the service and the account will be linked to the respective (first) Affiliate.`,
    contentanswer3: `If a User registers for an Investor account through ZuluTrade's main website without using an Affiliate URL, or without any previous landings on an Affiliate URL, the account will not be linked to any Affiliate. If a User registers for an Investor account through ZuluTrade's main website without using an Affiliate URL, and without an active Affiliate tracking cookie, however he/she has a previously existing account registered within the last 30 days (with the same email) under an Affiliate, then the new Live account goes to the first/introducing Affiliate.`,
    contentanswer4: `If a User registers for an Investor account through ZuluTrade's main website without using an Affiliate URL, the account will not be linked to any Affiliate. However if this was unintentional and the User mistakenly registered for an Investor account through the ZuluTrade main website (without using an Affiliate URL) instead of registering through an intended Affiliate, then the User (as the account owner) may send an email to`,
    contentanswer4a: `affiliates@zulutrade.com`,
    contentanswer4b: `from the registered email address within 5 days after activation of the account and request to be placed under the desired Affiliate. The email requesting linkage should also contain the User's account number, email and the Affiliate URL that the User wishes to be linked under.`,
    contentanswer5: `Please note: Requests from Affiliates to link referral accounts will not be accepted under any circumstances. The request must be initiated by the actual Account Owner.`,
    contentanswer6: `If a User registers for an Investor account through an Affiliate URL other than the intended one, the account cannot be unregistered from the Affiliate or be moved to another Affiliate under any circumstances.`,
    contentanswer7: `Example`,
    contentanswer8: `In the following example, we provide all possible ways a`,
    contentanswer20: `Client`,
    contentanswer21: `can register under`,
    contentanswer22: `Affiliate A:`,
    contentanswer9: `A`,
    contentanswer10: `Client registers for an Investor account through www.zulutrade.com?ref=affiliateA_ID or registers from www.zulutrade.com with a prior landing from Affiliate URL www.zulutrade.com?ref=affiliateA_ID`,
    contentanswer11: `Account is placed under the Affiliate: `,
    contentanswer12: `B`,
    contentanswer13: `Client registers a Demo or Live account through www.zulutrade.com without any prior landings from an Affiliate URL, however he/she has a previously existing account(s) with the same email under Affiliate A`,
    contentanswer14: `Account is placed under the Affiliate: `,
    contentanswer15: `Client registers for an Investor account through www.zulutrade.com without any prior landings from an Affiliate URL, however he/she has a previously existing account(s) under Affiliate A`,
    contentanswer16: `Account is NOT linked under any Affiliate`,
    contentanswer17: `The client can email the Affiliates Department at `,
    contentanswer24: `within 5 days after the activation of the account, to request linkage under Affiliate A.`,
    contentanswer18: `Account is placed under the Affiliate: `,
    contentanswer19: `C`,
  },
  advertising_promotional_guidelines: {
    contentTitle: `Advertising and Promotional Guidelines`,
    contentTab1: `Guidelines`,
    contentanswer1: `Affiliates must submit to ZuluTrade their Promotion Details. Promotional Details refer to Affiliate categories like Content sites, PPC Affiliates, Social Affiliates, communities and education, cashbacks, loyalty, video affiliates, etc. as well as their corresponding URLs.`,
    contentanswer2: `Affiliates must first submit to ZuluTrade for review/approval, their proposed advertising materials/texts and any email marketing campaigns referring to ZuluTrade or its services. Materials should be submitted for review to: `,
    contentanswer122: ` Affiliates will receive a reply as to if the materials have been approved for use or rejected as inappropriate. `,
    contentanswer3: `Advertising content (and use of statements/ opinions) should not be offensive, racist, abusive or fraudulent. ZuluTrade reserves the right to demand that the Affiliate remove any content deemed as such. All claims made in your promotional material must be true, balanced, accurate and verifiable.`,
    contentanswer4: `On the Context section of your page, promotion of competitors is prohibited. Cross Selling or Upselling Competitors is only allowed if Affiliates also promote ZuluTrade on the competitors review pages. Affiliates Desk may ask for content removal or content optimization to avoid account suspension and/or commissions rejection.`,
    contentanswer5: `The use of high pressure language or superlative claims should be avoided in Affiliate marketing materials, personal websites or social media pages in which the Affiliate endorses ZuluTrade or ZuluTrade’s services.`,
    contentanswer6: `Affiliates may NOT legally create any materials for personal or business use such as business cards, letterhead, photographs, personal websites or social media pages etc.) bearing or displaying the company's copyrighted Trademarked Logo or any part thereof, of the said trademarked logo for the purpose of advertising ZuluTrade or its services in any manner, nor misrepresent themselves as employees of ZuluTrade.`,
    contentanswer7: `With respect of any marketing and/or promotional activity involving “Cost Per Click” (“CPC”), “Pay Per Click” (“PPC“) and/or “Search Engine Marketing” (“SEM“), Affiliate may not to bid on the ZuluTrade Brands, including but not limited to the words “ZuluTrade”, “ZuluGuard”, “ZuluScripts”, “TradeWall” and/or any other Names and Trademarks of ZuluTrade collaborating Brokerage Firms spelled in any possible way (including with typos, spaces, signs, symbols, in any other way or in other languages). ZuluTrade reserves the right to cancel compensation without notice in the case of use of search requests using such keywords and any other variants of their spelling, including conformable words in other languages, in search engines such as, Google, Yahoo, Bing, Yandex, Baidu, etc., as a way of client attraction. The purpose of the Affiliates Program is to attract new clients; and not to acquire clients that are already looking for the corporate name and hence cannot be either rightfully classified or justified as new clients.`,
    contentTab2: `PPC Policy`,
    contentanswer8: `Are Affiliates allowed to send traffic directly to the ZuluTrade website?`,
    contentanswer9: `Are Affiliates allowed to display their ads when ZuluTrade brands are entered as a search keyword (e.g. myZuluTrade, myZuluGuard)?`,
    contentanswer10: `Are Affiliates allowed to display their ads when misspellings of ZuluTrade brands are entered as search keywords (e.g. ZuluTrade)?`,
    contentanswer11: `Are Affiliates allowed to display their ads when ZuluTrade brands plus generic words are entered as search keywords (e.g. ZuluTrade voucher)?`,
    contentanswer12: `Are Affiliates allowed to use ZuluTrade brands names in their display URL?`,
    contentanswer13: `Are Affiliates allowed to use ZuluTrade brands names in their display URL as an extension to their brand (e.g. www.affiliatesite.com/ZuluTrade)`,
    contentanswer14: `Are Affiliates allowed to use ZuluTrade brands names in their display URL as subdomain (e.g. ZuluTrade.affiliatesite.com/)`,
    contentanswer15: `Are Affiliates allowed to use ZuluTrade’s brand names within their title and description?`,
    contentanswer16: `Should affiliates add Zulutrade’s brand name and Trademarks into their negative keywords (thus preventing publishers from appearing in brands + generic)?`,
    contentanswer17: `For the above paid search policies, are Affiliates allowed to promote ZuluTrade on search engines and social media?`,
    contentanswer18: `Google`,
    contentanswer19: `Yahoo`,
    contentanswer20: `Bing`,
    contentanswer21: `Facebook`,
    contentanswer22: `YouTube`,
    contentanswer23: `Other`,
    contentanswer24: `Upon request`,
    contentanswer25: `We expect PPC Affiliates to add “ZuluTrade”,”ZuluGuard”, ”ZuluScripts”, ”TradeWall” and misspells in their negative keyword list. If you are unsure what you can or cannot use please contact `,
    contentanswer26: `We welcome legitimate PPC Affiliates. Affiliates who are trying to cannibalise our brands and make profit will not be tolerated and their accounts will be suspended, if not terminated immediately. More on our Intellectual Property restrictions is described in this Guide.`,
    contentanswer27: `Affiliates are expected to abide by the established rules regarding their postings on ZuluTrade’s website, including the social feed or other pages.`,
    contentanswer28: `Affiliates should contact the site's webmaster in order to obtain a clear explanation of their policy in case they promote ZuluTrade in forums, groups or communities on any platform, web or mobile.`,
    contentanswer29: `Spamming in any format is prohibited.`,
    contentanswer30: `Malicious slandering of the Affiliates Program or ZuluTrade’s services is prohibited.`,
    contentTab3: `Risk Disclosure`,
    contentanswer32: `When promoting either ZuluTrade or ZuluTrade’s services on either personal websites or social media pages, ensure that the following Disclaimer appear on all applicable pages:`,
    contentanswer33: `For social media use:
         Trading in financial instruments involves significant risk of loss.`,
    contentanswer34: `For website use:`,
    contentanswer47: `Non-EU Website disclaimer:`,
    contentanswer45: `Trading in financial instruments involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the financial market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the financial markets. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.
                            Hypothetical performance results have many inherent limitations. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    contentTab4: `Strategies`,
    contentanswer35: `If you have created your own website or blog page, this is always a practical place to start in terms of advertisement.`,
    contentanswer36: `Affiliates are encouraged to download the available ZuluTrade Banners, widgets, & Logos from our website. They can be used on different sites to advertise. If you are logged into the Affiliates interface; these items are accessible under the Promotional Tools tab.
        Please note that ZuluTrade advises all Affiliates to first thoroughly review the policies of any Websites or Forums where they plan to post their advertisements. This is to ensure that Affiliates understand the rules of use and to avoid any violation of regulations.`,
    contentanswer37: `Create your own blog pages, integrate ZuluTrade logos and banners and your ZuluTrade Affiliate URL link.`,
    contentanswer38: `Advertise using social media as a vehicle. Telegram and other groups or communities would be an additional method with added value provided that the group guidelines are respected accordingly.`,
    contentanswer39: `Investigate the Google Adwords (http://adwords.google.com) and other paid services, respecting ZuluTrade’s PPC policy.`,
    contentanswer40: `Find a specific niche in the Market and then forge and develop networks within that sector and use local business directories to advertise your webpage / business.`,
    contentanswer41: `Creating videos and other tutorials, manuals and guides can be very useful for users and also help the affiliates boost their business and have a high retention rate.`,
    contentanswer42: `Online advertising pricing methods should only be implemented by those already experienced in this area.`,
    contentanswer43: `Always ensure that you comply with section “A. Guidelines” under part “Advertising and Promotional Guidelines” of this guide.`,
    contentanswer44: `EU website disclaimer:`,
    contentanswer46: `Trading in financial instruments involves substantial risk and there is always the potential for loss. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Your trading results may vary. Because the risk factor is high in the financial market trading, only genuine "risk" funds should be used in such trading. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. No "safe" trading system has ever been devised, and no one can guarantee profits or freedom from loss. Past performance is not indicative of future results.
          Hypothetical performance results have many inherent limitations. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.`,
    contentanswer48: `In case you can’t distinguish EU and non-EU, please use the EU website disclaimer version.`,
  },
  payments: {
    contentTitle: `Payments`,
    contentTab1: `Commission Scheme`,
    contentanswer1: `Affiliates earn a commission for introducing new Referral accounts to the ZuluTrade Service. The default model is Cost Per Acquisition (CPA) per funded referral account based on Geo Tiers. Details for the rates and conditions are published inside our Affiliate portal.`,
    contentanswer2: `Referral accounts that connect existing broker accounts to ZuluTrade are NOT eligible for compensation.`,
    contentanswer3: `The affiliates receive payments only for the first and new broker account created and connected to ZuluTrade.`,
    contentanswer4: `At our own discretion, we may apply chargebacks, resulting in removing commissions, in case we face abuse or manipulation of our Affiliate program terms and conditions.`,
    contentTab2: `Requesting Payment`,
    contentanswer5: `You can request payment by navigating to the Affiliate earnings tab of your Affiliate Portal. The current methods of payment for Affiliates are via bank wire transfer, PayPal (for amounts up to $5000) and in popular Cryptos that are sent directly to your Crypto wallet. For Crypto payments network fees apply and are covered from the Affiliate.`,
    contentanswer6: `Modest fees may be applied by financial institutions in the case of wire transfers. For more details, please consult your Bank.`,
    contentanswer7: `Also please take the following under consideration:`,
    contentanswer8: `Before issuing payments, in accordance with routine due diligence procedures, ZuluTrade needs to be provided with sufficient evidence of the Affiliate’s credentials – typically`,
    contentanswer9: `a) a valid government issued photo identification document (a passport,a national identification card, or a driving licence) and`,
    contentanswer10: `b) a recent formal, printed bill indicating your name and full current residential address as entered on the registration application.`,
    contentanswer11: `To submit or release a payment, ZuluTrade needs to be provided with the Promotion Details of the Affiliate. Promotion Details refers to affiliate categories like Content sites, PPC Affiliates, Social Affiliates, communities and education, cashbacks, loyalty, video affiliates, etc and their URLs.`,
    contentanswer12: `Before issuing payments, relevant commissions need to be collected from our collaborating Brokers and trading activity needs to be cross-checked prior to releasing a payment; the estimated time frame required to process a payment after a request has been made via the Affiliate portal is approximately 2 weeks.`,
    contentanswer13: `An active account balance with a minimum of $100 is required before proceeding to request a payment. Funds cannot be transferred between accounts.`,
    contentanswer14: `Ensure that your bank account details, PayPal account or Crypto wallet is set up properly to avoid any complications with payment.`,
    contentanswer15: `Inactive Affiliate accounts and Affiliate accounts that perform inappropriate behaviours will be examined on a case-by-case basis, and may not be compensated and/or have their Referral Accounts removed.`,
    contentanswer16: `Any finance or payment-related issues should be reported to the Affiliates Dept.`,
    contentanswer17: `to be resolved.`,
  },
  support_assistance: {
    contentTitle: `Support Assistance`,
    contentanswer1: `The ZuluTrade Customer Support Dept. is here to help you. You can contact us by email at `,
    contentanswer33: ` by telephone or by Live Chat. For further details please visit`,
    contentanswer2: `Examples of issues handled by the Customer Support Department:`,
    contentanswer3: `General Questions Regarding ZuluTrade's Service`,
    contentanswer4: `Informing on Change of Name or Contact Details`,
    contentanswer5: `Account Sign Up Process`,
    contentanswer6: `Failure to Receive ZuluTrade Email Containing Username or Password`,
    contentanswer7: `Request for Linkage of Accounts`,
    contentanswer8: `Account Types (Affiliate, Live Investor, Leader)`,
    contentanswer9: `Technical Issues (banners, code errors, display problems, linkage loss, signal errors, etc.)`,
    contentanswer10: `Leader Related Issues`,
    contentanswer11: `Payment Requests or Finance Issues`,
    contentanswer12: `For Affiliate specific issues please contact the Affiliates Department at`,
    contentanswer34: ` Examples of issues handled by the Affiliates Department:`,
    contentanswer13: `Questions Regarding ZuluTrade's Affiliates Program`,
    contentanswer14: `Inquiries on Correct Usage of Affiliates URL links`,
    contentanswer15: `Referral Accounts`,
    contentanswer16: `General information on using Banners & Logos`,
    contentanswer17: `Advertising Strategies`,
    contentanswer18: `Suitability of Websites and Advertising Content`,
    contentanswer19: `Summary Report of Earnings`,
    contentanswer20: `No Commission Classified Accounts`,
    contentanswer21: `Should you wish to make a suggestion or complaint about any aspect of our service, please feel free to contact the Affiliates Accounts Department at `,
    contentanswer22: `Suspension/Termination`,
    contentanswer23: `Suspension of an Affiliate account may occur as a result of the following instances:`,
    contentanswer24: `Account Inactivity - an Affiliate account is considered inactive if there has been no successful login into the account for a period of more than 6 months.`,
    contentanswer25: `Failure to specifically acknowledge or abide by formal notifications made to the Affiliate by either email or telephone, during which ZuluTrade has made every reasonable effort to inform the Affiliate that they are engaging in conduct/ actions that directly or indirectly violates either the Affiliates Program codes or existing Regulatory codes.`,
    contentanswer26: `Affiliates who perform inappropriate behaviour, for example solicitation of customers in an unethical or illegal manner; such instances will be examined on a case-by-case basis.`,
    contentanswer27: `Failure to abide by all of the advertisement guidelines as stated in Section “Advertising and Promotional Guidelines”, may lead to the suspension of one’s Affiliate account solely at ZuluTrade’s discretion.`,
    contentanswer28: `The list is not exhaustive – in addition to the account suspension, other penalties may also apply, such as rejection of the outstanding revenues or pending payments as well as removal of the Affiliate’s Referral accounts prior to the account suspension.`,
    contentanswer29: `Suspension of an Affiliate account may lead to permanent banning from use of the Affiliates service – in other words, termination of the Affiliate’s account. Liability for any consequences resulting from an Affiliate’s account being either suspended or terminated is the sole burden of the Affiliate account holder.`,
  },
  number_list: {
    title: `All our phone numbers`,
    name1: `Europe`,
    name2: `United Kingdom, London`,
    name3: `Russia, Moscow`,
    name4: `Spain, Madrid`,
    name5: `Greece, Athens`,
    name6: `America`,
    name7: `Mexico, Mexico City`,
    name8: `Colombia, Cali`,
    name9: `Asia & Australia`,
    name10: `Japan, Tokyo`,
    name11: `Australia, Melbourne`,
    number1: `+44 20 3355 8805`,
    number2: `+7 499 918 7378`,
    number3: `+34 911 87 7655`,
    number4: `+30 213 0117 499`,
    number5: `+44 20 3355 8805`,
    number6: `+57 2 891 2645`,
    number7: `+81 3 4588 8774`,
    number8: `+61 3 9008 4202`,
  },
  about: {
    text1: `Who`,
    text2: `We Are`,
    text3: `We are ZuluTrade, a FINVASIA Group company. Established in 2007, we are the largest broker-agnostic social wealth management and the oldest copy trading platform in the world. We revolutionized copy trading by creating a “one of its kind” socially driven broker and platform-agnostic social network.`,
    text4: `We enable you to invest your capital and manage your wealth more efficiently. 
        With the help of our large and diverse community, technology, and open ecosystem, we help you make informed investment decisions in a digitally safe and trusted environment.`,
    text447: `We offer a smooth and simplified Copy Trading and Social Trading experience across multiple instruments, including Crypto CFD, Stock CFD, Forex, Commodities, and Indices.`,
    text5: `Industry Leaders for 17 years`,
    text6: `Our vision is to be the leading wealth management platform for everyone who doesn’t have the background, time, or technical capacity to invest and choose us for our open platform, diverse ecosystem, and strong community that can’t be copied.`,
    text7: `Our Mission`,
    text8: `Simplify the`,
    text9: `Trading experience`,
    text10: `and make it accessible to all`,
    text11: `Our Brokers`,
    winner11: `WINNER 2023`,
    winner11a: `WINNER 2024`,
    winner13: `Solution GLOBAL`,
    winner13a: `GLOBAL`,
    text12a: `MEA`,
    text12b: `DUBAI, 2024`,
    text12: `View all Brokers`,
    text13: `Our`,
    text14: `Awards`,
    text15a: `MEA at UF AWARDS`,
    text15b: `CYPRUS, 2023`,
    text15: `UF AWARDS`,
    text16: `CYPRUS, 2022`,
    text17: `Best Social Trading`,
    text18: `Solution`,
    text19: `FOREX EXPO`,
    text20: `DUBAI, 2022`,
    text21: `Best Social Wealth`,
    text22: `Management Platform`,
    text23: `DUBAI, 2023`,
    text24: `Solution in MEA`,
    text25: `Offices`,
    text26: `ZuluTrade’s`,
    text27: `head office is based in`,
    text28: `Greece`,
    text29: `The corporate office is in`,
    text30: `Cyprus`,
    text31: `and we have another office in`,
    text32: `JAPAN`,
    text33: `Finvasia Group has it’s offices in`,
    text34: `Dubai`,
    text35: `London`,
    text36: `South Africa`,
    text37: `Australia`,
    text38: `Mauritius`,
    text39: `and`,
    text40: `India`,
    text41: `Interested in our Services?`,
    text42: `Open your account`,
    text43: `2023`,
    text448: `BANGKOK, 2023`,
    text449: `Solution APAC`,
    text450: `FAME AWARD`,
    text451: `SA, 2023`,
    text452: `Best Wealth`,
    text44: `ZuluTrade revamped its platform and website to enhance social and technology features and provide users access to analysis, data, insights, and portfolio optimization tools that are typically available only to professional traders.`,
    text45: `Wins “Best Social Trading Solution” in the MEA region, UF awards, 2023, and "Best Wealth Management Platform Africa" by the Financial Achievements in Markets Excellency Awards.`,
    text46: `ZuluTrade reinvigorates its presence in Japan.`,
    text47: `2022`,
    text48: `ZuluTrade wins ‘Best Social Trading Solution’ award at iFX Expo, Cyprus and ‘Best Social Wealth Management Platform’ at Forex Expo, Dubai.`,
    text49: `ZuluTrade implements support for only Licensed brokers on its platform creating a more reliable and safe investing environment for its investors.`,
    text50: `2021`,
    text51: `ZuluTrade joins Finvasia Group in December of 2021 and a new era of our company begins.`,
    text52: `2020`,
    text53: `Stocks CFDs and our new follow trading model were introduced to make our platform a true multi-investment social trading platform.`,
    text54: `2017`,
    text55: `ZuluTrade`,
    text56: `introduces Crypto's CopyTrading which allows Investors to join a rapidly growing market by following experienced CryptoTraders from all over the world.`,
    text57: `2015`,
    text58: `The`,
    text59: ` Group is awarded an EU Portfolio Management License from the European Union. The designation made the company a licensed portfolio management company in the EU.`,
    text60: `2014`,
    text61: `expands to Asia and launches its collaboration with local FX retail brokers. `,
    text62: ` Japan Co Ltd was born out of this as a subsidiary.`,
    text63: `2013`,
    text64: `ZuluGuard™`,
    text65: `launches - an account protection feature that monitors each Trader's behaviour and automatically removes a Trader when detecting a trading strategy that has deviated from its expected loss profile.`,
    text66: `2011`,
    text67: `The company unveils several new features including the ability to follow other followers (rather than just signal providers) and the requirement for signal providers to be profitable to receive payment.`,
    text68: `2009`,
    text69: ` reaches the milestone of getting over thousands of growing signal provider portfolios whose trading strategies could be copied by the platform's user base. Making it the first company of its kind to reach such high levels of success within the trading community.`,
    text70: `2008`,
    text71: `creates software that at the time was still in beta testing, that connected to FXCM's API and allowed a basic form of copy trading strategies for investors. Marking the official beginning of`,
    text72: `2007`,
    text73: ` began as an idea in Charlottesville, Virginia to make Forex Trading accessible and easy for everyone. Copy Trading, the concept of copying trades from another investor, was the core aim of a truly transparent, fair win-win trading ecosystem.`,
    text74: `Hi`,
    text75: `Trendsetters`,
    text76: `we are`,
    text77: `Changemakers`,
    textTit: `We were the first to introduce Copytrading - the concept to copy trades from another investor.`,
    text78: `We believe in helping the global community to grow its wealth in a safe and secure environment using our innovative and forward-looking technology solutions across social wealth management, trading, strategy building, and copy trading.`,
    textQus: `When you join ZuluTrade, you join a global family.`,
    text79: `ZuluTrade is a brand owned by Finvasia Group - a global enterprise that owns and operates over a dozen brands in financial services, fintech, blockchain, real estate, healthcare and technology sectors. The group invests and actively builds technology and engineering-oriented products that solve real-life problems.`,
    text80: `Finvasia’s mission is to build sustainable and ethical businesses using science and technology that will permanently change the way problems are solved across various industry segments. `,
    text81: `Life at ZuluTrade`,
    text82: `Employee Testimonials`,
    text83: `I started my career at ZuluTrade 13 years ago and haven't looked back since. `,
    text84: `Believing in our product and our mission is a seamless experience but it is the people that make this job meaningful.`,
    text85: `The company has been through many changes over the years, while my career advancement has been a steady uphill ride with opportunities and chances for both personal and professional development. This steady course would have not been possible without the gifted leaders that I had the honor to work with.`,
    text86: `I truly enjoy and appreciate the fact that feedback is always appreciated and there is no room for micro-management or favoritism in this company.`,
    text87: `Ioulia Lavrentzou`,
    text88: `Affiliate manager EU`,
    text89: `It's been an incredible journey of growth and learning. When I first started, I was nervous and unsure, but as I began to take on more responsibilities and face new challenges, I discovered strengths I didn't even know I had.`,
    text90: `I've learned how to navigate difficult situations with grace and how to communicate effectively with people at all levels of the organization. Working in this role has helped me to develop not just professionally, but personally as well.`,
    text91: `I'm also grateful for the relationships I've formed with my colleagues, who have become not just coworkers, but also friends. And let me tell you, I really love what I do. It's incredibly fulfilling to be part of a team that is making a real impact in our industry. `,
    text92: `I feel proud to be contributing to the success of the company and the satisfaction of our customers and I look forward to continuing to grow and learn in this role, and I'm excited to see what the future holds.`,
    text93: `Giannis Kranas`,
    text94: `Java Developer/Software Engineer`,
    text95: `My experience as a Product Owner at ZuluTrade has been incredible. Right from the start, I've been impressed by the company's commitment to excellence and its lively teamwork culture.`,
    text96: `At ZuluTrade, innovation is key, and every idea is welcomed with open arms. I've had the freedom to think creatively and see my ideas come to life, knowing they make a real impact on traders worldwide.`,
    text97: `What sets ZuluTrade apart is its genuine care for its employees. They invest in our growth and well-being, creating a supportive environment where we can thrive both personally and professionally.`,
    text98: `In short, the excitement of working in such a thriving environment is what motivates me to get better every day.`,
    text99: `Punit Sharma`,
    text100: `ZuluTrade Product Owner`,
    text101: `This is Ronak, originally from Iran. Back in 2009, I had fallen in love with Athens. Ever since I loved and lived it to the fullest. However I could always feel the absence of my beloved ones and due to past working schedules, I could never find the balance between work and life.`,
    text102: `Today, after more than 10 years and after only 1 year of collaboration with ZuluTrade, I’m blessed to have a family as big as Finvasia. I have joined the company with my background in science and customer support, wanting nothing but a good environment. However, the bonds, ambition, motivations, team spirit and love in the company pushed me to learn and grow every day.`,
    text103: `The highlight of working for ZuluTrade for me would be the equal opportunities given to all and our great management in leading and helping me to choose the correct paths while celebrating every success with me. Looking back, I could not be more grateful for the trust, opportunity, guidance, support and love I have received from my managers and colleagues.`,
    text104: `Eagerly look forward to where this journey will lead us, and hope for this year to be just the beginning to many years of collaboration and success to come.`,
    text105: `Ronak Motazeti`,
    text106: `Client Support Team Leader`,
    text107: `What Makes us`,
    text108: `ZuluTrade?`,
    text109: `We like`,
    text110: ` to Innovate`,
    text111: `We always like to think outside the box. From analyzing the customer’s needs to designing products that solve their real-life problems, innovation drives everything that we do.`,
    text112: ` to Collaborate`,
    text113: `We value diversity in our workplace - people from different backgrounds and varying perspectives. It helps our culture thrive,  helps you to grow professionally and makes a real contribution to the long-term success of an industry leader.`,
    text114: `We love`,
    text115: ` our Team and our Work`,
    text116: `We believe when we are passionate and accountable, we deliver exceptional results. Open communication, inclusive culture, and integrity in all we do sparks our work everyday.`,
    text117: `to Make an Impact`,
    text118: `We are always there for our customers and love to establish lasting relationships with them. We love to give time to our customers' needs and improve their overall experience.`,
    text119: `Experience`,
    text120: `A glimpse into the daily life at ZuluTrade`,
    text121: `We`,
    text122: `Love`,
    text123: `What We Do`,
    text124: `And`,
    text125: `You Can`,
    text126: `Too!`,
    text127: `The application and interview process at ZuluTrade is pretty simple and straightforward.`,
    text128: `Apply now to be a part of this exciting journey of innovation and bringing the community together to grow together.`,
    text129: `30M+`,
    text130: `Global Accounts`,
    text131: `2M+`,
    text132: `Leaders`,
    text133: `80+`,
    text134: `Employees`,
    text135: `Apply Now`,
    text136: `Other`,
    text137: `Benefits`,
    text138: `Professional Trainings`,
    text139: `Regular office celebrations`,
    text140: `Paternity / Maternity leaves`,
    text141: `Private Health insurance plan`,
    text142: `Paid Sick Leaves`,
    text143: `News and`,
    text144: `Media`,
    text145: `Read More`,
    text146: `PARTNERSHIPS`,
    text147: `Partner with the industry leader for 17 years`,
    text148: `Contact Us`,
    text149: `About ZuluTrade`,
    text150: `ZuluTrade is the world's largest broker-agnostic social wealth management and copy trading platform. It revolutionized copy trading by creating a “one of its kind” socially driven broker and platform agnostic social network in 2007.`,
    text151: `ZuluTrade provides a full range of White Label Solutions to brokerage firms. Worldwide, our broker partners are utilizing ZuluTrade's cutting-edge technology to expand their offerings and increase their revenue. Hundreds of successful integrations and millions of executed trades combined with cutting-edge technology have made ZuluTrade the most trusted partner.`,
    text152: `How it works?`,
    text153: `Leaders join ZuluTrade`,
    text154: `connect their account & share their trading strategy.`,
    text155: `ZuluTrade ranks Leaders`,
    text156: `based on their ROI and risk behavior.`,
    text157: `Investors select the strategies to copy`,
    text158: `according to their capital and risk appetite.`,
    text159: `Leaders are rewarded`,
    text160: `based on followers' performance and popularity.`,
    text161: `Why ZuluTrade?`,
    text162: `Higher Retention and Conversion Rates`,
    text163: `Tailor Made Solutions`,
    text164: `No Hidden Fees`,
    text165: `Multiple Platforms Supported`,
    text166: `Strong Brand Name`,
    text167: `Out of the Box ZuluTrade Integration`,
    text168: `Regulated within EU, SA, Mauritius and Japan`,
    text169: `24/5 Technical Support`,
    text170: `Quick Set Up`,
    text171: `Multi Asset Platform`,
    text172: `White Labels`,
    text173: `Standard ZuluTrade White Label`,
    text174: `The standard White Label is a co-branded ZuluTrade website which creates a dedicated environment designed specifically for the Broker and its customers.`,
    text175: `Benefits:`,
    text176: `Participation in the largest social trading network with over 2M Leader Strategies.`,
    text177: `Easy setup and ultra-fast integration with major trading platforms like MT4, MT5, ActTrader and XOH.`,
    text178: `Strong brand name and high customer recognition.`,
    text179: `Powerful conversion and retention tool.`,
    text180: `State of the art mobile apps for all major mobile devices.`,
    text181: `24/5 access - Multi-lingual Customer Support.`,
    text183: `Additional Services`,
    text184: `Out of the box implementation. Real-time coverage monitoring tools.`,
    text185: `Technical Services`,
    text186: `Setup and Integration of all aforementioned systems. Complete hosting, monitoring and maintenance of all systems.`,
    text187: `Support`,
    text188: `24/5 on call support for all partnering Brokers Ongoing training for all systems and services provided.`,
    text189: `Contact us`,
    text190: `For more information please click the below link`,
    text191: `Become a Leader with`,
    text192: `ZuluTrade is one of the most transparent social trading platforms in the world, whose main goal is to support Investors and Leaders in their investment goals. Our Leader Program is open for individuals with a variety of investment strategies among Stocks, Forex, Cryptos, Indices and Commodities, who can attract Investors to follow them and earn additional income.`,
    text193: `Open Account`,
    text194: `Who is a ZuluTrade Leader?`,
    text195: `A 'Leader' is a ZuluTrade user, who shares and allows other users to copy his trading strategy into their account. Each trading action performed in the Leader’s account is copied to all the Investor accounts who follow him/her in the form of a broadcasted signal.`,
    text196: `What Does a Leader Do?`,
    text197: `Research`,
    text198: `Interact`,
    text199: `Broadcast`,
    text200: `Oversee the market and instruments, research news and updates regularly.`,
    text201: `Leaders are always researching their preferred products, historical performance and news daily. They need to understand the risks and investment needed before moving forward with their actions.`,
    text202: `Interact with Investors and communicate updates regarding trading strategy.`,
    text203: `Leaders on our platform are advised to explain their portfolio strategy on their profile and communicate regularly with their Investors regarding their decisions on buy/sell, product & instrument choices.`,
    text204: `Broadcast trading signals to the ZuluTrade Investors.`,
    text205: `Successful Leaders are featured on the ZuluTrade ranking where they get visibility on the website, a place where more and more Investors can easily copy their trading signals.`,
    text206: `Leaders Compensation Schemes`,
    text210: `What do I earn as a Leader?`,
    text211: `0.5 pip for each closed trade executed or $5 per $100K traded`,
    text212: `Pip cost depends on various factors such as the currency pair traded. The amount of commissions received will depend on the Broker group of the Investor.`,
    text213: `Note`,
    text214: `The amount of commissions received will depend on the Broker group of the Investor as well as the Tier of the Affiliate.`,
    text215: `Affiliate guide`,
    text216: `Leader guide`,
    text217: `How to Become a Leader in 3 Simple Steps`,
    text218: `Anyone can sign up to become a Leader. To register your Leader account, please click`,
    text219: `here`,
    text220: `Check`,
    text520: `for our Leaders’ Program Guide.`,
    text221: `Join ZuluTrade as a Leader`,
    text222: `Access the Leaders Program page and click on "Open Account".`,
    text223: `Complete the Registration Process & Start Trading`,
    text224: `Follow the onboarding instructions and when prompted click “Become a Leader” to start trading.`,
    text225: `Investors Copy your Strategy & you Get Compensated`,
    text226: `Investors copy your signals according to their capital and risk appetite and you will receive your earnings according to their payment scheme.`,
    text227: `Leaders with HIGHEST AUM - AMOUNT FOLLOWING`,
    text228: `Strategies that have the highest number of total Investors’ funds invested`,
    text229: `Share`,
    text230: `Compare`,
    text231: `COPIERS`,
    text232: `FOLLOWERS`,
    text233: `See all Leaders`,
    text234: `Trading Platforms`,
    text235: `MetaTrader 4 (MT4) Platform`,
    text236: `MT4 is most popular trading platform for all levels offering advanced charting, copy trading, and flexible programming options.`,
    text237: `MetaTrader 4 is a popular platform for Leaders to trade a variety of assets. It is the gateway between you and the trading markets. On ZuluTrade, you can link an external MT4 platform to a Leader account. In this case, the Leader account becomes read-only and any trading activity that takes place in the MT4 account is copied over to the ZuluTrade account.`,
    text238: `MetaTrader 5 (MT5) Platform`,
    text239: `MetaTrader 5 is a multi-asset platform that offers superior tools for trading. On ZuluTrade, you can link an external live or demo MT5 platform to a Leader account. In this case, the Leader account becomes read-only and any trading activity that takes place in the MT4 account is copied over to the ZuluTrade account.`,
    text240: `XOH Trading Platform`,
    text241: `XOH is a state-of-the-art technology platform that offers advanced charting, copy trading, and flexible programming options. On ZuluTrade, you can link an external XOH platform to a Leader account. In this case, the Leader account becomes read-only and any trading activity that takes place on the XOH account is copied over to the ZuluTrade account.`,
    text242: `ActTrader`,
    text243: `ActTrader is a feature-rich trading platform that is packed with a super-friendly interface, one-click trading, advanced charting tools, and an intuitive client management dashboard to cater to every leader's need.`,
    text244: `Reasons to Become a Leader with ZuluTrade`,
    text245: `No Hidden Costs and No Monthly Payout Limits`,
    text246: `The program is open to everyone, has no hidden costs or fees, and has no limits per Leader payout. The more popular your strategy is, the more commissions you earn.`,
    text247: `Broker-Agnostic Platform`,
    text248: `Our platform is compatible with multiple well-known and reliable brokers worldwide. You can choose the one that best suits your needs and share your strategy with the community.`,
    text249: `Verified Track Record`,
    text250: `Establish a verified track record at no cost and share it with your Investors’ Community. Save time, money, and resources!`,
    text251: `Dedicated Leaders Desk`,
    text252: `Our Leaders Desk will help you to set up a great Leader profile, gain and retain your Investors to improve your profitability!`,
    text253: `Frequently Asked Questions (FAQs)`,
    text254: `Can anyone become a ZuluTrade Leader?`,
    text255: `Yes, anyone can become a Leader on our platform. However, the most suitable Leaders are the ones with a trading background and a professional business mindset.`,
    text256: `Can I become a Forex Leader on ZuluTrade?`,
    text257: `Sure, we have a wide variety of instruments that you can trade including popular Forex pairs, Stocks, Indices, Commodities and Cryptos.`,
    text258: `Can I become a Stock Leader on ZuluTrade?`,
    text259: `Yes, there are multiple Stocks that a Leader can choose from including Tesla, Apple, Amazon, Facebook, Pfizer and more.`,
    text260: `What are the compensation Models for Leaders?`,
    text261: `Our payment model is the Classic Volume Based Model. In case you are being copied by Investors using the Legacy ‘Profit-sharing’ model, you will receive payments accordingly. Please take note that creation of Profit Sharing Investor accounts has been discontinued since April 2022.`,
    text262: `What are the general trading hours for all the instruments?`,
    text263: `All times are GMT+2 during non-DST, and GMT+3 during DST.`,
    text264: `Forex: 00.05 (Monday) until 24:00 (Friday), with a daily break from 00.00 until 00.05.`,
    text265: `Commodities: 01:00 (Monday) until 24:00 (Friday), with a daily break from 00.00 until 00.05.`,
    text266: `Indices: 01:00 (Monday) until 24:00 (Friday), with a daily break from 00.00 until 00.05.`,
    text267: `Cryptocurrencies: 00.05 (Monday) until 24:00 (Friday), with a daily break from 00.00 until 00.05.`,
    text268: `USD Stocks: 16:30 (Monday) to 23:00 (Friday), with a daily break from 00.00-16:30, 23:00-24:00.`,
    text269: `EUR Stocks: 10:00 (Monday) to 18:30 (Friday), with a daily break from 18:30-10:00.`,
    text270: `How do I become a successful Leader?`,
    text271: `Being a successful Leader is not a quick process and frankly, it never stops. Discipline, strategic approach, aggressiveness and professionalism are the fundamentals for becoming successful.`,
    text272: `What are Forex Signals and how do I share my signals through ZuluTrade?`,
    text273: `Forex signals are automatically generated signals from a trading strategy. You can share your signals by`,
    text274: `becoming a Leader`,
    text275: `Is there a minimum limit for me to start trading with ZuluTrade?`,
    text276: `Until now, there have not been any restrictions, however, it would be preferable that your balance would exceed the USD 500 level.`,
    text277: `What’s the best Forex Trading platform?`,
    text278: `There are many Forex trading platforms out there. ZuluTrade has been around since 2007 and offers Copy Trading services of Forex Pairs, Stocks, Cryptocurrency, Indices and Commodities via your connected broker.`,
    text279: `What tools does ZuluTrade offer for Capital Safety?`,
    text280: `ZuluTrade offers numerous tools to achieve capital safety, one of the most important being the`,
    text281: `ZuluGuard`,
    text282: ` How do I get featured on the Leaders Rankings?`,
    text283: `Global Ranking is assigned automatically after you have completed your first few trades. EU ranking is assigned only after your account has fulfilled the`,
    text284: `basic requirements`,
    text285: `What is Risk-score?`,
    text286: `Risk-score indicates how risky is the Leader's Strategy. Calculated using Sharpe Ratio formula, inverted and normalised on 1-5 scale (1 Low Risk & 5 High Risk).`,
    text287: `Join our`,
    text288: `Affiliate`,
    text289: ` Program `,
    text290: `ZuluTrade is one of the most transparent copy and social trading platforms in the world whose main goal is to support Investors and Leaders with their investment goals but to also compensate Affiliates for their promotional efforts. Our Affiliate Program is open for Website and Blog owners, Social Media Influencers, Trading Academies, YouTubers and Vloggers or even Professional Leaders who can attract Investors to sign up through them and earn additional income.`,
    text291: `Become an Affiliate`,
    text292: `Login`,
    text293: `What is a ZuluTrade Affiliate?`,
    text294: `An Affiliate can be either an individual user or a corporation that participates in ZuluTrade's Affiliate Program, promoting ZuluTrade's service. Affiliates register for the program and then choose a unique URL that they utilize to advertise/promote ZuluTrade. By promoting ZuluTrade, Affiliates introduce Referral Clients to ZuluTrade's Copy trading services. Affiliates receive a commission for their service for accounts that signed up through the Affiliate's website (URL).`,
    text295: `What does an Affiliate do?`,
    text296: `Create Affiliate Account`,
    text297: `Fill in your details and get your account enabled immediately. Join us. Fast, Easy, Free!`,
    text298: `Choose Promotional Content`,
    text299: `Choose the links, banners and landing pages that will help you promote our Social wealth management platform powered by copy trading.`,
    text300: `Earn`,
    text301: `Enjoy our transparent Geo-based CPA model and monetise your global based traffic and network.`,
    text302: `Compensation Schemes`,
    text303: `Our default monetisation model is CPA, but we are open to discuss more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Feel free to contact your account manager to discuss a bespoke monetisation model that fits your business operations and allows for rapid future growth.`,
    text304: `Our CPA payouts are categorised into Tiers, depending on the Geolocation of the accounts referred by our affiliates. Additionally, various yet simple triggering criteria apply.`,
    text305: `See our Affiliate Guide`,
    text306: `How to become an Affiliate in 3 easy steps`,
    text307: `Click Join ZuluTrade as an Affiliate`,
    text308: `Access the Affiliate Program page and click on “Become an Affiliate” to start the process.`,
    text309: `Complete the Registration Form and get your unique affiliate URL`,
    text310: `Fill in your information in the Online Registration Form, submit your application and get your unique Affiliate URL to start inviting Investors to ZuluTrade.`,
    text311: `Invite your contacts and start earning commissions`,
    text312: `Investors use your Affiliate URL to sign up to ZuluTrade and you receive commissions based on our  our collaboration agreement`,
    text313: `Reasons to become an Affiliate`,
    text314: `Transparent Compensation Plans`,
    text315: `We have a clear and transparent compensation scheme for our affiliates. We also offer  tailored agreements for our advanced and highly converting affiliates and those who want to get more out of the partnership.`,
    text316: `Dedicated Affiliate Team`,
    text317: `Our Affiliate Desk Team will help you develop your business by providing material, content, creative assets or any type of support you might need.`,
    text318: `Licensed and Regulated in the EU, Japan, Mauritius and SA`,
    text319: `ZuluTrade is a transparent, reliable and self-converting fully regulated brand.`,
    text320: `High Conversion Rates`,
    text321: `ZuluTrade is one of the first and most established brands in the industry. Watch your leads being effortlessly converted.`,
    text322: `No Limits in Payment Requests`,
    text323: `There are no limits per client, or in total commission payouts.`,
    text324: `Multi-lingual Support`,
    text325: `Our 24/5 Multilingual Support Team is available to assist you via live chat, phone or email!`,
    text326: `How does your tracking work?`,
    text327: `Regarding our tracking method, we keep a reference log for the users who click on your referral link. So even if the referral navigates to another tab or page after clicking on your referral link, they will be placed under your affiliate account after their registration.`,
    text327a: `Please note that each of your referral links is intended for specific use. The Web Affiliate Link is only meant for usage on the web, while the App Affiliate link is designed solely for application usage. For any further information, send an email to`,
    text327b: `with your questions.`,
    text328: `How can I connect a referral under my account if my referrals didn’t correctly use my unique URL?`,
    text329: `If your referral was not correctly placed under your affiliate account, the affiliate desk must be notified immediately, within a maximum of five days of the registration of the referral account. The referral in question, must personally send an email from their registered ZuluTrade email to `,
    text329a: `requesting to be added under your affiliate account. They must mention your email or affiliate ID. Please note, that all requests past the five days' window will not be handled.`,
    text330: ` Do you accept PPC Affiliates?`,
    text331: `We do accept PPC Affiliates with the condition that they will not bid on the ZuluTrade Brand and any other Trademarks or Names of ZuluTrade and its collaborating Brokers. If you are a PPC Affiliate interested in promoting ZuluTrade, please make sure that you read our PPC Policy `,
    text332: `Am I allowed to promote ZuluTrade on social media?`,
    text333: `Of course, feel free to invite friends or promote ZuluTrade through social media. You can use any of the material available in your Affiliate account. Kindly note that the use of disclaimers is mandatory. We suggest that you submit for approval to Affiliates Desk any banner or piece of content created to promote ZuluTrade.`,
    text334: `Do you offer any creative assets for me to use online to promote my affiliate account?`,
    text335: `We offer a variety of banners, widgets, dynamic and static ones that you can freely use on your website. These have high conversion rates and will offer your website evergreen content as most of them are dynamic.`,
    text336: `  Can I be a ZuluTrade Leader and An Affiliate at the same time?`,
    text337: `Being a ZuluTrade Leader and Affiliate at the same time is a good practice as it can maximize your overall payout from your network. For more information send an email to`,
    text338: `View More`,
    text339: `View less`,
    text340: `We are`,
    text341: `Affiliate`,
    text342: `ZuluTrade Banner`,
    text343: `Market And Instruments Research News`,
    text344: `Oversee`,
    text345: `Interact With Investors`,
    text346: `Interact`,
    text347: `Broadcast Trading Signals`,
    text348: `Broadcast`,
    text349: `Join ZuluTrade as a Trader`,
    text350: `Access the Traders Program `,
    text351: `Complete the Registration Form & Start Trading`,
    text352: `Fill in your information in the Online Registration Form`,
    text353: `Investors Copy your Strategy & you Receive your Earnings`,
    text354: `Investors use your Affiliate`,
    text355: `MetaTrader 4 (MT4) platform`,
    text356: `In ZTP, both Live and Demo Traders`,
    text357: `MetaTrader 5 (MT5) platform`,
    text358: `MetaTrader is a popular platform`,
    text359: `ActTrader`,
    text360: `ActTrader platform`,
    text361: `No Hidden Costs and No Monthly Payout Limits`,
    text362: `The ZuluScript feature enables`,
    text363: `Multiple Brokers Platform`,
    text364: `Our platform is compatible with multiple`,
    text365: `Verified Track Record`,
    text366: `Establish a verified track record`,
    text367: `Dedicated Traders Desk`,
    text368: `ZuluTrade is one of the first ..`,
    text369: `ZuluTrade Affiliate Program | Join the Fintech Era. Be our Brand's Partner`,
    text370: `ZuluTrade Affiliate program allows to website & community managers, influencers and any content creator to monetize their audience reach. Join the Fintech Era!`,
    text371: `Create Affiliate Account`,
    text372: `Fill in your details`,
    text373: `Choose Promotional Content`,
    text374: `Choose the links,..`,
    text375: `Earn`,
    text376: `Enjoy our transparent`,
    text377: `Click Join ZuluTrade as an Affiliate`,
    text378: `Access the Affiliate Program `,
    text379: `Complete the Registration Form and get your unique affiliate URL`,
    text380: `Fill in your information in the Online Registration Form`,
    text381: `Invite your contacts and start earning commissions`,
    text382: `Investors use your Affiliate`,
    text383: `Transparent Compensation Plans`,
    text384: `The ZuluScript feature enables`,
    text385: `Dedicated Affiliate Team`,
    text386: `Our Affiliate Desk Team`,
    text387: `Licensed and Regulated in the EU`,
    text388: `ZuluTrade is a transparent`,
    text389: `High Conversion Rates`,
    text390: `ZuluTrade is one of the first ..`,
    text391: `No Limits in Payment Requests`,
    text392: `There are no limits per client`,
    text393: `Multi-lingual Support`,
    text394: `Our 24/5 Multilingual Support`,
    text395: `Traders join ZuluTrade`,
    text396: `connect their account & share their trading strategy`,
    text397: `ZuluTrade ranks Traders`,
    text398: `based on their ROI and risk behavior`,
    text399: `Investors select the strategies to copy`,
    text400: `according to their capital and risk appetite`,
    text401: `Traders are rewarded`,
    text402: `based on followers' performance and popularity`,
    text403: `Partner with ZuluTrade | ZuluTrade Social Trading 5`,
    text404: `Partner with ZuluTrade | ZuluTrade Social Trading 6`,
    text405: `Partner with ZuluTrade | ZuluTrade Social Trading 7`,
    text406: `Partner with ZuluTrade | ZuluTrade Social Trading 8`,
    text407: `Partner with ZuluTrade | ZuluTrade Social Trading 9`,
    text408: `Partner with ZuluTrade | ZuluTrade Social Trading 10`,
    text409: `Partner with ZuluTrade | ZuluTrade Social Trading 11`,
    text410: `Partner with ZuluTrade | ZuluTrade Social Trading 12`,
    text411: `Partner with ZuluTrade | ZuluTrade Social Trading 13`,
    text412: `Partner with ZuluTrade | ZuluTrade Social Trading 14`,
    text413: `Partner with ZuluTrade | ZuluTrade Social Trading 23`,
    text414: `The standard White Label`,
    text415: `Partner with ZuluTrade | ZuluTrade Social Trading 34`,
    text416: `Out of the box implementation. Real-time coverage monitoring tools.`,
    text417: `Partner with ZuluTrade | ZuluTrade Social Trading 35`,
    text418: `based on their ROI and risk behavior`,
    text419: `Partner with ZuluTrade | ZuluTrade Social Trading 36`,
    text420: `according to their capital and risk appetite`,
    text421: `Partner with ZuluTrade | ZuluTrade Social Trading 37`,
    text422: `Who we are`,
    text423: `ZuluTrade`,
    text424: `Our Offices`,
    text425: `Word Map`,
    text426: `Interested in our Services?`,
    text427: `Interested`,
    text428: `We like to Innovate`,
    text429: `Photo gallery 5`,
    text430: `Photo gallery 2`,
    text431: `Photo gallery 3`,
    text432: `Photo gallery 15`,
    text433: `Photo gallery 6`,
    text434: `Photo gallery 7`,
    text435: `Photo gallery 17`,
    text436: `Photo gallery 16`,
    text437: `Photo gallery 13`,
    text438: `Photo gallery 18`,
    text439: `Photo gallery 19`,
    text440: `2021`,
    text441: `2018`,
    text442: `2017`,
    text443: `2015`,
    text444: `2007`,
    text445: `2013`,
    text446: `2011`,
    text456: `Match-Trader (MTR) `,
    text457: `Match-Trader (MTR) platform is developed by Match-Trader Technologies. The platform offers a wide range of built-in apps e.g. Client Office, Social and Copy trading solution and integrated payments.`,
    text458: `Improve Customer Satisfaction and Investor Trading Volume`,
    text459: `Easy Setup and Ultra-Fast`,
    text459a: `Integration`,
    text460: `Gain Access to a Large Trading Community`,
  },
  //=============Start Leader Guide page=================//
  zuluTradeTabs: {
    Tab1: `ZuluTrade Leaders`,
    Tab2: `Performance, Stats and Indicators`,
    Tab3: `Payments`,
    Tab4: `Trading from MT4, MT5, XOH, ActTrader Terminals`,
    Tab5: `Suspension`,
  },
  zuluTrade_leaders: {
    tab_title: `ZuluTrade Leaders`,
    tab_heading: `Introduction to Leaders Program`,
    content1: `A "Leader" is a ZuluTrade user, who trades on a Real account while ZuluTrade publishes his/her results and statistics publicly through the website. Each trading action performed in the Leader’s account is sent to all ZuluTrade accounts in the form of a broadcast signal. Users, who have chosen to follow the Leader ("Investors"), have the trading action automatically executed in their account, residing in their brokerage firm. Since a simple Leader signal is executed by various different Brokers that have different trading interfaces and configurations, trading problems can occur. A possible instance is an orphan trade. An orphan trade is a trade that although it is closed in the Leader’s account, due to a variety of reasons, it may be left open on one or more of the Investors' Real accounts. This incident can lead to different results between the Leader and the Investor- e.g. although the Leader gains, the Investor ends up losing as a result of the trade having closed long after it had been closed in the Leader’s account. However, Leaders have the ability to both monitor and close their Real Investors' positions that are detected as "orphans". Investors can fine-tune and override Leader's signals by configuring their settings in their ZuluTrade account portfolio, or even manually.`,
    content2: `For Risk Management purposes, ZuluTrade may restrict Leaders for being copied by Investors in case they don't follow the Program's Guidelines.`,
    tab_heading1: `Creating a Leader account`,
    content3: `Everyone can sign up to become a Leader. To register your Leader account, please click `,
    content103: `Just follow the onboarding instructions, and when prompted click “Become a Leader”. If you already have a Trading Account linked to ZuluTrade, go into your Trading Accounts section and select the option “Make my Account Public”.`,
    content104: `here`,
    content4: `You can open up to 10 Leader accounts with the same email address. Competition however is fierce. Leaders’ performance is constantly ranked by ZuluTrade's ZuluRank algorithm and the ZuluTrade community, so that only the best ones appear on the top of our lists.`,
    tab_heading2: `Trading platforms`,
    content5: `Leaders may select one of the following Trading Platforms to place their trades:`,
    content6: ` 1. MetaTrader 4 (MT4) platform`,
    content7: ` It is possible to link an external live MT4 platform to a Leader account. In this case the Leader account becomes read-only and any trading activity that takes place in the MT4 account is copied over to the ZuluTrade account.`,
    content8: ` 2. MetaTrader 5 (MT5) platform`,
    content9: `It is possible to link an external live MT5 platform to a Leader account. In this case the Leader account becomes read-only and any trading activity that takes place in the MT5 account is copied over to the ZuluTrade account.`,
    content10: `3. ActTrader`,
    content11: `It is possible to link an external live ActTrader platform to a Leader account. In this case the Leader account becomes read-only and any trading activity that takes place in the ActTrader account is copied over to the ZuluTrade account.`,
    content12: `4. X Open Hub (XOH) platform`,
    content13: `It is possible to link an external live XOH platform to a Leader account. In this case the Leader account becomes read-only and any trading activity that takes place in the XOH account is copied over to the ZuluTrade account.`,
    content14: `5. Match-Trader`,
    content15: `It is possible to link an external live Match-Trader platform to a Leader account. In this case, the Leader account becomes read-only, and any trading activity that takes place in the Match-Trader account is copied over to the ZuluTrade account.`,
  },
  performance_indicators: {
    tab_title1: `Performance, Stats and Indicators`,
    content14: `As a Leader trades, their performance and position in the ZuluTrade Leaders page will gradually evolve; moving up and down in ranking. All data and information of the Leaders are taken into consideration in order to calculate their place in the ranking. Some decisive factors for the ZuluRank are (please note that this list is not exhaustive):`,
    content15: `Maturity: How long the Leader has been trading for, reflected in ‘Weeks’.`,
    content16: `Exposure: How many positions might be open at the same time, reflected in ‘Max Open Trades’, the 'NME' required, etc.`,
    content17: `Drawdown: How many ups and downs the Leader’s historical performance has experienced.`,
    content18: `Performance: Overall pips earned, average pips per trade and other performance metrics.`,
    content19: `For Our European Performance Page only, the below mentioned factors are also taken into account in order to form the list of the top 2000 Leaders:`,
    content20: `Their minimum trading time is at least 8 weeks.`,
    content21: `They have less than 30% Drawdown (in pips or equity)`,
    content22: `The average number of pips is above 3 or average rate of return is above 0.015%.`,
    content23: `If you want to find out more about the way ZuluRank is calculated, please click`,
    content23a: `here!`,
    content24: `The Performance page is updated several times per day, as trades and Leader’s statistics develop.`,
    content25: `Performance page also contains visual indicators, helping Investors to identify certain Leader attributes. Most common of which are:`,
    content26: `The Leader is using a Demo Account (For legacy reference only, new accounts cannot use this option).`,
    content27: `The Leader is operating on a Demo Account but is copying his own signals in a Real Investor Account (for legacy reference only, new accounts cannot use this option).`,
    content28: `The Leader is using the MT4, MT5, XOH, ActTrader trading platform.`,
    content29: `The Leader is submitting trading requests through ZuluTrade API.`,
    content30: `The Leader is trading correlated currency pairs, by using a natural hedging strategy that potentially lead to limited drawdown.`,
    content31: `The Leader is frequently trading economic calendar releases!`,
    content32: `Zulu Veteran - a Leader that has been trading with ZuluTrade for more than 2 years with a consistently high ranking.`,
    content33: `Leaders that have partially or fully verified their account.`,
    content34: `Trading history from XX/XX/XX To XX/XX/XX has been imported to ZuluTrade.`,
    content35: `In order to appear in the Leaders  page, a Leader must first close 1 position, while a minimum of 8  weeks of trading activity is required in order to appear on the Leaders page for Europe!`,
  },
  general_payments: {
    tab_title2: `Payments`,
    content93: `A. General Information`,
    content36: `Leaders’ compensation differs according to the their Investors account type as well as the Leader's location in order to comply with local regulation.`,
    content37: `Volume Based Compensation – Zero Subscription plan (Default ZuluTrade Investor Account type)`,
    content38: `Volume Based Compensation – Classic plan  (legacy accounts, for reference only)`,
    content39: `Profit Sharing Compensation – Profit Sharing Accounts (legacy accounts, for reference only)`,
    content40: `B. Volume Based Compensation Scheme`,
    content41: `Leaders earn for each closed trade executed in a Real Investor account under Volume Based Rules. The 0.5 pip value depends on the lot size executed in the investor’s account, not in the leader’s. For example, the leader opens 1 standard lot and the investor receives 1 lot according to his/her settings, then the leader will get 0.5$. In case that the investor only opens a micro lot, then the leader will get 0.05$. Pip cost varies according to the currency pair traded.`,
    content42: `C. Profit Sharing Compensation Scheme (legacy accounts, for reference only) `,
    content43: `Leaders get compensated on a monthly basis according to the profits they generate on their Real Investors under Profit Sharing Account Model.`,
    content44: `Every time you generate a profitable monthly PnL to a Profit-Sharing Investor, you will be credited a 25% Performance Fee. This Fee will only apply for the amount above High Water Mark (“HWM”). HWM is the maximum profit made by the Leader and it is calculated on the 1st calendar day of each month since the Investor added the Leader to his portfolio.`,
    content45: `For each profitable monthly PnL, the Leader will be compensated based on a 50% Payment-Reserve model. More specifically:`,
    content46: `50% of the Performance Fee charged is being credited in the Leader’s account. The same amount (50%) is requested from the Reserve bucket. The amount that will be released and credited is limited to the reserved amount generated from previous periods.`,
    content47: `50% of the Performance Fee charged is added to the Leader’s Reserve Bucket for next periods.`,
    content48: `Each Leader holds one Reserve Bucket for all his Profit Sharing Investors. The Reserve amount is updated on the 1st calendar day of each month based on the profits/losses generated on the Real Investor accounts from the Leader during the previous month.`,
    content49: `Case 1: Profitable monthly PnL - Profit above HWM`,
    content50: `Investor’s Monthly Performance Fee (20% of the Profit above High Water Mark) is split, as follows:`,
    content51: `50% is credited in the Leader’s account`,
    content52: `50% is credited from Reserve Bucket (if applicable - see the example below)`,
    content53: `50% is placed into the Leader’s Reserve Bucket for future release.`,
    content54: `Case 2: Negative monthly PnL`,
    content55: `In case of underperforming, the Reserve amount will be deducted by 20% of the generated losses.`,
    content56: `Case3: Recovering Losses Period - Profit below HWM`,
    content57: `In case of a Recovering Losses Period, the Performance Fee will not apply, yet the Leader’s reserve will be increased by 20% of the generated Profit.`,
    content58: `For example:`,
    content59: `In June, a Leader makes a profit of $1000 - This will be the HWM. The Investor will be charged 25% (20% for the leader, 5% for ZuluTrade) out of the accumulated profit ($1000): $1000 x 25% = $250`,
    content60: `Based on the Payment-Reserve Scheme, the 20% for the Leader breaks as follows: 50% of the Performance Fee paid by the Investor will be credited in the Leader’s account immediately: $200 x 50% = $100.`,
    content61: `50% of the Performance Fee paid by the Investor will be credited in the Leader’s account from the Reserve (in case there is an available amount). In this example, there is no Reserve.`,
    content62: `50% of the Performance Fee paid by the Investor will be reserved in the Leader’s Reserve Bucket: $200 x 50% = $100`,
    content63: `Monthly Reserve Amount = $100`,
    content64: `Total Reserve Amount = $100`,
    content65: `In July, the Leader has a negative month (-$100) – HWM remains $1000.`,
    content66: `Performance Fee will not apply for the Investor`,
    content67: `The Leader’s Reserve amount will be deducted by 20% of the losing amount: -$100 x 20% = -$20.`,
    content68: `Monthly Reserve Amount = -$20`,
    content69: `New Total Reserve Amount = $80 (=$100 - $20)`,
    content70: `In August, the Leader makes a profit of $500 – New HWM $1400`,
    content71: `The first $100 is considered as Recovering Losses Amount (loss generated in July) and the Investor will not be charged any Performance Fee. However, the Leader’s Reserve amount will be refilled again with the amount of $20 (20% x $100).`,
    content72: `Performance Fee will apply for the Investor, only for the $400 (amount above new HWM): $400 x 20% = $80.`,
    content73: `The Leader will now receive`,
    content74: `50% of the Investors' Performance Fee will be credited immediately: $80 x 50% = $40.`,
    content75: `50% of the Investors' Performance Fee will be credited from Total Reserve Amount reserved so far: $80 x 50% = $40`,
    content76: `50% of the Investors' Performance Fee will be added to Reserve Bucket: $80 x 50% = $40.`,
    content77: `Monthly Reserve Amount = +$20 (from Recovering Loss Period) + $40 (placed into Reserve bucket) = $60`,
    content78: `New Total Reserve Amount = $100 (=$80 + $20 + $40 - $40)`,
    content94: `AutoPay Service for Real Leaders (temporarily deactivated, please request withdrawals manually)`,
    content79: `Real Leaders may select to receive their total outstanding commissions automatically in their Real Broker account at the 1st business day of each month. To be eligible for the AutoPay Service, Leaders must:`,
    content80: `Operate on a Real trading account registered in a Broker participating in this Program.`,
    content81: `Have completed the verification process by submitting a valid identification document as well as a proof of address.`,
    content82: `Leaders may enable the AutoPay service on the Revenue page of their account. On the 1st business day of each calendar month, they will be receiving automatically the accrued commissions of the previous month directly in their trading account. There are no minimum amount requirements nor fees apply if AutoPay is enabled.`,
    content83: `Also please take the following under consideration:`,
    content84: `To submit your request, ZuluTrade needs to be provided with sufficient evidence of the Leader credentials – typically we require a valid photo ID (passport, government identification card or government issued voter’s card) and a recent formal bill indicating your name and full current residential address.`,
    content85: `Before issuing payments, all trades need to be checked against our Trading Compliance rules and relevant commissions need to be collected from our collaborating Brokers; the estimated time frame required to process a payment after a request has been made via our website is approximately 30 days.`,
    content86: `An active account balance with a minimum of $100 is required before proceeding to request a payment. Please ensure that your bank account details, PayPal account or Crypto Wallet Address is set up properly to avoid any complications with payment.`,
    content87: `The relevant fees for each payment are the following. Bank transfer ($45), PayPal ($20 for 0-$500 transfer and 3.9% for $500 and above), Crypto (network transaction fees).`,
    content88: `The Leaders must at all times fully, promptly, truthfully and transparently communicate with the Investors copying their strategies via Social Pages, as well as the ZuluTrade Traders Community Desk.`,
    content89: `ZuluTrade values the safety of its Investors’ capital as an utmost priority. For this reason, Leaders who apply abusive trading behavior, introducing high risks to their Investors, will be examined carefully and if deemed malicious, they will be refused compensation.`,
    content90: `For each specific calendar month that a Leader presents a negative Total Monthly PnL performance in $, the Leader will not be compensated. Total Monthly PnL includes unrealized PnL deriving from the end of month valuation of the Leader’s open positions.`,
    content91: `Please note that payment must be requested within 30 days of receipt of the termination notification. Payment requests received after this period will be rejected. In case of disabled or terminated accounts, any accrued commissions that fail to reach the threshold of $100 get automatically rejected.`,
    content92: `Accounts with considerable deposits can be characterized from the Broker as Institutional due to the low spread offered. Compensation for such accounts is 0.1 pips per lot executed in a Leader’s account.`,
  },
  trading_from: {
    tab_title3: `Trading from MT4, MT5, XOH, ActTrader Terminals`,
    content123: `In addition to the above rules for Leaders operating on MT4, MT5, XOH, ActTrader terminals and outside of the ZuluTrade web interface, compliance with the following rules is also expected:`,
    content124: `Closing partial lots is not supported`,
    content95: `Deleting Stop/Limit via EA (updating Stop/Limit from non-zero values to zero via EA) is not supported and related signals will not be broadcast to Investors.`,
    content96: `We support trading in Forex, CFDs, Indices, Gold and Silver.`,
    content97: `Altering of MT4, MT5, XOH, ActTrader trading records and statistics in any way is strictly prohibited. Closing trades via the 'Close by' type is not supported.`,
    content98: `Demo Trading (legacy feature, for reference only)`,
    content99: `When trading from a Demo account, please make sure your trading strategy complies with the following restrictions:`,
    content100: `There is an upper limit of 50 market and 50 pending orders, as well as a limit of 5000 update signals, permitted to be broadcast to Investors within a 24-hour interval.`,
    content101: `Signals to create market/pending orders should have a minimum time interval of 5 seconds from the last one accepted in order to be broadcast to Investors.`,
    content102: `Maximum overall number allowed of open - market and pending - orders is limited to 50; any further signals will not be broadcast to Investors.`,
    content103: `Please note that none of the above restrictions apply to Leaders operating on a Real Account.`,
    content132: `Inappropriate Trading Behaviors`,
    content104: `ZuluTrade values the safety of its Investors’ capital as an utmost priority. Consequently, Leaders who apply abusive trading behavior, introducing high risks to their Investors, will be examined carefully and if deemed malicious, they will be refused compensation and may be banned from future use of ZuluTrade’s services Likewise any attempt to take advantage of their status, extort or even break the communication channel with the community or the company will be deemed as malicious.`,
    content105: `In order to avoid extremes, Leaders are required to comply with a minimum set of guidelines that aim to reduce risks, such as:`,
    content106: `A Leader's maximum total draw-down should not exceed their profit in currency or pips. This applies once there is an adequate record of Leader's performance (6 months).`,
    // 'content107': `No positions should be allowed to reach losses of 700 pips or more. The use of stop loss at reasonable values is mandatory to ensure Investors' safety. A careful and short use of Stop-Loss will also assist and promote you in our Ranking algorithm. Large stop-losses or no stop-losses is not appropriate trading.`,
    content108: `ZuluTrade is not endorsing a winning trades ratio close to 100%. It is a potentially risky strategy as it may indicate exposure to unusually large drawdowns.`,
    content109: `Note that the above thresholds in pips are indicative for basic FX pairs. Please assume equivalent amounts for exotic pairs including XAU, XAG or CFDs.`,
    content110: `Market Hours`,
    content111: `No trading should be performed outside of ZuluTrade's Market Hours. Market Opens Sunday 22:00 UTC and closes Friday 22:00 UTC. When Eastern time is in daylight saving, market opens Sunday 21:00 UTC and closes Friday 21:00 UTC.`,
    content112: `Recommendations for Effective Strategies`,
    content113: `Scalping is not Recommended.`,
    content114: `Due to the combination of different trading policies, configuration and rate feeds of various brokers to whom your Investors may subscribe, scalping is not a recommended manner of sending signals to your Investors. The practice frequently leads to very inconsistent results for different broker accounts.`,
    content115: `Trading During News`,
    content116: `Many brokerage firms will often reject trading signals during News announcements that affect the market, resulting in possible discrepancies between the execution in your account and your Leader`,
    content117: `Trading with Unrealistic Amounts of Virtual Funds`,
    content118: `Apart from producing unrealistic trading results, no leader will ever be able to relate with your strategy if your starting balance is extremely high, or you trade huge amounts of lots.`,
    content119: `Accurate Strategy descriptions`,
    content120: `Your Strategy description needs to be accurate and descriptive, so that your Investors can be aware of what to anticipate from your trading activity. As unexpected trading behavior is not appreciated by your ZuluTrade Investors, any Strategies that are not in accordance with your actual trading behavior will be disapproved, affecting your Compensation or Ranking accordingly.`,
    content121: `Up-to-date Status `,
    content122: `Your current Status should always be up to date. Please keep in mind that Investors can request for a Status Update, in which case you will be notified via email and expected to provide a status update as shortly as possible. Awkward silences are not friendly towards your ZuluTrade Investors, so please ensure timely updates are provided.`,
  },
  suspension: {
    tab_title4: `Suspension`,
    content125: `Suspension of a Leader’s account may occur in two ways: temporarily as a warning, or permanently, leading to prohibition from using the service. Liability for any consequences from a Leader account getting suspended burdens the account holder.`,
    content126: `Temporary Suspension`,
    content127: `Leaders linked with MT4, MT5, XOH, ActTrader terminals that violate the Compliance Policy Rules, will be automatically disconnected from their accounts. They can try to re-connect after they have reconfigured their strategy according to the suspension reason.`,
    content128: `Permanent suspension`,
    content129: `Inactive Accounts. A Leader account that has been inactive for more than 1 month will be permanently suspended.`,
    content130: `Leader accounts that receive multiple temporary suspensions.`,
  },
  //=============Start User Guide Page Content=================//
  user_guide_tabs: {
    guidetab1: `Onboarding`,
    guidetab2: `Dashboard`,
    guidetab3: `Leaders`,
    guidetab4: `Markets`,
    guidetab5: `Community`,
    guidetab6: `Account`,
    guidetab7: `Investor Guide`,
  },
  onboarding: {
    contenttitle1: `Onboarding`,
    contentsheet1: `A. Login`,
    contentsheet2: `You can login to your account by entering your ZuluTrade Username or your ZuluTrade registered email address, together with your ZuluTrade password `,
    contentsheet36: `here`,
    contentsheet35: ` Alternatively you may login using your Google Account.`,
    contentsheet3: ` B. Register`,
    contentsheet4: `To open a Live account with our revolutionary trading platform, first you need to`,
    contentsheet37: ` by providing your email (or a username) and a password. Alternatively you may register using your Google Account.`,
    contentsheet38: ` Register`,
    contentsheet5: `Upon your registration, a free Demo account will be automatically created for you, with exactly the same functionality as a Live account. This means that you can try out ZuluTrade risk-free and experiment with different CopyTrading strategies before investing any real funds!`,
    contentsheet6: `Demo is designed to provide the same experience as your Real account, irrespective to your selected Broker`,
    contentsheet7: `Rates accurately represent the market at all times`,
    contentsheet8: `Comprehensive Stop Out level at your desired level`,
    contentsheet9: `Try now!`,
    contentsheet10: `a. Personal Details`,
    contentsheet11: `After registration you will be prompted to complete your account. The first step is to enter your personal details, like name, country and phone number. Please note that it is not possible to change your e-mail address later, but we can change it for you with a simple e-mail request to: support@zulutrade.com`,
    contentsheet12: `In order to ensure smooth connectivity please note that your e-mail address at ZuluTrade should be the same as in your broker(s).`,
    contentsheet13: `b. Setup Broker Account`,
    contentsheet14: `After that you will be prompted to add a Live trading account. You can create a new trading account with one of our supported brokerage firms,`,
    contentsheet15: `Connect an existing broker account`,
    contentsheet16: `You can connect any MT4, MT5, ActTrader, XOH account you already own from any broker by following the on-screen instructions.`,
    contentsheet17: `In case your broker is not on our list you may select “Other Brokers” and you will be asked to provide the brokers IP in addition to your Broker Account Username and Password.`,
    contentsheet18: `Please note that the required password is the MT4, MT5, ActTrader, XOH Master password, and not the Read-Only (Investor) password. If you provide the Read-Only (Investor) password, although your ZuluTrade account will be connected to your Broker account, you will not be able to receive any signals as it will be set to read-only mode.`,
    contentsheet19: `If you do not have the Broker Account Username, Broker Account Password available, then please contact your Broker as they can remind/reset them for you – unfortunately, ZuluTrade does not have access to this information and thus cannot provide it.`,
    contentsheet20: `Create a new broker account`,
    contentsheet21: `You can also create a new Trading Account through ZuluTrade with our supported brokerage firms. In order to do so, please follow the on-screen instructions for each broker account.`,
    contentsheet22: `Keep in mind that for some brokers you will be directed to the broker’s website to create your trading account. After that you will need to return to ZuluTrade to eventually connect your new trading account. You will be provided with instructions (on-screen or via email) that you need to follow for the specific broker.`,
    contentsheet23: `Note: Please remember that if you ever change your Broker Password, connectivity with your ZuluTrade account will be lost, so you will need to repeat this process, and re-connect your Broker account to ZuluTrade using the updated Broker Password.`,
    contentsheet24: `c. Subscription Plan`,
    contentsheet25: `In order to be able to CopyTrade with a Live Trading Account you need to have a subscription plan.`,
    contentsheet26: `With a trading account of an`,
    contentsheet26a: `integrated broker`,
    contentsheet26b: `you can enjoy our`,
    contentsheet39: `FREE Unlimited CopyTrading plan.`,
    contentsheet27: `To CopyTrade with any other broker account, you can activate a paid subscription plan by entering the number of Leaders’ strategies you may want to CopyTrade and then select one of the available billing cycles (auto-renewal period). The longer the billing cycle the higher the discount you get per Leader-strategy you buy.`,
    contentsheet28: `After that you need to add a payment method to complete the purchase. The subscription plan gets activated immediately after a successful payment so you can start investing in our Leaders directly.`,
    contentsheet29: `Free subscription plans can be used only with the`,
    contentsheet29a: `integrated brokers’`,
    contentsheet29b: `accounts.`,
    contentsheet30: `With a paid plan you will be able to CopyTrade at the same time any number of Leader-strategies up to the number you have bought.`,
    contentsheet31: `The Leaders’ strategies you copy with your free plan do not count on your paid subscription plans. Free and paid plans are completely separated.`,
    contentsheet32: `With a Demo account you can CopyTrade unlimited strategies, with virtual money, without a subscription plan.`,
    contentsheet33: `Your subscription will renew automatically at the start of a new billing cycle by charging your payment method.`,
    contentsheet34: `You may change or cancel your plan at any time prior to the next billing cycle.`,
    contentsheet40: `c. Applicable Fees`,
    contentsheet41: `In order to be able to CopyTrade with a Live Trading Account you do not need to pay any subscription fee. The ZuluTrade Platform is available to all End - Users holding a linked trading account with a Co-operating Broker.`,
  },
  user_guide_dashboard: {
    contenttitle2: `Dashboard`,
    contentsheet35: `A. Basic Information`,
    contentsheet36: `On the Home/Dashboard you will find information like your accounts’ Equity, PnL, ROI and performance chart, as well as the Leaders you are CopyTrading and your trades.`,
    contentsheet37: `In case you have multiple trading accounts, you can select the account of your choice to display the relevant information, by using the account selector.`,
    contentsheet38: `B. My Trades`,
    contentsheet39: `This is where executed signals that Leaders place in your account and trades that you have placed on your own will show up. Here you can see what your current earnings or losses are at the moment for each trade. Trades are organised in open, pending orders and closed trades.`,
    contentsheet40: `a. Trade Details`,
    contentsheet41: `Clicking on any trade will show additional information for the specific trade/order. Here you can also find the Trader Ticket and Broker Ticket, which are essential IDs if you wish to contact ZuluTrade over specific trades.`,
    contentsheet42: `1. Update an open trade or a pending order:`,
    contentsheet62: `By clicking “Update” on an open trade, you will bring up a popup window where you can change the Stop Loss or Take Profit rates.`,
    contentsheet43: `On a pending order you can update the Stop Loss, Take Profit Rates, as well as the BUY or SELL rates that were previously set.`,
    contentsheet44: `When updating copied trades, you will overwrite any Stop Loss, Take Profit or BUY/SELL rates previously set by the Leader or by yourself. Please note that after you have updated any of these rates, any new updates on copied trades will be received normally at your account.`,
    contentsheet66: `My Leaders section`,
    contentsheet45: `2. Close an open trade:`,
    contentsheet46: `By choosing to close a trade you will then be prompted to a confirmation window, where you can either confirm or cancel your Close request. Upon confirmation the trade will close and should no longer appear in your trades.`,
    contentsheet47: `Take into consideration that sometimes there may be a brief delay of a few seconds between closing the trades and displaying that on your trades; if you notice a delay, please try using the Refresh option.`,
    contentsheet48: `3. Cancel a pending order:`,
    contentsheet49: `By choosing to cancel a pending order you will then be prompted to a confirmation window. Upon confirmation the order will close and should no longer appear in your orders. If you notice a delay, please try using the Refresh option.`,
    contentsheet50: `C. My Leaders`,
    contentsheet51: `This is where the Leaders that you already copy will appear. By clicking on any Leader you will see your PnL, ROI and the investment you have made on a specific Leader, as well as all the trades that have been copied by the specific Leader.`,
    contentsheet52: `a. Copied Trades`,
    contentsheet53: `Copied trades are organised in open, pending orders and closed trades exactly like your own (manual) trades. You can also update any open trade or pending order, in the same way you can do it for your own trades.`,
    contentsheet54: `By updating copied trades, you will overwrite any Stop Loss, Take Profit or BUY/SELL rates previously set by the Leader or by yourself. Please note that after you have updated any of these rates, any new updates on copied trades will be received normally at your account.`,
    contentsheet55: `b. Update Strategy`,
    contentsheet56: `You can update the CopyTrading settings of a specific Leader’s strategy by clicking/tapping on “Update” next to the Leader’s name. On the web app this option is under the context menu (3 dots). A new window will open where you can change the “Investment amount”, “Copy ratio”, “Take Profit amount”, as well as set or remove the “Trailing Stop” for the whole strategy.`,
    contentsheet57: ` Learn more on`,
    contentsheet58: `c. Stop Copying Strategy`,
    contentsheet59: `From the “Update Strategy” window you can also stop copying a Leader’s strategy. Once you stop copying a Leader you will stop receiving new trades by this specific Leader.`,
    contentsheet60: `In case you have open trades at the time you stop copying a Leader you will be asked if you want to keep or close all the open trades by this Leader’s strategy as well.`,
    contentsheet61: `If you choose to keep all open trades from a strategy you are stop copying, you will still receive signals for these trades until you close them manually or get closed by the Leader. New trades will not get copied though.`,
  },
  user_guide_leaders: {
    leadertitle: `Leaders`,
    leadercontent1: `If you click on the “See all Leaders” button, you will be directed to the Leaders’ page to check the Leaders and select which you wish to copy. `,
    leadercontent2: ` To copy a Leader, follow the steps below:`,
    leadercontent3: `Navigate to the Leaders’ tab.`,
    leadercontent67: ` Here you can explore all of the Leader’s that are available for copytrading in ZuluTrade. You can click/tap on any of the Leaders to open their profiles, where you can view all of the Leader’s trading statistics.`,
    leadercontent4: `Click/tap the “Copy Strategy” button`,
    leadercontent68: ` - A pop-up will open`,
    leadercontent5: `Select a trading account`,
    leadercontent69: `- In case you have multiple trading accounts make sure you have selected the one with which you want to invest.`,
    leadercontent6: `Specify the funds you wish to invest`,
    leadercontent70: `– This is the amount you wish to invest by following this Leader. You may adjust the amount according to your balance. In case you experience a loss equal to your investment amount,`,
    leadercontent71: `will automatically get activated to safeguard your invested capital.`,
    leadercontent7: `Advanced Settings (optional):`,
    leadercontent8: `Custom Copy Ratio:`,
    leadercontent72: `By setting a “Custom Copy Ratio” you will override our algorithm and the Leader’s trades will be copied with the ratio you set. `,
    leadercontent9: `Take Profit:`,
    leadercontent73: `When you make this amount of profit by the copied trades from this Leader, you will automatically stop copying the Leader and all of the open copied trades will get closed.`,
    leadercontent10: `Trailing Stop Loss: `,
    leadercontent74: `If you set Capital Protection to $500, in case the Leader is losing $500 in your account, ZuluGuard™ will step in. However, this mode will move up exactly like a trailing stop for the whole strategy. If the Leader has closed trades that have a PnL of $2,000 in your account and his next trade has floating losses of -$500, then ZuluGuard™ will step in, and secure profits of $1,500!`,
    leadercontent11: `Copy Open Trades:`,
    leadercontent75: `Enabling this will copy all of the Leader’s open trades at market price at the time you copy the Leader.`,
    leadercontent12: `By default all your CopyTrading investment is protected by `,
    leadercontent76: ` which creates a protection shield for your investment capital. ZuluGuard™ monitors each Leader’s trading behaviour and automatically takes action when a radical change in his trading strategy is detected.`,
  },
  user_guide_markets: {
    markettitle: `Markets`,
    marketcontent1: `Here you can place manual trades as you see fit. The rates displayed are received directly from your broker, so it is the same as if you were trading from your broker platform. You will see the BUY price per instrument as well as the change percentage since the previous market close.`,
    marketcontent2: `When the market is open, rates will be constantly highlighted alternating between green and red, indicating whether market tendencies are currently up or downwards for this pair.`,
    marketcontent3: `You can click/tap on any of the available instruments to open its page and see its chart as well as useful statistics and news.`,
    marketcontent4: `If you wish to place a manual trade, simply click on either Buy or Sell. A popup window will then appear. This window will consist of the current market price and some options that you can set to place your trade/order.`,
    marketcontent5: `You may open a trade at market price or set a specific price to place a pending order by selecting the “Limit Order” option and then setting your desired price.`,
    marketcontent6: `The investment amount can be set by entering the amount of Lots, Units or Margin (in your trading account’s currency).`,
    marketcontent7: `You may also amend the default Stop and Limit, and it is possible to set a Trailing Stop as well. Please note that if you select the Trailing Stop option, the Stop will start trailing immediately as soon as the trade is opened. For example, a BUY EUR/USD trade is set with -30 pips Trailing Stop and opened at 1.383. The trade is opened with a Stop of -30 pips (at 1.380), and if it starts winning even by one pip, the Trailing Stop will be updated accordingly. So when the trade reaches a High of 5 pips (rate 1.3835) , the Stop will be updated 30 pips away from this new rate, at 1.3805 (-25 pips away from the trade's opening price). After a Trailing Stop is set, it is updated automatically as described above without the need to remain logged in the account. Once you have entered the information you wish, simply click on OK and the trade will appear either in your open positions or pending orders accordingly.`,
  },
  user_guide_community: {
    communitytitle: `Community`,
    communitycontent1: `Here you can learn what is happening in the ZuluTrade community and participate in discussions about investment topics. This is where ZuluTrade’s users are sharing their thoughts about the markets.`,
    communitycontent2: `You can follow any hashtag of your interest so that you can stay up-to-date on a specific topic.`,
    communitycontent3: `You can create your own posts to initiate a discussion in the community.`,
    communitycontent4: `You can upload media (pictures and images) on your posts.`,
    communitycontent5: `You can like and comment on any post.`,
    communitycontent6: `Follow a Leader:`,
    communitycontent7: `In order to stay updated about specific Leaders’ posts, you can add them to your watchlist, by visiting their profiles and tapping the “Star” button (on mobile) or by clicking the “Add to watchlist” button (on web). Once you add a Leader to your watchlist you automatically “Follow” him/her so that you can see what they are posting in the community.`,
  },
  user_guide_account: {
    accounttitle: `Account`,
    accountcontent1: ` A. My Profile`,
    accountcontent2: `“My Profile” is available only on our mobile apps.`,
    accountcontent3: `On “My Profile” you will find an overview of your accounts with information like your accounts’ Equity, PnL, ROI and performance chart, as well as the Leaders you are CopyTrading and your trades.`,
    accountcontent4: `Learn more about`,
    accountcontent4a: `My Trades.`,
    accountcontent5: `Learn more about`,
    accountcontent5a: `My Leaders.`,
    accountcontent6: `In case you have multiple trading accounts, you can select the account of your choice to display the relevant information, by using the account selector.`,
    accountcontent7: `Additionally you can see all of your community posts in one place under the tab “Posts”.`,
    accountcontent8: `B. Watchlist`,
    accountcontent9: `The watchlist allows you to easily keep an eye on the market assets and Leaders that you're interested in. You can add any asset or leader to your watchlist by using the star icon on asset’s and Leader profiles, and then easily monitor their performance in real-time.`,
    accountcontent10: `Whether you're a seasoned trader or just getting started, the watchlist is an invaluable tool for keeping track of the market and staying up-to-date on the latest trends. You can use it to quickly identify trading opportunities, track your favorite leaders.`,
    accountcontent11: `With the watchlist, you're always in control. You can add and remove assets and leaders as you see fit, and customize your watchlist to suit your specific investing style, so you can easily access all the information you need to make informed trading decisions.`,
    accountcontent12: `C. Settings`,
    accountcontent13: `a. Security`,
    accountcontent14: `1. Change Password`,
    accountcontent15: `You can also change your password for logging into your ZuluTrade account – in order to do that you will need your Current Password. If you have forgotten your Current Password, you can always reset it by clicking on the Forgot Password.`,
    accountcontent16: `2. Biometrics`,
    accountcontent17: `Only available on Mobile devices.`,
    accountcontent18: `b. Display Currency`,
    accountcontent19: `Here you can select a display currency to view your (total) balance and stats (of all your live trading accounts).`,
    accountcontent20: `This currency will be used on your statistics when "All accounts" is selected in your home/dashboard or profile.`,
    accountcontent21: `Specific account portfolios, trades and statistics will still be displayed in each account currency as set by your broker.`,
    accountcontent22: `Transactions will always be executed in each account’s currency as set by your broker.`,
    accountcontent23: `C. Delete Account`,
    accountcontent24: `In order to process the deletion of your account please send an email to  support@zulutrade.com with the title 'Delete Account'. Kindly note that due to regulatory obligations ZuluTrade is legally obliged to maintain data for Live accounts for a period of time as per respective licences for EU and Global regions.`,
    accountcontent25: `Setup Broker Account`,
    accountcontent26: `We must start with the Integrated broker account suggestion, then the “create a new broker account” and finish with “connecting a broker account”.`,
    accountcontent27: `ZuluTrade has partnered with some of the world's largest and most successful brokerages to make the client's journey seamless and create a fast onboarding process. Opening an account with these brokers is as easy as following a simple guide. You never have to leave ZuluTrade’s website, and when you finish entering your details, you can start live trading.`,
    accountcontent28: `By opening an account with an Integrated broker, you can enjoy our FREE Unlimited CopyTrading plan.`,
    accountcontent29: `Check out the subscription plans`,
    supportAssistance1: `Support Assistance`,
    supportAssistance2: `The ZuluTrade Customer Support Dept. is here to help you. You can contact us by email at`,
    supportAssistance3: `by telephone or by Live Chat. For further detail please visit`,
  },
  //Help Center
  help: {
    text1: `How Can We Help?`,
    text2: `Investor Guide`,
    text3: `Read the full guide for your Investor Account`,
    text4: `Leader Guide`,
    text5: `Read the full guide for your Leader Account`,
    text6: `Affiliate Guide`,
    text7: `Read the full guide for your Affiliate Account`,
    text8: `Email Support`,
    text9: `You can mail with our Support in all languages`,
    text10: `Call Support`,
    text11: `Central Support Hotline`,
    text12: `You can chat with our Support in all languages`,
    text13: `About ZuluTrade`,
    text14: `A. Our History`,
    text15: `ZuluTrade was founded in 2007 by Leon Yohai, and created in response to the non-existence of a web-based platform that could audit Leaders globally and at the same time; enable Leaders to share their knowledge with people interested in their strategies.`,
    text16: `The ZuluTrade Platform bridged the gap between valuable information in money markets and trade execution by converting the advice of some of the most professional and talented Leaders globally, to a service which can rapidly execute trades. An additional advantage of the Platform is that it provides the ability and ease of driving selected trades to broker accounts of the interested parties. The concept of ZuluTrade is to offer an open environment, where Leaders on a global-level can connect any trading platform and share their knowledge.`,
    text17: `ZuluTrade's vision is to become the world‘s largest social trading community in the globe offering customer-focused investment solutions!`,
    text18: `B. What bodies is ZuluTrade regulated by?`,
    text19: `ZuluTrade is regulated in`,
    text20: `The Hellenic Capital Markets Commission in Greece (License No 2/540/17.2.2010) and under passporting provisions the service is also available to the European Economic Area ("EEA") countries.`,
    text21: `The Financial Service Authorities in Japan (License No 0123-01-006371) in Tokai Finance Bureau.`,
    text21a: `The Financial Services Commission in Mauritius (License No IK21000018).`,
    text21b: `The Financial Sector Conduct Authority in South Africa (License No 49299).`,
    text22: `C. How can I contact ZuluTrade?`,
    text23: `Do you have inquiries or simply need to talk to us? Our contact details are below:`,
    text24: `To contact our Sales and Business Development teams, email us at:`,
    text25: `or`,
    text26: `partnerships@zulutrade.com`,
    text27: `To contact our Leaders Community Desk, email us at:`,
    text28: `tradersdesk@zulutrade.com`,
    text29: `To contact our Affiliates Desk, email us at:`,
    text30: `affiliates@zulutrade.com`,
    text31: `To send us your feedback, your suggestion or a complaint, email us at: `,
    text32: `qualitycontrol@zulutrade.com`,
    text33: `Need Help? While we strive to provide you with a top-notch experience with our platform or service, sometimes we might miss something or you might have questions. Not to worry, we are here for you. Please contact our Multilingual`,
    text34: `Support Team`,
    text35: `24/5`,
    text36: `About Zulutrade`,
    text37: `sales@zulutrade.com`,
  },
  //Blogs
  blog: {
    text1: `Read More`,
    text2: `Load More`,
    text3: `Back`,
    text4: `No Data available!`,
    text5: `ZuluTrade 1`,
  },
  //Simulation
  simulation: {
    text1: `Simulation`,
    text2: `Select Trading Account`,
    text3: `No Trading Account in your account.`,
    text4: `The simulation will run based on data of the past selected period.`,
    text5: `Start Simulation`,
    text6: `Timeframe`,
    text7: `Strategies`,
    text8: `ROI`,
    text9: `WIN`,
    text10: `RISK`,
    text11: `Percentage`,
    text12: `Click below to add a leader to the simulation and Click Start Button.`,
    text13: `Add Leaders`,
    text14: `Copied Strategies`,
    text15: `Results`,
    text16: `All`,
    text17: `Statistics:`,
    text18: `Starting Balance`,
    text19: `Ending Balance`,
    text20: `Total Profit`,
    text21: `Click on a leader to add in your compare list.`,
    text22: `Following`,
    text23: `Weeks`,
    text24: `Investors`,
    text25: `ROI`,
    text26: `Ranking`,
    text27: `Add Leaders to compare`,
    text28: `Simulation is a tool that helps you replicate the results of the Leader strategies you are testing in a period of time you decide, based on real-time data.`,
  },
  //Calendar
  calendar: {
    text1: `Economic  Calendar`,
    text2: `Add Filters`,
    text3: `All`,
    text4: `Clear`,
    text5: `Apply Filter`,
    text6: `Impact`,
    text7: `From`,
    text8: `To`,
    text9: `Recent`,
    text10: `Tomorrow`,
    text11: `This Week`,
    text12: `Next Week`,
    text13: `Next Month`,
    text14: `Yesterday`,
    text15: `Previous Week`,
    text16: `Previous Month`,
    text17: `Custom`,
    text18: `Time`,
    text19: `Actual`,
    text20: `Forecast`,
    text21: `Consensus`,
    text22: `Previous`,
    text23: `Create Alert`,
    text24: `Notification Interval`,
    text25: `Hrs`,
    text26: `Frequency`,
    text27: `Once`,
    text28: `Recurring`,
    text29: `Delivery Method`,
    text30: `Website popup`,
    text31: `Mobile App notifications`,
    text32: `Email Notifications`,
    text33: `Today`,
  },
  //notifications
  notifications: {
    notifyText: `From`,
    notifyText1: `MESSAGE`,
    notifyText2: `RELATED TRADER TICKET`,
  },
  //Calculators
  calculator: {
    text1: `Trading`,
    text2: `Tools`,
    text3: `Our toolbox of trading tools includes several calculators which can take your risk management process to the next level.`,
    text4: `Pip Calculator`,
    text5: `The pip calculator will tell you the value of a pip in the currency you want to trade in.`,
    text6: `Margin Calculator`,
    text7: `The margin calculator helps you calculate the margin needed to open and hold positions.`,
    text8: `Profit Calculator`,
    text9: `The profit calculator is a simple tool that lets you calculate the profit that is earned from trading.`,
    text10: `Currency Converter`,
    text11: `The currency converter tool allows you to see the conversion of currency values based on present-day exchange rates.`,
    text12: `Frequently Asked Questions (FAQs)`,
    text13: `What are forex trading tools?`,
    text14: `Forex Trading Tools enable forex traders to reliably analyze markets, while also allowing them to set the relevant parameters to most effectively and efficiently select the trades to perform. Most such forex trading tools also greatly impact both the conception as well as the implementation of a trader’s forex trading strategy, while their effective and correct usage may prove useful to develop or correct a trading strategy.`,
    text15: ` What are the tools needed for forex trading?`,
    text16: `Your forex trading endeavor will be greatly aided by the use of the right forex trading tools, which are both popular with traders and useful for their trading aspirations. Most of these can be easily accessed online and include: Trading Platforms, Charting Software, Copy Trading Services, Forex Calculators.`,
    text17: ` How can forex calculators help with your forex trading?`,
    text18: `The importance of forex calculators lay exactly in the fact that they enable you to analyze the real-time values of various currencies and also be able to sort them according to your own goals as a trader, against the backdrop of an ever-evolving, volatile environment. Without the effective use of a forex calculator, it is almost impossible to evaluate the main trends of the global currency market and make the relevant successful trading decisions.`,
    text19: `ALL TOOLS`,
    text20: `PIP CALCULATOR`,
    text21: `MARGIN CALCULATOR`,
    text22: `PROFIT CALCULATOR`,
    text23: `CURRENCY CONVERTER`,
    text24: `The pip calculator will tell you the value of a pip in the currency you want to trade in.`,
    text25: `Instrument`,
    text26: `Lots`,
    text27: `Pips`,
    text28: `Base Currency`,
    text29: `Select Base Currency`,
    text30: `Lots`,
    text31: `Calculate`,
    text32: `CURRENT CONVERSION RATE`,
    text33: `ACCOUNT BASE CURRENCY`,
    text34: `What is a pip in forex?`,
    text35: `Currency prices typically move in such tiny increments that they are quoted in pips or percentages in point. In most cases, a pip refers to the fourth decimal point of a price that is equal to 1/100th of 1%.`,
    text36: `How does the pip calculator work?`,
    text37: `To calculate pip value with a pip calculator, you simply enter the currency pair, the lot size, the number of pips, your base currency and the type of lot into the calculator. The pip calculator will then calculate the value of a pip in the currency you want to trade in.`,
    text38: ` How to calculate pip value?`,
    text39: `To calculate pip value, divide one pip (usually 0.0001) by the current market value of the forex pair. Then, multiply that figure by your lot size, which is the number of base units that you are trading.`,
    text40: `What causes pip values to change?`,
    text41: `The value of one pip is always different between currency pairs because of differences between the exchange rates of various currencies. A phenomenon does occur when the U.S. dollar is quoted as the quote currency. When this is the case, for a notional amount of 100,000 currency units, the value of the pip is always equal to US$10.`,
    text42: `How does leverage affect pip value?`,
    text43: `Increasing your leverage increases the volatility of your position because small changes in pip value will result in larger fluctuations in your account value.`,
    text44: `What is the difference between a pip, a point, and a tick?`,
    text45: `Point, tick, and pip are terms traders use to describe price changes in the financial markets. While traders and analysts similarly use all three terms, each is unique in the degree of change it signifies and how it is used in the markets.`,
    text46: `The margin calculator helps you calculate the margin needed to open and hold positions.`,
    text47: `Unit`,
    text48: `Leverage`,
    text49: ` What is the margin in forex?`,
    text50: `Forex margin rates are usually expressed as a percentage, with forex margin requirements typically starting at around 3.3 percent for major foreign exchange currency pairs. Your FX broker’s margin requirement shows you the amount of leverage that you can use when trading forex with that broker. Margin is the amount of money that a trader needs to put forward in order to open a trade. When trading forex on margin, you only need to pay a percentage of the full value of the position to open a trade. Margin is one of the most important concepts to understand when it comes to leveraged forex trading, and it is not a transaction cost.`,
    text51: `Why are margin calculations important?`,
    text52: `Your profit margin shows how much your trades are making and the general health of your funds. Profit margins are important because, simply put, it shows how much of every revenue dollar is flowing to the bottom line. It can quickly help determine pricing problems. Further, pricing errors can create cash flow challenges and, therefore, threaten the ongoing existence of your entity.`,
    text53: `How does the forex margin calculator work?`,
    text54: `To calculate forex margin with a forex margin calculator, you simply enter the currency pair, the trade currency, the trade size in units and the leverage into the calculator. The forex margin calculator will then calculate the amount of margin required.`,
    text55: `How is the forex margin calculated?`,
    text56: `The formula for calculating the margin for a forex trade is simple. Just multiply the size of the trade by the margin percentage. Then, subtract the margin used for all trades from the remaining equity in your account. The resulting figure is the amount of margin that you have left.`,
    text57: `What are the risks and rewards of trading forex on margin?`,
    text58: `When you trade with 10x or 100x your account value, you could see profits equivalent to a much larger investment. That allows you to start small and see big returns if you make good trades. On the flip side, with margin trading, your losses add up fast, and they may even put you in debt. Always make sure you fully understand what you're doing and the risks involved before trading forex on margin. Most investors should not take part in any forex margin trading. It's essential to understand the risks involved. If you weigh the risks and benefits and fully understand what you're doing with your forex account, you could use margin trading to accelerate your profits.`,
    text59: `What is the relationship between leverage and margin requirement?`,
    text60: `Forex margin and leverage are related, but they have different meanings. We have already discussed what forex margin is. It is the deposit needed to place a trade and keep a position open. Leverage, on the other hand, enables you to trade larger position sizes with a smaller capital outlay.`,
    text61: `The profit calculator is a simple tool that lets you calculate the profit that is earned from trading.`,
    text62: `Open price`,
    text63: `Close price`,
    text64: `What is profit in forex?`,
    text65: `Profit in forex trading is calculated based upon the difference in your opening and closing prices of trade. When you close out a trade, take the price (exchange rate) when selling the base currency and subtract the price when buying the base currency, then multiply the difference by the transaction size.`,
    text66: `What is a good forex profit?`,
    text67: `Forex also allows trading 24 hours a day, while stock trading times are more limited. You can make money (or lose money) in any market, so what's most important is to know your particular market and how to trade effectively. This all of course depends on how much you're risking per trade. It’s important to remember that in order to be profitable you must have knowledge of how forex works in general.`,
    text68: `How does the forex profit calculator work?`,
    text69: `When you close out a trade, take the price (exchange rate) when selling the base currency and subtract the price when buying the base currency, then multiply the difference by the transaction size. That will give you your profit or loss.`,
    text70: `How do you calculate profit from pips?`,
    text71: `The actual calculation of profit and loss in a position is quite straightforward. To calculate the P&L of a position, what you need is the position size and the number of pips the price has moved. The actual profit or loss will be equal to the position size multiplied by the pip movement.`,
    text72: `Currency Converter`,
    text73: `The currency converter tool allows you to see the conversion of currency values based on present-day exchange rates.`,
    text74: `Amount`,
    text75: `From`,
    text76: `To`,
    text77: ` What is profit in forex?`,
    text78: `When you close out a trade, take the price (exchange rate) when selling the base currency and subtract the price when buying the base currency, then multiply the difference by the transaction size. That will give you your profit or loss.`,
    text79: `Which currencies are included in the currency converter?`,
    text80: `Every major currency that is traded on the forex market.`,
    text81: `How does this currency converter work?`,
    text82: `The currency converter starts by calculating an exchange rate by dividing the amount of the currency you start with by the amount of the foreign currency you’ll get back. For example, if you have $100 and you get €80 back, your exchange rate would be 100 divided by 80, or 1.25 Euros per dollar.`,
    text83: `What is a quote currency?`,
    text84: `The quote currency, commonly known as the counter currency, is the second currency in both a direct and indirect currency pair and is used to determine the value of the base currency. The quote currency is listed after the base currency in the pair when currency exchange rates are quoted.`,
    text85: `What are the major forex pairs?`,
    text86: `The major forex pairs come down to four of the most heavily traded currency pairs in the forex market. The four major pairs at present are the EUR/USD, USD/JPY, GBP/USD, USD/CHF. These four major currency pairs are deliverable currencies and are part of the Group of Ten currency group. While these currencies contribute a significant amount of volume related to economic transactions, they are also some of the most heavily traded pairs for speculative purposes.`,
  },
  // No Data
  no_data: {
    noData: `No data to show yet`,
    nodataAlt: `Nodata`,
  },
  // Leader List
  top_traders: {
    leaderText: `Leaders`,
    share: `Share`,
    compare: `Compare`,
    copiers: `COPIERS`,
    followers: `FOLLOWERS`,
    end_text: `We do not recommend or make any implications about the future performance of any of these systems.`,
    simulatorHeading: `Simulator`,
    simulatorText: `Check how much you could earn by investing in our Leaders and trying out different combinations!`,
    simulatorButton: `Try now`,
    viewAll: `View All`,
    risk: `Risk`,
    WhatLeaders: `What is a Leader?`,
    LeadersDec: `A ‘Leader’ is a ZuluTrade user, who shares his/her trades on a real account. Each trading action performed in the Leader’s account is copied to all the Investor accounts who follow him/her in the form of a broadcasted signal.`,
  },
  // Leader Profile Page
  leader_profile: {
    tradeText1: `History`,
    tradeText2: `Trade History Log`,
    tradeText3: `Cancelled Orders Log`,
    tradeText4: `Monthly`,
    tradeText5: `Switch to another selector`,
    tradeText6: `Trader Name`,
    tradeText7: `Date Open`,
    tradeText8: `Trader`,
    tradeText9: `Currency`,
    tradeText10: `Type`,
    tradeText11: `Lots`,
    tradeText12: `Rate`,
    tradeText13: `Date Open`,
    tradeText14: `Date Closed`,
    tradeText15: `Broker Ticket`,
    tradeText16: `Trader Ticket`,
    zulu: `Zulu`,
    rank: `Rank`,
    overview: `Overview`,
    portfolio: `Portfolio`,
    advancedStats: `Advanced Stats`,
    copiers: `Copiers`,
    feedName: `’s feed`,
    strategyDescription: `Strategy description`,
    otherBroker: `Other Broker`,
    realAlt: `The Trader is using a Real Account`,
    metaTraderAly: `MetaTrader 4 Expert Advisor`,
    fullyVerified: `The Trader has fully verified his account!`,
    mobilePhoneNumber: `Mobile Phone Number`,
    verified: `Verified`,
    pending: `Pending`,
    proofOfAddress: `Proof of Address`,
    identificationDocument: `Identification Document`,
    partiallyVerified: `The Trader has partially verified his account`,
    tradingHistoryFrom: `Trading history from`,
    to: `To`,
    importedZuluTrade: `has been imported to ZuluTrade`,
    correlatedCurrency: `The Trader is trading correlated currency pairs, by using a natural hedging strategy that potentially lead to limited drawdown`,
    frequentlyTrading: `The Trader is frequently trading economic calendar releases!`,
    ZuluVeteran: `Zulu Veteran - a Trader that has been trading with ZuluTrade for more than 2 years with a consistently high ranking`,
    original: `Original`,
    translate: `Translate`,
    noApprovedDescription: `This trader does not have an approved description yet.`,
    showLess: `show less`,
    showMore: `show more`,
    InactivityDetected: `Inactivity detected!`,
    update: `Update`,
    copyStrategy: `Copy Strategy`,
    inactiveTrader: `Inactive Trader detected: This trader has no trading activity for the last 4 weeks.`,
    simulate: `Simulate`,
    roi: `ROI`,
    risk: `RISK`,
    winRate: `WIN RATE`,
    copiersCAP: `COPIERS`,
    performance: `Performance`,
    trading: `Trading`,
    drawDown: `DrawDown`,
    slippage: `Slippage`,
    staticsOverall: `STATISTICS - OVERALL`,
    profit: `PROFIT`,
    closedTrader: `The total number of trades closed.`,
    trades: `TRADES`,
    maximumNumber: `This is the maximum number of trades the Trader has opened simultaneously since the moment he/she joined ZuluTrade. Note that if a Trader opens a greater number of trades than usually at one time, this statistic will be updated accordingly. The value is updated when the trades get closed (pending orders are not counted in this statistic, only open positions).`,
    maxOpenTrades: `MAX OPEN TRADES`,
    avrRate: `Average Rate of Return per trade`,
    avgPips: `AVG PIPS`,
    avgprofit: `AVG PROFIT`,
    winningTrades: `The percentage of winning trades. Does not include profits or losses on open positions.`,
    winningTradtext: `WINNING TRADES`,
    recommendedInvestment: `Recommended Minimum Investment for optimal CopyTrading based on Leader's settings and historical data for this timeframe. The closer your investment is to this amount, the more positions will open while CopyTrading.`,
    recommendedInvestmenttext: `Recommended Min Investment`,
    greatestDiffrence: `The greatest difference between the highest and the lowest point reached during the selected period`,
    maximumDradown: `MAXIMUM DRAWDOWN`,
    averageTrading: `The average trading time per trade.`,
    avgTradeWeek: `AVG TRADE TIME`,
    pips: `Pips`,
    openTrades: `Open Trades`,
    additionalInfo: `Additional Info`,
    dailyDrawdown: `Daily Drawdown`,
    closedTradesOnly: `Closed Trades Only`,
    profitDrawdown: `Profit`,
    uploadedVideo: `Uploaded Video`,
    uploadedImage: `Uploaded Image`,
    photo: `Photo`,
    video: `Video`,
    uploadImage: `upload Image`,
    uploadVideo: `upload Video`,
    latestStatus: `Latest Status update`,
    postPlaceholderstart: `Write a post...Use # for hashtags or`,
    postPlaceholderEnd: `for mentions`,
    loadMore: `Load More`,
    imageValid: `Image size should be less than or equal to 12mb`,
    videoValid: `Video size should be less than or equal to 20mb`,
    lightbox: `lightbox`,
    copying: `COPYING`,
    hasCopied: `HAS COPIED`,
    feed: `Feed`,
    socialFeed: `Social Feed`,
    share: `Share`,
    linkCopied: `Link copied in clipboard!`,
    linkFailed: `Failed to copy link`,
    followed: `Followed`,
    follow: `Follow`,
    viewLess: `View Less`,
    viewAll: `View All`,
    trending: `Trending`,
    myHashtags: `My hashtags`,
    community: `Community`,
    posts: `My post`,
    postsFollowing: `Following`,
    postsTrending: `Trending`,
    myText: `My`,
    tradingHistory: `Trading History`,
    openPositions: `Open Positions`,
    portfolioName: `Name`,
    portfolioDateClosed: `Date Closed`,
    portfolioSTD: `STD LOTS`,
    portfolioOpenClose: `OPEN /CLOSE`,
    portfolioHigh: `HIGH`,
    portfolioLow: `LOW`,
    portfolioRoll: `ROLL`,
    portfolioProfit: `PROFIT`,
    portfolioTotal: `TOTAL`,
    portfolioTraderTicket: `Trader Ticket:`,
    portfolioBrokerTicket: `Broker Ticket:`,
    portfolioAmount: `Amount:`,
    portfolioPips: `pips`,
    portfolioTradesshow: `trades Show`,
    portfolioTrades: `trades`,
    portfolioCURRENCY: `CURRENCY`,
    portfolioTYPE: `TYPE`,
    portfolioDateOpen: `DATE OPEN`,
    portfolioOpen: `OPEN`,
    portfolioStop: `STOP`,
    portfolioLimit: `LIMIT`,
    portfolioCURRENT: `CURRENT`,
    text1: `tumblr`,
    text2: `oauth`,
    text3: `telegram`,
    text4: `line`,
    text5: `Copy`,
    text6: `SMS`,
    text7: `Feed`,
    text8: `Unfollow`,
    text9: `You cannot follow leaders from a public account`,
  },
  // Compare Leaders
  compare_leaders: {
    compareLeaders: `Compare Leaders`,
    addLeaderstoCompare: `Add Leaders to compare`,
    followers: `Followers`,
    following: `Following`,
    amountFollowing: `Amount Following`,
    profit: `Profit`,
    pipsText: `pips`,
    weeks: `Weeks`,
    investors: `Investors`,
    roi: `ROI`,
    trades: `Trades`,
    winningTrades: `Winning trades`,
    averagePipstrade: `Average pips/trade`,
    maximumDrawdown: `Maximum Drawdown`,
    maxOpenTrades: `Max Open Trades`,
    worstTrade: `Worst Trade`,
    pips: `pips`,
    bestTrade: `Best Trade`,
    necessaryMinimumEquity: `Necessary Minimum Equity`,
    viewed: `Viewed`,
    times: `times`,
    addTraders: `Add Leaders`,
    searchTrader: `Search Leader`,
    clicktoAdd: `Click on a leader to add in your compare list.`,
    ranking: `Ranking`,
    text1: `Add Leaders to compare`,
    text2: `pips`,
    text3: `$0`,
  },
  // Copy Strategy
  copy_strategy: {
    stillPending: `Your Onboarding is still Pending.`,
    copyTradingLimitReached: `CopyTrading Limit Reached.`,
    pleaseUpgrade: `Please upgrade your plan to Copy more leaders.`,
    noSubscriptionPlan: `No Subscription Plan found for this Trading Account`,
    totalAmountInvest: `This is the total amount you will invest to copy this Leader’s strategy. In any case, ZuluGuard will ensure that you will not risk more funds than the selected maximum amount by following this Leader.`,
    investmentAmount: `Investment Amount`,
    max: `Max`,
    zuluTradeAutomatically: `By default ZuluTrade automatically calculates the size of positions compared to the Leader. Use this field to override the default value with a fixed percentage.`,
    customCopyRatio: `Pro-Rata`,
    advancedSettings: `Advanced settings`,
    takeProfit: `Take Profit`,
    enterProfityouWant: `Enter the profit you want to secure. Once reached, ZuluTrade will automatically stop copying the Leader for you.`,
    selectingOption: `By selecting this option you will automatically stop copying the Leader upon investment loss from highest PNL point.`,
    trailingStopLoss: `Trailing Stop Loss`,
    copyOpenTrades: `Copy Open Trades`,
    stopStrategy: `Stop Strategy`,
    update: `Update`,
    purchaseNow: `Purchase Now`,
    takeSuitabilityTest: `Take Suitability Test`,
    copyStrategy: `Copy Strategy`,
    upgrade: `Upgrade`,
    youAreNowCopying: `You are now Copying`,
    continue: `Continue`,
    stop: `Stop`,
    strategySuccessfully: `Strategy successfully`,
    closeAllTradesfrom: `Close all trades from`,
    byChoosingNo: `By choosing no, trades will be closed when the leader closes them.`,
    yes: `Yes`,
    no: `No`,
    youareUpdated: `You are updated`,
    success: `success`,
    updateTitle: `Update`,
    copyTitle: `Copy`,
    changeAccount: `Change`,
    alt_title: "ZuluTrade",
    text1: `ZuluTrade`,
    text2: `Pro-Rata value must be greater than zero.`,
  },
  // Watchlist Page and Widget
  watchlist_widget: {
    watchlist: `Watchlist`,
    watchlistEmpty: `Your Watchlist is Empty`,
    AddMoreAssets: `Add more Assets`,
    leaders: `Leaders`,
    AddMoreLeaders: `Add more Leaders`,
    assets: `Assets`,
    Addmore: `Add more`,
    added: `Remove`,
    add: `Add`,
    noDataFound: `No data found`,
    AddToWatchlist: `Add to watchlist`,
    TraderName: `Trader Name`,
    alt_text: `watchlist`,
  },
  // View all Leaders
  view_all_leaders: {
    winningToday: `WINNING TODAY`,
    oneWeek: `1 WEEK`,
    oneMonth: `1 MONTH`,
    threeMonth: `3 MONTH`,
    sixMonth: `6 MONTH`,
    oneYear: `1 YEAR`,
    customViews: `Custom Views`,
    noCustomView: `No Custom View`,
    defaultViews: `Default Views`,
    winningtodayLower: `Winning today`,
    winningLastWeek: `Winning last Week`,
    winningLastMonth: `Winning last month`,
    winningLast3month: `Winning last 3 month`,
    winningLast6month: `Winning last 6 month`,
    winningLastYear: `Winning last year`,
    winning: `Winning`,
    tradingCryptos: `Trading Cryptos`,
    allSortbyZuluRank: `All/Sort by: ZuluRank`,
    roi: `ROI`,
    createaNewView: `Create a new view`,
    compare: `Compare`,
    following: `Following`,
    weeks: `Weeks`,
    investors: `Investors`,
    selectFewTrader: `Select a few Traders from the Performance below and compare them!`,
    strategyDescription: `Strategy description`,
    translate: `Translate`,
    theSumofTheInvested: `The sum of the invested funds of all Real Investors copying this Trader's signals`,
    amountFollowing: `Amount Following`,
    theNumberOfLive: `The number of Live and Demo users following this Trader.`,
    inverstor: `INVESTORS`,
    theNumberOfPips: `The number of pips earned by this Trader.`,
    minInvestment: `MIN INVESTMENT`,
    liveInvestmentProfit: `LIVE INVESTORS PROFIT (1M)`,
    follow: `Follow`,
    advancedSearch: `Advanced Search`,
    name: `Name`,
    namePlaceholder: `Write a name for your view...`,
    save: `Save`,
    add: `Add`,
    delete: `Delete`,
    clear: `Clear`,
    search: `Search`,
    timeframe: `Timeframe`,
    countries: `Countries`,
    countryText: `country`,
    countriesText: `countries`,
    selectCountry: `Select a Country`,
    searchCountry: `Search Country`,
    currencies: `Currencies`,
    anyCcurrencyPair: `Any currency pair`,
    currencyText: `currency`,
    currenciesText: `currencies`,
    sortBy: `Sort by`,
    ascending: `Ascending`,
    descending: `Descending`,
    areIinTheTop100: `are in the top 100`,
    haveApproved: `have approved photo and description`,
    tradedWithin: `traded within the last week`,
    haveBeenRated: `have been rated by Investors`,
    haveLiveUsers: `have live users subscribed`,
    haveStrategy: `have strategy description videos`,
    areNotTrading: `are not trading exotics`,
    arecharacterizedas: `Are characterized as:`,
    runningWeeks: `Running weeks`,
    value: `Value`,
    to: `To`,
    from: `From`,
    maxDD: `Max DD (pips)`,
    minInvestmentDollar: `Min Investment ($)`,
    avgPips: `Avg Pips`,
    maxDDPips: `Max DD %`,
    correlationPercent: `Correlation Percent`,
    win: `Win %`,
    pips: `Pips`,
    maxDDpips: `Max DD pips`,
    worstTradepips: `Worst Trade (pips)`,
    bestTradepips: `Best Trade (pips)`,
    maxOpenTrades: `Max Open Trades`,
    bestTrade: `Best Trade`,
    numberOfTrades: `Number of trades`,
    avgTradeTime: `Avg Trade Time`,
    worstTrade: `Worst Trade`,
    oneDay: `1 day`,
    sevenDay: `7 days`,
    oneMonthText: `1 month`,
    threeMonthText: `3 months`,
    sixMonths: `6 months`,
    oneYearText: `1 year`,
    overallText: `Overall`,
    investorsjson: `Investors`,
    pipsJson: `Pips`,
    tradesJson: `Trades`,
    avgPipsJson: `Avg Pips`,
    WinJson: `Win %`,
    MaxDDpipsJson: `Max DD pips`,
    weeksJson: `Weeks`,
    amountFollowingJson: `Amount Following`,
    liveInvestorsProfitJson: `Live Investors Profit`,
    zuluRankJson: `ZuluRank`,
    nameToast: `Provide a name for your view with at least 5 characters.`,
    selectView: `Select View`,
  },
  //=============Start Introducing ZuluTrade Page Content=================//
  introducing_zuluTrade: {
    introducing1: `Introducing `,
    introducing26: `ZuluTrade`,
    introducing2: `The Social Intelligence Platform.`,
    introducing3: `Invest smarter`,
    introducing4: `Go to Dashboard`,
    introducing5: `Join now`,
    introducing6: `ZuluTrade enables you to invest your capital and manage your wealth more efficiently. With the help of our large and diverse community, technology, and open ecosystem, we help you make informed investment decisions in a safe and trusted environment.`,
    introducing7: `New features`,
    introducing8: `Subscription Plan`,
    introducing9: `ZuluTrade now features a new and innovative subscription plan that eliminates platform fees based on transactions and volume. With our transparent subscription plan, you'll always know exactly what you're paying for. The cost is just $10 per month for each Leader Strategy, and you can enjoy discounts by choosing quarterly, semi-annual, or annual subscriptions. If you're using an Integrated Broker, you don't need to worry about subscribing to a plan. Existing users of  ZuluTrade can continue to access CopyTrade without charge.`,
    introducing10: `Become a Leader`,
    introducing11: `Join the ranks of successful traders on ZuluTrade by becoming a Leader. As long as you have a linked trading account, you can opt to make your account public and start earning Rewards for the signals others copy from you. Simply enter your Strategy Title & Description and get started.`,
    introducing12: `Integrated Broker`,
    introducing13: `ZuluTrade has made it easier than ever for traders to create an Integrated Broker account. With a few simple steps, you can now create your own Integrated Broker account without leaving the platform - allowing you to access more features and benefits. With this feature, you can quickly and securely manage your trading account from one place, giving you more control over your investments.`,
    introducing14: `Platform Agnostic`,
    introducing15: `ZuluTrade expands its options of available platforms. You can now link any MT4, MT5, ActTrader, X Open Hub broker account using our on-the-fly terminal connection. Simply enter your platform credentials, wait for a few seconds for the connection, and you are ready to go!`,
    introducing16: `Improved Manual Trade`,
    introducing17: `We've enhanced our Manual Trade functionality to give you more control. Now, you can easily switch inputs between units, lot size, or investment amount in your base currency (trade margin) and enter your Take Profit and Stop Loss with ease.`,
    introducing18: `Risk Score`,
    introducing19: `To help investors make informed decisions, we now display the Risk score for all Leaders. Risk is calculated on a 1-5 scale, with 1 indicating Low Risk and 5 indicating High Risk.`,
    introducing20: `Watchlist`,
    introducing21: `With our new Watchlist feature, you can easily keep track of your favorite Leaders and Market Assets. Simply click on the Star icon to add them to your Watchlist.`,
    introducing22: `Affiliates`,
    introducing23: `We're excited to announce the launch of our dedicated Affiliate space.`,
    introducing27: ` here`,
    introducing28: ` Affiliates can easily keep track of their tracked CPA users and stay up-to-date on their performance.`,
    introducing24: ` Photo & Video`,
    introducing25: `You can now upload photos and videos to the Social Feed, giving you even more ways to express yourself and connect with others on ZuluTrade.`,
  },
  alttag: {
    alt1: `Introducing Zulutrade`,
    title1: `The Community Intelligence platform. Invest smarter`,
    alt2: `Subscription Plan`,
    title2: `ZuluTrade now features a new and innovative..`,
    alt3: `Subscription`,
    title3: `Join the ranks of successful traders..`,
    alt4: `Integrated Broker`,
    title4: `ZuluTrade has made it easier than ever for traders..`,
    alt5: `Platform agnostic`,
    title5: `Platform agnostic`,
    alt6: `Improved Manual Trade`,
    title6: `We've enhanced our Manual Trade functionality..`,
    alt7: `Risk Score`,
    title7: `To help investors make informed decisions..`,
    alt8: `Watchlist`,
    title8: `With our new Watchlist feature`,
    alt9: `Affiliates`,
    title9: `We're excited to announce the launch ..`,
    alt10: `Photo & Video`,
    title10: `You can now upload photos and videos..`,
  },
  //=============Start Feedback Page Content=================//
  feedback: {
    feedbacktitle: `We value your opinion!`,
    feedbackcontent1: `Take a minute to tell us what you think about our service`,
    feedbackcontent2: `Name`,
    feedbackcontent3: `Email Address`,
    feedbackcontent4: `What do you like most about ZuluTrade?`,
    feedbackcontent5: `What would you wish to see changed / added?`,
    feedbackcontent6: `How likely would you be to recommend ZuluTrade to others?`,
    feedbackcontent7: `Not likely to recommend`,
    feedbackcontent8: `Extremely likely to recommend `,
    feedbackcontent9: `Submit feedback`,
  },
  feedback_alt: {
    feedalt1: `Sad`,
    feedtitle1: `Sad`,
    feedalt2: `Extremely likely to recommend`,
    feedalt3: `Not likely to recommend`,
    feedtitle2: `Name...`,
    feedtitle3: `Email Address...`,
    feedtitle4: `Write here your opinion...`,
  },
  //=============Start Sitemap Page Content=================//
  sitemap: {
    sitemaptitle: `ZuluTrade Site Map`,
    sitemapcontent1: `Features`,
    sitemapcontent2: `Login`,
    sitemapcontent3: `Register`,
    sitemapcontent4: `Forgot Password`,
    sitemapcontent5: `About`,
    sitemapcontent6: `Knowledge Crunch`,
    sitemapcontent7: `Trading Floor`,
    sitemapcontent8: `New Features`,
    sitemapcontent9: `Market View`,
    sitemapcontent12: `Introducing ZuluTrade`,
    sitemapcontent13: `Margin Calculator`,
    sitemapcontent15: `Profit Calculator`,
    sitemapcontent16: `Currency Converter`,
    sitemapcontent17: `Pip Calculator`,
    sitemapcontent18: `Help Center`,
    sitemapcontent19: `Pricing`,
    sitemapcontent20: `Markets`,
    sitemapcontent21: `Calendar`,
    sitemapcontent23: `Autoprotect`,
    sitemapcontent24: `Leaders`,
    sitemapcontent25: `Programs`,
    sitemapcontent26: `Affiliate Program`,
    sitemapcontent27: `Affiliate Guide`,
    sitemapcontent28: `Introducing Zulutrade`,
    sitemapcontent29: `Zuluguard Guide`,
    sitemapcontent30: `Trading Tools`,
    sitemapcontent31: `Leader Guide`,
    sitemapcontent32: `Leader Program`,
    sitemapcontent33: `Partnership Program`,
    sitemapcontent34: `Investor Guide`,
    sitemapcontent35: `Tool`,
    sitemapcontent36: `Simulation`,
    sitemapcontent37: `Compare Leaders`,
    sitemapcontent38: `Social Feed`,
    sitemapcontent40: `News & Media`,
    sitemapcontent41: `Help`,
    sitemapcontent42: `Privacy Policy`,
    sitemapcontent43: `Zulu Ranking`,
    sitemapcontent44: `Risk Disclaimer`,
    sitemapcontent45: `Investor Account`,
    sitemapcontent46: `Select Broker`,
    sitemapcontent47: `Social Trading`,
    sitemapcontent48: `Questions`,
    sitemapcontent49: `Terms of Service`,
    sitemapcontent50: `Interest Policy`,
    sitemapcontent51: `Consent Declaration`,
    sitemapcontent53: `Share`,
    sitemapcontent54: `Facebook`,
    sitemapcontent55: `Twitter`,
    sitemapcontent56: `Linkedin`,
    sitemapcontent57: `Youtube`,
    sitemapcontent58: `Instagram`,
    sitemapcontent59: `End-User License Agreement`,
    sitemapcontent60: `TikTok`,
    sitemapcontent61: `Reddit`,
    sitemapcontent62: `ZuluTrade Data`,
    sitemapcontent63: `Terms of Service EU`,
    sitemapcontent64: `Copy Trading`,
  },
  // Select Broker //
  broker: {
    Welcome: `Select Broker`,
    start_trading: `Choose your broker to start copytrading today!`,
    pick: `Pick a Broker to trade with`,
    platform: `PLATFORMS`,
    regulate: `REGULATED IN`,
    min_deposit: `MINIMUM DEPOSIT`,
    supp: `SUPPORTED CURRENCIES`,
    select: `Connect Trading Account`,
    can_not: ` Can’t find your broker?`,
    request: `Request to add`,
    register: `Please Register First`,
    need: `You need to register a new account to continue.`,
    create: `Create Account`,
    add_request: `Request to add new broker`,
    name: `Broker Name`,
    url: `Broker URL`,
    info: `Additional Info`,
    submit: `Submit`,
  },
  // Login Page //
  login: {
    zulu: `Zulutrade 2.0 is live! Learn more`,
    here: `here`,
    existing: `Existing users can visit the old platform`,
    title: `ZuluTrade Web Application`,
    mobile_app: `Continue with mobile app`,
    scan_qr: `Scan the OR Code to download the ZuluTrade mobile app on your mobile device`,
    ios: `iOS`,
    android: `Android`,
    log_in: `Log in`,
    or: `OR`,
    address: `Email Address`,
    email_placeholder: `Enter Email Address`,
    password: `Password`,
    password_placeholder: `Enter Password`,
    forgot: `Forgot your Password?`,
    button: `Login`,
    member: `Not a member yet?`,
    sign_up: `Sign up now!`,
    copy: `Copyright`,
    year: `©`,
    terms: `Terms of service`,
    privacy: `Privacy Policy`,
    risk: `Risk Disclaimer`,
    text1: `Logo`,
    text2: `zuluScan`,
    text3: `zuluScan Android And IOS`,
    text4: `For Mac`,
    text5: `Mac App Store`,
    text6: `For Android`,
    text7: `Play Store Android`,
    text8: `Footer Logo`,
    text9: `Switch theme mode Dark/Light`,
  },
  // Header Without Login //
  header: {
    zulu_trade: `ZuluTrade`,
    home: `Home`,
    leader: `Leaders`,
    market: `Markets`,
    tool: `Tools`,
    help: `Help Center`,
    guide: `Investor Guide`,
    leader_guide: `Leader Guide`,
    affiliate: `Affiliate Guide`,
    blog: `Blog`,
    check_out: `Check out the latest news from the markets and expand your knowledge from our blogs.`,
    trading: `Trading Tools`,
    use: `Make use of these tools to help with your investments.`,
    text1: `Simulation`,
    text2: `Calendar`,
    text3: `Calculator`,
    text4: `Pricing`,
    community: `Community`,
    about: `About`,
    about_us: `About Us`,
    about_title: "Have a look at what we’re about and how you can join us.",
    company: `Company`,
    media: `News and Media`,
    partner: `Partnerships`,
    partner_title: "Partner with us.",
    program1: `Affiliate Program`,
    program2: `Leader Program`,
    program3: `Broker Partnerships`,
    more: `More`,
    register: `Register`,
    mode_change: `Switch theme mode Dark/Light`,
    title1: `Check out the latest news`,
    title2: `help centre`,
    title3: `how you can join us`,
    title4: `Notifications`,
    title5: `Watchlist`,
    title6: `Switch theme mode Dark/Light`,
    title7: `Icon`,
    title8: `Trading Accounts`,
    title9: `Subscription plan`,
    title10: `My Posts`,
    title11: `Change Password`,
    title12: `Rewards`,
    title13: `Log out`,
    title14: `Blog`,
    title15: `Help`,
    title16: `About Us`,
    title17: `Partnerships`,
    title18: `ZuluTrade 1`,
    title19: `how you can join us`,
    title20: `Partnerships with us`,
    Knowledgebase: `Knowledge Base`,
    support: `Support`,
    TradingSignals: `Trading Signals`,
    ForexTrading: `Forex Trading`,
  },
  head: {
    text1: `ZuluTrade is live! Learn more`,
    text2: `Dashboard`,
    text3: `No new notifications`,
    text4: `View all notifications`,
    text5: `Live Account`,
    text6: `All Live Accounts`,
    text7: `Demo Account`,
    text8: `All Demo Accounts`,
    text9: `Personal Details`,
    text10: `Trading Accounts`,
    text11: `Subscription plan`,
    text12: `Posts`,
    text13: `Change Password`,
    text14: `Rewards`,
    text15: `Need help`,
    text16: `Log out`,
    text17: `Terms of service`,
    text18: `Privacy Policy`,
    text19: `Risk Disclaimer`,
    text20: `Zulutrade ©`,
    program3: `Broker Partnerships`,
    text21: `Logo`,
    text22: `ZuluTrade`,
    text23: `Help`,
    text24: `help centre`,
    text25: `Widgets`,
    text26: `Trade History`,
    text27: `Account Settings`,
  },
  zuluGuard_guide: {
    guidetitle: `What is`,
    guidetitle1: `ZuluGuard™ ?`,
    guidecontent1: `It’s simple –`,
    guidecontent1a: `ZuluGuard™ is your safety net`,
    guidecontent1b: ` when trading in ZuluTrade!`,
    guidecontent2: `It is there to protect your Equity by always monitoring your account, protecting your funds, and mitigate any potential losses.`,
    guidecontent3: ` Why would I need a safety net?`,
    guidecontent4: `Although most Leaders typically provide consistent strategies, even the best ones can get things wrong! If that happens, we want you to be able to protect your funds and mitigate potential losses – and ZuluGuard™ can offer you just that.`,
    guidecontent5: `ZuluGuard™ will automatically monitor your account and the performance of each Leader for you every second, even when you’re not online! If ZuluGuard™ detects any significant changes in a Leader’s performance, it will step in and`,
    guidecontent5a: `protect your account.`,
    guidecontent6: `All you need to do is set your own risk allowance, the exact parameters of `,
    guidecontent6a: `how much exposure you wish to allow in your account`,
    guidecontent6b: `prior to ZuluGuard™ taking action!`,
    guidecontent7: `How does ZuluGuard™ work?`,
    guidecontent8: `ZuluGuard™ allows you to set the funds you wish to invest for each Leader and will ensure that you will not risk more funds than the selected maximum amount per Leader.`,
    guidecontent9: `For example, if you set the Funds to invest at €2500, it means that the maximum amount of your initial capital that you can lose by following this specific Leader is €2500. In the advanced settings mode, you will find more options allowing you to set more advanced settings to protect your profits and capital!`,
    guidecontent10: ` ZuluGuard Take Profit`,
    guidecontent11: `Simply tell ZuluGuard the monetary amount you wish to secure! When this profit is reached, ZuluGuard™ will be triggered!`,
    guidecontent12: `For example: You set ZuluGuard Capital Protection at $1500 and Take Profit at $800. If the Leader has a total PnL (total PnL = realized + floating) that reaches your Take Profit value, ZuluGuard will step in, close all trades and disable the Leader, securing your profit of $800. Note: You will be able to re-enable the Leader at any moment by re-setting ZuluGuard in your account.`,
    guidecontent13: `ZuluGuard™ Capital Protection Mode`,
    guidecontent14: `Fixed:`,
    guidecontent14a: `If you set Capital Protection to $500, in case the Leader is losing $500 in your account, ZuluGuard™ will step in and close the trades of this Leader. Attention, in the fixed version, the Capital Protection is set as per the initial capital invested - not taking into consideration any future profits. If the Leader has closed trades that have a PnL of $2,000 in your account and his next trade has floating losses of -$500, the ZuluGuard™ will not step in. The ZuluGuard™ will only be activated at $-2500.`,
    guidecontent15: `Trailing`,
    guidecontent15a: `If you set Capital Protection to $500, in case the Leader is losing $500 in your account, ZuluGuard™ will step in. However, this mode will move up exactly like a trailing stop for the whole strategy. If the Leader has closed trades that have a PnL of $2,000 in your account and his next trade has floating losses of -$500, then ZuluGuard™ will step in, and secure profits of $1,500!`,
    guidecontent16: `Capital Protection Notification`,
    guidecontent17: `Get notified when your Used Capital (realized plus floating PnL) reaches a specified percentage of the Capital Protection amount you have set for this Leader.`,
    guidecontent18: `Example: You set ZuluGuard Capital protection at $1500 and you set the notification option at 50%. If the used capital for your Leader reaches 50% (- $750) of the Capital Protection amount you have set ($1500), you will receive an email inviting you to monitor closely your account and ZuluGuard Settings for this Leader.`,
    guidecontent19: `So how exactly ZuluGuard™ `,
    guidecontent19a: `protects my account?`,
    guidecontent20: `Once ZuluGuard™ detects that your allowances have been exceeded, it `,
    guidecontent20a: `takes action:`,
    guidecontent21: `Close all Leader's trades`,
    guidecontent22: `ZuluGuard™ closes all the currently open trades of the Leader in your account.`,
    guidecontent23: `Disable the Leader`,
    guidecontent24: `ZuluGuard™ disables the Leader so that he cannot open any new trades in your account.`,
    guidecontent25: `Sounds useful! How do I set ZuluGuard™?`,
    guidecontent26: `Setting up ZuluGuard™ is very easy!`,
    guidecontent27: `You can enable and customize ZuluGuard™ from your`,
    guidecontent27a: `Settings tab.`,
    guidecontent28: `Simply click on the shield and review/amend your ZuluGuard™ settings for each of your Leaders.`,
    guidecontent29: `The gray ZuluGuard™ shield indicates you have not set any ZuluGuard™ protection for this Leader yet. The orange ZuluGuard™ shield indicates you are protected by ZuluGuard™!`,
    guidecontent30: `* Important Disclaimer: In volatile, or fast moving market conditions, ZuluGuard closing signals will be filled at the prevailing market price, which may be vastly different from the desired price. Due to this, there may be substantial losses, as ZuluGuard does not always guarantee the desired Protection.`,
    guidecontent31: `This option allows you to set a fixed or trailing capital loss stop.`,
    guidecontent32: `ZuluGuard™ allows`,
    guidecontent33: `How does ZuluGuard™ work?`,
    guidecontent34: `specific Leader`,
    guidecontent35: `Setting`,
    guidecontent36: `You can enable and customize ZuluGuard™`,
  },
  //=============Start privacy policy  Page Content=================//
  privacy_policy: {
    privacytitle: `Privacy Policy`,
    privacycontent1: `Personal Information`,
    privacycontent2: `When you apply for or maintain a ZuluTrade Demo or Live account, we collect personal information about you for business purposes; to evaluate your financial needs, to process your requests and transactions, to inform you about products and services that may be of interest to you, and to provide client service. You may choose what you receive from us at any point by accessing the notification option in your account. Such information may include:`,
    privacycontent3: `Application Information - Information that you provide to us on applications and other forms: your name, address, birth date, social security number, occupation, assets, and income.`,
    privacycontent4: `Transaction Information - Information about your transactions with us or our affiliates, as well as information regarding our communications with you. Examples: your account balances, trading activity, your inquiries and our responses.`,
    privacycontent5: `Verification Information - Information necessary to verify your identity such as a passport or drivers license. Examples: background information about you we receive from public records or from other entities not affiliated with ZuluTrade. The USA Patriot Actrequires us to collect information and take necessary actions to confirm your identity.`,
    privacycontent6: `Updated Privacy Policy compliant with the `,
    privacycontent6a: `General Data Protection Regulation (GDPR).`,
    privacycontent7: `Security Technology`,
    privacycontent8: `ZuluTrade uses Secure Socket Layer(SSL) encryption technology to protect the information that you submit. This technology protects Users from having their information intercepted by anyone other than ZuluTrade during transmission. We work hard to ensure that our websites are secure and that they meet industry standards. We also use additional safeguards: firewalls, authentication systems (e.g., passwords and personal identification numbers) and access control mechanisms to control unauthorized access to systems and data.`,
    privacycontent9: `Sharing Information With Our Affiliates`,
    privacycontent10: `As permitted by applicable law, we may share the personal information described above with our affiliates for business purposes; such as servicing client accounts and informing clients about new products and services. Our affiliates may include companies controlled or owned by ZuluTrade, as well as companies which have an ownership interest in our company. The information we share with affiliates may include any of the information described above: your name, address, account information, etc. Our affiliates maintain the privacy of your information to the same extent that ZuluTrade does in accordance with this Policy.`,
    privacycontent11: `Sharing Information With Third-Parties`,
    privacycontent12: `With the exception of the purposes described in this Policy, ZuluTrade does not disclose personal information to third-parties. Third-party disclosures may include: sharing such information with non-affiliated companies that perform support services for your account or facilitate your transactions with ZuluTrade; including those that provide professional, legal, or accounting advice to ZuluTrade. Non-affiliated companies that assist ZuluTrade in providing services to you are required to maintain the confidentiality of such information to the extent they receive it and to use your personal information only in the course of providing such services and only for the purposes which ZuluTrade dictates. We may also disclose your personal information to third-parties in order to fulfill your instructions or pursuant to your express consent. ZuluTrade will not sell your personal information.`,
    privacycontent13: `Regulatory Disclosure`,
    privacycontent14: `Under limited circumstances, ZuluTrade may disclose your personal information to third-parties as permitted by, or to comply with, applicable laws and regulations. ZuluTrade may disclose personal information to cooperate with regulatory authorities, law enforcement agencies, to comply with subpoenas or other official requests, and as necessary to protect ZuluTrade’s rights or property. Except as described in this Privacy Policy, your personal information will not be used for any other purpose unless we explicitly describe in what manner such information will be used at the time you disclose it to us or we obtain your permission.`,
    privacycontent15: `Cookies`,
    privacycontent15a: `Cookies Policy`,
    privacycontent16: `Intro`,
    privacycontent17: `When you use our Website, ZuluTrade will use cookies to distinguish you from other users of our Website. This helps us provide you with a more relevant and effective experience, including presenting the Website according to your needs and preferences.`,
    privacycontent18: `This Cookie Policy provides you with comprehensive information about the cookies we use and the way in which we use them. You should also read our Privacy Policy in conjunction with this Policy.`,
    privacycontent19: `What is a cookie?`,
    privacycontent20: `Cookies are small files of information that often include a unique identification number or value, which are stored on your computer’s hard drive as a result of you using our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies as soon as you visit our Website.`,
    privacycontent21: `Cookies are frequently used on many websites on the internet and you can choose if and how a cookie will be accepted by changing your preferences and options in your browser. Some of our business partners (e.g. advertisers) use cookies on our Website. We have no access to, or control over, these cookies.`,
    privacycontent22: `The cookies do not contain personally identifying information nor are they used to identify you. You may choose to disable the cookies. However, you will not be able to access several parts of our Website if you choose to disable the cookie acceptance on your browser.`,
    privacycontent23: ` How to delete and block cookies`,
    privacycontent24: `You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website. For further information about disabling cookies, please refer to`,
    privacycontent25: `Your consent`,
    privacycontent26: `By continuing to use our Website, you are agreeing to our placing cookies on your computer in order to analyze the way you use our Website. If you do not wish to accept cookies in connection with your use of this Website, you must stop using our Website.`,
    privacycontent27: `The way in which we use cookies Session Cookies`,
    privacycontent28: `We use session cookies for the following purposes:`,
    privacycontent29: `To allow you to carry information across pages of our site and avoid having to re-enter information.`,
    privacycontent30: `Within registration to allow you to access stored information.`,
    privacycontent31: `Cookies Policy`,
    privacycontent32: `Persistent Cookies We use persistent cookies for the following purposes: To help us recognize you as a unique visitor (just a number) when you return to our website and to allow us to tailor content or advertisements to match your preferred interests or to avoid showing you the same adverts repeatedly. To compile anonymous, aggregated statistics that allow us to understand how users use our site and to help us improve the structure of our Website. To internally identify you by account name, name, email address, customer ID, currency and location (geographic and computer ID/IP address). Within research surveys to ensure you are not invited to complete a questionnaire too often or after you have already done so. Third Party Cookies Third parties serve cookies via this site. These are used for the following purposes:We use persistent cookies for the following purposes: To serve advertisements on our site and track whether these advertisements are clicked on by users. To control how often you are shown a particular advertisement. To tailor content to your preferences. To count the number of anonymous users of our site. For website usage analysis`,
    privacycontent33: `Use of Web Beacons`,
    privacycontent34: `Some of our Web pages may contain electronic images known as Web beacons (sometimes known as clear gifs) that allow us to count users who have visited these pages. Web beacons collect only limited information which including a cookie number, time and date of a page view, and a description of the page on which the Web beacon resides. We may also carry web beacons placed by third party advertisers. These beacons do not carry any personally identifiable information and are only used to track the effectiveness of a particular campaign.`,
    privacycontent35: `If you wish to know more about cookies please consult the help menu on your web browser or visit independent information providers such as`,
    privacycontent36: `If you have any questions regarding our privacy or security measures, please email `,
    privacycontent36a: `support@zulutrade.com`,
    privacycontent37: `Opt Out`,
    privacycontent38: `You are not required to supply any of the personal information that we may request. However, failure to do so may result in our being unable to open or maintain your account or to provide services to you. While we make every effort to ensure that all information we hold about you is accurate, complete and up to date, you can help us considerably in this regard by promptly notifying us if there are any changes to your personal information. If you do not wish to have your personal information disclosed to third parties as described in this Policy, please contact us via`,
    privacycontent38a: `e-mail.`,
  },
  //=============Start Personal Detail Page Content=================//
  personal_detail: {
    detailtitle: `Personal information`,
    placeolderName: `First Name`,
    placeolderLast: `Last Name`,
    placeolderEmail: `Email Address`,
    placeolderOtp: `OTP`,
    placeolderDisplay: `Display Name`,
    detailcontent1: `Select Residence Country`,
    detailcontent2: `Residence Country`,
    detailcontent3: `Loading...`,
    detailcontent4: `VERIFIED`,
    detailcontent5: `Verify`,
    detailcontent6: `Update`,
    detailcontent7: `Public Profile`,
    detailcontent8: `ZuluTrade does not accept any photos that contain:`,
    detailcontent9: `Hostile or offensive content (such as images of nudity or adult material, violence or victims of violence, racial discrimination etc.)`,
    detailcontent10: `Advertising content (registered logos/images, trademarks, branded material, etc.)`,
    detailcontent11: `Direct or indirect reference to public figures (e.g. celebrities, politicians, business or religious leaders etc.)`,
    detailcontent12: `Please follow the Guidelines`,
    detailcontent13: `Country`,
    detailcontent14: `Get Code`,
    detailcontent15: `Resend Code`,
    detailcontent16: `Verify`,
    detailcontent17: `Upload Your Profile Picture`,
    detailcontent18: `Search here..`,
    updateToast: `Your Personal Information has updated`,
    mobile_otp_error: `The OTP entered is incorrect or invalid. Enter the correct OTP or click 'Resend Code' to generate a new OTP`,
    extensionMsg: `Please only upload files with extensions .jpeg, .jpg, .png, or .heic.`,
  },
  //=============Start Trading Account Page Content=================//
  trading_accounts: {
    trading_accountstitle: `Trading Accounts`,
    placeHolder1: `Platform`,
    placeHolder2: `Server/IP`,
    placeHolder3: `Username`,
    placeHolder4: `Password`,
    placeHolder5: `Strategy title`,
    trading_accountscontent1: `Add Trading Account`,
    trading_accountscontent2: `Leader Account`,
    trading_accountscontent3: `Demo`,
    trading_accountscontent4: `Withdraw`,
    trading_accountscontent5: `Update settings`,
    trading_accountscontent6: `Loading...`,
    trading_accountscontent7: `Update settings`,
    trading_accountscontent8: `Become a Leader`,
    trading_accountscontent9: `Strategy description`,
    trading_accountscontent10: ` Are you sure that you want to disable your Demo Leader strategy? Please note that this action is IRREVERSIBLE as Demo Leader Strategies are only available to legacy zulutrade accounts.`,
    trading_accountscontent11: `Close`,
    trading_accountscontent12: `Yes`,
    trading_accountscontent13: `Capital Wallet`,
    trading_accountscontent14: `Deposit`,
    trading_accountscontent15: `Withdraw`,
    text1: `Do you want to switch your account and become a Leader?`,
    text2: `1. You are currently following`,
    text3: `leader strategies. If you continue and become a Leader, the strategies you follow will be canceled.`,
    text4: `If you continue and become a Leader, your subscription will be canceled.`,
    text5: `I accept`,
    text6: `Continue`,
    text7: `2. You have an active subscription`,
    text8: `leader strategies/strategy.`,
    text9: `Month's`,
    text10: `By checking  “I Accept” you agree to cancel your`,
    text11: `active subscription plan,`,
    text12: `copy trades, and leader strategies under this trading account.`,
    text13: `plan`,
    text14: `Import Trade History`,
    text15: `Import History`,
    text16: `Import History Request has been submittes Successfully.`,
    text17: `Disclaimer: The DEMO account terms may vary from the REAL account one's!`,
  },
  //=============Start subscription Plan Page Content=================//
  subscription_plan: {
    subscriptiontitle: `Subscription Plans`,
    subscriptioncontent1: `Unlimited Leader copytrade`,
    subscriptioncontent2: `Congratulations!`,
    subscriptioncontent2a: `As a valued ZuluTrade user, you've been granted access to our`,
    subscriptioncontent2b: `'Free Subscription Plan'.`,
    subscriptioncontent3: `With this plan, you can effortlessly copy`,
    subscriptioncontent3a: `unlimited strategies`,
    subscriptioncontent3b: `across all your linked accounts and take advantage of our enhanced`,
    subscriptioncontent3c: `social intelligence features.`,

    subscriptioncontent4: `Make the most of this exclusive opportunity while it's available!`,
    subscriptioncontent5: `Copy Trading Now!`,
    subscriptioncontent6: ` Subscription Plan Settings`,
    subscriptioncontent7: `Leader copytrade`,
    subscriptioncontent8: ` month plan`,
    subscriptioncontent9: `Your subscription will auto-renew on `,
    subscriptioncontent10: `Edit`,
    subscriptioncontent11: `Cancel`,
    subscriptioncontent12: `Leader copytrade`,
    subscriptioncontent13: `month plan`,
    subscriptioncontent14: `Your subscription will auto-renew on`,
    subscriptioncontent15: `Recurring Payment is Cancelled. Plan will remain Active until`,
    subscriptioncontent16: `Payment Method`,
    subscriptioncontent17: `Back`,
    subscriptioncontent18: ` Choose a Subscription Plan`,
    subscriptioncontent19: `Choose your desired monthly copytrading and payment auto-renewal period.`,
    subscriptioncontent20: `Leaders you want to Copytrade`,
    subscriptioncontent21: `Plan auto-renewal`,
    subscriptioncontent22: `months`,
    subscriptioncontent23: `/Per Copy`,
    subscriptioncontent24: `Save`,
    subscriptioncontent25: `Continue`,
    subscriptioncontent26: `Your subscription plan's billing cycle has been updated!`,
    subscriptioncontent27: `Starting on your next billing date of`,
    subscriptioncontent28: `your payment method will be charged`,
    subscriptioncontent29: `and it will recur every`,
    subscriptioncontent30: `months.`,
    subscriptioncontent31: `Your subscription plan's billing cycle has been updated!`,
    subscriptioncontent32: `Starting on your next billing date of`,
    subscriptioncontent33: `your payment method will be charged`,
    subscriptioncontent34: `and it will recur every`,
    subscriptioncontent35: `months.`,
    subscriptioncontent36: `You have changed your subscription plan to`,
    subscriptioncontent37: `strategies, which will get billed and take effect on`,
    subscriptioncontent38: `and from there on your payment method will be charged every`,
    subscriptioncontent39: `months. Until then you can still enjoy your`,
    subscriptioncontent40: `strategies.`,
    subscriptioncontent41: `Months`,
    subscriptioncontent42: `Leaders`,
    subscriptioncontent43: `/per slot`,
    subscriptioncontent44: `Discount applied`,
    subscriptioncontent45: `Subtotal`,
    subscriptioncontent46: `Today’s total`,
    subscriptioncontent47: `Includes first payment and applicable taxes based on your billing address.`,
    subscriptioncontent48: `Continue`,
    subscriptioncontent49: `Back`,
    subscriptioncontent50: `Cancel Current Plan`,
    subscriptioncontent51: ` Are you sure you want to cancel your subscription ?`,
    subscriptioncontent52: `Cancel Subscription`,
    subscriptioncontent53: `Free`,
    subscriptioncontent54: `days remaining`,
    subscriptioncontent55: `No expiration date`,
    subscriptioncontent56: `Payment Method`,
    subscriptioncontent57: `Payment Summary`,
    subscriptioncontent58: `Copy`,
    subscriptioncontent59: `Default`,
    subscriptioncontent60: `Pay`,
    subscriptioncontent61: `Expire on`,
    subscriptioncontent62: `Card Holder Name`,
    subscriptioncontent63: `Upgrade`,
    subscriptioncontent64: `Choose a Plan`,
    subscriptioncontent65: `Transaction History`,
    subscriptioncontent66: `Enter numbers only`,
    text1: `This is a Leader account. Your subscription plan for this trading account is no longer available.`,
    text2: `Add Trading Account`,
    text3: `This is a Leader account. Your subscription plan for this trading account is no longer available.`,
    text4: `No payment method selected, please select subscription plan again.`,
    text5: `Your payment is under process`,
  },
  //=============Start Change Password Content=================//
  change_password: {
    change_passwordtitle: `Change password`,
    change_passwordcontent1: `Password strength `,
    change_passwordcontent2: ` 1 uppercase character`,
    change_passwordcontent3: `8 or more characters`,
    change_passwordcontent4: `at least 1 number`,
    change_passwordcontent5: `at least 1 letter`,
    change_passwordcontent6: `at least 1 special character`,
    change_passwordcontent7: `Change Password`,
    change_passwordcontent8: `Retype password`,
    change_passwordcontent9: `New password`,
    change_passwordcontent10: `Create a new password for your account`,
  },
  //=============Start Rewards Content=================//
  rewards: {
    rewardstitle: `Earn with Zulutrade`,
    rewardscontent1: ` Total number of copiers`,
    rewardscontent2: `Rewards not yet withdrawn`,
    rewardscontent3: `Withdraw`,
    rewardscontent4: ` Withdrawal History`,
    rewardscontent5: ` Rewards Breakdown`,
    rewardscontent6: ` Attract Investors`,
    rewardscontent7: `earn additional income.`,
    rewardscontent8: `Go to Affiliate Portal`,
    rewardscontent9: `Back`,
    rewardscontent10: `Withdrawal History`,
    rewardscontent11: ` Select dates`,
    rewardscontent12: `Withdrawal`,
    rewardscontent13: `Cancel`,
    rewardscontent14: `Rewards Breakdown`,
    rewardscontent15: `Withdraw funds`,
    rewardscontent16: `Crypto Wallet`,
    rewardscontent17: `Wallet ID (USDT-ERC20)`,
    rewardscontent18: `Wallet ID Invalid`,
    rewardscontent19: `Send funds`,
    rewardscontent20: `Paypal`,
    rewardscontent21: `Email address`,
    rewardscontent22: `Send funds`,
    rewardscontent23: `Bank Transfer`,
    rewardscontent24: `Select bank account`,
    rewardscontent25: `View details`,
    rewardscontent26: `Beneficiary Name:`,
    rewardscontent27: ` Bank Country:`,
    rewardscontent28: `Bank Name:`,
    rewardscontent29: `IBAN:`,
    rewardscontent30: `Add Bank account`,
    rewardscontent31: `Cancel Withdrawal Request`,
    rewardscontent32: `Are you sure you want to cancel your withdrawal request ?`,
  },
  copyTrade: {
    copyTradecontent1: `Remove`,
    copyTradecontent2: `Update`,
    copyTradecontent3: `Disable Zuluguard`,
    copyTradecontent4: `Invested`,
    copyTradecontent5: `ZuluGuard Activated`,
    copyTradecontent6: `The Leader has been disabled due to excesssive losses.`,
    copyTradecontent7: `Learn more`,
    copyTradecontent8: `Take Profit reached`,
    copyTradecontent9: `Your desired profit value has been reached and hence all open positions were closed.`,
    copyTradecontent10: `Learn more`,
    copyTradecontent11: `Go to our`,
    copyTradecontent41: `Leaders`,
    copyTradecontent42: `page to find your first leader to copy`,
    copyTradecontent12: `The graph will be displayed one day after...`,
    copyTradecontent13: `Statistics`,
    copyTradecontent14: `Equity in `,
    copyTradecontent15: `The Leader has been disabled due to excesssive losses.`,
    copyTradecontent16: `Your desired profit value has been reached and hence all open positions were closed.`,
    copyTradecontent17: `ZuluGuard`,
    copyTradecontent18: `Nodata`,
    copyTradecontent19: `Trader Name`,
    copyTradecontent20: `ZuluGuard`,
    copyTradecontent21: `Trader Name`,
    copyTradecontent22: `Graph Demo`,
    copyTradecontent23: `CopyTrade`,
    rewardscontent33: `Withdrawal History`,
    rewardscontent34: `Rewards Breakdown`,
    rewardscontent35: `Bank Withdrawal`,
    rewardscontent36: `Crypto Withdrawal`,
    rewardscontent37: `payPal Withdrawal`,
    rewardscontent38: `Crypto Wallet`,
    rewardscontent39: `payPal Wallet`,
    rewardscontent40: `Bank Transfer`,
    text1: `Pro-Rata:`,
  },
  leaderMarket: {
    leaderMarketcontent1: `Popular`,
    leaderMarketcontent2: `Gainers`,
    leaderMarketcontent3: `Losers`,
    leaderMarketcontent4: `Watchlist`,
    leaderMarketcontent5: `watchlist`,
  },
  stats: {
    statscontent1: `Strategy description`,
    statscontent2: `Other Broker`,
    statscontent3: `The Trader is using a Real Account`,
    statscontent4: `The Trader is using a Real Account`,
    statscontent5: `MetaTrader 4 Expert Advisor`,
    statscontent6: ` MetaTrader 4 Expert Advisor`,
    statscontent7: `TradeIcon`,
    statscontent8: `The Trader has fully verified his account!`,
    statscontent9: `Mobile Phone Number`,
    statscontent10: `Mobile Phone Number`,
    statscontent11: `Mobile Phone Number -`,
    statscontent211: `Verified`,
    statscontent12: `Proof of Address`,
    statscontent13: `Proof of Address`,
    statscontent14: `Proof of Address - Verified`,
    statscontent212: `Verified`,
    statscontent15: `Identification Document`,
    statscontent16: `Identification Document -`,
    statscontent213: `Verified`,
    statscontent17: `TradeIcon`,
    statscontent18: `The Trader has partially verified his account`,
    statscontent19: `Mobile Phone Number`,
    statscontent20: ` Mobile Phone Number -`,
    statscontent21: `Proof of Address`,
    statscontent22: `The Trader is trading correlated currency pairs, by using a natural hedging strategy that potentially lead to limited drawdown`,
    statscontent23: `The Trader is frequently trading economic calendar releases!`,
    statscontent24: ` Zulu Veteran - a Trader that has been trading with ZuluTrade for more than 2 years with a consistently high ranking`,
    statscontent25: `This trader does not have an approved description yet.`,
    statscontent26: `Inactive Trader detected: This trader has no trading activity for the last 4 weeks.`,
    statscontent27: `Inactivity detected!`,
    statscontent28: ` ROI`,
    statscontent29: `RISK`,
    statscontent30: ` 0%`,
    statscontent31: ` WIN RATE`,
    statscontent32: ` 0`,
    statscontent33: `COPIERS`,
    statscontent34: `Performance`,
    statscontent35: `Trading`,
    statscontent36: `DrawDown`,
    statscontent37: `Slippage`,
    statscontent38: `Pips`,
    statscontent39: `STATISTICS - OVERALL`,
    statscontent40: `PROFIT`,
    statscontent41: `The total number of trades closed.`,
    statscontent42: `TRADES`,
    statscontent43: `This is the maximum number of trades the Trader has opened simultaneously since the moment he/she joined ZuluTrade. Note that if a Trader opens a greater number of trades than usually at one time, this statistic will be updated accordingly. The value is updated when the trades get closed (pending orders are not counted in this statistic, only open positions).`,
    statscontent44: `MAX OPEN TRADES`,
    statscontent45: `Average Rate of Return per trade`,
    statscontent46: `AVG PIPS`,
    statscontent47: `Average Rate of Return per trade`,
    statscontent48: `AVG PROFIT %`,
    statscontent49: `The percentage of winning trades. Does not include profits or losses on open positions.`,
    statscontent50: `WINNING TRADES`,
    statscontent51: `AUD/CAD: 80.1% (458 trades) AUD/NZD: 76.4% (509 trades) NZD/CAD: 78% (481 trades)`,
    statscontent52: `Recommended Minimum Investment for optimal CopyTrading based on Leaders settings and historical data for this timeframe. The closer your investment is to this amount, the more positions will open while CopyTrading.`,
    statscontent53: `Recommended Min Investment`,
    statscontent54: `The greatest difference between the highest and the lowest point reached during the selected period`,
    statscontent55: `MAXIMUM DRAWDOWN`,
    statscontent56: `pips`,
    statscontent57: ` AVG TRADE/week`,
    statscontent60: `The average trading time per trade.`,
  },
  upgradeHeader: {
    upgradeHeadercontent1: `Complete Onboarding Process`,
    upgradeHeadercontent2: `Your Onboarding is still Pending.`,
    upgradeHeadercontent3: `Upgrade`,
    upgradeHeadercontent4: `Zulu Suitability Test`,
    upgradeHeadercontent5: `Complete to begin CopyTrading`,
    upgradeHeadercontent6: `Integrated Broker`,
    upgradeHeadercontent7: `Continue with your account`,
    upgradeHeadercontent8: `Continue`,
    upgradeHeadercontent9: `Live Account`,
    upgradeHeadercontent10: `Connect a Live Account`,
  },
  LanguageHead: {
    languageHeadcontent1: `Language`,
    languageHeadcontent2: `Choose your preferred displayed language.`,
    languageHeadcontent3: `Greek`,
    languageHeadcontent4: `You may want to choose`,
    languageHeadcontent5: `Afghanistan`,
    languageDashcontent6: `Back`,
  },
  paymentMethod: {
    paymentMethodcontent1: `Payment Method`,
    paymentMethodcontent2: `Saved Card`,
    paymentMethodcontent3: `Visa`,
    paymentMethodcontent4: `No saved card`,
    paymentMethodcontent5: `Upgrade`,
    paymentMethodcontent6: `Add New Card`,
    paymentMethodcontent7: `PAN`,
    paymentMethodcontent8: `Card`,
    paymentMethodcontent9: `Error`,
    paymentMethodcontent10: `Expiry date`,
    paymentMethodcontent11: `CVV`,
    paymentMethodcontent12: `I have read and I agree with the privacy policy of the provided service, the cancellation policy and the terms of use of ZuluTrade.`,
    paymentMethodcontent13: `Pay`,
    paymentMethodcontent14: `Add Card`,
    paymentMethodcontent15: `Powered by`,
    paymentMethodcontent16: `Ecommpay`,
    paymentMethodcontent17: `Summary`,
    paymentMethodcontent18: `You’ve chosen the `,
    paymentMethodcontent19: `Leader strategies for `,
    paymentMethodcontent20: `months from`,
    paymentMethodcontent21: `This is a recurring payment. You can cancel anytime.`,
    paymentMethodcontent22: `Payment Method`,
    paymentMethodcontent23: `Overall payment`,
    paymentMethodcontent24: `Remove Card`,
    paymentMethodcontent25: `Are you sure you want to remove this card ?`,
    paymentMethodcontent26: `No`,
    paymentMethodcontent27: `Remove`,
    paymentMethodcontent28: `Default Card`,
    paymentMethodcontent29: `Remove Card`,
    pm_1: `Your credit card will be automatically billed with $`,
    pm_2: `on`,
    pm_3: `and further notifications will be received via email from merchant's email.`,
  },
  bankAccount: {
    bankAccountcontent1: `Bank Account`,
    bankAccountcontent2: `Beneficiary Name:`,
    bankAccountcontent3: `IBAN or Account Number:`,
    bankAccountcontent4: `SWIFT or ABA or BIC:`,
    bankAccountcontent5: `Bank Name:`,
    bankAccountcontent6: `Select Residence Country`,
    bankAccountcontent7: `Select Country`,
    bankAccountcontent8: `Close`,
    bankAccountcontent9: `edit Bank`,
    bankAccountcontent10: `Add Bank`,
    bankAccountcontent11: `Edit`,
    bankAccountcontent12: `Add`,
  },
  setting_account: {
    settingAccountcontent1: `Profile settings`,
    settingAccountcontent2: `Light`,
    settingAccountcontent3: `Language`,
    settingAccountcontent4: `English`,
    settingAccountcontent5: `Timezone`,
    settingAccountcontent6: `USD`,
    settingAccountcontent7: `Notifications`,
    settingAccountcontent8: `Security`,
    settingAccountcontent9: `Trading Settings`,
    settingAccountcontent10: `Theme`,
  },
  notificationAlert: {
    notificationAlertcontent1: `Back`,
    notificationAlertcontent2: `Notifications`,
    notificationAlertcontent3: `Social notifications`,
    notificationAlertcontent4: `Mentions`,
    notificationAlertcontent5: `Email`,
    notificationAlertcontent6: `Push`,
    notificationAlertcontent7: `New Comment/Post Reply`,
    notificationAlertcontent8: `Reaction on my posts/comments`,
    notificationAlertcontent9: `Direct Message`,
    notificationAlertcontent10: `Trading`,
    notificationAlertcontent11: `Copy-Trade New Position`,
    notificationAlertcontent12: `Email`,
    notificationAlertcontent13: `Push`,
    notificationAlertcontent14: `Copy-Trade Closed by Leader`,
    notificationAlertcontent15: ` Copy-portfolio Take Profit Close`,
    notificationAlertcontent16: `Strategy removed from you portfolio`,
    notificationAlertcontent17: `Copy-portfolio Stop Loss Close`,
    notificationAlertcontent18: `Significant Strategy Drawdown`,
    notificationAlertcontent19: `Strategy performance decreased`,
    notificationAlertcontent20: ` Strategy trade-pattern changed`,
  },
  // Redirect after payment
  pages_redirect: {
    text1: `Oops! Payment Failed`,
    text2: `Payment Method`,
    text3: `Retry`,
  },
  // Success after payment
  pages_success: {
    text1: `You are`,
    text2: `all set!`,
    text3: `Your account is now ready for Copytrading with real money.`,
    text4: `Start Trading!`,
    text5: `Order placed Successfully`,
  },
  // Fail after payment
  pages_failure: {
    text1: `Transaction Failed`,
    text2: `Fail`,
    text3: `Try Again`,
  },
  // Profile Menus
  dashboard: {
    text1: `Personal Details`,
    text2: `Trading Accounts`,
    text3: `Subscription Plan`,
    text4: `My`,
    text5: `Posts`,
    text6: `Change Password`,
    text7: `Need Help?`,
  },
  // Dashhboard Trades
  dashboard_trades: {
    text1: `Trades`,
    text2: `Open`,
    text3: `Pending`,
    text4: `History`,
    text5: `Unrealized PNL`,
    text6: `Name`,
    text7: `Owner`,
    text8: `Lots`,
    text9: `Unit`,
    text10: `Open`,
    text11: `Current`,
    text12: `PNL`,
    text13: `Close Trade`,
    text14: `Trade Detail`,
    text15: `Update SL/TP`,
    text16: `No open orders, go to`,
    text17: `Markets`,
    text18: `to start trading`,
    text19: `Name`,
    text20: `Owner`,
    text21: `Amount`,
    text22: `Entry Rate`,
    text23: `Interest`,
    text24: `Cancel Trade`,
    text25: `No pending orders`,
    text26: `Name`,
    text28: `Owner`,
    text29: `PNL`,
    text30: `Date Open`,
    text31: `Date Closed`,
    text32: `No orders history`,
    text33: `Currency`,
    text34: `Type`,
    text35: `Trade Owner`,
    text36: `Open Date/Time`,
    text37: `Profit`,
    text38: `Units`,
    text39: `ROI`,
    text40: `Stop Loss`,
    text41: `Take Profit`,
    text42: `Update`,
    text43: `Opening Rate`,
    text44: `Current Rate`,
    text45: `Interest`,
    text46: `Commission`,
    text47: `Broker Ticket`,
    text48: `Trade log`,
    text49: `Stop Loss at price`,
    text50: `Take profit at price`,
    text51: `Market closed`,
    text52: `Update`,
    text53: `Are you sure you want to close`,
    text54: `all Manual positions`,
    text55: `all Copytrade positions`,
    text56: `No`,
    text57: `Close`,
    text58: `Realized PNL`,
  },
  // Need Help
  need_help: {
    text1: `How Can We Help?`,
    text2: `Live chat`,
    text3: `You can chat with our Support in all languages`,
    text4: `Investor Guide`,
    text5: `Read the full guide for your Investor Account`,
    text6: `Trader Guide`,
    text7: `Read the full guide for your Leader Account`,
    text8: `Leader Guide`,
    text9: `Read the full guide for your Leader Account`,
    text10: `Affiliate Guide`,
    text11: `Read the full guide for your Affiliate Account`,
    text12: `Read the full guide for your Trader Account`,
    text13: `Email Support`,
    text14: `You can mail with our Support in all languages`,
    text15: `Call Support`,
    text16: `Central Support Hotline`,
  },
  // My Posts
  my_feed: {
    text1: `posts`,
  },
  // Global Search
  global_search: {
    text1: `Result`,
    text2: `No data found`,
    text3: `Search for Leaders...`,
    text4: `Search for CFD, stocks, commodities...`,
    text5: `Leaders`,
    text6: `Assets`,
  },
  // App Loader
  app_loader: {
    text1: `ZuluTrade`,
  },
  // Expand Menu
  expand_menu: {
    text1: `Total portfolio value`,
    text2: `Deposit`,
    text3: `Withdraw`,
    text4: `Complete Onboarding Process`,
    text5: `Upgrade`,
    text6: `Copying`,
    text7: `Following`,
    text8: `OPEN TRADES`,
    text9: `Portfolio overview`,
    text10: `Total PnL`,
    text11: `ROI`,
    text12: `arrowDown`,
    text13: `Add Leader`,
    text14: `arrowUp`,
    text15: `The graph will be displayed one day after...`,
    text16: `Graph Demo`,
  },
  // Leader Menu
  leader_menu: {
    text1: `COPYING`,
    text2: `View Profile`,
    text3: `FOLLOWING`,
    text4: `Total portfolio value`,
    text5: `Deposit`,
    text6: `Withdraw`,
    text7: `Rewards`,
    text8: `$56`,
    text9: `Total earned`,
    text10: `$24`,
    text11: `Pending`,
    text12: `zulu`,
  },
  //Login Popup
  login_popup: {
    text1: `Please login or sign up...`,
    text2: `You must be authenticated to add Leaders in your portfolio. If you already have a Real or a Demo Investor account with ZuluTrade please login, otherwise you may create a new ZuluTrade account!`,
    text3: `Login`,
    text4: `Create Account`,
  },
  // Old User
  old_user: {
    text1: `Important Notice`,
    text2: `Free CopyTrading`,
    text3: `Congratulations! As an existing user of ZuluTrade, you have been awarded a `,
    text4: `‘Free Subscription Plan’`,
    text5: `With this plan you can copy `,
    text6: `unlimited strategies`,
    text7: ` from all of your `,
    text8: `linked accounts`,
    text9: ` and also make use of the added `,
    text10: `social intelligence features `,
    text11: `Enjoy the exclusive benefit while it lasts!`,
    text12: `Copy Trading Now!`,
  },
  // Page not found
  page_found: {
    text1: `page not found`,
    text2: `The page you are looking for might have been removed, had its name changed, or is temporarily unavailable`,
    text3: `Back to homepage`,
  },
  // News Media
  news_media: {
    text1: `News and `,
    text2: `Media`,
    text3: `Read More`,
  },

  // Pricing page
  pricing: {
    content1: `Subscription`,
    content1a: `Plan`,
    content2a: `Zero`,
    content2b: `subscription fee when you register yourself with one of our`,
    content2c: `Integrated Brokers`,
    content2d: `or selected `,
    content2e: `Co-Branded Brokers.`,
    content3: `To see the list of`,
    content4: `Subscription Plan Table`,
    content5: `1 Month`,
    content6: `3 Months`,
    content7: `6 Months`,
    content8: `12 Months`,
    content9: `Price / Leader Strategy / Month`,
    content10: `$10 / Leader Strategy / Month`,
    content11: `$9.5 / Leader Strategy / Month`,
    content12: `$9 / Leader Strategy / Month`,
    content13: `$8.3 / Leader Strategy / Month`,
    content14: `Discount/Month`,
    content15: `Save 5%`,
    content16: `Save 10%`,
    content17: `Save 17%`,
    content18: `Copy-Trade Limits`,
    content19: `You may copy-trade a number of Leaders’ Strategies equal to the available slots provided in your subscription plan.`,
    content20: `Leader Strategy changes`,
    content21: `As many as the plan’s Leader strategy slots`,
    content22: `To see the list of all other brokers that are currently available on ZuluTrade, `,
    content23: `If you cannot find your broker on the list, simply make a request to our support and we will add your broker for free and you can pay subscription fees to avail all our services in your preferred broker account.`,
    content24: `Learn how much you pay in the`,
    content24a: `Subscription Plan`,
    content25: `It's plain and straightforward. Simply choose`,
    content26: `1`,
    content27: `Your Broker`,
    content28: `2`,
    content29: `The number of Leader Strategies you want to CopyTrade per month`,
    content30: `3`,
    content31: `Your auto-renewal option for your recurring subscription (1, 3, 6 or 12 months)`,
    content32: `Select Broker`,
    content33: `Search Broker`,
    content34: `Fxview`,
    content35: `Enter Leader Strategies`,
    content36: `Calculate Now`,
    content37: `6 Months`,
    content38: ` / Leader Strategies / Month`,
    content39: `Amount/Discount:-`,
    content40: `10%`,
    content41: `Ready to Open Your Account/ Link Your Existing Account with this Broker ?`,
    content411: `Click Here`,
    content42: `*You pay `,
    content42a: `No Subscription Fee `,
    content42b: `when you `,
    content42c: `sign-up`,
    content42d: `with one of our Integrated Brokers or selected Co-branded brokers.`,
    content43: `*Existing users of ZuluTrade can`,
    content43a: `continue to access`,
    content43b: `Copy Trading without any charge of subscription fee.`,
    content44: ` Benefits of the`,
    content44a: `Subscription Plan`,
    content45: `It`,
    content45a: `eliminates platform fees`,
    content45b: `based on transactions.`,
    // 'content46' : `The old pricing model was based on volume. The volume-based trade always incentivises the traders to trade more. And the more the volume the Leader trades, the more the incentives the Leader gets paid. Hence, paying any service based on volume results in a conflict of interest with the client.`,
    content47: `Pay only`,
    content47a: `$10`,
    content47b: `per month for each Leader Strategy. Plus, benefit from our`,
    content47c: `discount structure`,
    content48: `Unlock the`,
    content481: `Quarterly Subscription: Save `,
    content482: `Semi-Annual Subscription: Save `,
    content483: `Annual Subscription: Save `,
    save1: `5%.`,
    save2: `10%.`,
    save3: `17%.`,
    content48a: `Zero Subscription Fee`,
    content48b: `when you sign-up with one of our Integrated Brokers or selected Co-branded Brokers. They`,
    content49: `cover the costs`,
    content49a: `of your signal providers!`,
    content52: `Select Subscription Month`,
    content53: `Frequently Asked Questions (FAQs)`,
    content54: `A Leader Strategy is a fixed trading plan that is designed to go long or short in markets. Depending on Leader's choice, it's usually based on predefined rules used to make trading decisions.`,
    content55: `What is a Leader Strategy?`,
    content56: `What are the basic rules of the Subscription Plan?`,
    content57: `You will need to have an active Subscription Plan in order to be able to copy trades. Once a plan is active, you will be able to copy at the same time as many leader strategies per month as you have chosen while activating the Subscription Plan during the onboarding process.`,
    content58: `Paid Subscription Plans are activated on a user account  level and can be used by all your Live Trading Accounts. Strategies copied by Demo Trading Accounts do not count.`,
    content59: `Initial Billing cycle will be based on the plan's starting date.`,
    content60: `Plans are always recurring, the billing cycle will be renewed each month on the date of subscription.`,
    content61: `Charging is prepaid and the recurring payment gets processed at the renewal/start of each billing cycle.`,
    content62: `You will have the option to set up auto-renewal for periods of 1,3,6 or 12 months.`,
    content63: `How do I upgrade my Plan?`,
    content64: `Upgrade is a plan change where you select more slots, even if the pre-paid period gets decreased.`,
    content65: `When upgrading, the new plan will get activated immediately and billed at the start of the next billing cycle.`,
    content66: `The billing date will change based on the remaining balance of the last payment and the slots of the new plan.`,
    content67: `Upgrade Plan Example:`,
    content68: `You register for a $10/month plan on 01/10 and upgrades to a $30/month plan on 20/10. An immediate charge of $30 will get processed for a temporary billing cycle from 20/10 to 23/11 (34 days) to adjust for the previous remaining balance.`,
    content69: `Temporary billing cycle is equal to 1 month + a proration of the previous plan's remaining balance = 1m + 4d.`,
    content70: `Proration = ($10*(31d-20d)/31d)/$30*30d = 3.54d ≈ 4d`,
    content71: `From 23/11 you will be charged the plan's price of $30, on the 23rd of each month (billing date).`,
    content72: `How do I downgrade my Plan?`,
    content73: `Downgrade is a plan change where you select fewer slots, even if the pre-paid period gets increased. (e.g.: You have a 1-month plan of 5 slots so you pay $50/month. You decide to change the plan to 12-months and 4 slots so you will pay $398.4/month.)`,
    content74: `This is considered a downgrade even if the recurring payment is higher.`,
    content75: `In case the Investor is copying more Leaders than the new plan's slots at the time the new plan gets activated, then some of the oldest copied Leaders may get removed automatically so that you have an equal number of Leaders and slots.`,
    content76: `You will stop receiving signals for new trades from the leaders.`,
    content77: `Open trades will not get closed and they will still receive update signals until they are closed.`,
    content78: `You will be informed by notification/email/SMS about that and will be prompted to remove Leaders :`,
    content79: `At the time of choosing to downgrade`,
    content80: `7 days before the end of billing cycle`,
    content81: `3 days before the end of billing cycle`,
    content82: `At the time of downgrade if leaders were removed by the system`,
    content83: `Downgrade Plan Mid-Cycle example:`,
    content84: `In the previous example (the one you upgraded) you wish to downgrade from the $30/month plan (3 slots) to the $10/month (1 slot) plan on 25/10.`,
    content85: `The $30/month (3 slots) plan will remain active (with the 3 slots) until the end of the current billing cycle (23/11).`,
    content86: `At the start of the next cycle (23/11) you will get charged $10 dollars for the upcoming month and the plan will be downgraded automatically.`,
    content87: `On 23/11, 2 of the 3 copied Leaders will get removed automatically.`,
    content88: `How do I Cancel my Subscription Plan?`,
    content89: `If you go to plans and choose to cancel, then the Active Plan will be canceled at the end of the current billing cycle.`,
    content90: `You will have access to all features of your plans until the end of the billing cycle and will not get charged again on the next cycle.`,
    content93: `You will be informed by notification/email/SMS about that:`,
    content94: `At the time of cancellation`,
    content95: `7 days before the end of the billing cycle`,
    content96: `3 days before the end of the billing cycle`,
    content97: `At the time of cancellation if leaders were removed by the system`,
    content98: `What happens if a recurring payment fails to go through?`,
    content99: `Plans will never expire as long as the recurring payment gets processed successfully.`,
    content100: `If a payment fails on the billing date the following will happen:`,
    content101: `A notification will be sent to you, to inform you about the failed payment, and that the plan has expired but we will keep your plan active for 3 more days. Copying new leaders will not be allowed until successful payment.`,
    content102: `One day after the billing day, a new payment will be requested. If it fails, one more notification/email/SMS will be sent to you to inform you that you plan is active for 2 more days.`,
    content103: `On the third day a final attempt for payment will be made.`,
    content104: `If it fails then, then all the Leaders that you copy will be removed automatically so that you will stop receiving signals for new trades.`,
    content105: `However the already open trades by Leaders will not get closed. These will remain open and continue to receive only "closed" signals.`,
    content106: `What if I already have a Brokerage Account?`,
    content108: `First register yourself to create a ZuluTrade account`,
    content109: `From your Profile menu, go to ‘Trading Account’.`,
    content1099: `here`,
    content110: `Click on ‘Add Trading Account’.`,
    content111: `Click ‘Yes’ when asked ‘Do you have an existing broker account?’`,
    content112: `Select your platform and broker.`,
    content112a: `Enter your existing broker account server, username and other details.`,
    content112b: `Click on ‘Connect Account’.`,
    content112c: `Once your account is connected, follow the further steps based on your selected broker to complete the suitability test and start copytrading.`,
    content113: `What happens if my broker is not listed as an Integrated Broker or a Co-Branded Broker?`,
    content114: `If you wish to open an account with a broker who is not integrated with us or not listed as a Co-branded Broker, you can follow the following steps to open your account with a Standard broker.`,
    content115: `You can go online and find the broker of your choice.`,
    content116: `Create your account with the broker.`,
    content117: `Come back to our website and add the details of your broker. The broker will be available within 24 hours.`,
    content118: `Now open your account with the same email on our website.`,
    content119: `An authentication email will be sent to your email /account listed to create the account.`,
    content120: `Choose your subscription plan.`,
    content121: `What is the Subscription Plan for an Integrated Brokerage Account?`,
    content122: `Subscription is free.`,
    content123: `You get connected within minutes and take advantage of all the platform features at zero Subscription Fee.`,
    content124: `No hidden fees, or charges.`,
    content125: `What is the Subscription Plan for a Co-Branded Brokerage Account?`,
    content126: `Open/link your brokerage account within minutes.`,
    content127: `Choose your subscription plan. Subscription Fee  may or may not be applicable depending on the linked broker.`,
    content128: `You can buy a subscription plan for $10/month per Leader Strategy. Discounts apply for quarterly, semi-annual and annual plans.`,
    content129: `There will be no additional charges imposed by ZuluTrade on top of your subscription. You will be charged trading fees from your broker only.`,
    content130: `You get the advantage of all the platform features of ZuluTrade along with your co-branded broker platform features.`,
    contentFaq: `What is Integrated broker?`,
    contentFaq1: `Integrated Broker : A broker that has been integrated in the ZuluTrade platform allowing  copy trading by accessing and managing your account with the broker directly from Zulu Platform.`,
    contentFaq2: `What is Co-branded broker?`,
    contentFaq3: `Co-Branded Broker : A cooperating broker with a co-branded ZuluTrade website with a dedicated environment designed specifically for the broker and its customers. In this case, you will have to open the account independently at the broker site and then  to ZuluTrade platform to connect your account.`,
    contentFaq4: `What is Standard broker?`,
    contentFaq5: `Standard Broker : Any broker which offers trading accounts on MT4, MT5, ActTrader, XOH. Using your account with any broker from anywhere in the world (subject to any applicable geographical restrictions for regulatory purposes), you may join and use ZuluTrade services.`,
    content131: `What is the Subscription Plan for a Standard Brokerage Account?`,
    content132: `Open your standard brokerage account and link it with ZuluTrade.`,
    content133: `Choose your Subscription Plan.`,
    content134: `You can buy a Subscription Plan for $10/month per Leader Strategy. Discounts apply for quarterly, semi-annual and annual plans.`,
    content136: `You can take advantage of all of your standard brokerage account platform.`,
    content137: `Manual trading with ZuluTrade is not available if you are registered with a Standard Broker.`,
    content138: `Free CopyTrading`,
    content139: `Integrated Brokers`,
    content140: `and`,
    content141: `Co-Branded Brokers`,
    content142: `that offer Copy Trading with zero Subscription Fee`,
    content143: `click here`,
    content144: `Do I have to pay for using ZuluTrade?`,
    content145: `You can use the Zulutrade Platform without paying any subscription fees. Users of the platform do not have to pay to ZuluTrade any fees.`,
  },
  // Second File
  deleteAccount: {
    text1: `How to Delete Account`,
    text2: `Log in to your account`,
    text3: `Click on Profile Icon`,
    text4: `Click on Settings`,
    text5: `Click on Delete Account`,
    text6: `Click on Proceed to Delete Account`,
    text7: `or`,
    text8: `In order to process the deletion of your account Please send an email to`,
    text9: `support@zulutrade.com`,
    text10: `with title`,
    text11: `Delete Account`,
    text12: `Kindly note that due to regulatory obligations ZuluTrade is legally obliged to maintain data for the Live account for a period of time as per respective licenses for EU and Global regions`,
  },

  // Second File
  changes: {
    text1: `The user can't change his number more than twice in the last 24 hours.`,
    text2: `Users can resend OTP up to 3 times per day.`,
    text3: `If the user phone number is verified After that user can't change the phone number within 72 hours.`,
    text4: `Resend OTP cannot be sent within 1 minute.`,
    text5: `Important Instructions`,
    text6: `You got Unlimited Free CopyTrading`,
    text7: `You have been granted unlimited free copytrading. It is automatically registered into your trading account.`,
    text8: `Publish your Leader's performance!`,
    text9: `Select one of the following Leader to generate the HTML code that you will need to add in your website, blog or favourite forum.`,
    text9a: `blog or favourite forum.`,
    text10: `Select Leader`,
    text11: `Horizontal Widget`,
    text12: `Copy & paste the following HTML code exactly in the place you want to show the banner in a forum/blog or your webpage.`,
    text13: `Widget Preview`,
    text14: `Widget Code`,
    text15: `Vertical Widget`,
    text16: `Copy & paste the following HTML code exactly in the place you want to show the banner in a forum/blog or your webpage.`,
    text17: `Widget Preview`,
    text18: `Widget Code`,
    text19: `Integrated/Co-Branded Brokers`,
    text20: `Co-Branded Brokers`,
    text21: `Free CopyTrading`,
    text22: `Yes`,
    text23: `No`,
    text24: `Region`,
    text25: `No Data Found`,
    text26: `Please select a Broker`,
    text27: `Select Months`,
    text28: `Trading Tools`,
    text29: `Close`,
    text30: `Are you sure you want to close trade ?`,
    text31: `No`,
    text32: `Close Trade`,
    text33: `All Brokers`,
    text34: `Notifications Settings`,
    text35: `Resubmit Suitability`,
    text36: `Portfolio and Traders`,
    text37: `Account Statements`,
    text38: `Email`,
    text39: `Push Notification`,
    text40: `Update`,
    text41: `Reset`,
    text42: `Country field is required`,
    text43: `You can't copy any leader`,
    text44: `Available Capital :`,
    text45: `Phone number is required`,
    text46: `Account Settings`,
    text47: `You have reached a Stop Out`,
    text48: `Phone number is required.`,
    text49: `Enter your phone number`,
    text50: `When a live trader follows a signal provider`,
    text51: `When a new rating comment is posted on a signal provider that a user have rated before`,
    text52: `Υou have updated your portfolio settings`,
    text53: `When a signal provider has a new message in his THI`,
    text54: `When a signal provider has Worst trade > 50 pips from last worst trade`,
    text55: `When a signal provider has stopped trading his live account in ZuluTrade`,
    text56: `When a signal provider has a 30% change is his avg trade time`,
    text57: `When a signal provider (MT4) account is disconnected`,
    text58: `-700 pips warning to providers.`,
    text59: `When a signal provider has requested updates from his followers`,
    text60: `The 'Worst Trade' of a Trader whom you follow has registered a new negative record`,
    text61: `A Real Trader whom you follow has switched his trading platform to Demo`,
    text62: `The 'Average Trade Time' of a Trader whom you follow has changed significantly`,
    text63: `When a signal provider (MT4) account is disconnected`,
    text64: `-700 pips warning to providers.`,
    text65: `A Trader whom you follow has updated his/her Status`,
    text66: `When a new request update is posted on a provider`,
    text67: `When a new comment is posted for a provider`,
    text68: `A user liked your comment`,
    text69: `When a new rating is posted on a provider`,
    text70: `When a provider status update gets a reply`,
    text71: `The Trader replied to your comment`,
    text72: `When a status update gets a like`,
    text73: `When a new reply to a comment is posted / receiver is the provider`,
    text74: `News from trader desk`,
    text75: `News from trading desk`,
    text76: `For new blog postsBackend`,
    text77: `For provider's EU removal`,
    text78: `For new provider's worst DD`,
    text79: `When a follower comments to provider`,
    text80: `When a follower makes a post`,
    text81: `New features, newsletters, platform upgrades and other tips`,
    text82: `Daily newletter to promote new signal providers on ZuluTrade`,
    text83: `When user wants to suggest another follower to become signal provider`,
    text84: `Daily Account Statement`,
    text85: `Weekly Account Statement`,
    text86: `Send a push notification to mobile devices to update their ZuluTrade Application`,
    text87: `A new position has been opened in your account`,
    text88: `A position has been closed in your account`,
    text89: `Notify user about a deposit on his broker account`,
    text90: `Notify user about a withdrawal on his broker account`,
    text91: `70 percent of capital allowance notification`,
    text92: `A Trader whom you follow has updated his/her Recommended Settings`,
    text93: ` A Margin Call has been issued by your Broker`,
  },
};
