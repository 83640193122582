import { __assign } from "tslib";
var StompJs = require("@stomp/stompjs");
import moment from "moment";
import { myStore } from "@/store/pinia-store";
var store = {};
var PriceFeed = /** @class */ (function () {
    function PriceFeed() {
        var _this = this;
        this.activate = function () {
            if (!_this.socket.connected) {
                _this.socket.activate();
            }
        };
        this.deactivate = function () {
            _this.socket.deactivate();
        };
        // this.topic = topic
        // this.url = url
        store = myStore();
        this.start();
    }
    PriceFeed.getInstance = function () {
        return this._instance || (this._instance = new this());
    };
    PriceFeed.prototype.start = function () {
        var _this = this;
        this.socket = new StompJs.Client({
            brokerURL: "" + process.env.VUE_APP_MARKET_URL,
            connectHeaders: {},
            // debug: function () {
            // 	console.log('STOMP: ' + str);
            // },
            forceBinaryWSFrames: true,
            appendMissingNULLonIncoming: true,
            reconnectDelay: 200,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: function (e) {
                _this.socket.subscribe("/topic/feed/group/1000", function (message) {
                    if (Object.keys(JSON.parse(message.body)).length) {
                        var isins_1 = JSON.parse(message.body);
                        if (!store.lastSocketUpdate || moment().diff(store.lastSocketUpdate, "milliseconds") > 1000) {
                            store.$patch({ lastSocketUpdate: new Date() });
                            // store.$patch({ allPrices: JSON.parse(message.body) });
                            store.$patch(function (state) {
                                for (var key in isins_1) {
                                    var d = state.allPrices[key] || {};
                                    state.allPrices[key] = __assign(__assign({}, isins_1[key]), { 'o_b': (d.buyPrice || 0), 'o_s': (d.sellPrice || 0) });
                                }
                            });
                        }
                    }
                });
            },
            onStompError: function (frame) {
                console.log("Broker reported error: " + frame.headers["message"]);
                console.log("Additional details: " + frame.body);
            },
        });
        //this.socket.activate();
    };
    return PriceFeed;
}());
export default PriceFeed;
