import { __assign } from "tslib";
var StompJs = require("@stomp/stompjs");
import moment from "moment";
import { myStore } from "@/store/pinia-store";
import { useToast } from "vue-toastification";
import * as Common from '../commonFunctions.js';
var store = {};
var toast = {};
var PriceFeed = /** @class */ (function () {
    function PriceFeed() {
        var _this = this;
        this.subscribeTrades = function () {
            _this.tradeData = _this.socket.subscribe('/user/topic/follower/v2/opentrades', function (message) {
                var _a, _b, _c;
                try {
                    var data = JSON.parse(message.body);
                    if (data === null || data === void 0 ? void 0 : data.brokerAccountId) {
                        if (parseInt(data === null || data === void 0 ? void 0 : data.brokerAccountId) === parseInt((_a = store.userSelectedAccount) === null || _a === void 0 ? void 0 : _a.brokerAccountId)) {
                            var openOrders = ((_b = data === null || data === void 0 ? void 0 : data.openTrades) === null || _b === void 0 ? void 0 : _b.filter(function (i) { return i.market; })) || [];
                            var pendingOrders = ((_c = data === null || data === void 0 ? void 0 : data.openTrades) === null || _c === void 0 ? void 0 : _c.filter(function (i) { return !i.market; })) || [];
                            store.$patch({ providerOpenTrades: { 'content': openOrders }, providerPendingTrades: { 'content': pendingOrders } });
                        }
                    }
                }
                catch (e) {
                    console.log('position catch', e);
                }
            });
        };
        this.subscribeMarket = function () {
            var topic = "/topic/feed/group/";
            if (store.userTradingAccountsList.length) {
                if (store.allTypeAccount) {
                    var data = [];
                    if (store.allTypeAccount == 'ALL_DEMO') {
                        data = store.userTradingAccountsList.filter(function (i) { return i.demo === true; });
                    }
                    else if (store.allTypeAccount == 'ALL_LIVE') {
                        data = store.userTradingAccountsList.filter(function (i) { return i.demo === false; });
                    }
                    if (data.length) {
                        topic = topic.concat("" + data[0].feedGroupId);
                    }
                }
                else {
                    topic = topic.concat("" + store.userSelectedAccount.feedGroupId);
                }
            }
            _this.marketData = _this.socket.subscribe(topic, function (message) {
                if (Object.keys(JSON.parse(message.body)).length) {
                    var isins_1 = JSON.parse(message.body);
                    if (!store.lastSocketUpdate || moment().diff(store.lastSocketUpdate, "milliseconds") > 1000) {
                        store.$patch({ lastSocketUpdate: new Date() });
                        // store.$patch({ allPrices: JSON.parse(message.body) });
                        store.$patch(function (state) {
                            for (var key in isins_1) {
                                var d = state.allPrices[key] || {};
                                state.allPrices[key] = __assign(__assign({}, isins_1[key]), { 'o_b': (d.buyPrice || 0), 'o_s': (d.sellPrice || 0) });
                            }
                        });
                    }
                }
            });
        };
        this.subscribeMessages = function () {
            _this.messagesData = _this.socket.subscribe('/user/topic/usermessages', function (message) {
                if (Object.keys(JSON.parse(message.body)).length) {
                    var data = JSON.parse(message.body);
                    if (Object.keys(data).length) {
                        toast.success(Common.htmlToText(data.message));
                        //const data =  JSON.parse(message.body);
                        var json = {
                            'id': '',
                            'brokerAccountId': data.brokerAccountId,
                            'dateRead': '',
                            'dateTime': data.dateTime,
                            'message': data.message,
                            'providerTicket': data.providerTicket,
                            'signalId': '',
                            'userMessageTypeId': '',
                            'userMessageValues': ''
                        };
                        var topNotif = store.usertopNotifications.content || [];
                        topNotif = topNotif.unshift(json);
                        var allNotif = store.userNotificationList.content || [];
                        allNotif = allNotif.unshift(json);
                        //store.$patch({ usertopNotifications : {'content' : store.usertopNotifications.content.push(json)}});
                        //store.$patch({ userNotificationList: {'content' : store.userNotificationList.content.unshift(data)}});
                    }
                }
                else {
                    store.$patch({ usertopNotifications: { 'content': [] } });
                    store.$patch({ userNotificationList: { 'content': [] } });
                }
            });
        };
        this.subscribeLinkAccount = function () {
            _this.linkAccount = _this.socket.subscribe('/user/topic/follower/linking', function (message) {
                if (Object.keys(JSON.parse(message.body)).length) {
                    var data = JSON.parse(message.body);
                    if (data.success) {
                        toast.success('Your Trading Account has been connected successfully');
                        try {
                            window.gtag('event', 'Live_ac_verified');
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                        var form = {
                            'grant_type': 'refresh_token',
                            'refresh_token': store.user.refresh_token
                        };
                        store.refreshToken(form, false, 'basic').then(function (response) {
                            if (response.access_token) {
                                store.callCustomerDetail({}, false);
                            }
                        });
                    }
                    else {
                        toast.success('Could not connect your Trading Account');
                    }
                }
            });
        };
        this.unsubscribe = function () {
            if (_this.tradeData) {
                _this.tradeData.unsubscribe();
                _this.subscribeTrades();
            }
            if (_this.marketData) {
                _this.marketData.unsubscribe();
                _this.subscribeMarket();
            }
            if (_this.messagesData) {
                _this.messagesData.unsubscribe();
                _this.subscribeMessages();
            }
            if (_this.linkAccount) {
                _this.linkAccount.unsubscribe();
                _this.subscribeLinkAccount();
            }
        };
        this.activate = function () {
            if (!_this.socket.connected) {
                _this.socket.activate();
            }
        };
        this.deactivate = function () {
            _this.socket.deactivate();
        };
        // this.topic = topic
        // this.url = url
        store = myStore();
        toast = useToast();
        this.start();
    }
    PriceFeed.getInstance = function () {
        return this._instance || (this._instance = new this());
    };
    PriceFeed.prototype.start = function () {
        var _this = this;
        this.socket = new StompJs.Client({
            brokerURL: process.env.VUE_APP_MARKET_URL + "?access_token=" + store.user.access_token,
            connectHeaders: {},
            debug: function () {
                //console.log('STOMP: ' + str);
            },
            forceBinaryWSFrames: true,
            appendMissingNULLonIncoming: true,
            reconnectDelay: 200,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: function (e) {
                _this.subscribeTrades();
                _this.subscribeMarket();
                _this.subscribeMessages();
                _this.subscribeLinkAccount();
            },
            onStompError: function (frame) {
                console.log("Broker reported error: " + frame.headers["message"]);
                console.log("Additional details: " + frame.body);
            },
        });
        //this.socket.activate();
    };
    return PriceFeed;
}());
export default PriceFeed;
